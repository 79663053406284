import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { actionCreators } from "../../../store/AppStore";
import UserGroupMemberGrid from './UserGroupMemberGrid';
import { post } from "../../CallApi";
import { 
    convertInfoFromAWS,
    convertMembersOfAllGroups,
    convertMembersToCallSaveAPI,
    convertUserGroupMemberData
} from "../../../utils/DataConverter";
import { Message, UserGroupMemberModel } from "../../../models/Index";
import AlertDialog from "../../common/AlertDialog";
import UserGroupMemberDialog from '../../dialogs/UserGroupMemberDialog';
import { DATA_STATUS, NEW_ID_NOT_DEFINED } from '../../../statics';
import { Path } from '../../../path';
import { ROLE_ADMIN } from '../../../models/Constants';

type UserGroupMemberListProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
    
export const UserGroupMemberList = (props: UserGroupMemberListProps) => {
    const { user, onShowMessage } = props;
    
    let navigate = useNavigate();
    const { userGroupId } = useParams();
    const gridRef = useRef() as any;

    const isSystemAdmin = user?.userRole === ROLE_ADMIN

    const [loadingFlag, setLoadingFlag] = useState(false);
    const [allUserList, setAllUserList] = useState<any[]>([]);
    const [membersOfAllGroups, setMembersOfAllGroups] = useState<any[]>([]);
    const [groupMemberList, setGroupMemberList] = useState<UserGroupMemberModel[]>([]);
    const [memberSelected, setMemberSelected] = useState<UserGroupMemberModel|null>(null);
    const defaultMember: UserGroupMemberModel = {
        userGroupId: Number(userGroupId!),
        userId: NEW_ID_NOT_DEFINED,
        sub: "",
        username: "",
        email: "",
        fullName: "",
        permission: "standard",
        joinedDatetime: "",
        lastUpdateDatetime: "",
        status: DATA_STATUS.ADD
    };
    
    const [openUserGroupMemberDialog, setOpenUserGroupMemberDialog] = useState(false);
    const [isAddMember, setIsAddMember] = useState(true); // add/edit member

    const [groupName, setGroupName] = useState("");
    const [messageDelete, setMessageDelete] = useState("");
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    
    useEffect(() => { handleGetMemberData() },[])
    
    // #region handleMethod
    const handleGetMemberData = async () => {
        setLoadingFlag(true);

        const params: any = {
            user_id: user?.userId,
            user_group_id: userGroupId,
        };
        const url = "/user-group/get-group-member";
        const result = await post(url, params);
        if (result.success) {
            handleConvertMembersDataAndDisplay(result.data.data);
        } else {
            navigate(Path.UserGroupList)
            onShowMessage({
                type: "error",
                title: "ユーザー",
                body: "ユーザーを取得できません。"
            });
        }
        setMemberSelected(null);
        setLoadingFlag(false);
    }

    const handleConvertMembersDataAndDisplay = (data: any) => {
        setGroupName(data.userGroupName);
        setMembersOfAllGroups(convertMembersOfAllGroups(data.membersOfAllGroups))

        const userListConverted = convertInfoFromAWS(data.userList);
        setAllUserList(userListConverted);

        const memberList = convertUserGroupMemberData(data.userGroupMembers, userListConverted);
        handleDisplayMembers(memberList);
    }

    const handleDisplayMembers = (memberList: UserGroupMemberModel[]) => {
        setGroupMemberList(memberList);
        if (gridRef && gridRef.current) {
            gridRef.current.setRowData(memberList.filter(e => e.status !== DATA_STATUS.DELETE));
        }
    }
    
    const handleAdd = () => {
        setIsAddMember(true);
        setOpenUserGroupMemberDialog(true);
    }

    const handleEdit = () => {
        setIsAddMember(false);
        setOpenUserGroupMemberDialog(true);
    }

    const handleOK = (newValue: any) => {
        if (isAddMember) {
            addMember(newValue);
        } else {
            editMember(newValue);
        }
    }

    const addMember = (newValue: UserGroupMemberModel) => {
        let oldValue = groupMemberList.find(e => e.sub == newValue.sub);
        let memberList: UserGroupMemberModel[] = []
        if (oldValue === undefined){
            // add new
            memberList = [...groupMemberList, newValue];
        } else {
            // Add back deleted data
            memberList = groupMemberList.map(e => {
                if (e.sub === newValue.sub) {
                    e.permission = newValue.permission;
                    e.status = DATA_STATUS.UPDATE;
                }
                return e;
            });
        }

        handleDisplayMembers(memberList);
        setMemberSelected(null);
        setOpenUserGroupMemberDialog(false);
    }

    const editMember = (newValue: any) => {
        if (memberSelected !== null) {
            const memberList = groupMemberList.map(e => {
                if (e.sub === newValue.sub) return newValue;
                return e;
            });

            handleDisplayMembers(memberList);
        }
        setMemberSelected(null);
        setOpenUserGroupMemberDialog(false);
    }

    const handleCancelDialog = () => {
        setOpenUserGroupMemberDialog(false);
    }

    const handleDelete = () => {
        if (memberSelected === null) return;
        const msg = "ユーザー名「" + memberSelected.fullName + "」を削除しますか？";
        setMessageDelete(msg);
        setOpenDeleteDialog(true);
    }

    const deleteMember = () => {
        if (memberSelected !== null) {
            let memberList: UserGroupMemberModel[] = []
            if (memberSelected.userId === NEW_ID_NOT_DEFINED) {
                memberList = groupMemberList.filter(e => e.sub !== memberSelected.sub);
            } else {
                memberList = groupMemberList.map(e => {
                    if (e.sub === memberSelected.sub) e.status = DATA_STATUS.DELETE
                    return e
                });
            }

            handleDisplayMembers(memberList);
        }
        setMemberSelected(null);
        setOpenDeleteDialog(false);
    }

    const handleSelectMember = (e: any) => {
        setMemberSelected(e.data);
    }

    const handleSaveMembers = async () => {
        const memberList = convertMembersToCallSaveAPI(groupMemberList);
        if (memberList.length === 0) return;

        setLoadingFlag(true);
        const params: any = {
            user_id: user?.userId,
            user_group_id: userGroupId,
            member_list: memberList
        };
        const url = "/user-group/save-group-member";
        const result = await post(url, params);
        if (result.success) {
            handleConvertMembersDataAndDisplay(result.data.data);
            onShowMessage({
                type: "info",
                title: "グループ編集",
                body: "グループ編集が成功しました。"
            });
        } else {
            onShowMessage({
                type: "error",
                title: "ユーザーリストを保存",
                body: "ユーザーリストの保存に失敗しました。"
            });
        }
        setMemberSelected(null);
        setLoadingFlag(false);
    }

    const handleBackToPreviousPage = () => {
        navigate(Path.UserGroupList);
    }
    //  #endregion handleMethod

    return (<>
        {loadingFlag && (
            <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                <CircularProgress />
            </div>
        )}
        {openUserGroupMemberDialog &&
            <UserGroupMemberDialog
                mode={isAddMember ? "ADD" : "UPDATE"}
                data={isAddMember ? defaultMember : memberSelected!}
                membersOfAllGroups={membersOfAllGroups}
                allUserList={allUserList}
                onOK={handleOK}
                onCancel={handleCancelDialog}
            />
        }
        {openDeleteDialog &&
            <AlertDialog
                title={"ユーザー削除"} 
                message={messageDelete} 
                isOpen={true} 
                onOK={deleteMember} 
                onCancel={() => setOpenDeleteDialog(false)} 
            />
        }
        <div style={{ height: '100%', width: '100%', marginTop: '10px' }}>
            <Stack style={{ color: 'MenuText' }}>
                <Typography variant="h4">グループ編集</Typography>
            </Stack>
            
            <div>
                <fieldset 
                    style={{
                        border:'0.5px solid grey',
                        height:'calc(100vh - 220px)',
                        width:'calc(100% - 36px)'
                    }}
                >
                    <Stack width={"50%"} mt={1}>
                        <TextField
                            type="text"
                            label="グループ名"
                            value={groupName}
                            size="small"
                            InputProps={{ readOnly: true, sx: { backgroundColor: 'floralwhite' }}}
                        />
                    </Stack>
                    <Stack direction="row" justifyContent={"flex-end"} my={1}>
                        <Stack direction={{ xs: 'column', sm: 'row' }} ml={2} spacing={1}>
                            <Button variant="contained" size="small" onClick={handleAdd} disabled={!isSystemAdmin}>{<AddIcon />}</Button>
                            <Button variant="contained" size="small" onClick={handleEdit} disabled={memberSelected === null}>{<EditIcon />}</Button>
                            <Button variant="contained" size="small" onClick={handleDelete} disabled={!isSystemAdmin || memberSelected === null}>{<ClearIcon />} </Button>
                        </Stack>
                    </Stack>
                    <Stack>
                        <UserGroupMemberGrid
                            ref={gridRef}
                            handleSelectMember={handleSelectMember}
                        />
                    </Stack>
                </fieldset>
                <Stack direction='row' justifyContent="flex-end" spacing={2} mt={2}>
                    <Button variant="contained" onClick={handleSaveMembers}>保存</Button>
                    <Button color="inherit" variant="contained" onClick={handleBackToPreviousPage}>キャンセル</Button>
                </Stack>
            </div>
        </div>
    </>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    user: state.app.user,
})

const mapDispatchToProps = (dispatch: any) => ({
    onShowMessage: (message: Message) => dispatch(actionCreators.showMessage(message)),
});

export default connect(
    mapStateToProps, mapDispatchToProps
)(UserGroupMemberList as any);
