import { ToggleButton, ToggleButtonGroup } from "@mui/lab";
import { Button, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { OptionItem } from "../../../models/Index";
import { roleOptions, userGroupRoleOptions } from "../../../statics";

export type UsersAddFormProps = {
    groupList: any[];
    onOK: (data: any) => void;
    onCancel: () => void;
};

// 検索フォーム
export interface ItemForm {
    custom_management_no:string;
    custom_company_name:string,
    custom_zip_code:string,
    address:string,
    family_name:string,
    given_name:string,
    name: string;
    custom_role: OptionItem | null;
    email: string;
    phone_number: string;
    custom_plan: string;
    custom_expiration_start_date: string;
    custom_expiration_end_date: string;
    custom_mfa: string;
    account_status: boolean;
    custom_annual_support:string;
    custom_serial_no:string;
    custom_memo:string;
    custom_mfa_system:string;
    send_invitation_mail_flag: boolean;
    set_email_auto_verify_flag: boolean;
    temporary_pass_manual_setting_flag: boolean;
    temporary_pass_manual_setting_value: string;
    DesiredDeliveryMediums: string;
    group_id:number|null;
    group_permission:string|null;
}

// 初期値設定
const initialValue: ItemForm = {
    custom_management_no:"",
    custom_company_name:"",
    custom_zip_code:"",
    address:"",
    family_name:"",
    given_name:"",
    name: "",
    custom_role: null,
    email: "",
    phone_number: "",
    custom_plan: "sp",
    custom_expiration_start_date: "",
    custom_expiration_end_date: "",
    custom_mfa: "false",
    custom_mfa_system:"email",
    account_status: true,
    custom_annual_support:"false",
    custom_serial_no:"",
    custom_memo:"",
    send_invitation_mail_flag: true,
    set_email_auto_verify_flag: true,
    temporary_pass_manual_setting_flag: false,
    temporary_pass_manual_setting_value: "",
    DesiredDeliveryMediums: "EMAIL",
    group_id:null,
    group_permission:null
}

export const UsersAdd = (props: UsersAddFormProps) => {
    const { groupList, onOK, onCancel } = props;

    const [startDateSelect,setStartDateSelect] = useState<any>();
    const [endDateSelect,setEndDateSelect] = useState<any>();
    const [isRoleAdmin,setIsRoleAdmin] = useState<boolean>(false);
    const [isTypePhone,setIsTypePhone] = useState<boolean>(false);
    const [isShowMFAMethod,setIsShowMFAMethod] = useState<boolean>(false);

    const { handleSubmit, formState: { errors }, control, setValue, getValues, setError, clearErrors } = useForm<ItemForm>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    
    const groupListOptions: OptionItem[] = [
        {
            label: "なし",
            value: null,
            attribute: ""
        },
        ...groupList.map((e: any) => {
            return {
                label: e["userGroupName"],
                value: e["userGroupId"],
                attribute: ""
            }
        })
    ];

    // Submitイベント
    const handleEditSubmit = (value: any) => {
        const valid = validating(value);
        if (!valid) return;

        const request = createRequestData(value);
        onOK(request);
    }

    const validating = (formValue: ItemForm) => {
        // 有効期限の開始・終了日のチェック
        if (!isRoleAdmin) {
            if (Number(formValue.custom_expiration_start_date.replaceAll("/", "")) >= Number(formValue.custom_expiration_end_date.replaceAll("/", ""))) {
                setError("custom_expiration_end_date", {
                    message: "有効期限（開始日）よりもあとの日付を指定してください。",
                    type: "validate"
                })
                return false;
            }
        }
        if(formValue.custom_serial_no == "" && formValue.custom_annual_support == "true"){
            setError("custom_serial_no",{message:"必須項目です。入力してください。",type:"required"})
            return false
        }
        return true;
    };

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: ItemForm) => {
        let group_id = undefined;
        let group_permission = undefined;
        if (formValue.group_id !== undefined && formValue.group_id !== null){
            group_id = formValue.group_id;
            group_permission = formValue.group_permission ?? "standard";
        }
        
        let request = [{
            family_name:formValue.family_name,
            given_name:formValue.given_name,
            custom_role:formValue.custom_role,
            email: formValue.email,
            custom_expiration_date: isRoleAdmin ? "" : formValue.custom_expiration_start_date.replaceAll("/", "") + "~" + formValue.custom_expiration_end_date.replaceAll("/", ""),
            custom_memo:formValue.custom_memo,
            custom_mfa: formValue.custom_mfa,
            custom_plan: isRoleAdmin ? "admin" : formValue.custom_plan,
            account_status: formValue.account_status,
            custom_annual_support: formValue.custom_annual_support,
            additional_info: {
                send_invitation_mail_flag: formValue.send_invitation_mail_flag,
                set_email_auto_verify_flag: formValue.set_email_auto_verify_flag,
                temporary_pass_manual_setting_flag: formValue.temporary_pass_manual_setting_flag,
                temporary_pass_manual_setting_value: formValue.temporary_pass_manual_setting_value,
                DesiredDeliveryMediums: formValue.DesiredDeliveryMediums
            },
            group_info: group_id !== undefined ? {
                group_id: group_id,
                group_permission: group_permission,
            } : undefined
        }] as any;
        if(formValue.phone_number){
            if(formValue.phone_number[0] == '+'){
                request[0].phone_number = formValue.phone_number;
            }else if(formValue.phone_number[0] == '0'){
                request[0].phone_number = "+81" + formValue.phone_number.substring(1);
            }else{
                request[0].phone_number = "+" + formValue.phone_number;
            }
        }
        if(formValue.custom_mfa == "true"){
            request[0].custom_mfa_system = formValue.custom_mfa_system;
        }
        if(formValue.custom_serial_no){
            request[0].custom_serial_no = formValue.custom_serial_no;
        }
        if(formValue.custom_management_no){
            request[0].custom_management_no = formValue.custom_management_no;
        }
        if(formValue.custom_company_name){
            request[0].custom_company_name = formValue.custom_company_name;
        }
        if(formValue.custom_zip_code){
            request[0].custom_zip_code = formValue.custom_zip_code;
        }
        if(formValue.address){
            request[0].address = formValue.address;
        }
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleChangeRole = (event:any) => {
        if (event && event.target && event.target.value && event.target.name) {
            setValue(event.target.name, event.target.value)
            if (event.target.value == "admin") {
                setIsRoleAdmin(true);
				setValue("custom_plan","admin");
            }
            else{
                setValue("custom_plan","sp");
                const currentTime = new Date();
                setIsRoleAdmin(false);
                setStartDateSelect(dayjs(currentTime));
                const startDateData = currentTime.getFullYear() + "/" + (currentTime.getMonth() + 1 < 10 ? "0" + (currentTime.getMonth()+1).toString() 
                : (currentTime.getMonth()+1).toString()) + "/" + (currentTime.getDate() < 10 ? "0" + currentTime.getDate().toString() : currentTime.getDate().toString());
                setValue("custom_expiration_start_date",startDateData);
                if(event.target.value == "trial"){
                    currentTime.setDate(currentTime.getDate() + 14);
                    setEndDateSelect(dayjs(currentTime));
                    const endDateData = currentTime.getFullYear() + "/" + (currentTime.getMonth() + 1 < 10 ? "0" + (currentTime.getMonth()+1).toString() 
                                        : (currentTime.getMonth()+1).toString()) + "/" + (currentTime.getDate() < 10 ? "0" + currentTime.getDate().toString() : currentTime.getDate().toString());
                    setValue("custom_expiration_end_date",endDateData);
                }
                else if(event.target.value == "customer"){
                    currentTime.setFullYear(currentTime.getFullYear() + 1);
                    setEndDateSelect(dayjs(currentTime));
                    const endDateData = currentTime.getFullYear() + "/" + (currentTime.getMonth() + 1 < 10 ? "0" + (currentTime.getMonth()+1).toString() 
                                        : (currentTime.getMonth()+1).toString()) + "/" + (currentTime.getDate() < 10 ? "0" + currentTime.getDate().toString() : currentTime.getDate().toString());
                    setValue("custom_expiration_end_date",endDateData);
                }
                else {
                    setEndDateSelect(dayjs(getValues("custom_expiration_end_date")));
                }
            }
        }
    }

    const handleChangeGroupId = (event: any) => {
        setValue(event.target.name, event.target.value);
        if (event.target.value === null){
            setValue("group_permission", null);
        }
    }

    return (
        <>
            <div style={{ height: '100%', width: '100%' }}>
                <form onSubmit={handleSubmit(handleEditSubmit)}>
                        <Stack style={{margin:"auto",maxHeight:"70vh",overflow:"auto"}} spacing={1.7}>
                            <Stack>
                                <Grid paddingTop={"8px"} container direction="row">
                                    <Controller
                                        control={control}
                                        name="custom_management_no"
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="管理No"
                                                size="small"
                                                InputProps={{ sx: { borderRadius: "8px" } }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Stack>
                            <Stack>
                                <Grid container direction="row">
                                    <Controller
                                        control={control}
                                        name="custom_company_name"
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="会社名"
                                                size="small"
                                                InputProps={{ sx: { borderRadius: "8px" } }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Stack>
                            <Stack> 
                                <Grid container direction="row">
                                    <Controller
                                        control={control}
                                        name="custom_zip_code"
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="〒"
                                                size="small"
                                                InputProps={{ sx: { borderRadius: "8px" } }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Stack>
                            <Stack> 
                                <Grid container direction="row">
                                    <Controller
                                        control={control}
                                        name="address"
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="住所"
                                                size="small"
                                                InputProps={{ sx: { borderRadius: "8px" } }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Stack>
                            <Stack> 
                                <Grid container direction="row">
                                    <Grid item xs={5.7}>
                                        <Controller
                                            control={control}
                                            name="family_name"
                                            rules={{ required: "必須項目です。入力してください。", }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="ユーザー名(姓)"
                                                    size="small"
                                                    error={!!errors?.family_name}
                                                    helperText={errors?.family_name?.message}
                                                    InputProps={{ sx: { borderRadius: "8px" } }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={0.6}></Grid>
                                    <Grid item xs={5.7}>
                                        <Controller
                                            control={control}
                                            name="given_name"
                                            rules={{ required: "必須項目です。入力してください。", }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="ユーザー名(名)"
                                                    size="small"
                                                    error={!!errors?.given_name}
                                                    helperText={errors?.given_name?.message}
                                                    InputProps={{ sx: { borderRadius: "8px" } }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Stack>
                            <Stack>
                                <Grid container direction="row">
                                    <FormControl fullWidth
                                        size="small" error={!!errors?.custom_role}>
                                        <InputLabel >権限</InputLabel>
                                        <Controller
                                            control={control}
                                            name="custom_role"
                                            rules={{ required: "必須項目です。選択してください。", }}
                                            render={({ field }) => (
                                                <>
                                                    <Select
                                                        {...field}
                                                        fullWidth
                                                        size="small"
                                                        label="権限"
                                                        style={{ borderRadius: '8px'}}
                                                        onChange={handleChangeRole}
                                                    >
                                                        {roleOptions.map((item: any) => (
                                                            <MenuItem key={item.label} value={item.value}>
                                                                {item.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {
                                                        !!errors?.custom_role &&
                                                        <FormHelperText>{errors?.custom_role?.message}</FormHelperText>
                                                    }
                                                </>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Stack>
                            <Stack>
                                <Grid container direction="row">
                                    <Controller
                                        control={control}
                                        name="email"
                                        rules={
                                            {
                                                required: "必須項目です。入力してください。",
                                                pattern: {
                                                    value: /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                    message: "メールアドレス形式で入力してください。"
                                                }
                                            }
                                        }
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="メールアドレス"
                                                inputProps={{
                                                    maxLength: 255
                                                }}
                                                size="small"
                                                InputProps={{ sx: { borderRadius: "8px" } }}
                                                error={!!errors?.email}
                                                helperText={errors?.email?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Stack>
                            <Stack>
                                <Grid container direction="row">
                                    <Controller
                                        control={control}
                                        name="phone_number"
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="電話番号"
                                                InputProps={{ sx: { borderRadius: "8px" } }}
                                                onChange={(event) => {
                                                    field.onChange(event);
                                                    if(event.target.value){
                                                        setIsTypePhone(true);
                                                        setValue("custom_mfa_system","sms");
                                                    }else{
                                                        setIsTypePhone(false);
                                                        setValue("custom_mfa_system","email");
                                                    }
                                                }}
                                                size="small"
                                                error={!!errors?.phone_number}
                                                helperText={errors?.phone_number?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Stack>
                            <Stack>
                                <Grid container>
                                    <Grid item xs={5.7}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel>グループ</InputLabel>
                                            <Controller
                                                name="group_id"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select {...field} label="グループ" onChange={handleChangeGroupId}>
                                                        {groupListOptions.map((item: any) => (
                                                            <MenuItem key={item.value} value={item.value}>
                                                                {item.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={0.6}></Grid>
                                    <Grid item xs={5.7}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel>グループ権限</InputLabel>
                                            <Controller
                                                name="group_permission"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select {...field} label="グループ権限">
                                                        {userGroupRoleOptions.map((item: any) => (
                                                            <MenuItem key={item.value} value={item.value}>
                                                                {item.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Stack>

                            {
                                !isRoleAdmin
                                &&
                                <>
                                    <Stack>
                                        <Typography marginTop={"-3px"}>パック</Typography>
                                        <Controller
                                            control={control}
                                            name="custom_plan"
                                            render={({ field: { value } }) => (
                                                <ToggleButtonGroup
                                                    color="info"
                                                    value={value}
                                                    exclusive
                                                    onChange={(e, value) => {
                                                        if (value != null) {
                                                            setValue("custom_plan", value);
                                                        }
                                                    }}
                                                    aria-label="Platform"
                                                    size="small"
                                                    aria-labelledby="権限"
                                                >
                                                    <ToggleButton value={"lp"} sx={{ borderRadius: "8px" }}>Ligth Pack</ToggleButton>
                                                    <ToggleButton value={"sp"} sx={{ borderRadius: "8px" }}>Service Pack</ToggleButton>
                                                    <ToggleButton value={"pp"} sx={{ borderRadius: "8px" }}>PM Pack</ToggleButton>
                                                </ToggleButtonGroup>
                                            )}
                                        />
                                    </Stack>
                                    <Stack mt={1}>
                                        <Grid container direction="row">
                                            <FormControl size='medium' fullWidth>
                                                <Controller
                                                    control={control}
                                                    name="custom_expiration_start_date"
                                                    rules={{ required: '必須項目です。選択してください。' }}
                                                    render={({ field: { value } }) => (
                                                        <>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DateTimePicker
                                                                    format="YYYY/MM/DD"
                                                                    views={['year', 'month', 'day']}
                                                                    openTo="day"
                                                                    label="有効期限（開始日）"
                                                                    value={startDateSelect}
                                                                    onChange={(newValue: any) => {
                                                                        if (newValue != null) {
                                                                            if (newValue.$d.toString() === "Invalid Date") {
                                                                                setError("custom_expiration_start_date", {
                                                                                    message: "必須項目です。選択してください。",
                                                                                    type: "required"
                                                                                })
                                                                                setValue("custom_expiration_start_date", "")
                                                                            } else {
                                                                                const month = Number(newValue.$M) + 1;
                                                                                const date = newValue.$y + "/" + (month < 10 ? "0" + month.toString() : month.toString()) + "/" + (Number(newValue.$D) < 10 ? "0" + newValue.$D.toString() : newValue.$D.toString())
                                                                                setValue("custom_expiration_start_date", date)
                                                                            }
                                                                        }
                                                                        if (newValue == null) {
                                                                            setError("custom_expiration_start_date", {
                                                                                message: "必須項目です。選択してください。",
                                                                                type: "required"
                                                                            })
                                                                            setValue("custom_expiration_start_date", "")
                                                                        }
                                                                    }}
                                                                    slotProps={{ actionBar: { actions: [] }, textField: { size: 'small', error: !!errors?.custom_expiration_start_date, sx: { backgroundColor: "white" } } }}
                                                                />
                                                            </LocalizationProvider>
                                                        </>
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                        {
                                            !!errors?.custom_expiration_start_date &&
                                            <FormHelperText style={{ color: 'red' }}>{errors?.custom_expiration_start_date?.message as any}
                                            </FormHelperText>
                                        }
                                    </Stack>
                                    <Stack mt={1}>
                                        <Grid container direction="row">
                                            <FormControl size='medium' fullWidth>
                                                <Controller
                                                    control={control}
                                                    name="custom_expiration_end_date"
                                                    rules={{ required: '必須項目です。選択してください。' }}
                                                    render={({ field: { value } }) => (
                                                        <>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DateTimePicker
                                                                    format="YYYY/MM/DD"
                                                                    views={['year', 'month', 'day']}
                                                                    openTo="day"
                                                                    label="有効期限（終了日）"
                                                                    value={endDateSelect}
                                                                    onChange={(newValue: any) => {
                                                                        if (newValue != null) {
                                                                            if (newValue.$d.toString() === "Invalid Date") {
                                                                                setError("custom_expiration_end_date", {
                                                                                    message: "必須項目です。選択してください。",
                                                                                    type: "required"
                                                                                })
                                                                                setValue("custom_expiration_end_date", "")
                                                                            } else {
                                                                                const month = Number(newValue.$M) + 1;
                                                                                const date = newValue.$y + "/" + (month < 10 ? "0" + month.toString() : month.toString()) + "/" + (Number(newValue.$D) < 10 ? "0" + newValue.$D.toString() : newValue.$D.toString())
                                                                                setValue("custom_expiration_end_date", date)
                                                                            }
                                                                        }
                                                                        if (newValue == null) {
                                                                            setError("custom_expiration_end_date", {
                                                                                message: "必須項目です。選択してください。",
                                                                                type: "required"
                                                                            })
                                                                            setValue("custom_expiration_end_date", "")
                                                                        }
                                                                    }}
                                                                    slotProps={{ actionBar: { actions: [] }, textField: { size: 'small', error: !!errors?.custom_expiration_end_date, sx: { backgroundColor: "white" } } }}
                                                                />
                                                            </LocalizationProvider>
                                                        </>
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                        {
                                            !!errors?.custom_expiration_end_date &&
                                            <FormHelperText style={{ color: 'red' }}>{errors?.custom_expiration_end_date?.message as any}
                                            </FormHelperText>
                                        }
                                    </Stack>
                                </>
                            }

                            <Stack>
                                <Grid container direction="row">
                                    <Grid item xs={4}>
                                        <Typography marginTop={"-3px"}>多要素認証（MFA）</Typography>
                                        <Controller
                                            control={control}
                                            name="custom_mfa"
                                            render={({ field: { value } }) => (
                                                <ToggleButtonGroup
                                                    color="info"
                                                    value={value}
                                                    exclusive
                                                    onChange={(e, value) => {
                                                        if (value != null) {
                                                            setValue("custom_mfa", value);
                                                            if(value == "true"){
                                                                setIsShowMFAMethod(true);
                                                                if(isTypePhone){
                                                                    setValue("custom_mfa_system","sms");
                                                                }else{
                                                                    setValue("custom_mfa_system","email");
                                                                }
                                                            }else{
                                                                setIsShowMFAMethod(false)
                                                            }
                                                        }
                                                    }}
                                                    aria-label="Platform"
                                                    size="small"
                                                    aria-labelledby="権限"
                                                >
                                                    <ToggleButton value={"true"} sx={{ borderRadius: "8px" }}>有効</ToggleButton>
                                                    <ToggleButton value={"false"} sx={{ borderRadius: "8px" }}>無効</ToggleButton>
                                                </ToggleButtonGroup>
                                            )}
                                        />
                                    </Grid>
                                    {
                                        isShowMFAMethod  &&
                                        <Grid item xs={6}>
                                            <fieldset style={{border:"1px solid #D3D3D3"}}>
                                                <legend>多要素認証（MFA）方式</legend>
                                                <Controller
                                                    name="custom_mfa_system"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <RadioGroup {...field}>
                                                            {
                                                                isTypePhone &&
                                                                <FormControlLabel 
                                                                    value='sms'
                                                                    control={<Radio size='small'/>}
                                                                    label={<Typography variant="body2" style={{color:"grey"}}>SMS</Typography>}
                                                                />
                                                            }
                                                            <FormControlLabel
                                                                value='email'
                                                                control={<Radio size='small'/>}
                                                                label={<Typography variant="body2" style={{color:"grey"}}>Eメ一ル</Typography>}
                                                            />
                                                        </RadioGroup>
                                                    )}
                                                />
                                            </fieldset>
                                        </Grid>
                                    }
                                    
                                </Grid>
                                
                            </Stack>
                            <Stack>
                                <Typography marginTop={"-3px"}>ステータス</Typography>
                                <Controller
                                    control={control}
                                    name="account_status"
                                    render={({ field: { value } }) => (
                                        <ToggleButtonGroup
                                            color="info"
                                            value={value}
                                            exclusive
                                            onChange={(e, value) => {
                                                if (value != null) {
                                                    
                                                    setValue("account_status", value);
                                                }
                                            }}
                                            aria-label="Platform"
                                            size="small"
                                            aria-labelledby="ステータス"
                                        >
                                            <ToggleButton value={true} sx={{ borderRadius: "8px" }}>有効</ToggleButton>
                                            <ToggleButton value={false} sx={{ borderRadius: "8px" }}>無効</ToggleButton>
                                        </ToggleButtonGroup>
                                    )}
                                />
                            </Stack>
                            <Stack>
                                <Typography marginTop={"-3px"}>ソフト版年間サポートの加入状況</Typography>
                                <Grid container direction="row">
                                    <Grid item xs={6} >
                                        <Controller
                                            control={control}
                                            name="custom_annual_support"
                                            render={({ field: { value } }) => (
                                                <ToggleButtonGroup
                                                    color="info"
                                                    value={value}
                                                    exclusive
                                                    onChange={(e, value) => {
                                                        if (value != null) {
                                                            setValue("custom_annual_support", value);
                                                        }
                                                    }}
                                                    aria-label="Platform"
                                                    size="small"
                                                    aria-labelledby="ソフト版年間サポートの加入状況"
                                                >
                                                    <ToggleButton value={true} sx={{ borderRadius: "8px" }}>年間サポート有</ToggleButton>
                                                    <ToggleButton value={false} sx={{ borderRadius: "8px" }}>年間サポート無</ToggleButton>
                                                </ToggleButtonGroup>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={0.5}></Grid>
                                    <Grid item xs={5.5}>
                                        <Controller
                                            control={control}
                                            name="custom_serial_no"
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="ソフトのシリアルNo"
                                                    size="small"
                                                    error={!!errors?.custom_serial_no}
                                                    helperText={errors?.custom_serial_no?.message}
                                                    InputProps={{ sx: { borderRadius: "8px" } }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Stack>
                            <Stack> 
                                <Grid container direction="row">
                                    <Controller
                                        control={control}
                                        name="custom_memo"
                                        // rules={{ required: "必須項目です。入力してください。", }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="メモ"
                                                size="small"
                                                error={!!errors?.custom_memo}
                                                helperText={errors?.custom_memo?.message}
                                                inputProps={{maxLength:2048}}
                                                InputProps={{ sx: { borderRadius: "8px" } }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Stack>
                        </Stack>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
                        <Button variant="contained" type="submit" sx={{ borderRadius: "8px" }}>保存</Button>
                        <Button color="inherit" variant="contained" sx={{ borderRadius: "8px" }} onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                </form>
            </div>
        </>
    );
};
export default UsersAdd;