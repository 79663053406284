import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, Stack, TextField, TextareaAutosize, Typography } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import theme from "../../themes/globalStyles";
import DraggablePaper from "../common/DraggablePaper";

const fieldsetStyle = {
    border:"1px solid #AAAA",
}

interface SettingTitleGraph{
    graphName:string,
    printDetailHead:boolean,
    figTitle:string,
    figSubTitle:string,
    // 
    titleCorporation:string,
    corporation:string,
    revision1:string,
    // 
    titleProject:string,
    project:string,
    titleDrawn:string,
    drawner:string,
    titleDateDrawn:string,
    dateDrawn:string,
    revision2:string,
    // 
    titleReference:string,
    reference:string,
    titleChecked:string,
    checker:string
    titleDateChecked:string,
    dateChecked:string,
    revision3:string,
    //
    titleNumber:string,
    figNumber:string,
    titleDesigned:string,
    designer:string,
    titleDateDesigned:string,
    dateDesigned:string,
    revision4:string,
    // 
    titleMode:string,
    titleApproved:string,
    approver:string,
    titleDateApproved:string,
    dateApproved:string,
    revision5:string,
    // 
    titleComment:string,
    comments:string,
} 

export type SettingDiagramDialogFormProps = {
    data: any,
    onOK: (data: any) => void;
    onCancel: () => void;
}; 

export const SettingDiagramDialog = (props:SettingDiagramDialogFormProps) => {
    const { data, onOK, onCancel  } = props;
    const initialValue = {
        ...data
    }
    const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<SettingTitleGraph>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });
    const [headlineFlag,setHeadlineFlag] = React.useState<boolean>(data.printDetailHead);

    // Submitイベント
    const handleEditSubmit = (value : any) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: any) => {
        let request = {
            ...formValue,
        } as any;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };

    const handleChangeFormat = (e: any) => {
        setValue(e.target.name, e.target.value);
    }

    const handleResetLabel = (e:any) => {
        setValue("titleComment","コメント");
        setValue("titleMode","解析モード");
        setValue("titleApproved","検認");
        setValue("titleDateApproved","日付");
        setValue("titleDateChecked","日付");
        setValue("titleDateDesigned","日付");
        setValue("titleDateDrawn","日付");
        setValue("titleNumber","図番");
        setValue("titleDesigned","設計");
        setValue("titleReference","名称");
        setValue("titleChecked","照査");
        setValue("figTitle","保護協調曲線図");
        setValue("figSubTitle","Protective Characteristic Coordination Curves");
        setValue("titleDrawn","作成");
    }

    return (

        <>
            <Dialog open={true} fullWidth maxWidth={"md"}
                PaperComponent={
                    DraggablePaper
                }
            >   
                <DialogTitle 
                    onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>保護協調図の設定
                </DialogTitle>
                <DialogContent style={{paddingTop: '10px'}}>
                    <div style={{ height: '40vh', width: '100%' ,marginLeft:'-10px'}}>
                        <form 
                            onSubmit={handleSubmit(handleEditSubmit)} 
                            style={{ width: '100%', margin: 'auto' }}
                        >
                            <div style={{height:"calc(40vh - 45px)",overflowY:"auto", width: '100%'}}>
                                <Grid container>
                                        <Grid container spacing={2} direction={"row"}>
                                            <Grid item xs={7}>
                                                <Stack flexDirection='row' alignItems='center'>
                                                    <Typography width={"25%"} variant="body2">保護協調名:</Typography>
                                                    <Controller
                                                        name="graphName"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <>
                                                                <TextField
                                                                    {...field}
                                                                    type="text"
                                                                    size="small"
                                                                    fullWidth
                                                                    error={!!errors?.graphName}
                                                                    onBlur={handleChangeFormat}
                                                                    onFocus={e => e.target.select()}
                                                                    InputLabelProps={{
                                                                        style: { color: 'black' },
                                                                    }}
                                                                    InputProps={{
                                                                        sx:{height:32},
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            
                                        </Grid>       
                                        <Grid container  mt={1}>
                                                <fieldset style={{width:'100%',border:'1px solid #AAAA'}}>
                                                    <legend><Typography variant="body2">保護協調図の印刷で使用</Typography></legend>
                                                        <Stack direction="row" justifyContent={"space-between"}>
                                                                <Stack>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Controller
                                                                            name='printDetailHead'
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <Checkbox
                                                                                    {...field}
                                                                                    style={{height:"20px"}}
                                                                                    // size="medium"
                                                                                    // style={checkboxStyle}
                                                                                    checked={field.value}
                                                                                    // onChange={handleChangeCheckbox}
                                                                                    onClick={(e:any) => {
                                                                                        setHeadlineFlag(e.target.checked);
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                                    }
                                                                    label={<Typography variant="body2">ヘッドラインを印刷</Typography>}
                                                                /> 
                                                                </Stack>
                                                                
                                                                <Stack mr={1}>
                                                                    <Button color="inherit" variant="contained" style={{width:"120px"}} onClick={handleResetLabel}>リセット</Button>
                                                                </Stack>
                                                        </Stack>
                                                        <Grid container direction={"row"} mt={0.3}>
                                                                <Grid item xs={4}>
                                                                    <Stack>
                                                                        <Typography variant="body2">タイトル:</Typography>
                                                                    </Stack>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Stack>
                                                                        <Typography variant="body2">サブタイトル:</Typography>
                                                                    </Stack>
                                                                </Grid>
                                                        </Grid>
                                                        <Grid container direction={"row"} spacing={0.3} >
                                                                <Grid item xs={3.95}>
                                                                    <Controller
                                                                        name="figTitle"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <TextField
                                                                                    {...field}
                                                                                    type="text"
                                                                                    size="small"
                                                                                    fullWidth
                                                                                    error={!!errors?.figTitle}
                                                                                    onBlur={handleChangeFormat}
                                                                                    onFocus={e => e.target.select()}
                                                                                    InputLabelProps={{
                                                                                        style: { color: 'black' },
                                                                                    }}
                                                                                    InputProps={{
                                                                                        sx:{height:32},
                                                                                    }}
                                                                                />
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={5.95}>
                                                                    <Controller
                                                                        name="figSubTitle"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <TextField
                                                                                    {...field}
                                                                                    type="text"
                                                                                    size="small"
                                                                                    fullWidth
                                                                                    error={!!errors?.figSubTitle}
                                                                                    onBlur={handleChangeFormat}
                                                                                    onFocus={e => e.target.select()}
                                                                                    InputLabelProps={{
                                                                                        style: { color: 'black' },
                                                                                    }}
                                                                                    InputProps={{
                                                                                        sx:{height:32},
                                                                                    }}
                                                                                />
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={1} mt={0.6} ml={0.3}>
                                                                    <Typography>Revisions:</Typography>
                                                                </Grid>
                                                        </Grid>
                                                        <Grid container direction={"row"}  mt={1}>
                                                            <Grid item xs={2}>
                                                                <Controller
                                                                    name="titleCorporation"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                // value={"会社"}
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.titleCorporation}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={true}
                                                                                style={{backgroundColor:"floralwhite"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs ={0.2}  display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                                :
                                                            </Grid>
                                                            <Grid item xs={7.698}>
                                                                <Controller
                                                                    name="corporation"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.corporation}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={true}
                                                                                style={{backgroundColor:"floralwhite"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid xs={2} ml={0.4}>
                                                                <Controller
                                                                    name="revision1"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.revision1}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={!headlineFlag}
                                                                                style={{backgroundColor: !headlineFlag ? "floralwhite" : "white"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container direction={"row"} mt={1}>
                                                            <Grid item xs={2}>
                                                                <Controller
                                                                    name="titleProject"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                // value="プロジェクト"
                                                                                error={!!errors?.titleProject}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={true}
                                                                                style={{backgroundColor:"floralwhite"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                                :
                                                            </Grid>
                                                            <Grid item xs={1.75}>
                                                                <Controller
                                                                    name="project"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.project}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={true}
                                                                                style={{backgroundColor:"floralwhite"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1.4} ml={0.3}>
                                                                <Controller
                                                                    name="titleDrawn"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.titleDrawn}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={!headlineFlag}
                                                                                style={{backgroundColor: !headlineFlag ? "floralwhite" : "white"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                                :
                                                            </Grid>
                                                            <Grid item xs={1.75}>
                                                                <Controller
                                                                    name="drawner"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.drawner}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={!headlineFlag}
                                                                                style={{backgroundColor: !headlineFlag ? "floralwhite" : "white"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={0.9} ml={0.3}>
                                                                <Controller
                                                                    name="titleDateDrawn"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.titleDateDrawn}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={!headlineFlag}
                                                                                style={{backgroundColor: !headlineFlag ? "floralwhite" : "white"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                                :
                                                            </Grid>
                                                            <Grid item xs={1.42}>
                                                                <Controller
                                                                    name="dateDrawn"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.dateDrawn}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={!headlineFlag}
                                                                                style={{backgroundColor: !headlineFlag ? "floralwhite" : "white"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid  item xs={2} ml={0.4}>
                                                                <Controller
                                                                    name="revision2"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.revision2}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={!headlineFlag}
                                                                                style={{backgroundColor: !headlineFlag ? "floralwhite" : "white"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container direction={"row"} mt={1}>
                                                            <Grid item xs={2}>
                                                                <Controller
                                                                    name="titleReference"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.titleReference}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                                :
                                                            </Grid>
                                                            <Grid item xs={1.75}>
                                                                <Controller
                                                                    name="reference"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.reference}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1.4} ml={0.3}>
                                                                <Controller
                                                                    name="titleChecked"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.titleChecked}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={!headlineFlag}
                                                                                style={{backgroundColor: !headlineFlag ? "floralwhite" : "white"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                                :
                                                            </Grid>
                                                            <Grid item xs={1.75}>
                                                                <Controller
                                                                    name="checker"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.checker}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={!headlineFlag}
                                                                                style={{backgroundColor: !headlineFlag ? "floralwhite" : "white"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={0.9} ml={0.3}>
                                                                <Controller
                                                                    name="titleDateChecked"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.titleDateChecked}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={!headlineFlag}
                                                                                style={{backgroundColor: !headlineFlag ? "floralwhite" : "white"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                                :
                                                            </Grid>
                                                            <Grid item xs={1.42}>
                                                                <Controller
                                                                    name="dateChecked"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.dateChecked}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={!headlineFlag}
                                                                                style={{backgroundColor: !headlineFlag ? "floralwhite" : "white"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2} ml={0.4}>
                                                                <Controller
                                                                    name="revision3"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.revision3}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={!headlineFlag}
                                                                                style={{backgroundColor: !headlineFlag ? "floralwhite" : "white"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container direction={"row"} mt={1}>
                                                            <Grid item xs={2}>
                                                                <Controller
                                                                    name="titleNumber"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.titleNumber}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={!headlineFlag}
                                                                                style={{backgroundColor: !headlineFlag ? "floralwhite" : "white"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                                :
                                                            </Grid>
                                                            <Grid item xs={1.75}>
                                                                <Controller
                                                                    name="figNumber"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.figNumber}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={!headlineFlag}
                                                                                style={{backgroundColor: !headlineFlag ? "floralwhite" : "white"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1.4} ml={0.3}>
                                                                <Controller
                                                                    name="titleDesigned"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.titleDesigned}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={!headlineFlag}
                                                                                style={{backgroundColor: !headlineFlag ? "floralwhite" : "white"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                                :
                                                            </Grid>
                                                            <Grid item xs={1.75}>
                                                                <Controller
                                                                    name="designer"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.designer}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={!headlineFlag}
                                                                                style={{backgroundColor: !headlineFlag ? "floralwhite" : "white"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={0.9} ml={0.3}>
                                                                <Controller
                                                                    name="titleDateDesigned"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.titleDateDesigned}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={!headlineFlag}
                                                                                style={{backgroundColor: !headlineFlag ? "floralwhite" : "white"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                                :
                                                            </Grid>
                                                            <Grid item xs={1.42}>
                                                                <Controller
                                                                    name="dateDesigned"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.dateDesigned}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={!headlineFlag}
                                                                                style={{backgroundColor: !headlineFlag ? "floralwhite" : "white"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2} ml={0.4}>
                                                                <Controller
                                                                    name="revision4"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.revision4}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={!headlineFlag}
                                                                                style={{backgroundColor: !headlineFlag ? "floralwhite" : "white"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container direction={"row"} mt={1}>
                                                            <Grid item xs={2}>
                                                                <Controller
                                                                    name="titleMode"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.titleMode}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled
                                                                                style={{backgroundColor: "floralwhite"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                    
                                                            </Grid>
                                                            <Grid item xs={1.75}>
                                                                <Stack>
                                                                        
                                                                </Stack>
                                                            </Grid>
                                                            <Grid item xs={1.4} ml={0.3}>
                                                                <Controller
                                                                    name="titleApproved"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.titleApproved}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={!headlineFlag}
                                                                                style={{backgroundColor: !headlineFlag ? "floralwhite" : "white"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                                :
                                                            </Grid>
                                                            <Grid item xs={1.75}>
                                                                <Controller
                                                                    name="approver"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.approver}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={!headlineFlag}
                                                                                style={{backgroundColor: !headlineFlag ? "floralwhite" : "white"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={0.9} ml={0.3}>
                                                                <Controller
                                                                    name="titleDateApproved"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.titleDateApproved}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={!headlineFlag}
                                                                                style={{backgroundColor: !headlineFlag ? "floralwhite" : "white"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs ={0.2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                                :
                                                            </Grid>
                                                            <Grid item xs={1.42}>
                                                                <Controller
                                                                    name="dateApproved"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.dateApproved}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={!headlineFlag}
                                                                                style={{backgroundColor: !headlineFlag ? "floralwhite" : "white"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2} ml={0.4}>
                                                                <Controller
                                                                    name="revision5"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.revision5}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                                disabled={!headlineFlag}
                                                                                style={{backgroundColor: !headlineFlag ? "floralwhite" : "white"}}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        {/* <Grid container direction={"row"} mt={1}>
                                                            <Grid item xs={2}>
                                                                <Controller
                                                                    name="titleComment"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <>
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                fullWidth
                                                                                error={!!errors?.titleComment}
                                                                                onBlur={handleChangeFormat}
                                                                                onFocus={e => e.target.select()}
                                                                                InputLabelProps={{
                                                                                    style: { color: 'black' },
                                                                                }}
                                                                                InputProps={{
                                                                                    sx:{height:32},
                                                                                }}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs ={0.2} pl={0.5} alignItems={'center'} justifyContent={'center'}>
                                                                :
                                                            </Grid>
                                                            <Grid item xs={9.75}>
                                                                <Controller
                                                                    name="comments"
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <TextareaAutosize
                                                                                    {...field}
                                                                                    
                                                                                    maxRows={6}
                                                                                    minRows={6}
                                                                                    style={{overflowY:'scroll',width:'99%',resize:'none',fontFamily:'Roboto,Helvetica,Arial,sans-serif',fontSize:'16px'}}
                                                                                    />
                                                                                    )}
                                                                                />
                                                            </Grid>
                                                        </Grid> */}
                                                        <Grid container mt={1}>
                                                            <Grid item xs={2}>
                                                                <Stack direction={'row'} >
                                                                    <Controller
                                                                        name="titleComment"
                                                                        control={control}
                                                                        render={({ field }: any) => (
                                                                            <TextField
                                                                                {...field}
                                                                                type="text"
                                                                                size="small"
                                                                                // sx={{flex: 1}}
                                                                                InputProps={{sx: { height: 32 }}}

                                                                            />
                                                                        )}
                                                                    />
                                                                    </Stack>
                                                            </Grid>
                                                            <Grid item xs ={0.2} pl={0.5} alignItems={'center'} justifyContent={'center'}>
                                                                :
                                                            </Grid>
                                                            <Grid item xs ={9.75}>
                                                                <Stack>
                                                                <Controller
                                                                name="comments"
                                                                control={control}
                                                                
                                                                render={({ field }) => (
                                                                    <TextareaAutosize
                                                                                {...field}
                                                                                
                                                                                maxRows={6}
                                                                                minRows={6}
                                                                                style={{overflowY:'scroll',resize:'none',fontFamily:'Roboto,Helvetica,Arial,sans-serif',fontSize:'16px'}}
                                                                                />
                                                                                )}
                                                                            />
                                                                </Stack>
                                                            </Grid>
                                                        </Grid>
                                                </fieldset>
                                            </Grid>
                                </Grid>
                            </div>
                            <Stack direction='row' justifyContent="flex-end" spacing={2} mt={1} paddingBottom={1}>
                                <Button variant="contained" type="submit">OK</Button>
                                <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                            </Stack>
                        </form>
                    </div>
                </DialogContent>
            </Dialog>
        </>
        
    )
}

export default SettingDiagramDialog;
