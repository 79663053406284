import { Button, CircularProgress,Stack, TextField, Typography } from "@mui/material";
import { connect } from "react-redux";
import { memo, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AddCircleOutlineOutlined, ArrowBack } from "@mui/icons-material";
import { PageData } from "../../models/Index";
import AWS from "aws-sdk";
import MaitainanceGrid from "./MaitenanceGrid";
import { convertMaintenanceData } from "../../utils/DataConverter";
import * as Model from "../../models/Index";
import { actionCreators } from "../../store";
import { downloadFileByBlob, openFileInNewTabByBlob } from "../../utils/downloadFile";
import AlertDialog from "../common/AlertDialog";
import UploadFileDiaglog from "../dialogs/UploadFileDiaglog";
import { deleteFileS3, downloadFileFromS3, getDownloadFileUrlS3, getGetFileListS3, getUploadFileUrlS3, post, uploadFileToS3 } from "../CallApi";
import { useNavigate } from "react-router-dom";

export type MaintainanceScreenForm = {
  controlName:string,
  fileName:string
  searchItemForm: SearchItemForm,
}
type MaintenanceSreenProps = {
  dataShareProject: any;
  controlId: string;
  handleDisplayDiagram?: () => void;
};
// 検索フォーム
export interface SearchItemForm {
  "target": string;
  "limit": number;
  "page": number;
}

// 初期値設定
const initialValueSearch: SearchItemForm = {
  target: '',
  limit: 50,
  page: 1,
}
const initialPageData: PageData = {
  rowFrom: '0',
  rowTo: '0',
  totalRow: '0',
  currPage: '0',
  totalPage: '0',
};
export type PureMaintenanceSreenProps = MaintenanceSreenProps &
  ReturnType<typeof mapStateToProps> & 
  ReturnType<typeof mapDispatchToProps>

const MaintenanceSreen = memo((props: PureMaintenanceSreenProps) => {
  const {
    dataShareProject,
    controlId,
    projectData,
    user,
    diagramDataList,
    currentTabId,
    handleDisplayDiagram,
    onShowMessage,
  } = props;
  
  const navigate = useNavigate();
  const gridRef = useRef() as any;
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [openUploadFileDiaglog, setOpenUploadFileDiaglog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  
  const [listAllControls, setListAllControls] = useState<any[]>([]);
  const [controlIdSelected, setControlIdSelected] = useState(controlId || '');
  const [pageData, setPageData] = useState(initialPageData);
  const [fileSelected, setFileSelected] = useState<any>(null);

  // Search and Pagination
  const [isGetAllFile, setIsGetAllFile] = useState(false);
  const [allFileList, setAllFileList] = useState<any[]>([]);
  const [displayFileList, setDisplayFileList] = useState<any[]>([]);
  const [disableButtonAddFile, setDisableButtonAddFile] = useState(false);
  
  const controlClick = diagramDataList.find((item:any) => item.tabId === currentTabId)?.shape.filter((item:any) => item.id == controlId);
  let initialValue: MaintainanceScreenForm = {
    controlName: controlClick.length > 0 ? controlClick[0].properties.refNo : '',
    fileName: '',
    searchItemForm: initialValueSearch
  }
  const { control, handleSubmit, setValue, getValues } = useForm<MaintainanceScreenForm>({
    mode: "all",
    criteriaMode: "all",
    defaultValues: initialValue,
  })

  const handleEditSubmit = async (value: any)=> {
    await handleSearchFile(value);
  }

  const handleClear = () => {
    setValue("controlName", "")
    setValue("fileName", "")
  }

  const handleBack = () => {
    if (controlId && handleDisplayDiagram) handleDisplayDiagram()
    else navigate("/projects")
  }

  // #region Amazon S3
  useEffect(() => {
    // get file list
    handleInitFileList();
  }, [])
  const getUserSub = async() => {
    if (user?.userId === projectData.ownerId){
      return user?.userSub
    }
    setDisableButtonAddFile(true)

    let userSub = ""
    if(dataShareProject !== null && dataShareProject !== undefined && dataShareProject.length > 0){
      const shareProject = dataShareProject.find((item:any) => item.projectIdEdit == projectData.projectId)
      if(shareProject !== undefined){
        return shareProject.sub
      }
    }
    
    const res = await post("/user/get-user-sub", {userId: projectData.ownerId})
    if (res.success){
      userSub = res.data.data
    }
    return userSub
  }
  const handleInitFileList = async () => {
    setLoadingFlag(true);
    const userSub = await getUserSub()
    let key = `${userSub}/${projectData.projectId}/`;
    if (controlIdSelected) key = key.concat(`${controlIdSelected}/`);
    else setIsGetAllFile(true);

    const fileList = await getFileList(key);

    setAllFileList(fileList);
    setDisplayFileList(fileList);
    handlePagination(fileList);
    setLoadingFlag(false);
  }

  const getFileList = async (prefix: string) => {
    let fileList = [];

    let allControls: any[] = [...listAllControls];
    if (allControls.length == 0){
      diagramDataList.forEach((diagramData: any) => {
        allControls.push(
          ...diagramData.shape.map((e: any) => {
            return {id: e.id, name: e.properties.refNo};
          })
        );
      });
      setListAllControls(allControls);
    }

    let res = await getGetFileListS3(prefix)
    if (res.success) {
      fileList = convertMaintenanceData(res.data, allControls);
    } else {
      onShowMessage({
        type: "error",
        title: "ファイルを取得",
        body: res.error.message,
      });
    }

    return fileList;
  }

  const handleSearchFile = async (value: any) => {
    let fileList: any = []
    if (!isGetAllFile) {
      setLoadingFlag(true);
      const userSub = getUserSub()
      let folderName = `${userSub}/${projectData.projectId}/`;
      fileList = await getFileList(folderName);
      setAllFileList(fileList);
      setIsGetAllFile(true);

      setLoadingFlag(false);
    } else {
      fileList = allFileList;
    }

    const searchControl = value.controlName.toLowerCase();
    const searchFile = value.fileName.toLowerCase();
    if (searchControl) {
      fileList = fileList.filter(
        (e: any) => e.controlName.toLowerCase().indexOf(searchControl) != -1
      );
    }
    if (searchFile) {
      fileList = fileList.filter(
        (e: any) => e.fileName.toLowerCase().indexOf(searchFile) != -1
      );
    }

    setDisplayFileList(fileList);
    setValue("searchItemForm.page", 1);
    handlePagination(fileList);
  }

  const handleAddFile = async (selectedFile: any) => {
    if (selectedFile) {
      setLoadingFlag(true);

      let key = `${user.userSub}/${projectData.projectId}/${controlIdSelected}/${selectedFile.name}`;

      const resGetUrl = await getUploadFileUrlS3(key, selectedFile.type);
      if (resGetUrl.success) {
        const uploadFileUrlS3 = resGetUrl.data;
        const resUpload = await uploadFileToS3(uploadFileUrlS3, selectedFile)
        if (resUpload.success) {
          const controlName = listAllControls.find(e => e.id == controlIdSelected)?.name
          if (gridRef && gridRef.current) {
            gridRef.current.addData({
              controlName: controlName,
              fileName: selectedFile.name,
              lastUpdateDatetime: new Date(),
              key: key
            });
          }

          // 操作履歴対応
          const params = {
            userId: user?.userId,
            type: "add_maintenance_data",
            information: `機器名：${controlName}|ファイル名：${selectedFile.name}`
          }
          post("/user/add-operation-history", params)
          
          onShowMessage({
            type: 'info',
            title: 'ファイル追加',
            body: 'ファイル追加しました',
          });
        } else {
          onShowMessage({
            type: "error",
            title: "ファイル追加",
            body: resUpload.error.message,
          });
        }
      } else {
        onShowMessage({
          type: "error",
          title: "プリサインドURLを取得",
          body: resGetUrl.error.message,
        });
      }

      setLoadingFlag(false);
    }
  }

  const handleDownloadFile = async (data: any) => {
    if (!data.key) return;
    setLoadingFlag(true);

    const resGetUrl = await getDownloadFileUrlS3(data.key);
    if (resGetUrl.success) {
      const resDownloadFile = await downloadFileFromS3(resGetUrl.data)
      if (resDownloadFile.success){
        downloadFileByBlob(resDownloadFile.data, data.fileName)
        
        // 操作履歴対応
        const params = {
          userId: user?.userId,
          type: "display_maintenance_data",
          information: `機器名：${data.controlName}|ファイル名：${data.fileName}`
        }
        post("/user/add-operation-history", params)
      } else {
        onShowMessage({
          type: "error",
          title: "ダウンロードファイル",
          body: resGetUrl.error.message,
        });
      }
    } else {
      onShowMessage({
        type: "error",
        title: "プリサインドURLを取得",
        body: resGetUrl.error.message,
      });
    }

    setLoadingFlag(false);
  }

  const handleOpenDeleteDialog = (data: any) => {
    // When the opener is different from the owner, deletion is not allowed.
    if(!disableButtonAddFile){
      setOpenDeleteDialog(true);
      setFileSelected(data);
    }
  }

  const handleDeleteFile = async () => {
    if (!fileSelected) return;
    setLoadingFlag(true);

    const resDelete = await deleteFileS3(fileSelected.key);
    if (resDelete.success) {
      onShowMessage({
        type: 'info',
        title: 'ファイルを削除する',
        body: 'ファイルを削除するしました',
      });
      if (gridRef && gridRef.current) {
        gridRef.current.deleteData(fileSelected.key);
      }

      // 操作履歴対応
      const params = {
        userId: user?.userId,
        type: "delete_maintenance_data",
        information: `機器名：${fileSelected.controlName}|ファイル名：${fileSelected.fileName}`
      }
      post("/user/add-operation-history", params)

    } else {
      onShowMessage({
        type: "error",
        title: "ファイルを削除する",
        body: resDelete.error.message
      });
    }

    setOpenDeleteDialog(false);
    setFileSelected(null);
    setLoadingFlag(false);
  }

  const handleOpenFileInNewTab = async (data: any) => {
    if (!data.key) return;
    setLoadingFlag(true);
    
    const resGetUrl = await getDownloadFileUrlS3(data.key);
    if (resGetUrl.success) {
      const resDownloadFile = await downloadFileFromS3(resGetUrl.data)
      if (resDownloadFile.success){
        openFileInNewTabByBlob(resDownloadFile.data);
        
        // 操作履歴対応
        const params = {
          userId: user?.userId,
          type: "display_maintenance_data",
          information: `機器名：${data.controlName}|ファイル名：${data.fileName}`
        }
        post("/user/add-operation-history", params)
      } else {
        onShowMessage({
          type: "error",
          title: "新しいタブで開く",
          body: resDownloadFile.error.message,
        });
      }
    } else {
      onShowMessage({
        type: "error",
        title: "プリサインドURLを取得",
        body: resGetUrl.error.message,
      });
    }
    
    setLoadingFlag(false);
  }
  // #endregion Amazon S3
  
  // #region pagination
  const onChangePaginationCondition = (name: string, value: any) => {
    if (name === "limit") {
      setValue("searchItemForm.limit", value);
      setValue("searchItemForm.page", 1);
    } else {
      setValue("searchItemForm.page", value);
    }

    handlePagination(displayFileList);
  }
  
  const handlePagination = (displayList: any[]) => {
    if (displayList.length == 0) {
      setPageData(initialPageData);
      if (gridRef && gridRef.current) {
        gridRef.current.setRowData([]);
      }
    } else {
      const totalCount = displayList.length;
      const pageSize = getValues("searchItemForm.limit");
      const page = getValues("searchItemForm.page");
      const totalPage = Math.ceil(totalCount / pageSize);
      const rowFrom = (pageSize * page - pageSize) + 1;
      const rowTo = Math.min((pageSize * page), totalCount);
      const updateData: PageData = {
        rowFrom: rowFrom.toString(),
        rowTo: rowTo.toString(),
        totalRow: totalCount.toString(),
        currPage: page.toString(),
        totalPage: totalPage.toString(),
      };
      setPageData(updateData);
      if (gridRef && gridRef.current) {
        gridRef.current.setRowData(displayList.slice(rowFrom-1, rowTo));
      }
    }
  }
  // #endregion

  return (
    <>
      {loadingFlag && (
        <div
          style={{
            top: "0px",
            left: "0px",
            position: "fixed",
            zIndex: 1500,
            width: "100%",
            height: "100%",
            padding: "50vh 50% 50% 50%",
            background: "#00000030",
          }}
        >
          <CircularProgress />
        </div>
      )}

      {openDeleteDialog && 
        <AlertDialog
          title={`ファイル「${fileSelected && fileSelected.fileName}」を削除`} 
          message={"このファイルを削除しますか?"} 
          isOpen={true}
          onOK={handleDeleteFile} 
          onCancel={() => setOpenDeleteDialog(false)} 
        />
      }

      <UploadFileDiaglog
        openDialog={openUploadFileDiaglog}
        setOpenDialog={setOpenUploadFileDiaglog}
        listAllControls={listAllControls}
        controlId={controlIdSelected}
        setControlId={setControlIdSelected}
        onOK={handleAddFile}
      />

      <Stack style={{ background: "#F9FAFB" }}>
        <Stack alignSelf={"center"} height={"100%"} width={"95%"}>
          <Stack mt={1} flexDirection={'row'} justifyContent={'space-between'}>
            <Typography variant="h6" fontWeight={"bold"}>メンテナンスデータ{projectData.projectName && ` ${projectData.projectName}`}</Typography>
            <Button color="inherit" variant="contained" onClick={handleBack} startIcon={<ArrowBack />}>戻る</Button>
          </Stack>

          {/* search box */}
          <Stack mt={1} sx={{border:'3px solid #ccc', borderRadius:'8px', width:'75%'}}>
            <form onSubmit={handleSubmit(handleEditSubmit)}>
              <Stack ml={2}>
                <Stack mt={1}><Typography variant="h6">検索条件</Typography></Stack>
                <Stack flexDirection={'row'} mt={2} mb={1}>
                  <Stack>
                    <Controller
                      name="controlName"
                      control={control}
                      render={({ field }: any) => (
                        <TextField 
                          {...field}
                          type="text"
                          size="small"
                          fullWidth
                          InputProps={{ sx: { height: 36 }}}
                          label={<Typography variant="body2" color={'black'}>機器名</Typography>}
                        /> 
                      )}
                    />
                  </Stack>
                  <Stack ml={1}>
                    <Controller
                      name="fileName"
                      control={control}
                      render={({ field }: any) => (
                        <TextField 
                          {...field}
                          type="text"
                          size="small"
                          fullWidth
                          InputProps={{ sx: { height: 36 }}}
                          label={<Typography variant="body2" color={'black'}>ファイル名</Typography>}
                        /> 
                      )}
                    />
                  </Stack>
                  <Stack direction='row' ml={2}>
                    <Button color = "info" variant="outlined" onClick={handleClear}>クリア</Button>
                    <Button variant="contained" style={{marginLeft:'8px'}} type="submit">検索</Button>
                  </Stack>
                </Stack>
              </Stack>
            </form>
          </Stack>
            
          <Stack py={1} sx={{ flexDirection: "row", justifyContent: "flex-end" }}>
            <Button
              size="medium"
              variant="contained"
              startIcon={<AddCircleOutlineOutlined />}
              component='label'
              onClick={() => {setOpenUploadFileDiaglog(true)}}
              disabled={disableButtonAddFile}
            >
              ファイル追加
            </Button>
          </Stack>
          <MaitainanceGrid
            ref={gridRef}
            pageData={pageData}
            pageSize={getValues('searchItemForm.limit')}
            handleDownloadFile={handleDownloadFile}
            handleOpenDeleteDialog={handleOpenDeleteDialog}
            handleOpenFileInNewTab={handleOpenFileInNewTab}
            onChangePaginationCondition={onChangePaginationCondition}
          />
        </Stack>
      </Stack>
    </>
  );
});

const mapStateToProps = (state: any) => {
  return {
    user: state.app.user,
    processMode: state.app.diagram.processMode,
    projectData: state.app.projectData,
    diagramDataList: state.app.diagram.diagramData,
    currentTabId: state.app.diagram.currentIDDiagramTab,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    onShowMessage: (message: Model.Message) => dispatch(actionCreators.showMessage(message)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceSreen);
