import { Box, ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Stack, Typography } from "@mui/material"
import theme from "../themes/globalStyles"
import { useNavigate } from "react-router-dom";
import { Path as RoutePath } from '../path';
import logo from "../images/icon/MSSV3_logo.png";
import AccountPopover from "../components/AccountPopover";
import { Home, NavigateNext, Settings as SettingsIcon, Notifications, Error } from '@mui/icons-material';
import { useSelector } from "react-redux";
import { ApplicationState } from "../store";
import React, { useEffect, useState } from "react";
import { CONTROL_GRAPH_SCREEN, CONTROL_SCREEN, GRAPH_SCREEN, MenuOptions, ROLE_ADMIN, ROLE_LP, ROLE_PM, ROLE_SP } from "../models/Constants";
import NoticeDialog from "../components/notification/NoticeDialog";
import sharing_project from "../images/icon/sharing_project.png";
import { GROUP_PERMISSION, PROJECT_DISPLAYED } from "../statics";

const getMenuData = (
    modePM: boolean,
    modeViewOnly: boolean,
    hasSelectedElement: boolean,
    hasCopyShapes: boolean,
    hasOpenGraphScreen:boolean,
    hasUndo: boolean,
    hasOpenEditGraphName:boolean,
    openScreen: any,
    isJapaneseElectricMode: any
) => {
    return [
        {
            Key: MenuOptions.FILE,
            DispText:"ファイル",
            SubItem: [
                // {
                //     Key: MenuOptions.FILE_SAVE,
                //     DispText:"上書き保存",
                //     SubItem: []
                // },
                // {
                //     Key: MenuOptions.FILE_SAVE_AS,
                //     DispText:"名前を付けて保存",
                //     SubItem: []
                // },
                {
                    Key: MenuOptions.FILE_DXF,
                    DispText:"DXF出力",
                    SubItem: []
                },
                {
                    Key: MenuOptions.FILE_CIRCUIT,
                    DispText:"回路情報",
                    SubItem: [],
                    Disabled: modePM
                },
                {
                    Key: MenuOptions.FILE_PDF_MAIN_DIAGRAM,
                    DispText:"PDF出力(単線結線図)",
                    SubItem: [],
                    Disabled: !(openScreen == CONTROL_GRAPH_SCREEN || openScreen == CONTROL_SCREEN)
                },
                {
                    Key: MenuOptions.FILE_PDF_LINE_DIAGRAM,
                    DispText:"PDF出力(保護協調図)",
                    SubItem: [],
                    Disabled: !(hasOpenGraphScreen || openScreen == CONTROL_GRAPH_SCREEN || openScreen == GRAPH_SCREEN)
                },
                {
                    Key: MenuOptions.FILE_PDF_MAIN_AND_LINE_DIAGRAM,
                    DispText:"PDF出力(単線結線図+保護協調図)",
                    SubItem: [],
                    Disabled: !((hasOpenGraphScreen || openScreen == CONTROL_GRAPH_SCREEN || openScreen == GRAPH_SCREEN) && isJapaneseElectricMode)
                },
                {
                    Key: MenuOptions.EDIT_HISTORY,
                    DispText:"編集履歴",
                    SubItem: [],
                    // Disabled: modeViewOnly
                }
            ]
        },
        {
            Key: MenuOptions.EDIT,
            DispText:"編集",
            SubItem: [
                {
                    Key: MenuOptions.EDIT_UNDO,
                    DispText:"元に戻す",
                    SubItem: [],
                    Disabled: (hasUndo !== true) || modeViewOnly
                },
                {
                    Key: MenuOptions.EDIT_CUT,
                    DispText:"切り取り",
                    SubItem: [],
                    Disabled: (hasSelectedElement !== true) || modeViewOnly
                },
                {
                    Key: MenuOptions.EDIT_COPY,
                    DispText:"コピー",
                    SubItem: [],
                    Disabled: (hasSelectedElement !== true) || modeViewOnly
                },
                {
                    Key: MenuOptions.EDIT_PASTE,
                    DispText:"貼り付け",
                    SubItem: [],
                    Disabled: (hasCopyShapes !== true) || modeViewOnly
                },
                {
                    Key: MenuOptions.EDIT_DELETE,
                    DispText:"削除",
                    SubItem: [],
                    Disabled: (hasSelectedElement !== true) || modeViewOnly
                },
                {
                    Key: MenuOptions.EDIT_SEL_ALL,
                    DispText:"すべて選択",
                    SubItem: []
                }
            ]
        },
        {
            Key: MenuOptions.DIAGRAM,
            DispText:"単線結線図",
            SubItem: [
                {
                    Key: MenuOptions.DIAGRAM_SETTING,
                    DispText:"単線結線図の設定",
                    SubItem: [],
                    Disabled: modePM == true || modeViewOnly
                },
                {
                    Key: MenuOptions.DIAGRAM_RE_DEVICE_NAME,
                    DispText:"デバイス名の再採番",
                    SubItem: [],
                    Disabled: modePM == true || modeViewOnly
                },
                {
                    Key: MenuOptions.DIAGRAM_RE_POINT_NAME,
                    DispText:"事故点名称の再採番",
                    SubItem: [],
                    Disabled: modePM == true || modeViewOnly
                },
            ]
        },
        {
            Key: MenuOptions.DISP,
            DispText:"表示",
            SubItem: [
                {
                    Key: MenuOptions.DISP_DIAGRAM,
                    DispText:"単線結線図ウィンドウ",
                    SubItem: []
                },
                {
                    Key: MenuOptions.DISP_GRAPH,
                    DispText:"保護協調図ウィンドウ",
                    SubItem: []
                },
                {
                    Key: MenuOptions.DISP_REPORT,
                    DispText:"レポートウインドウ",
                    SubItem: []
                },
                {
                    Key: MenuOptions.EDIT_ELE_LIST,
                    DispText:"機器リスト",
                    SubItem: []
                },
                {
                    Key: MenuOptions.EDIT_POINT_LIST,
                    DispText:"事故点リスト",
                    SubItem: []
                }
            ]
        },
        {
            Key: MenuOptions.GRAPH,
            DispText:"保護協調図",
            SubItem: [
                {
                    Key: MenuOptions.GRAPH_SETTING,
                    DispText:"保護協調図の設定",
                    SubItem: [],
                    Disabled: !hasOpenEditGraphName || modeViewOnly,
                },
                {
                    Key: MenuOptions.GRAPH_DELETE,
                    DispText:"保護協調図を削除",
                    SubItem: [],
                    Disabled: modePM == true || modeViewOnly ? true :  (hasOpenGraphScreen !== true)
                }
            ]
        },
        {
            Key: MenuOptions.HELP,
            DispText:"ヘルプ",
            SubItem: [
                {
                    Key: MenuOptions.HELP_HELP,
                    DispText:"MSSV3のヘルプ",
                    SubItem: []
                },
                {
                    Key: MenuOptions.HELP_VERSION,
                    DispText:"MSSV3のバージョン情報",
                    SubItem: []
                }
            ]
        }
    ];
}

const DrawSubItem = ({menuItem, isRoot, handleParentClick, handleCheckCopyData}: any) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState(false);
    
    const handleOpenSubClick = (event: React.MouseEvent<HTMLElement>, menuItemKey: string) => {
        if (menuItemKey === MenuOptions.EDIT){
            handleCheckCopyData();
        }
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };
    
    const handleClick = (actionId: any) => {
        let ele = menuItem.SubItem.find((element: any) => element.Key === actionId)
        if (!(ele && ele.Disabled)) handleParentClick(actionId);
        handleClose();
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    const style = {
        width:"100%",
        height: isRoot ? "20px" : "30px",
        paddingTop: isRoot ? "0px":"5px",
        paddingLeft: '16px',
        paddingRight: isRoot || menuItem.SubItem.length == 0 ? '16px': "4px",
        "&:hover": { backgroundColor: "#e6f7ff" },
        color: menuItem.Disabled ? 'grey.500' : 'black'
    }

    return (
        <>
            {
                menuItem.SubItem.length > 0 && (
                    <MenuItem key={menuItem.Key} style={{padding:"0px", height: isRoot ? "20px" : "30px", display:"inline"}}>
                        <Typography onClick={(e) => {handleOpenSubClick(e, menuItem.Key)}} sx={style}variant="body2">
                            {menuItem.DispText} {!isRoot && <NavigateNext fontSize="small" style={{float: 'inline-end'}}/>}
                        </Typography>
                        <Popper
                            open={open}
                            anchorEl={anchorEl}
                            role={undefined}
                            placement={isRoot ? "bottom-start" : "right-start"}
                            style={{zIndex:1000}}
                            transition
                            >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                {...TransitionProps}
                                >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList
                                            autoFocusItem={open}
                                            onKeyDown={handleListKeyDown}
                                        >
                                            {(menuItem.SubItem as []).map((menuSubItem, index1) => (
                                                <DrawSubItem menuItem={menuSubItem} handleParentClick={handleClick}/>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </MenuItem>
                )
            }
            {
                menuItem.SubItem.length == 0 && (
                    <MenuItem onClick={()=>handleParentClick(menuItem.Key)} style={{padding:0}}>
                        <Typography  sx={style} variant="body2">
                            {menuItem.DispText}
                        </Typography></MenuItem>
                )
            }          
            
        </>
    );
}

const MainHeader = ({ 
    user,
    userGroupPermission = null,
    modeProject = false,
    modeHome,
    handleMenuOptions,
    m_bModePM=false,
    modeViewOnly = false,
    hasSelectedElement=false,
    hasCopyShapes=false,
    hasOpenGraphScreen=false,
    hasUndo,
    hasOpenEditGraphName,
    handleCheckCopyData,
} : any) => {
    const projectData = useSelector((state : ApplicationState) => state.app.projectData)
    const projectDisplayed = useSelector((state : ApplicationState) => state.app.projectDisplayed)

    const openScreen = useSelector((state : ApplicationState) => state.app.diagram.isShowScreen)
    const notificationList = useSelector((state : ApplicationState) => state.app.notificationList)
    const isJapaneseElectricMode = useSelector((state : ApplicationState) => state.app.diagram.chartData.find(chartTab => chartTab.tabId === state.app.diagram.currentIDChartTab)?.isJapaneseElectricMode)
    
    const navigate = useNavigate();
   
    const [openNoticeDialog, setOpenNoticeDialog] = useState(false)
    const [hasUnreadNotice, setHasUnreadNotice] = useState(false)
    const [menuData, setMenuData] = useState(
        getMenuData(m_bModePM, modeViewOnly, hasSelectedElement, hasCopyShapes, hasOpenGraphScreen, hasUndo, hasOpenEditGraphName, openScreen, isJapaneseElectricMode)
    )

    useEffect(() => {
        if (notificationList) {
            const hasUnread = notificationList.filter(e => e.unread == true).length > 0
            setHasUnreadNotice(hasUnread)
        } else {
            setHasUnreadNotice(false)
        }
    }, [notificationList])

    useEffect(() => {
        setMenuData(getMenuData(
            m_bModePM, modeViewOnly, hasSelectedElement, hasCopyShapes, hasOpenGraphScreen, hasUndo, hasOpenEditGraphName, openScreen, isJapaneseElectricMode
        ))
    }, [m_bModePM, modeViewOnly, hasSelectedElement, hasCopyShapes,hasOpenGraphScreen, hasUndo, hasOpenEditGraphName, openScreen, isJapaneseElectricMode])

    const handleClickBackBtn = () => {
        navigate(`/projects`)
    }
    const getNameMode =() =>{
        let name = ""
        if(user?.userRole == ROLE_ADMIN){
            name = "Admin"
        }
        else if(user?.userRole == ROLE_PM){
            name = "PM"
        }
        else if(user?.userRole == ROLE_LP){
            name = "LP"
        }
        else if(user?.userRole == ROLE_SP){
            name = "SP"
        }
        return name
    }
    return (<div>
        <NoticeDialog 
            openDialog={openNoticeDialog}
            setOpenDialog={setOpenNoticeDialog}
        />
        <div style = {{width: '100%', display: 'flex', alignItems: "center", justifyContent:"space-between",flexWrap:"nowrap", flexDirection:"row", height:'50px', backgroundColor: theme.palette.secondary.light, borderBottom: !modeHome ?'1px solid #cccfcd' : '' }}> 
            <div style={{ display: "flex", flexDirection:"row" , alignItems:"flex-start"}}>
                {!modeHome && 
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width:"100px", marginLeft:"4px", height:"50px"}}>
                        <img src={logo} style={{width: '100px', height: '40px', cursor: 'pointer'}} onClick={() => navigate(RoutePath.projects)}/>
                    </div> 
                }
                <div style={{ display: "flex", flexDirection:"column" , flexWrap:"nowrap"}}>
                    {modeHome ? (
                        <>
                        <div style={{ height: "30px" }}>
                            <Stack direction="row" justifyContent="flex-start" alignItems={'center'} height={"100%"} spacing={1}>
                                <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                                    {projectData.projectName && `[${projectData.projectName}]`}
                                </Typography>
                                {projectDisplayed === PROJECT_DISPLAYED.SHARED_PROJECT &&
                                    <Stack direction="row" justifyContent="flex-start" alignItems={'center'} style={{width:"100px", height:"25px"}}>
                                        <img src={sharing_project} style={{ width: '25px', height: '25px', marginRight:'2px'}}/>
                                        <Typography variant="subtitle1" fontSize={14}>
                                        {modeViewOnly?"読み取り専用" :"編集可能"}
                                        </Typography>
                                    </Stack>
                                }
                                {(projectDisplayed === PROJECT_DISPLAYED.GROUP_PROJECT && modeViewOnly) &&
                                    <Stack direction="row" justifyContent="flex-start" alignItems={'center'} style={{width:"100px", height:"25px"}}>
                                        <img src={sharing_project} style={{ width: '25px', height: '25px', marginRight:'2px'}}/>
                                        <Typography variant="subtitle1" fontSize={14}>読み取り専用</Typography>
                                    </Stack>
                                }
                            </Stack>
                            
                        </div>
                        <div style={{ display: "flex", height: "20px" , flexWrap:"nowrap"}}>
                            {menuData.map((menuItem, index) => (
                                <DrawSubItem
                                    menuItem={menuItem}
                                    handleParentClick={handleMenuOptions}
                                    isRoot={true}
                                    handleCheckCopyData={handleCheckCopyData}
                                />
                            ))}
                        </div>
                        </>
                    ) : (
                        <div style={{display: "flex", flexDirection:"row", marginTop:"23px", width:"100px"}}>
                        <Box height={"20px"} />
                        <Box ml={2}>
                            <Typography variant="caption">
                                {process.env.REACT_APP_VERSION}
                            </Typography>
                        </Box>
                        </div>
                    )}
                </div>
            </div>
            { modeProject ?
                <Stack flexDirection={"row"}>
                    <Box width={'52px'} height={'52px'} position={'relative'}>
                        <Box position={'absolute'}>
                            <IconButton sx={{color: 'black'}} size="large" onClick={() => {setOpenNoticeDialog(true)}}>
                                <Notifications fontSize="inherit" />
                            </IconButton>
                        </Box>
                        {hasUnreadNotice && 
                            <Box position={'absolute'} right={'10px'} top={'8px'}>
                                <Error sx={{width: 14, height: 14, color:'red', backgroundColor:'white', borderRadius:8}}/>
                            </Box>
                        }
                    </Box>
                    {((user?.userRole && user?.userRole == ROLE_ADMIN) || (userGroupPermission && userGroupPermission.permission == GROUP_PERMISSION.ADMIN)) &&
                        <IconButton sx={{color: '#000'}} size="large" onClick={() => navigate(RoutePath.UserGroupList)}>
                            <SettingsIcon fontSize="inherit" />
                        </IconButton>
                    }
                    <AccountPopover/>
                    <Typography noWrap mr={1} variant="inherit" marginTop={"14px"}>{user?.cognit_user?.getUsername()}{user?.expired?'(参照のみ)':''}</Typography>
                    <Stack mr={1} border={"2px solid #1976d2"} borderRadius={"5px"} height={"25px"} marginTop={"12px"}>
                        <Typography variant="inherit" color={"#1976d2"} marginLeft={"7px"} marginRight={"7px"}>{getNameMode()}</Typography>
                    </Stack> 
                </Stack>
                :
                <Stack flexDirection={"row"}>
                    <IconButton sx={{color: '#000'}} size="large" onClick={handleClickBackBtn}>
                        <Home fontSize="inherit" />
                    </IconButton>
                    <AccountPopover/>
                    <Typography noWrap mr={1} variant="inherit" marginTop={"14px"}>{user?.cognit_user?.getUsername()}{user?.expired?'(参照のみ)':''}</Typography>
                    <Stack mr={1} border={"2px solid #1976d2"} borderRadius={"5px"} height={"25px"} marginTop={"12px"}>
                        <Typography variant="inherit" color={"#1976d2"} marginLeft={"7px"} marginRight={"7px"}>{getNameMode()}</Typography>
                    </Stack> 
                </Stack>
            }
            
        </div>
    </div>)
}

export default MainHeader