import { RS_DIAL_CONTINUE, RS_DIAL_MIX } from "../models/Constants"
import { BaseDetailsModel, AdjustModel, OptionModel, PartDetailsModel, NotificationModel, UserGroupMemberModel } from "../models/Index"
import { MAKE_YEAR_MAX, MAKE_YEAR_MIN, DATA_STATUS } from "../statics"

export function convertGroupProjectList(projectList: any[], userList: any[]) {
    let res: any[] = [];
    if (projectList) {
        res = projectList.map((project: any) => {
            const ownerAWS = userList.find((user) => user.sub === project.owner_sub);
            const lastUpdateUserAWS = userList.find((user) => user.sub === project.last_update_user_sub);
            
            return {
                projectId: project.project_id,
                ownerId: project.owner_id,
                projectName: project.project_name,
                lastUpdateUserId: project.last_update_user_id,
                importProjectId: project.import_project_id,
                importErrorCode: project.import_error_code,
                lastUpdateDatetime: project.last_update_datetime,
                isShared: project.is_shared,
                lockedUserId: project.locked_user_id,
                deleteProjectOnExpor: project.delete_project_on_export,
                ownerSub: project.owner_sub,
                lastUpdateUserSub: project.last_update_user_sub,
                owner: (ownerAWS.family_name || "") + (ownerAWS.given_name || ""),
                nameLastUpdateUser: (lastUpdateUserAWS.family_name || "") + (lastUpdateUserAWS.given_name || "")
            };
        })
    }
    return res;
}

export function convertAllUserGroupList(groupList: any[]) {
    let res: any[] = [];
    if (groupList) {
        res = groupList.map((group: any) => {
            return {
                userGroupId: group.user_group_id,
                userGroupName: group.user_group_name
            };
        })
    }
    return res;
}

export function convertAllUserGroupMemberList(memberList: any[]) {
    let res: any[] = [];
    if (memberList) {
        res = memberList.map((member: any) => {
            return {
                userGroupId: member.user_group_id,
                userId: member.user_id,
                permission: member.permission,
                sub: member.sub
            };
        })
    }
    return res;
}

export function convertMembersToCallSaveAPI(members: UserGroupMemberModel[]) {
    let res: any[] = [];
    if (members) {
        res = members.filter(e => e.status !== DATA_STATUS.ORIGINAL).map((member: UserGroupMemberModel) => {
            return {
                user_group_id: member.userGroupId,
                user_id: member.userId,
                sub: member.sub,
                permission: member.permission,
                action: member.status
            }
        })
    }
    return res;
}

export function convertUserGroupMemberData(members: any[], userList: any[]) {
    let res: UserGroupMemberModel[] = [];
    if (members) {
        res = members.map((member: any) => {
            const infoFromAWS = userList.find((user) => user.sub === member.sub);
            return {
                userGroupId: member.user_group_id,
                userId: member.user_id,
                sub: member.sub,
                username: infoFromAWS.username,
                email: infoFromAWS.email,
                fullName: (infoFromAWS.family_name || "") + (infoFromAWS.given_name || ""),
                permission: member.permission,
                joinedDatetime: member.joined_datetime,
                lastUpdateDatetime: member.last_update_datetime,
                status: DATA_STATUS.ORIGINAL
            };
        })
    }
    return res;
}

export function convertInfoFromAWS(userList: any[]) {
    let res: any[] = [];
    if (userList) {
        for (let i=0; i<userList.length; i++){
            let tmpObj = {username: userList[i].Username};
            const tmpAttr = userList[i].Attributes;
            for (let j=0; j<tmpAttr.length; j++){
                Object.defineProperty(tmpObj, tmpAttr[j].Name, {value: tmpAttr[j].Value});
            }
            res.push(tmpObj);
        }
    }
    return res;
}

export function convertMembersOfAllGroups(memberList: any[]) {
    let res: any[] = [];
    if (memberList) {
        res = memberList.map((member: any) => {
            return {
                userId: member.user_id,
                sub: member.sub,
                userGroupId: member.user_group_id,
                userGroupName: member.user_group_name
            };
        })
    }
    return res;
}

export function convertUserGroupData(data: any[]) {
    let res: any[] = [];
    if (data) {
        res = data.map((e: any) => {
            return {
                userGroupId: e.user_group_id,
                userGroupName: e.user_group_name,
                notes: e.notes,
                numberOfMembers: e.number_of_members
            };
        })
    }
    return res;
}

export function convertNotificationData(data: any[]) {
    let res: NotificationModel[] = [];
    if (data) {
        res = data.map((e: any) => {
            return {
                userId: e.user_id,
                notificationId: e.notification_id,
                unread: e.unread,
                title: e.title,
                content: e.content,
                startDate: e.start_date,
                endDate: e.end_date,
                target: e.target,
                sendMail: e.send_mail,
                dateCreated: e.date_created,
                emailGreeting: e.email_greeting,
                emailSignature: e.email_signature
            };
        })
    }
    return res;
}

export function convertNotiDatetime(datetime: string|null) {
    // '2024-07-18*01:00:00' => 2024年07月18日
    if (!datetime) return ''
    const dateArr = datetime.split('-')
    return dateArr[0] + '年' + dateArr[1] + '月' + dateArr[2].slice(0, 2) + '日'
}

export function convertMaintenanceData(data: any, listAllControls: any[]) {
    let res: any[] = [];
    if (data) {
        res = data.map((e: any) => {
            const arr = e.Key.toString().split("/");
            const filename = arr[arr.length - 1];
            const controlId = arr[arr.length - 2];
            const controlName = listAllControls.find((e: any) => e.id == controlId)?.name;
            return {
                controlName: controlName,
                fileName: filename,
                lastUpdateDatetime: new Date(e.LastModified),
                key: e.Key.toString(),
            };
        })
    }
    return res;
}

export function formatDatetimeYMDHMS (datetime: any) {
    // YYYY-MM-DD HH:MM:SS
    const dateString =  
        convertNumberToString(datetime.getFullYear())
        + "-" + convertNumberToString((datetime.getMonth()+1))
        + "-" + convertNumberToString(datetime.getDate()) 
        + " " + convertNumberToString(datetime.getHours()) 
        + ":" + convertNumberToString(datetime.getMinutes()) 
        + ":" + convertNumberToString(datetime.getSeconds());
    return dateString;
}

export function formatDatetimeYMDHMS2 (datetime: any) {
    // 2024-10-15T19:51:32.493124 => YYYY/MM/DD HH:MM:SS
    if (!datetime) return ""
    const tmpArr = datetime.split("T")
    const dateString =
        tmpArr[0].replaceAll("-", "/")
        + " " + tmpArr[1].slice(0, 8)
    return dateString;
}

function convertNumberToString (time: number) {
    if (time < 10) return "0" + time.toString();
    return time.toString();
}

export function yyyymmdd2Date (dateString: string) {
    var year = dateString.substring(0,4);
    var month = dateString.substring(4,6);
    var day = dateString.substring(6,8);

    var date = new Date(+year, +month-1, +day); // +string: string to number
    return date;
}

export function convertGraphDataFromBE (graphData: any, infoCircuit: any) {
    return {
        graphNo: graphData.graph_no,
        graphKind: graphData.graph_kind,
        nominalVolt: graphData.nominal_volt,
        thickInFrame: graphData.thick_in_frame,
        printDetailHead: graphData.print_detail_head_view2,
        kindInFrame: graphData.kind_in_frame,
        colorInFrame: graphData.color_in_frame,
        normalXMin: graphData.normal_x_min,
        normalXMax: graphData.normal_x_max,
        normalYMin: graphData.normal_y_min,
        normalYMax: graphData.normal_y_max,
        societyXMin: graphData.society_x_min,
        societyXMax: graphData.society_x_max,
        societyYMin: graphData.society_y_min,
        societyYMax: graphData.society_y_max,
        zoom: graphData.zoom,
        dispBand: graphData.disp_band,
        marginTop: graphData.margin_top,
        marginBottom: graphData.margin_bottom,
        marginLeft: graphData.margin_left,
        marginRight: graphData.margin_right,
        orientation: graphData.orientation,
        society: graphData.society,
        graphName: graphData.graph_name,
        figTitle: graphData.fig_title,
        figSubTitle: graphData.fig_sub_title,
        revision1: graphData.revision1,
        titleDrawn: graphData.title_drawn,
        drawner: graphData.drawner,
        titleDateDrawn: graphData.title_date_drawn,
        dateDrawn: graphData.date_drawn,
        revision2: graphData.revision2,
        // 
        titleReference: graphData.title_reference,
        reference: graphData.reference,
        titleChecked: graphData.title_checked,
        checker: graphData.checker,
        titleDateChecked: graphData.title_date_checked,
        dateChecked: graphData.date_checked,
        revision3: graphData.revision3,
        //
        titleNumber: graphData.title_number,
        figNumber: graphData.fig_number,
        titleDesigned: graphData.title_designed,
        designer: graphData.designer,
        titleDateDesigned: graphData.title_date_designed,
        dateDesigned: graphData.date_designed,
        revision4: graphData.revision4,
        // 
        titleMode: graphData.title_mode,
        titleApproved: graphData.title_approved,
        approver: graphData.approver,
        titleDateApproved: graphData.title_date_approved,
        dateApproved: graphData.date_approved,
        revision5: graphData.revision5,
        // 
        titleComment: graphData.title_comment,
        comments: graphData.comments,
        titleCorporation: infoCircuit.titleCorporation,
        corporation: infoCircuit.corporation,
        titleProject: infoCircuit.titleProject,
        project: infoCircuit.project
    }
}

export function addListToData (oldData: any, newData: any) {
    oldData.voltageList = newData.voltageList

    if (newData.details){
        oldData.details.parts = newData.details.parts
        if (oldData.details.base){
            oldData.details.base = {...oldData.details.base, ...newData.details.base}
            oldData.details.base['ctPrimaryList'] = newData.ctPrimaryList
        } else 
            oldData.details.base = newData.details.base
    }

    return oldData
}

export const convertDataFromBE = (BEData: any) => {
    let voltageList = convertVoltToOptionList(BEData.voltageList);

    // details
    let base = getBaseFromBE(BEData.details.base);

    // TODO: detail.parts: HOW to get old data when open ??
    let parts = getPartsFromBE(BEData.details.parts);

    let delaySettings = null;
    if (BEData.details.base.useOpenTime === true || BEData.details.base.useBreakingTime === true)
        delaySettings = getDelaySettingsFromBE()

    // ModePM
    let makeYearList: OptionModel[] = [];
    for (let i = MAKE_YEAR_MAX; i >= MAKE_YEAR_MIN; i--){
        let optionModel: OptionModel = {
            label: i?.toString(),
            value: i
        };
        makeYearList.push(optionModel)
    }
    let makeMonthList: OptionModel[] = [];
    for (let i = 1; i <= 12; i++){
        let optionModel: OptionModel = {
            label: i?.toString(),
            value: i
        };
        makeMonthList.push(optionModel)
    }
    return {
        voltageList: voltageList,
        details: {
            base: base,
            parts: parts,
            delaySettings: delaySettings
        },
        // ModePM
        makeYearList: makeYearList,
        makeMonthList: makeMonthList,
    }
}

export const convertDetailsDataFromBE = (details: any) => {
    let base: any = getBaseFromBE(details.base);
    delete base.ctPrimaryList;

    let parts = getPartsFromBE(details.parts);

    let delaySettings = null;
    if (details.base.useOpenTime === true || details.base.useBreakingTime === true)
        delaySettings = getDelaySettingsFromBE()

    return {
        base: base,
        parts: parts,
        delaySettings: delaySettings
    }
}

export function addListToHVCB (oldData: any, newData: any) {
    oldData.voltageList = newData.voltageList

    oldData.details.base = {...oldData.details.base, ...newData.details.base}
    oldData.details.base['ctPrimaryList'] = newData.ctPrimaryList
    oldData.details.parts = newData.details.parts
    
    // ModePM
    oldData.equipManufactureList = newData.equipManufactureList
    return oldData
}

export const convertHVCBDataFromBE = (BEData: any) => {
    let voltageList = convertVoltToOptionList(BEData.voltageList);
    // ModePM
    let makeInfoList = getMakeInfoList(BEData.equipManufactureList);

    // details
    let base = getBaseFromBE(BEData.details.base);

    // TODO: detail.parts: HOW to get old data when open ??
    let parts = getPartsFromBE(BEData.details.parts);

    let delaySettings = null;
    if (BEData.details.base.useOpenTime === true || BEData.details.base.useBreakingTime === true)
        delaySettings = getDelaySettingsFromBE()
    
    return {
        voltageList: voltageList,
        details: {
            base: base,
            parts: parts,
            delaySettings: delaySettings,
        } as AdjustModel,
        // ModePM
        ...makeInfoList
    }
}

export const convertTrans1ScottData = (responseData: any) => {
    let voltagePrimaryList = convertVoltToOptionList(responseData.voltageHighList);
    let voltageSecondaryList = convertVoltToOptionList(responseData.voltageLowList);
    let capacityList = convertCapacityToOptionList(responseData.capacityList);
    // ModePM
    let makeInfoList = getMakeInfoList(responseData.equipManufactureList);
    
    return {
        voltagePrimaryList: voltagePrimaryList,
        voltageSecondaryList: voltageSecondaryList,
        capacityList: capacityList,
        // ModePM
        ...makeInfoList
    }
}

export const convertTrans3Data = (responseData: any) => {
    let voltageList = convertVoltToOptionList(responseData.voltageList);
    let capacityList = convertCapacityToOptionList(responseData.capacityList);
    // ModePM
    let makeInfoList = getMakeInfoList(responseData.equipManufactureList);

    return {
        voltagePrimaryList: voltageList,
        voltageSecondaryList: voltageList,
        capacityList: capacityList,
        // ModePM
        ...makeInfoList
    }
}

export const convertVoltToOptionList = (data: any) => {
    let res: OptionModel[] = [];
    if (data){
        data.forEach((element: any) => {
            if(element.lvoltage !== null){
            let optionModel: OptionModel = {
                label: element.lvoltage?.toString(),
                value: element.lvoltage
            };
            res.push(optionModel)
        }
        });
    }
    return res
}

const convertCapacityToOptionList = (data: any) => {
    let res: OptionModel[] = [];
    if (data){
        data.forEach((element: any) => {
            if (element >= 0){
                let optionModel: OptionModel = {
                    label: element?.toString(),
                    value: element
                };
                res.push(optionModel)
            }
        });
    }
    return res
}

const getMakeInfoList = (data: any) => {
    // ModePM
    let makerNameList: OptionModel[] = [];
    if (data){
        data.forEach((element: any) => {
            let optionModel: OptionModel = {
                label: element.smanuName,
                value: element.smanuName
            };
            makerNameList.push(optionModel)
        });
    }

    let makeYearList: OptionModel[] = [];
    for (let i = MAKE_YEAR_MAX; i >= MAKE_YEAR_MIN; i--){
        let optionModel: OptionModel = {
            label: i?.toString(),
            value: i
        };
        makeYearList.push(optionModel)
    }
    let makeMonthList: OptionModel[] = [];
    for (let i = 1; i <= 12; i++){
        let optionModel: OptionModel = {
            label: i?.toString(),
            value: i
        };
        makeMonthList.push(optionModel)
    }

    return {
        // ModePM
        makerNameList: makerNameList,
        makeYearList: makeYearList,
        makeMonthList: makeMonthList,
    }
}

// 機器詳細ダイアログ -- 形式タブ
const getBaseFromBE = (BaseData: any) => {
    let ctPrimaryList: OptionModel[] = [];
    if (BaseData.ctPrimaryList && BaseData.ctPrimaryList.length > 0){
        BaseData.ctPrimaryList.forEach((element: any) => {
            let optionModel: OptionModel = {
                label: element.dct1Current?.toString(),
                value: element.dct1Current
            };
            ctPrimaryList.push(optionModel)
        });
    }

    let manufacturerList: OptionModel[] = [];
    if (BaseData.manufacturerList && BaseData.manufacturerList.length > 0){
        BaseData.manufacturerList.forEach((element: any) => {
            let optionModel: OptionModel = {
                label: element.smanuName?.toString(),
                value: element.lmanufactureID
            };
            manufacturerList.push(optionModel)
        });
    }

    let kindList: OptionModel[] = [];
    if (BaseData.kindList && BaseData.kindList.length > 0){
        BaseData.kindList.forEach((element: any) => {
            let optionModel: OptionModel = {
                label: element.sgroupName?.toString(),
                value: element.lgroupID
            };
            kindList.push(optionModel)
        });
    }

    let typeList: OptionModel[] = [];
    let typeName: any = null;
    if (BaseData.typeList && BaseData.typeList.length > 0){
        BaseData.typeList.forEach((element: any) => {
            if (BaseData.typeID != null && BaseData.typeID == element.ltypeID){
                typeName = element.stypeName?.toString()
            }
            if(element.hidden == false){
                let optionModel: OptionModel = {
                    label: element.stypeName?.toString(),
                    value: element.ltypeID
                };
                typeList.push(optionModel)
            }
        });
    }

    let opeKindList: OptionModel[] = [];
    if (BaseData.opeKindList && BaseData.opeKindList.length > 0){
        BaseData.opeKindList.forEach((element: any) => {
            let optionModel: OptionModel = {
                label: element.sopeKindName?.toString(),
                value: element.lopeKindID
            };
            opeKindList.push(optionModel)
        });
    }

    let ratedRangeList: OptionModel[] = [];
    if (BaseData.ratedRangeList && BaseData.ratedRangeList.length > 0){
        BaseData.ratedRangeList.forEach((element: any) => {
            let optionModel: OptionModel = {
                label: element.sfixedName?.toString(),
                value: element.lbreakRatedID
            };
            ratedRangeList.push(optionModel)
        });
    }

    let ratedList: OptionModel[] = [];
    let ratedValue = 0
    let ratedSelectionValue = 0
    let ratedID = BaseData.ratedID;
    if (BaseData.ratedList && BaseData.ratedList.length > 0){
        if (BaseData.ratedFlag === 3 || BaseData.ratedFlag === 4){
            let firstElement = BaseData.ratedList[0]
            ratedList = convertToOptionList(firstElement, false, true)
            if (BaseData.ratedValue) {
                ratedSelectionValue = BaseData.ratedValue
                ratedValue = BaseData.ratedValue
            } else {
                ratedSelectionValue = firstElement.dcontiDefValue
                ratedValue = firstElement.dcontiDefValue
            }
        } else {
            BaseData.ratedList.forEach((element: any) => {
                if (element.lbreakRatedID === ratedID) {
                    ratedSelectionValue = element.lbreakRatedID
                    ratedValue = element.dfixedValue
                }
                let optionModel: OptionModel = {
                    label: element.sfixedName?.toString(),
                    value: element.lbreakRatedID,
                    itemValue: element.dfixedValue,
                    itemId: element.lbreakRatedID,
                };
                ratedList.push(optionModel)
            });
        }
    }

    let magniValue = null
    let magniList: OptionModel[] = [];
    if (BaseData.magniList && BaseData.magniList.length > 0){
        if (BaseData.magniFlag === 3){
            let firstElement = BaseData.magniList[0]
            magniList = convertToOptionList(firstElement)
            magniValue = firstElement.dcontiDefValue
        } else {
            BaseData.magniList.forEach((element: any) => {
                if(element.bisDefault) magniValue = element.dfixedValue
                let optionModel: OptionModel = {
                    label: element.sfixedName?.toString(),
                    value: element.lbreakMagniID
                };
                magniList.push(optionModel)
            });
        }
    }

    const defaultOpeKind = BaseData.opeKindList?.find((ope : any) => ope.lopeKindID === BaseData.opeKindID)
    const ratedUnit = defaultOpeKind?.sratedUnit
    const magniUnit = defaultOpeKind?.smagniUnit
    
    const kindFlag = BaseData.kindList?.find((kind : any) => kind.lgroupID === BaseData.kindID)?.nkindFlag
    const ratedFlag = BaseData.ratedFlag
    const magniFlag = BaseData.magniFlag

    return {
        manufacturerList: manufacturerList,
        manufacturerID: BaseData.manufacturerID,
        kindList: kindList,
        kindID: BaseData.kindID || "",
        typeList: typeList,
        typeID: BaseData.typeID || "",
        typeName: typeName,
        opeKindTitle: BaseData.opeKindTitle,
        opeKindList: opeKindList,
        opeKindID: BaseData.opeKindID || "",
        ratedRangeList: ratedRangeList,
        ratedRangeID: BaseData.ratedRangeID || "",
        ratedTitle: BaseData.ratedTitle,
        ratedList: ratedList,
        ratedID: ratedID || "",
        ratedValue,
        ratedSelectionValue,    
        magniTitle: BaseData.magniTitle,
        magniList: magniList,
        magniValue: magniValue || "",
        series: BaseData.series,
        oldNew: BaseData.oldNew,
        notes: BaseData.notes,
        // canUseCT: BaseData.canUseCT,
        // canUseCC: BaseData.canUseCC,
        useOpenTime: BaseData.useOpenTime,
        readBase: {
            kindFlag,
            ratedFlag,
            magniFlag,
            magniUnit,
            ratedUnit,
            canUseCT: BaseData.canUseCT,
            canUseCC: BaseData.canUseCC,
        },
        ctPrimaryList: ctPrimaryList
    } as BaseDetailsModel
}

// 機器詳細ダイアログ -- 特性タブ
const getPartsFromBE = (PartsData: any) => {
    let result: any[] = []
    if (PartsData && PartsData.length > 0){
        let newID = 0;
        PartsData.forEach((element: any) => {
            newID += 1;
            let partID = element.partID || newID;
            let setLock = element.setLock || false;

            let charDialList: OptionModel[] = [];
            let characterName: any = null;
            if (element.charDialList && element.charDialList.length > 0){
                if (element.characterName)
                    characterName = element.characterName
                
                element.charDialList.forEach((element2: any) => {
                    if (characterName == null)
                        if (element2.bisDefault) characterName = element2.scharacterName
                    let optionModel: OptionModel = {
                        label: element2.scharacterName?.toString(),
                        value: element2.ldialCharID
                    };
                    charDialList.push(optionModel)
                });
            }
            
            let currentValue: any = null;
            let currentList: OptionModel[] = [];
            if (element.currentList && element.currentList.length > 0){
                if (element.currentValue != null)
                    currentValue = element.currentValue

                if (element.currentFlag === 3){
                    let firstElement = element.currentList[0]
                    currentList = convertToOptionList(firstElement, element.dispCurrentMulti)
                    if (currentValue == null)
                        currentValue = firstElement.dcontiDefValue
                } else {
                    element.currentList.forEach((element2: any) => {
                        if (currentValue == null)
                            if (element2.bisDefault) currentValue = element2.dfixedValue
                        let optionModel: OptionModel = {
                            label: element2.sfixedName?.toString(),
                            value: element2.dfixedValue
                        };
                        currentList.push(optionModel)
                    });
                    currentList = currentList.sort(
                        (a: OptionModel, b: OptionModel) =>
                            parseFloat(a.label) < parseFloat(b.label)
                                ? -1
                                : parseFloat(a.label) > parseFloat(b.label)
                                ? 1
                                : 0
                    );
                }
            }

            let timeValue: any = null
            let timeList: OptionModel[] = [];
            if (element.timeList && element.timeList.length > 0){
                if (element.timeValue != null)
                    timeValue = element.timeValue
                if (element.timeFlag === 3){
                    let firstElement = element.timeList[0]
                    timeList = convertToOptionList(firstElement)
                    if (timeValue == null)
                        timeValue = firstElement.dcontiDefValue
                } else {
                    element.timeList.forEach((element2: any) => {
                        if (timeValue == null)
                            if (element2.bisDefault) timeValue = element2.dfixedValue
                        let optionModel: OptionModel = {
                            label: element2.sfixedName?.toString(),
                            value: element2.dfixedValue
                        };
                        timeList.push(optionModel)
                    });
                    timeList = timeList.sort(
                        (a: OptionModel, b: OptionModel) =>
                            parseFloat(a.label) < parseFloat(b.label)
                                ? -1
                                : parseFloat(a.label) > parseFloat(b.label)
                                ? 1
                                : 0
                    );
                }
            }

            let magniCurrentValue: any = null
            let magniCurrentList: OptionModel[] = [];
            if (element.magniCurrentList && element.magniCurrentList.length > 0){
                if (element.magniCurrentValue != null)
                    magniCurrentValue = element.magniCurrentValue
                if (element.magniCurrentFlag === 3){
                    let firstElement = element.magniCurrentList[0]
                    magniCurrentList = convertToOptionList(firstElement)
                    if (magniCurrentValue == null)
                        magniCurrentValue = firstElement.dcontiDefValue
                } else {
                    element.magniCurrentList.forEach((element2: any) => {
                        if (magniCurrentValue == null)
                            if (element2.bisDefault) magniCurrentValue = element2.dfixedValue
                        let optionModel: OptionModel = {
                            label: element2.sfixedName?.toString(),
                            value: element2.dfixedValue
                        };
                        magniCurrentList.push(optionModel)
                    });
                    magniCurrentList = magniCurrentList.sort(
                        (a: OptionModel, b: OptionModel) =>
                            parseFloat(a.label) < parseFloat(b.label)
                                ? -1
                                : parseFloat(a.label) > parseFloat(b.label)
                                ? 1
                                : 0
                    );
                }
            }

            let magniTimeValue: any = null
            let magniTimeList: OptionModel[] = [];
            if (element.magniTimeList && element.magniTimeList.length > 0){
                if (element.magniTimeValue != null)
                    magniTimeValue = element.magniTimeValue
                if (element.magniTimeFlag === 3){
                    let firstElement = element.magniTimeList[0]
                    magniTimeList = convertToOptionList(firstElement)
                    if (magniTimeValue == null)
                        magniTimeValue = firstElement.dcontiDefValue
                } else {
                    element.magniTimeList.forEach((element2: any) => {
                        if (magniTimeValue == null)
                            if (element2.bisDefault) magniTimeValue = element2.dfixedValue
                        let optionModel: OptionModel = {
                            label: element2.sfixedName?.toString(),
                            value: element2.dfixedValue
                        };
                        magniTimeList.push(optionModel)
                    });
                    magniTimeList = magniTimeList.sort(
                        (a: OptionModel, b: OptionModel) =>
                            parseFloat(a.label) < parseFloat(b.label)
                                ? -1
                                : parseFloat(a.label) > parseFloat(b.label)
                                ? 1
                                : 0
                    );
                }
            }

            const defaultDialChar = element.charDialList?.find((charDial : any) => charDial.charDialID = element.charDialID)
            const isLink = defaultDialChar?.bisLink 
            const linkNumber = defaultDialChar?.nlinkNumber
            const isCurrent = defaultDialChar?.bisCurrent  
            const isNoneSadamu = defaultDialChar?.bisNoneSadamu  
            const currentNotes = defaultDialChar?.scurrentNotes
            const currentUnit = defaultDialChar?.scurrentUnit
            const magniCurrentUnit = defaultDialChar?.smagniCurrentUnit
            const magniTimeUnit = defaultDialChar?.smagniTimeUnit
            const timeUnit = defaultDialChar?.stimeUnit
            const dispCurrentMulti = defaultDialChar?.bdispCurrentMulti

            let partDetailsModel: PartDetailsModel = {
                partID: partID,
                charDialList: charDialList,
                charDialID: element.charDialID || "",
                setLock: setLock,
                useLock: element.useLock,
                characterName: characterName,
                currentFlag: element.currentFlag,
                currentTitle: element.currentTitle,
                currentList: currentList,
                currentValue: currentValue || "",
                timeFlag: element.timeFlag,
                timeTitle: element.timeTitle,
                timeList: timeList,
                timeValue: timeValue || "",
                magniCurrentFlag: element.magniCurrentFlag,
                magniCurrentTitle: element.magniCurrentTitle,
                magniCurrentList: magniCurrentList,
                magniCurrentValue: magniCurrentValue || "",
                magniTimeFlag: element.magniTimeFlag,
                magniTimeTitle: element.magniTimeTitle,
                magniTimeList: magniTimeList,
                magniTimeValue: magniTimeValue || "",
                tabName: element.elementName,
                readPart: {
                    isLink,
                    linkNumber,
                    isCurrent,
                    isNoneSadamu,
                    currentNotes,
                    currentUnit,
                    magniCurrentUnit,
                    magniTimeUnit,
                    timeUnit,
                    dispCurrentMulti,
                }
            }
            result.push(partDetailsModel)
        });
        
    }

    return result;
}

// 機器詳細ダイアログ -- 遅延設定タブ
const getDelaySettingsFromBE = () => {
    let breakingTimeHzList: OptionModel[] = [];
    for (let i = 2; i <= 60; i++){
        breakingTimeHzList.push({label: i?.toString(), value: i});
    }

    return {
        breakingTime: false,
        breakingTimeHzList: breakingTimeHzList,
        breakingTimeHzID: 3
    }
}

const convertToOptionList = (e: any, dispCurrentMulti: boolean = false, isRated : boolean = false) => {
    let result: OptionModel[] = [];
    let step = e.dcontiWidthValue > 0 ? e.dcontiWidthValue : 1
    for (
        let i = e.dcontiMiniValue;
        i <= e.dcontiMaxValue;
        i = Number.parseFloat((i + step).toFixed(6))
    ) {
        let value = i

        let optionModel: OptionModel 
        if (!isRated) {
            optionModel = {
                label: dispCurrentMulti ? (value / 100)?.toString() : value?.toString(),
                value: value,
            };
        } else {
            optionModel = {
                label: dispCurrentMulti ? (value / 100)?.toString() : value?.toString(),
                value: value,
                itemId: e.lbreakRatedID,
                itemValue: value,
            };

        }
        result.push(optionModel)
    }
    return result;
}

export const convertBaseFromBEToFE = (baseData: any) => {
    return {
        // manufacturerList: [],
        manufacturerID: baseData.manufacture_id,
        // kindList: [],
        kindID: baseData.group_id,
        // typeList: [],
        typeID: baseData.type_id,
        opeKindTitle: baseData.opeKindTitle, //?
        // opeKindList: [],
        opeKindID: baseData.ope_kind_id,
        // ratedRangeList: [],
        ratedRangeID: baseData.ratedRangeID, //?
        ratedTitle: baseData.ratedTitle, //?
        // ratedList: [],
        ratedID: baseData.break_rated_id,
        ratedValue: baseData.rated_value,
        ratedSelectionValue: baseData.rated_flag === RS_DIAL_CONTINUE || baseData.rated_flag === RS_DIAL_MIX  ? baseData.rated_value : baseData.break_rated_id,
        magniTitle: baseData.magniTitle, //?
        // magniList: [],
        magniValue: baseData.magniValue,
        series: baseData.series, //?
        oldNew: baseData.oldNew, //?
        notes: baseData.notes, //?
        useCT: baseData.use_ct,
        useCC: baseData.use_cc,
        typeName: baseData.type_name,
        fixedName: baseData.fixed_name,
        readBase: {
            kindFlag: baseData.kindFlag, //?
            ratedFlag: baseData.ratedFlag, //?
            magniFlag: baseData.magniFlag, //?
            magniUnit: baseData.magniUnit, //?
            ratedUnit: baseData.ratedUnit, //?
            canUseCT: baseData.canUseCT, //?
            canUseCC: baseData.canUseCC //?
        },
        kindFlag: baseData.kind_flag,
        setBreakingTime: baseData.set_breaking_time,
        setBreakHz: baseData.set_break_hz,
        breakTimeHz: baseData.break_time_hz,
        useOpenTime: baseData.set_open_time,
        
        // ctPrimaryList: [],
        ctPrimary: baseData.ct1_current,
        ctSecondaryList: [
            {label: '1', value: 1},
            {label: '5', value: 5},
        ],
        ctSecondary: baseData.ct2_current,
    }
}
export const doConvertDataUserCurve =(listUserCurve:any[],listUserPoint:any[]) =>{
    let sort = listUserCurve.map((userCurve:any) => {return {
        refNo: userCurve.ref_no,
        noUser: userCurve.no_user,
        name: userCurve.name,
        notes: userCurve.notes,
        freeOrCircuitVolt: Number(userCurve.free_volt),
        voltage: userCurve.voltage,
        stdVoltage: userCurve.voltage,
        calcCircuitVolt: userCurve.calc_circuit_volt,
        formulaProperties: {
            formula: userCurve.formula,
            currentMax: userCurve.current_max,
            currentMin: userCurve.current_min,
            currentInterval: userCurve.current_interval,
            currentPartition: userCurve.current_partition,
            timeMax: userCurve.time_max,
            timeMin: userCurve.time_min,
            checkTime: userCurve.check_time,
            checkCurrent: userCurve.check_current
        },
        remProperties:{
            puScale: userCurve.pu_scale,
            stallTime: userCurve.stalltime,
            weightingFactor: userCurve.weighting_factor,
            timeConstant: userCurve.time_constant,
            ambientTemperature: userCurve.ambient_temperature,
            ratedPower: userCurve.rated_power,
            ratedVoltage: userCurve.rated_voltage,
            ratedCurrent: userCurve.rated_current,
            startingCurrent: userCurve.starting_current,
            startingTime: userCurve.starting_time,
            input5Aor1A: Number(userCurve.input_5a_or_1a),
            ctPrimary: userCurve.ct_primary,
            ctSecondary: userCurve.ct_secondary,
            shortCircuitCurrent: userCurve.short_circuit_current,
            shortOperateTime: userCurve.short_operate_time,
            shortCircuitUse: userCurve.short_circuit_use,
            priorLoadCurrent: userCurve.prior_load_current,
        },
        pageIndex: userCurve.page_index,
        userCurveId: userCurve.user_curve_id,
        listPoint: listUserPoint.sort(function(a, b){return a.user_curve_id - b.user_curve_id}).sort(function(a, b){return a.user_curve_point_id - b.user_curve_point_id}).filter((item:any) => 
                                        item.user_curve_id === userCurve.user_curve_id).map((curveUserPoint:any) => 
            { return {
                user_curve_id: curveUserPoint.user_curve_id,
                x: curveUserPoint.x,
                y:curveUserPoint.y
          }})
      }})
      
    return sort.sort(function(a, b){return a.userCurveId - b.userCurveId});
}
export const convertPartsFromBEToFE = (partsData: any[]) => {
    let result: PartDetailsModel[] = []
    if (partsData.length > 0){
        partsData.forEach((element: any) => {
            let partDetailsModel: PartDetailsModel = {
                partID: element.part_id,
                charDialList: [],
                charDialID: element.dial_char_id,
                setLock: element.set_lock,
                useLock: element.useLock, //?
                characterName: element.characterName,
                currentFlag: element.currentFlag, //?
                currentTitle: element.currentTitle, //?
                currentList: [],
                currentValue: element.current_value,
                timeFlag: element.timeFlag, //?
                timeTitle: element.timeTitle, //?
                timeList: [],
                timeValue: element.timeValue, //?
                magniCurrentFlag: element.magniCurrentFlag, //?
                magniCurrentTitle: element.magniCurrentTitle, //?
                magniCurrentList: [],
                magniCurrentValue: element.magni_current_value,
                magniTimeFlag: element.magniTimeFlag, //?
                magniTimeTitle: element.magniTimeTitle, //?
                magniTimeList: [],
                magniTimeValue: element.magniTimeValue, //?
                tabName: element.elementName, //?
                readPart: {
                    isLink: element.isLink, //?
                    linkNumber: element.linkNumber, //?
                    isCurrent: element.isCurrent, //?
                    isNoneSadamu: element.isNoneSadamu, //?
                    currentNotes: element.currentNotes, //?
                    currentUnit: element.currentUnit, //?
                    magniCurrentUnit: element.magniCurrentUnit, //?
                    magniTimeUnit: element.magniTimeUnit, //?
                    timeUnit: element.timeUnit, //?
                    dispCurrentMulti: element.dispCurrentMulti, //?
                }
            }
            result.push(partDetailsModel)
        })
    }
    return result
}

export const convertUndefinedToNull = (obj: any) => {
    let keyList = Object.keys(obj)
    keyList.forEach((e: string) => {
        if (obj[e] === undefined){
            obj[e] = null
        }
    })
    return obj;
}

export const convertAdjustToCallAPI = (details: AdjustModel) => {
    let params = {...details}
    let baseParams = convertBaseToCallAPI(params.base)

    let partsParams: any = []
    if (params.parts.length > 0){
        params.parts.forEach((e) => {
            partsParams.push(convertPartToCallAPI(e))
        })
    }
    return {...params, base: baseParams, parts: partsParams}
}

const convertBaseToCallAPI = (base: BaseDetailsModel) =>  {
    let manuName = base.manufacturerList?.find(
        (e) => e.value === base.manufacturerID
    )?.label || null

    let groupName = base.kindList?.find(
        (e) => e.value === base.kindID
    )?.label || null

    let opeKindName = base.opeKindList?.find(
        (e) => e.value === base.opeKindID
    )?.label || null

    let ratedRangeName = base.ratedRangeList?.find(
        (e) => e.value === base.ratedRangeID
    )?.label || null

    let magniValue = base.magniList?.find(
        (e) => e.value === base.magniValue
    )?.label || null
    // TODO: setOpenHz, openTimeHz, openTimeSec, breakTimeSec

    return {
        manufacturerID: base.manufacturerID,
        manuName,
        groupID: base.kindID || (base.kindID == 0 ? 0 : null),
        groupName,
        kindFlag: base.kindFlag,
        typeID: base.typeID || (base.typeID == 0 ? 0 : null),
        typeName: base.typeName || null,
        opeKindID: base.opeKindID || (base.opeKindID == 0 ? 0 : null),
        opeKindName,
        breakRatedID: base.ratedID || (base.ratedID == 0 ? 0 : null),
        ratedRangeName,
        ratedValue: base.ratedValue,
        magniValue,
        useCT: base.useCT,
        useCC: base.useCC,
        turnNumber: base.turnNumber || (base.turnNumber == 0 ? 0 : null),
        setOpenTime: base.useOpenTime,
        setOpenHz: null,
        openTimeHz: null,
        openTimeSec: null,
        setBreakingTime: base.setBreakingTime,
        setBreakHz: base.setBreakHz,
        breakTimeHz: base.breakTimeHz,
        breakTimeSec: null,
        ct1Current: base.ctPrimary,
        ct2Current: base.ctSecondary
    }
}

const convertPartToCallAPI = (part: PartDetailsModel) =>  {
    let characterName = part.charDialList?.find(
        (e) => e.value === part.charDialID
    )?.label || null
    // TODO: setTimer, setTimerHz, timerHz, timerSec
    return {
        partID: part.partID,
        charDialID: part.charDialID,
        characterName,
        currentValue: 
            part.currentValue
            || (part.currentValue == 0 ? 0 : null),
        magniCurrentValue: 
            part.magniCurrentValue
            || (part.magniCurrentValue == 0 ? 0 : null),
        setLock: part.setLock,
        setTimer: null,
        setTimerHz: null,
        timerHz: null,
        timerSec: null,
        timeValue: part.timeValue
            || (part.timeValue == 0 ? 0 : null),
        magniTimeValue: part.magniTimeValue
            || (part.magniTimeValue == 0 ? 0 : null)
    }
}