import { Auth } from "aws-amplify";
import axios from 'axios';
import { store } from "../";
import { SessionExpiredAction } from "../store/AppStore";
import { setProjectOpenTime } from "../store/Actions";

export interface callApiResult {
    success: boolean;
    data?: any;
    error?: any
};

const getToken = async () => {
    let token = "";
    try {
        const session = await Auth.currentSession()
        token = session.getIdToken().getJwtToken()
    } catch (error) {
        store.dispatch({
            type: "SESSION_EXPIRED",
        } as SessionExpiredAction);
    }
    return token;
};

export const post = async (url: string, params: object | null) => {
    const token = await getToken();
    params = checkUrlForAddTimeToParams(url, params);
    try {
        const res = await axios.post(`${process.env.REACT_APP_APIGW_URL}${url}`, params, { headers: { 'Authorization': token } })

        checkNewProjectOpenTime(res.data)
        return {
            success: res.status === 200,
            data: res.data,
        } as callApiResult;
    } catch (err: any) {
        if (err.response && err.response.status === 503){
            redirectMaintenancePage(err.response);
        }
        if (err.response && err.response.status === 406){
            dispatchNotEditable()
        }
        
        return {
            success: false,
            data: null,
            error: err,
        } as callApiResult;
    }
};

export const checkUrlForAddTimeToParams = (url: string, params: object | null) => {
    const addedUlrList = ["/diagram/", "/graph/", "/check/"]
    if (addedUlrList.some((addedUrl) => url.startsWith(addedUrl))){
        const projectOpenTime = store.getState().app.diagram.projectOpenTime
        params = {...params, projectOpenTime: projectOpenTime}
    }
    return params;
}

export const checkNewProjectOpenTime = (data: any) => {
    if ("new_project_open_time" in data){
        store.dispatch(setProjectOpenTime(data.new_project_open_time))
    }
}

export const dispatchNotEditable = () => {
    store.dispatch({
        type: "SHOW_MESSAGE",
        payload: { message:{
            type: "error",
            title: "別のユーザーが編集しています。",
            body: "プロジェクトを開き直すかブラウザを更新してから再度実行してください。",
        }}
    });
}

export const getElements = async (userId: string, editProjectId: string, elementIds: number[],ownerProject:number) => {
    const getElementsUrl = "/project/get_elements";
    const limitNum = 450;
    let elementsData = [] as any;
    const token = await getToken();

    try {
        while (elementIds.length > 0) {
            const paramElementIds = elementIds.splice(0, limitNum);
            let params = {
                requestPrms: {
                    userId: userId,
                    editProjectId: editProjectId,
                    elementIds: paramElementIds,
                    ownerProject: ownerProject
                }
            };

            const res = await axios.post(`${process.env.REACT_APP_APIGW_URL}${getElementsUrl}`, params, { headers: { 'Authorization': token } });
            const succcess = res.status == 200;
            if (succcess) {
                elementsData = [
                    ...elementsData,
                    ...res.data.data.elements
                ]
            } else {
                return {
                    success: false,
                    data: null,
                    error: 'request failed.',
                } as callApiResult;
            }
        }
        return {
            success: true,
            data: elementsData,
        } as callApiResult;

    } catch (err: any) {
        if (err.response && err.response.status === 503){
            redirectMaintenancePage(err.response);
        }
        return {
            success: false,
            data: null,
            error: err,
        } as callApiResult;
    }
};

export const getUser = async (sub: string, username: string) => {
    const token = await getToken();
    const getUserUrl = "/user/get";
    const params = {sub, username}
    try {
        const res = await axios.post(`${process.env.REACT_APP_APIGW_URL}${getUserUrl}`, params, { headers: { 'Authorization': token } })
        return {
            success: res.status === 200,
            data: res.data,
        } as callApiResult;
    } catch (err: any) {
        if (err.response && err.response.status === 503){
            redirectMaintenancePage(err.response);
        }
        return {
            success: false,
            data: null,
            error: err,
        } as callApiResult;
    }
};

export const redirectMaintenancePage = (res: any) => {
    // redirect
    const location = res.headers["location"] || "/";
    const XMaintenanceStatus = res.headers["x-maintenance-status"]
    if (XMaintenanceStatus && XMaintenanceStatus == "Active"){
        window.location.replace(location)
    }
}

// #region Amazon S3
export const getGetFileListS3 = async (Key: string) => {
    const token = await getToken();
    const url = "/maintenance/get-file-list";

    const params = {Key}
    try {
        const res = await axios.post(`${process.env.REACT_APP_APIGW_URL}${url}`, params, { headers: { 'Authorization': token } })
        return {
            success: res.status === 200,
            data: res.data.data,
        } as callApiResult;
    } catch (err: any) {
        if (err.response && err.response.status === 503){
            redirectMaintenancePage(err.response);
        }
        return {
            success: false,
            data: null,
            error: err,
        } as callApiResult;
    }
};

export const getUploadFileUrlS3 = async (Key: string, ContentType: string, isMaintenancePage = false) => {
    const token = await getToken();
    const url = "/maintenance/get-upload-url";

    const params: any = {Key, ContentType}
    if (isMaintenancePage) params["isMaintenancePage"] = true
    try {
        const res = await axios.post(`${process.env.REACT_APP_APIGW_URL}${url}`, params, { headers: { 'Authorization': token } })
        return {
            success: res.status === 200,
            data: res.data.data,
        } as callApiResult;
    } catch (err: any) {
        if (err.response && err.response.status === 503){
            redirectMaintenancePage(err.response);
        }
        return {
            success: false,
            data: null,
            error: err,
        } as callApiResult;
    }
};

export const uploadFileToS3 = async (url: string, file: any) => {
    const options = {headers: { 'Content-Type': file.type }};
    try {
        const res = await axios.put(url, file, options)
        return {
            success: res.status === 200,
            data: file.name,
        } as callApiResult;
    } catch (err) {
        return {
            success: false,
            data: null,
            error: err,
        } as callApiResult;
    }
};

export const getDownloadFileUrlS3 = async (Key: string, isMaintenancePage = false) => {
    const token = await getToken();
    const url = "/maintenance/get-download-url";

    const params: any = { Key }
    if (isMaintenancePage) params["isMaintenancePage"] = true
    try {
        const res = await axios.post(`${process.env.REACT_APP_APIGW_URL}${url}`, params, { headers: { 'Authorization': token } })
        return {
            success: res.status === 200,
            data: res.data.data,
        } as callApiResult;
    } catch (err: any) {
        if (err.response && err.response.status === 503){
            redirectMaintenancePage(err.response);
        }
        return {
            success: false,
            data: null,
            error: err,
        } as callApiResult;
    }
};

export const downloadFileFromS3 = async (url: string) => {
    try {
        const res = await axios.get(url, { 'responseType': 'blob' })
        return {
            success: res.status === 200,
            data: res.data,
        } as callApiResult;
    } catch (err) {
        return {
            success: false,
            data: null,
            error: err,
        } as callApiResult;
    }
};

export const deleteFileS3 = async (Key: string) => {
    const token = await getToken();
    const url = "/maintenance/delete-file";

    const params = { Key }
    try {
        const res = await axios.post(`${process.env.REACT_APP_APIGW_URL}${url}`, params, { headers: { 'Authorization': token } })
        return {
            success: res.status === 200,
            data: res.data.data,
        } as callApiResult;
    } catch (err: any) {
        if (err.response && err.response.status === 503){
            redirectMaintenancePage(err.response);
        }
        return {
            success: false,
            data: null,
            error: err,
        } as callApiResult;
    }
};

export const deleteFolderS3 = async (Key: string) => {
    const token = await getToken();
    const url = "/maintenance/delete-folder";

    const params = { Key }
    try {
        const res = await axios.post(`${process.env.REACT_APP_APIGW_URL}${url}`, params, { headers: { 'Authorization': token } })
        return {
            success: res.status === 200,
            data: res.data.data,
        } as callApiResult;
    } catch (err: any) {
        if (err.response && err.response.status === 503){
            redirectMaintenancePage(err.response);
        }
        return {
            success: false,
            data: null,
            error: err,
        } as callApiResult;
    }
};

export const deleteFileByIdsS3 = async (
    userSub: string, projectId: number, idList: number[]
) => {
    const token = await getToken();
    const url = "/maintenance/delete-files";

    let keyList: any[] = []
    idList.forEach((id: any) => {
        keyList.push(`${userSub}/${projectId}/${id}/`)
    })
    const params = { keyList }
    try {
        const res = await axios.post(`${process.env.REACT_APP_APIGW_URL}${url}`, params, { headers: { 'Authorization': token } })
        return {
            success: res.status === 200,
            data: res.data.data,
        } as callApiResult;
    } catch (err: any) {
        if (err.response && err.response.status === 503){
            redirectMaintenancePage(err.response);
        }
        return {
            success: false,
            data: null,
            error: err,
        } as callApiResult;
    }
};

// #endregion
