import { useRef, useState } from 'react';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, Typography, IconButton } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
// components
import MenuPopover from './common/MenuPopover';
import { actionCreators, ApplicationState, AppState } from '../store';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { post } from './CallApi';

// ----------------------------------------------------------------------
interface AccountPopoverActions {
    onLogout: () => void;
}

export type AccountPopoverProps =
    AppState &
    AccountPopoverActions;

export const AccountPopover = (props: AccountPopoverProps) => {
    const { user, onLogout } = props;
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    let navigate = useNavigate();

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleChangePassword = () => {
        navigate('/changepass');
    }

    const handleLogout = () => {
        const params = {
            userId: user?.userId,
            type: "logout",
        }
        post("/user/add-operation-history", params)
        onLogout()
    }
    
    return (
        <>
            <IconButton
                ref={anchorRef}
                size="large"
                onClick={handleOpen}
                sx={{
                    color: user?.expired ? 'red' : 'black',
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',  
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
                        }
                    })
                }}
            >
                <PersonIcon fontSize="inherit" />
            </IconButton>
            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{ width: 220 }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {user?.name}
                    </Typography>
                    {/* <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {user?.name[2].getValue()}
                    </Typography> */}
                </Box>

                <Divider sx={{ my: 1, borderColor: 'rgba(24, 144, 255, 0.24)' }} />

                <Box sx={{ p: 1, pt: 1 }}>
                    <Button fullWidth color="inherit" variant="outlined"
                        onClick={() => {navigate("/settinguser")}}>
                        ユーザー設定
                    </Button>
                </Box>

                <Box sx={{ p: 1, pt: 1 }}>
                    <Button fullWidth color="inherit" variant="outlined"
                        onClick={handleChangePassword}>
                        パスワード変更
                    </Button>
                </Box>

                <Box sx={{ p: 1, pt: 1 }}>
                    <Button fullWidth color="inherit" variant="outlined"
                        onClick={handleLogout}>
                        ログアウト
                    </Button>
                </Box>
            </MenuPopover>
        </>
    );
}

export default connect(
    (state: ApplicationState) => state.app,
    dispatch => ({
        onLogout: () => { dispatch(actionCreators.clearSession()) }
    } as AccountPopoverActions)
)(AccountPopover as any)