import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, CircularProgress, Collapse, Grid, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { ApplicationState } from "../../../store";
import { actionCreators } from "../../../store/AppStore";
import UserGroupGrid from './UserGroupGrid';
import { post } from "../../CallApi";
import { downloadFile } from "../../../utils/downloadFile";
import UserGroupDialog from "../../dialogs/UserGroupDialog";
import { convertUserGroupData } from "../../../utils/DataConverter";
import { Message, UserGroupModel } from "../../../models/Index";
import AlertDialog from "../../common/AlertDialog";
import { ROLE_ADMIN } from "../../../models/Constants";

interface SearchItemForm{
    userGroupName: string;
    notes: string;
    target: string;
    limit: number;
    page: number;
}

const initialValue: SearchItemForm = {
    userGroupName: "",
    notes: "",
    target: "",
    limit: 50,
    page: 1,
};

const initialPageData = {
    rowFrom: '0',
    rowTo: '0',
    totalRow: '0',
    currPage: '0',
    totalPage: '0',
};

type UserGroupListProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
    
export const UserGroupList = (props: UserGroupListProps) => {
    // #region props
    const { user, userGroupPermission, onShowMessage } = props;
    
    const { handleSubmit, control, setValue, getValues } = useForm<SearchItemForm>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    let navigate = useNavigate();
    const gridRef = useRef() as any;

    const isSystemAdmin = user?.userRole === ROLE_ADMIN

    const [loadingFlag, setLoadingFlag] = useState(false);
    const [userGroupSelected, setUserGroupSelected] = useState<UserGroupModel|null>(null);
    
    const [openUserGroupDialog, setOpenUserGroupDialog] = useState(false);
    const [isAddUserGroup, setIsAddUserGroup] = useState(true); // add/edit user group

    const [messageDelete, setMessageDelete] = useState("");
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [pageData, setPageData] = useState(initialPageData);
    const [openSearch, setOpenSearch] = useState(false);
    const [keyword, setKeyword] = useState("");
    
    // #endregion props

    useEffect(() => {
        handleSearchSubmit(getValues());
    },[])
    
    // #region handleMethod
    const handleChangePageData = (name: any, value: any) => {
        // 値変更
        setValue(name, value);
        if (name === 'limit') {
            setValue("page", 1);
        }
        handleSearchSubmit(getValues());
    };

    const handleChangeKeyword = (value: string) => {
        setKeyword(value);
    }

    // 検索実行
    const handleSearchSubmit = async (value: any) => {
        setLoadingFlag(true);

        const params = getSearchCondition(value)
        const result = await post("/user-group/get-user-group", params);
        if (result.success) {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData(convertUserGroupData(result.data.results))
            }
            handleSetPageData(result.data)
        } else {
            onShowMessage({
                type: "error",
                title: "グループ",
                body: "グループを取得できません。"
            });
        }
        setUserGroupSelected(null);
        setLoadingFlag(false);
    }

    const getSearchCondition = (value: any) => {
        const params: any = {
            user_id: user?.userId,
            search_conditions:{
                default: true,
                limit: getValues('limit'),
                page: getValues("page")
            }
        };
        if(!isSystemAdmin){
            params.search_conditions["default"] = false;
            params.search_conditions["user_group_id"] = userGroupPermission?.groupId;
        }
        if(value.userGroupName) {
            params.search_conditions["default"] = false;
            params.search_conditions["user_group_name"] = value.userGroupName;
        }
        if(value.notes) {
            params.search_conditions["default"] = false;
            params.search_conditions["notes"] = value.notes;
        }
        return params
    }

    const handleSetPageData = (data: any) => {
        const totalCount = data.totalCount;
        const pageSize = getValues('limit');
        const page = Number(data.page);
        const totalPage = Math.ceil(totalCount / pageSize);
        const rowFrom = (pageSize * page - pageSize) + 1;
        const rowTo = (pageSize * page) > totalCount ? totalCount : (pageSize * page);
        const updateData = {
            rowFrom: rowFrom.toLocaleString(),
            rowTo: rowTo.toLocaleString(),
            totalRow: totalCount.toLocaleString(),
            currPage: page.toLocaleString(),
            totalPage: totalPage.toLocaleString(),
        };
        setPageData(updateData);
        
        if (page !== getValues("page")) setValue("page", page);
    }

    const handleClearSeachCondition = () => {
        setValue("userGroupName", "");
        setValue("notes", "");
    }

    const handleSearch = () => {
        handleSearchSubmit(getValues());
    }
    
    const handleAdd = () => {
        setIsAddUserGroup(true);
        setOpenUserGroupDialog(true);
    }

    const handleEdit = () => {
        setIsAddUserGroup(false);
        setOpenUserGroupDialog(true);
    }

    const handleOk = (newValue: any) => {
        if (isAddUserGroup) {
            addUserGroup(newValue);
        } else {
            editUserGroup(newValue);
        }
    }

    const addUserGroup = async (newValue: any) => {
        const params = {
            user_group_name: newValue.userGroupName,
            notes: newValue.notes
        };
        const url = "/user-group/add-user-group";

        setLoadingFlag(true);
        const response = await post(url, params);
        if (response.success){
            setOpenUserGroupDialog(false);
            setValue("page", 1)
            handleSearch();
        } else {
            setLoadingFlag(false);
            setOpenUserGroupDialog(false);
            onShowMessage({
                type: "error",
                title: "グループ追加",
                body: "グループ追加に失敗しました。"
            })
        }
    }

    const editUserGroup = async (newValue: any) => {
        const params = {
            user_group_id: newValue.userGroupId,
            user_group_name: newValue.userGroupName,
            notes: newValue.notes
        };
        const url = "/user-group/edit-user-group";

        setLoadingFlag(true);
        const response = await post(url, params);
        if (response.success){
            if (gridRef && gridRef.current) {
                gridRef.current.updateRowData(newValue);
            }
            setUserGroupSelected(null);
        } else {
            onShowMessage({
                type: "error",
                title: "グループ編集",
                body: "グループ編集に失敗しました。"
            })
        }
        setLoadingFlag(false);
        setOpenUserGroupDialog(false);
    }

    const handleCancel = () => {
        setOpenUserGroupDialog(false);
    }

    const handleDelete = () => {
        if (userGroupSelected === null) return;
        const msg = "グループ名「" + userGroupSelected.userGroupName + "」を削除しますか？";
        setMessageDelete(msg);
        setOpenDeleteDialog(true);
    }

    const deleteUserGroup = async () => {
        if (userGroupSelected === null) return;
        const params = {
            user_group_id: userGroupSelected.userGroupId
        };
        const url = "/user-group/delete-user-group";

        setLoadingFlag(true);
        const response = await post(url, params);
        if (response.success){
            if (gridRef && gridRef.current) {
                gridRef.current.removeRowData(response.data.data)
            }
            setUserGroupSelected(null);
        } else {
            onShowMessage({
                type: "error",
                title: "グループ削除",
                body: "グループ削除に失敗しました。"
            })
        }
        setOpenDeleteDialog(false);
        setLoadingFlag(false);
    }

    const handleExportCSV = async () => {
        const params = getSearchCondition(getValues());
        const url = "/user-group/export-csv-user-group";
        setLoadingFlag(true);
        const result = await post(url, params);
        if (result.success) {
            if (result.data.data) downloadFile(result.data.data);
        } else {
            onShowMessage({
                type: "error",
                title: "csvにてエクスポート",
                body: "csvにてエクスポートを取得できません。"
            });
        }
        setLoadingFlag(false);
    }

    const handleSelectUserGroup = (e: any) => {
        setUserGroupSelected(e.data);
    }

    const handleOpenUserGroupMemberList = (e: any) => {
        if (e.data && e.data.userGroupId)
            navigate(`/setting/userGroup/${e.data.userGroupId}`)
    }
    //  #endregion handleMethod

    return (<>
        {loadingFlag && (
            <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                <CircularProgress />
            </div>
        )}
        {openUserGroupDialog &&
            <UserGroupDialog
                isAdd={isAddUserGroup}
                data={isAddUserGroup ? {userGroupName: "", notes: ""} : userGroupSelected}
                onOK={handleOk}
                onCancel={handleCancel}
            />
        }
        {openDeleteDialog &&
            <AlertDialog
                title={"グループ削除"} 
                message={messageDelete} 
                isOpen={true} 
                onOK={deleteUserGroup} 
                onCancel={() => setOpenDeleteDialog(false)} 
            />
        }
        <div style={{ height: '100%', width: '100%', marginTop: '10px' }}>
            <Stack style={{ color: 'MenuText' }}>
                <Typography variant="h4">グループ</Typography>
            </Stack>
            <form onSubmit={handleSubmit(handleSearchSubmit)}>
                <Stack spacing={1} p={1} style={{ border: openSearch ? "2px solid gray" : '', borderRadius: "15px" }}>
                    <div style={{ cursor: 'pointer', display: 'inline-block' }} onClick={() => {setOpenSearch((prev:boolean) => !prev)}}>
                        <div style={{ display: 'inline-block' }}>
                            <Typography variant="h6" >検索条件</Typography>
                        </div>
                        <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                            {
                                openSearch ? <ArrowDropDownIcon /> : <ArrowRightIcon />
                            }
                        </div>
                    </div>
                    <div>
                        <Collapse in={openSearch} timeout="auto" unmountOnExit>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <Controller
                                        control={control}
                                        name="userGroupName"
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="グループ名"
                                                size="small"
                                                InputProps={{ sx: { borderRadius: "8px" } }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Controller
                                        control={control}
                                        name="notes"
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="備考"
                                                size="small"
                                                InputProps={{ sx: { borderRadius: "8px" } }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" onClick={handleClearSeachCondition}>クリア</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" onClick={handleSearch}>検索</Button>
                                </Grid>
                            </Grid>
                        </Collapse>
                    </div>
                </Stack>
            </form>

            <div style={{ marginBottom: "10px", paddingTop:"5px", marginTop:`${openSearch?10:0}px`}}>
                <Stack direction="row" justifyContent={"flex-end"} mb={1}>
                    <Box>
                        <TextField
                            fullWidth
                            autoComplete="searchStr"
                            name="keyword"
                            type="text"
                            label="フィルター"
                            size="small"
                            value={keyword}
                            InputProps={{ sx: { borderRadius: "8px" } }}
                            onChange={e => handleChangeKeyword(e.target.value)}
                        />
                    </Box>
                    <Box ml={1}>
                        <Button variant="outlined" onClick={() => handleChangeKeyword("")}>クリア</Button>
                    </Box>
                    <Stack direction={{ xs: 'column', sm: 'row' }} ml={2} spacing={1}>
                        <Button variant="contained" size="small" onClick={handleAdd} disabled={!isSystemAdmin}>{<AddIcon />}</Button>
                        <Button variant="contained" size="small" onClick={handleEdit} disabled={userGroupSelected === null}>{<EditIcon />}</Button>
                        <Button variant="contained" size="small" onClick={handleDelete} disabled={!isSystemAdmin || userGroupSelected === null}>{<ClearIcon />} </Button>
                        <Button variant="contained" size="small" onClick={handleExportCSV}>CSV</Button>
                    </Stack>
                </Stack>
                <Stack>
                    <UserGroupGrid
                        ref={gridRef}
                        openSearch={openSearch}
                        pageSize={getValues('limit')}
                        pageData={pageData}
                        keyword={keyword}
                        onChangeSearchCondition={handleChangePageData}
                        handleSelectUserGroup={handleSelectUserGroup}
                        handleOpenUserGroupMemberList={handleOpenUserGroupMemberList}
                    />
                </Stack>
            </div>
        </div>
    </>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    user: state.app.user,
    userGroupPermission: state.app.userGroupPermission,
})

const mapDispatchToProps = (dispatch: any) => ({
    onShowMessage: (message: Message) => dispatch(actionCreators.showMessage(message)),
});

export default connect(
    mapStateToProps, mapDispatchToProps
)(UserGroupList as any);
