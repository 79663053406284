import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox, FormControlLabel } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';
import { roleOptions } from '../../../statics';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// -------------
// Props
export type UsersGridProps = {
    colState: any[];
    onSelect: (data: any) => void;
    keyword: string;
    openSearch:boolean;
    onChangePassword: (props: any) => void;
    show2line:boolean;
    exportCsvFlag: number;
    authRole?: any;
};

// -------------
// Component
export const UsersGrid = forwardRef((props: UsersGridProps, ref) => {
    const { colState, keyword, openSearch, exportCsvFlag, show2line, onSelect } = props;

    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState<any>();

    useEffect(() => {
        if(exportCsvFlag != 0){
            handleExportCSV()
        }
    },[exportCsvFlag])

    useImperativeHandle(ref, () => ({
        saveState() {
            if (gridRef && gridRef.current && gridRef.current.columnApi) {
                return gridRef.current.columnApi.getColumnState();
            }
            return null;
        },

        setRowData(value: any) {
            setRowData(value)
        },

        setSelectRowData(value: any) {
            if (rowData) {
                let list = rowData?.filter((item: any) => item.username === value)
                list[0].isSelect = !list[0].isSelect;
            }
        },

        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));

    // ag-Grid の準備完了後に呼ばれるコールバック関数
    const onGridReady = (grid: any) => {

    };

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);

    const handleCheckedDelete = (props: any) => {
        onSelect(props.data)
        let rowNode = gridRef.current.api.getRowNode(props.node.id);
        gridRef.current.api.redrawRows({ rowNodes: [rowNode] });
    };

    // 並び順セル
    const CellCheckRenderer = (props: any) => {
        const { email, selected } = props.data;
        let isSelected = selected
        if (props.data.isSelect) {
            isSelected = true;
        }
        return (
            <FormControlLabel
                control={<Checkbox icon={icon} checkedIcon={checkedIcon} checked={isSelected} onChange={() => handleCheckedDelete(props)} />}
                label={email}
                labelPlacement="end"
            />
        );
    };

    const CellPlanRenderer = (props: any) => {
        const { custom_plan } = props.data;
        let result = "";
        switch (custom_plan) {
            case "lp":
                result = "Light Pack";
                break;
            case "sp":
                result = "Service Pack";
                break;
            case "pp":
                result = "PM Pack";
                break;
            case "admin":
                result = "ー";
                break;
        }
        return result;
    }

    const CellExpirationDateRenderer = (props: any) => {
        const { custom_expiration_date, custom_plan } = props.data;
        if (custom_plan == "admin")
            return "無期限";
        if (!custom_expiration_date)
            return "";
        if(typeof custom_expiration_date == 'string' && custom_expiration_date.length == 17) {
            const startDate = custom_expiration_date.substring(0, 4) + "/" + custom_expiration_date.substring(4, 6) + "/" + custom_expiration_date.substring(6, 8);
            const endDate = custom_expiration_date.substring(9, 13) + "/" + custom_expiration_date.substring(13, 15) + "/" + custom_expiration_date.substring(15, 18);
            return startDate + "~" + endDate;
        }
        return "";
    }

    const CellMFARenderer = (props: any) => {
        const { custom_mfa } = props.data;
        if (custom_mfa == "true")
            return "有効";
        else
            return "無効";
    }

    const CellAccountStatusRenderer = (props: any) => {
        const { account_status } = props.data;
        if (account_status)
            return "有効";
        else
            return "無効";
    }

    const CellFullnameRenderer = (props:any) => {
        const { family_name, given_name } = props.data;
        return family_name + given_name;
    }

    const CellPhoneNumberRenderer = (props:any) => {
        const { phone_number } = props.data;
        if(phone_number){
            let result = "";
            if(phone_number.startsWith("+81")){
                result = "0";
                result += phone_number.substring(3);
            }else{
                result = phone_number;
            }
            return result;
        }
        return "";
    }

    const CellRoleRenderer = (props:any) => {
        const { custom_role } = props.data;
        return roleOptions.find((item:any) => item.value == custom_role)?.label;
    }

    const CellEmailVerifiedRenderer = (props:any) =>{
        const {email_verified} = props.data
        const verified = email_verified ?? ""
        return verified.toLowerCase() == "true" ? "確認済み" : "未確認"
    }
    const CellUserStatusRenderer = (props:any) =>{
        const {user_status} = props.data
        let status = ""
        switch(user_status){
            case "UNCONFIRMED":
                status = "未確認"
                break;
            case "CONFIRMED":
                status = "確認済み"
                break;
            case "FORCE_CHANGE_PASSWORD":
                status = "パスワード強制変更"
                break;
            case "RESET_REQUIRED":
                status = "パスワードリセットが必要"
                break;
            case "ARCHIVED":
                status = "アーカイブ済み"
                break;
            case "COMPROMISED":
                status = "侵害された可能性あり"
                break;
            case "UNKNOWN":
                status = "不明"
                break;
        }
        return status
    }

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };

    // 列の個別定義
    const columnDefs = [
        {
            headerName: "メールアドレス",
            field: "email",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            cellRenderer: CellCheckRenderer,
            width: colState ? colState[0].width : 200,
        },
        {
            headerName: "管理No",
            field: "custom_management_no",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[1].width : 100,
        },
        {
            headerName: "会社名",
            field: "custom_company_name",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[2].width : 200,
        },
        {
            headerName: "郵便番号",
            field: "custom_zip_code",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[3].width : 200,
        },
        {
            headerName: "住所",
            field: "address",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[4].width : 150,
        },
        {
            headerName: "ユーザー名",
            field: "family_name",
            rowDrag: false,
            cellRenderer: CellFullnameRenderer,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[5].width : 200,
        },
        {
            headerName: "権限",
            field: "custom_role",
            cellRenderer: CellRoleRenderer,
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[6].width : 130,
        },
        {
            headerName: "電話番号",
            field: "phone_number",
            cellRenderer: CellPhoneNumberRenderer,
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[7].width : 130,
        },
        {
            headerName: "有効期限",
            field: "custom_expiration_date",
            cellRenderer: CellExpirationDateRenderer,
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[8].width : 130,
        },        
        {
            headerName: "多要素認証（MFA）",
            field: "custom_mfa",
            cellRenderer: CellMFARenderer,
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[9].width : 160,
        },
        {
            headerName: "多要素認証（MFA）方式",
            field: "custom_mfa_system",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[10].width : 200,
        },
        {
            headerName: "パック",
            field: "custom_plan",
            cellRenderer: CellPlanRenderer,
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[11].width : 130,
        },
        {
            headerName: "ソフトのシリアルNo",
            field: "custom_serial_no",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[12].width : 160,
        },
        {
            headerName: "メモ",
            field: "custom_memo",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[13].width : 130,
        },
        {
            headerName: "Eメール確認済み",
            field: "email_verified",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[14].width : 160,
            cellRenderer: CellEmailVerifiedRenderer
        },
        {
            headerName: "確認ステータス",
            field: "user_status",
            rowDrag: false,
            cellStyle: { textAlign: 'left' },
            width: colState ? colState[15].width : 200,
            cellRenderer: CellUserStatusRenderer
        },
    ];

    const rowHeight = 48;
    gridRef.current?.api?.setQuickFilter(keyword)

    const getRowStyle = (params: any) => {
        if (params.data.isSelect) {
            return {
                backgroundColor: '#e8f4fe',
            };
        }
        return {
            backgroundColor: 'white',
        };
    }

    const handleExportCSV = () => {
        const params = {
            fileName: "ユーザー",
            allColumns:true,
        }
        gridRef?.current?.api.exportDataAsCsv(params);
    }

    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: openSearch ? (!show2line ? "calc(100vh - 430px)" : "calc(100vh - 450px)") : (!show2line ? "calc(100vh - 270px)" : "calc(100vh - 300px)"), width: "100%", marginTop: "15px" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        getRowStyle={getRowStyle}
                        onGridReady={onGridReady}
                        domLayout={"normal"}
                        rowData={rowData}
                        animateRows={true}
                        rowDragManaged={true}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        rowHeight={rowHeight}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    />
                </div>
            </div>
        </>
    );
});

export default UsersGrid;
