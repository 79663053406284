import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MsTransScottModel } from '../../models/Index';
import TextFieldOptions from '../common/TextFieldOptions';
import { 
    msTransScottGetDropPerUnit, 
    msTransScottGetDropVoltDiff, 
    msTransScottGetInrushValue, 
    msTransScottGetIntensityValue, 
    msTrans1ScottGetLoadCapacity, 
    msTrans1ScottGetLoadCurrent, 
    msTransScottGetRatedCurrent, 
    msTransFromXR_ZCalculation, 
    msTransNormalCalculation, 
    doCalcSetValuesTransscott,
    beamsNumberTransscott
} from '../../utils/PowerCalculation';
import { BeamsNumber } from '../../utils/FormatNumber';
import { MS_MODE_CHECKING, MS_SEAT_MAIN, MS_SEAT_T, MS_XR_MAX, VOLT_SIDE_LOW_MAX } from '../../statics';
import * as Constant from "../../models/Constants";
import { isNumber } from 'mathjs';

const radioStyles = { padding: '4px 4px 4px 8px' }
const checkBoxStyles = { padding: '4px 4px 4px 8px' }
const checkboxStyle = {
    padding: '0px 0px 0px 9px'
}

const radioStyles1 = {
    padding: '0 0 0 9px'
}
export type MsTransScottEditDialogFormProps = {
    data: any;
    onOK: (data: any) => void;
    onCancel: () => void;
    userRole: string;
    m_bModePM: boolean;
    modeViewOnly: boolean;
    dispInputDialog: any;
    processMode:number;
    transscottList:any;
};

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

export const MsTransScottEditDialog = (props: MsTransScottEditDialogFormProps) => {
    const { m_bModePM, modeViewOnly, userRole, data, onOK, onCancel,dispInputDialog ,processMode,transscottList} = props;
    const [selectTab, setSelectTab] = useState(0);
    // 初期値設定
    const initialValue: MsTransScottModel = { ...data,
        partSeatT:{...data.partSeatT,calcPoint:{...data.partSeatT.calcPoint,
                    loadCapacity: BeamsNumber(data.partSeatT.calcPoint.loadCapacity,7),
                    loadCurrent: BeamsNumber(data.partSeatT.calcPoint.loadCurrent,5)},
                    voltageDrop:  BeamsNumber(data.partSeatT.voltageDrop,4),
                    voltageDrop1:  BeamsNumber(data.partSeatT.voltageDrop1,4)
                },
        partSeatM:{...data.partSeatM,calcPoint:{...data.partSeatM.calcPoint,
            loadCapacity: BeamsNumber(data.partSeatM.calcPoint.loadCapacity,7),
            loadCurrent: BeamsNumber(data.partSeatM.calcPoint.loadCurrent,5)},
            voltageDrop:  BeamsNumber(data.partSeatM.voltageDrop,4),
            voltageDrop1:  BeamsNumber(data.partSeatM.voltageDrop1,4)
        }
        
     }
    const { control, handleSubmit, formState: { errors }, setValue, getValues,setError,clearErrors } = useForm<MsTransScottModel>({
        mode: 'all',
        criteriaMode: 'all',
        defaultValues: initialValue,
    });
    
    const [dropRegular1, setDropRegular1] = 
        useState<boolean>(initialValue.partSeatM.dropRegular);
    const [dropRegular2, setDropRegular2] = 
        useState<boolean>(initialValue.partSeatT.dropRegular);
    const [makeDataInput, setMakeDataInput] = useState(initialValue.makeDataInput); // ModePM

    // Submitイベント
    const handleEditSubmit = (value: MsTransScottModel) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: MsTransScottModel) => {
        if(!Number(formValue.loadFactor)){
            formValue.loadFactor = 0
        }
        if(!Number(formValue.partSeatM.xr)){
            formValue.partSeatM.xr = 0
        }
        if(!Number(formValue.partSeatM.percentR)){
            formValue.partSeatM.percentR = 0
        }
        if(!Number(formValue.partSeatM.percentX)){
            formValue.partSeatM.percentX = 0
        }
        if(!Number(formValue.partSeatT.xr)){
            formValue.partSeatT.xr = 0
        }
        if(!Number(formValue.partSeatT.percentR)){
            formValue.partSeatT.percentR = 0
        }
        if(!Number(formValue.partSeatT.percentX)){
            formValue.partSeatT.percentX = 0
        }
        if(!Number(formValue.partSeatM.calcPoint.loadCapacity)){
            formValue.partSeatM.calcPoint.loadCapacity = 0
        }
        if(!Number(formValue.partSeatM.calcPoint.loadCurrent)){
            formValue.partSeatM.calcPoint.loadCurrent = 0
        }
        if(!Number(formValue.partSeatM.calcPoint.powerFactor)){
            formValue.partSeatM.calcPoint.powerFactor = 0
        }
        if(!Number(formValue.partSeatT.calcPoint.loadCapacity)){
            formValue.partSeatT.calcPoint.loadCapacity = 0
        }
        if(!Number(formValue.partSeatT.calcPoint.loadCurrent)){
            formValue.partSeatT.calcPoint.loadCurrent = 0
        }
        if(!Number(formValue.partSeatT.calcPoint.powerFactor)){
            formValue.partSeatT.calcPoint.powerFactor = 0
        }
        let request = { ...formValue } as MsTransScottModel;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };
    
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue >= 0) {
            if (getValues('refNo') == "" ||getValues('refNo') == null || getValues('refNo') == undefined ) {
                setError("refNo",{ type: 'custom', message: '必須項目です。入力してください。' });
            }
            else if (isNaN(Number(getValues('voltagePrimary'))) || Number(getValues('voltagePrimary')) <= 0) {
                setError("voltagePrimary",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('voltageSecondary'))) || Number(getValues('voltageSecondary')) <= 0) {
                setError("voltageSecondary",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('capacity'))) || Number(getValues('capacity')) <= 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('capacity').toString())) {
                setError("capacity",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (Number(getValues('loadFactor')) < 0 || Number(getValues('loadFactor')) >  1 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('loadFactor').toString())) {
                setError("loadFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else if (isNaN(Number(getValues('powerFactor'))) || Number(getValues('powerFactor')) <= 0 || Number(getValues('powerFactor')) > 1 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('powerFactor').toString())) {
                setError("powerFactor",{ type: 'custom', message: '0 < 数字 <= 1 を入力してください。' });
            }
            else if (Number(getValues('partSeatM.xr')) < 0 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('partSeatM.xr').toString())) {
                setError("partSeatM.xr",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('partSeatM.percentZ'))) || Number(getValues('partSeatM.percentZ')) <= 0 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('partSeatM.percentZ').toString())) {
                setError("partSeatM.percentZ",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }
            else if (Number(getValues('partSeatM.percentR')) < 0 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('partSeatM.percentR').toString())) {
                setError("partSeatM.percentR",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (Number(getValues('partSeatM.percentX')) < 0 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('partSeatM.percentX').toString())) {
                setError("partSeatM.percentX",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (Number(getValues('partSeatT.xr')) < 0 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('partSeatT.xr').toString())) {
                setError("partSeatT.xr",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('partSeatT.percentZ'))) || Number(getValues('partSeatT.percentZ')) <= 0 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('partSeatT.percentZ').toString())) {
                setError("partSeatT.percentZ",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }
            else if (Number(getValues('partSeatT.percentR')) < 0 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('partSeatT.percentR').toString())) {
                setError("partSeatT.percentR",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (Number(getValues('partSeatT.percentX')) < 0 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('partSeatT.percentX').toString())) {
                setError("partSeatT.percentX",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('partSeatM.inrushTimes'))) || Number(getValues('partSeatM.inrushTimes')) <= 0 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('partSeatM.inrushTimes').toString())) {
                setError("partSeatM.inrushTimes",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }
            else if (isNaN(Number(getValues('partSeatM.dampingTime'))) || Number(getValues('partSeatM.dampingTime')) <= 0 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('partSeatM.dampingTime').toString())) {
                setError("partSeatM.dampingTime",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }
            else if (isNaN(Number(getValues('partSeatM.intensityTimes'))) || Number(getValues('partSeatM.intensityTimes')) <= 0 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('partSeatM.intensityTimes').toString())) {
                setError("partSeatM.intensityTimes",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }
            else if (isNaN(Number(getValues('partSeatM.intensityTime'))) || Number(getValues('partSeatM.intensityTime')) <= 0 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('partSeatM.intensityTime').toString())) {
                setError("partSeatM.intensityTime",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }
            else if (isNaN(Number(getValues('partSeatT.inrushTimes'))) || Number(getValues('partSeatT.inrushTimes')) <= 0 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('partSeatT.inrushTimes').toString())) {
                setError("partSeatT.inrushTimes",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }
            else if (isNaN(Number(getValues('partSeatT.dampingTime'))) || Number(getValues('partSeatT.dampingTime')) <= 0 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('partSeatT.dampingTime').toString())) {
                setError("partSeatT.dampingTime",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }
            else if (isNaN(Number(getValues('partSeatT.intensityTimes'))) || Number(getValues('partSeatT.intensityTimes')) <= 0 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('partSeatT.intensityTimes').toString())) {
                setError("partSeatT.intensityTimes",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }
            else if (isNaN(Number(getValues('partSeatT.intensityTime'))) || Number(getValues('partSeatT.intensityTime')) <= 0 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('partSeatT.intensityTime').toString())) {
                setError("partSeatT.intensityTime",{ type: 'custom', message: '数字 > 0を入力してください。' });
            }
            else if (isNaN(Number(getValues('partSeatM.calcPoint.loadCapacity'))) ||Number(getValues('partSeatM.calcPoint.loadCapacity')) < 0 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('partSeatM.calcPoint.loadCapacity').toString())) {
                setError("partSeatM.calcPoint.loadCapacity",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('partSeatM.calcPoint.loadCurrent'))) ||Number(getValues('partSeatM.calcPoint.loadCurrent')) < 0 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('partSeatM.calcPoint.loadCurrent').toString())) {
                setError("partSeatM.calcPoint.loadCurrent",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('partSeatM.calcPoint.powerFactor'))) ||Number(getValues('partSeatM.calcPoint.powerFactor')) < 0 || Number(getValues('partSeatM.calcPoint.powerFactor')) > 1 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('partSeatM.calcPoint.powerFactor').toString())) {
                setError("partSeatM.calcPoint.powerFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else if (isNaN(Number(getValues('partSeatT.calcPoint.loadCapacity'))) ||Number(getValues('partSeatT.calcPoint.loadCapacity')) < 0 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('partSeatT.calcPoint.loadCapacity').toString())) {
                setError("partSeatT.calcPoint.loadCapacity",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('partSeatT.calcPoint.loadCurrent'))) ||Number(getValues('partSeatT.calcPoint.loadCurrent')) < 0 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('partSeatT.calcPoint.loadCurrent').toString())) {
                setError("partSeatT.calcPoint.loadCurrent",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('partSeatT.calcPoint.powerFactor'))) ||Number(getValues('partSeatT.calcPoint.powerFactor')) < 0 || Number(getValues('partSeatT.calcPoint.powerFactor')) > 1 ||!/^[0-9]*[.]?[0-9]+$/.test(getValues('partSeatT.calcPoint.powerFactor').toString()))  {
                setError("partSeatT.calcPoint.powerFactor",{ type: 'custom', message: '数字 0 ~ 1 を入力してください。' });
            }
            else{
                setSelectTab(newValue);

            }
        }
        else{
            setSelectTab(newValue);
        }
    }

    // ModePM
    const handleChangeMakeDataInput = (e: any) => {
        setValue(e.target.name, e.target.checked);
        setMakeDataInput(e.target.checked);
    }

    const handleChangeFormat = (e: any) => {
        setValue(e.target.name, e.target.value);
    }
    
    const handleChangeCheckbox = (e: any) => {
        setValue(e.target.name, e.target.checked);
    }
    
    const handleChangeVoltagePrimary = (e: any) => {
        setValue(e.target.name, e.target.value);
        if(e.target.value > 0){
            clearErrors("voltagePrimary")
        }
        doCalcRatedCurrent();
    }
    
    const handleChangeVoltageSecondary = (e: any) => {
        setValue(e.target.name, e.target.value);
        if(e.target.value > 0){
            clearErrors("voltageSecondary")
        }
        doCalcRatedCurrent1();
        
        doCalcPowerNormal(true);
        doCalcPowerNormal(false);
        
        doCalcInrushValue(true);
        doCalcInrushValue(false);

        doCalcIntensityValue(true);
        doCalcIntensityValue(false);

        doCalcVoltageDrop1(true);
        doCalcVoltageDrop1(false);
        
        doCalcVoltageDrop(true);
        doCalcVoltageDrop(false);
    }
    
    const handleChangeCapacity = (e: any) => {
        setValue(e.target.name, e.target.value);
        clearErrorsTransscott()
        if(e.target.value > 0){
            clearErrors("capacity")
        }
        doCalcSetValuesTransscott(transscottList,+e.target.value/2,getValues("partSeatM"),getValues("partSeatT"),"")
        beamsNumberTransscott(getValues("partSeatM"))
        beamsNumberTransscott(getValues("partSeatT"))


        doCalcRatedCurrent();
        doCalcRatedCurrent1();

        doCalcPowerNormal(true);
        doCalcPowerNormal(false);
        
        doCalcInrushValue(true);
        doCalcInrushValue(false);

        doCalcIntensityValue(true);
        doCalcIntensityValue(false);

        doCalcVoltageDrop1(true);
        doCalcVoltageDrop1(false);
        
        doCalcVoltageDrop(true);
        doCalcVoltageDrop(false);
    }

    const handleChangeXR_Z = (e: any) => {
        setValue(e.target.name, e.target.value);
        clearErrorsTransscott()
        doCalcSetValuesTransscott(transscottList,+getValues("capacity")/2,getValues("partSeatM"),getValues("partSeatT"),e.target.name.split('.')[1])
        beamsNumberTransscott(getValues("partSeatM"))
        beamsNumberTransscott(getValues("partSeatT"))
        let tmpList = [
            'partSeatT.xr', 
            'partSeatT.percentZ'
        ]
        if (tmpList.includes(e.target.name))
            doCalcPowerFromXR_Z(false,e.target.name.split('.')[1]);
        else
            doCalcPowerFromXR_Z(true,e.target.name.split('.')[1]);
    }

    const handleChangeR_X = (e: any) => {
        setValue(e.target.name, e.target.value);
        clearErrorsTransscott()
        let tmpList = [
            'partSeatT.percentX', 
            'partSeatT.percentR'
        ]
        if (tmpList.includes(e.target.name)){
            doCalcXr(false);
            doCalcPowerNormal(false);
        } else {
            doCalcXr(true);
            doCalcPowerNormal(true);
        }
    }

    const handleChangeInrushTimes = (e: any) => {
        setValue(e.target.name, e.target.value);

        if (e.target.name === 'partSeatT.inrushTimes')
            doCalcInrushValue(false);
        else
            doCalcInrushValue(true);
    }

    const handleChangeIntensityTimes = (e: any) => {
        setValue(e.target.name, e.target.value);

        if (e.target.name === 'partSeatT.intensityTimes')
            doCalcIntensityValue(false);
        else
            doCalcIntensityValue(true);
    }
        
    const hanDleChangedropRegularr = (e: any) => {
        setValue(e.target.name, e.target.checked);
        if (e.target.name === 'partSeatM.dropRegular')
            setDropRegular1(e.target.checked);
        else
            setDropRegular2(e.target.checked);
    }

    const handleChangeLoadCapacity = (e: any) => {
        setValue(e.target.name, e.target.value);
        if (e.target.name === 'partSeatT.calcPoint.loadCapacity'){
            setValue("partSeatT.inputedCapacity1", true)
            setValue("partSeatT.inputedCurrent1", false)
            doCalcLoadCurrent(false);
            if(+getValues('partSeatT.calcPoint.loadCurrent') >= 0){
                clearErrors('partSeatT.calcPoint.loadCurrent')
            }
        }
        else{
            setValue("partSeatM.inputedCapacity1", true)
            setValue("partSeatM.inputedCurrent1", false)
            doCalcLoadCurrent(true);
            if(+getValues('partSeatM.calcPoint.loadCurrent') >= 0){
                clearErrors('partSeatM.calcPoint.loadCurrent')
            }
        }
    }

    const handleChangeLoadCurrent = (e: any) => {
        setValue(e.target.name, e.target.value);
        if (e.target.name === 'partSeatT.calcPoint.loadCurrent'){
            setValue("partSeatT.inputedCurrent1", true)
            setValue("partSeatT.inputedCapacity1", false)
            doCalcLoadCapacity(false);
            doCalcVoltageDrop1(false);
            doCalcVoltageDrop(false);
            if(+getValues('partSeatT.calcPoint.loadCapacity') >= 0){
                clearErrors('partSeatT.calcPoint.loadCapacity')
            }
        }
        else{
            setValue("partSeatM.inputedCurrent1", true)
            setValue("partSeatM.inputedCapacity1", false)
            doCalcLoadCapacity(true);
            doCalcVoltageDrop1(true);
            doCalcVoltageDrop(true);
            if(+getValues('partSeatM.calcPoint.loadCapacity') >= 0){
                clearErrors('partSeatM.calcPoint.loadCapacity')
            }
        }
    }
    
    const handleChangeCalcVoltDrop = (e: any) => {
        if (e.target.name.includes('calcPoint.isLead'))
            setValue(e.target.name, e.target.value == 'true');
        else
            setValue(e.target.name, e.target.value);

        let tmpList = [
            'partSeatT.calcPoint.powerFactor', 
            'partSeatT.calcPoint.isLead'
        ]
        if (tmpList.includes(e.target.name)){
            setValue("partSeatT.inputedPowerFactor1", true);
            doCalcVoltageDrop1(false);
            doCalcVoltageDrop(false);
        } else {
            setValue("partSeatM.inputedPowerFactor1", true);
            doCalcVoltageDrop1(true);
            doCalcVoltageDrop(true);
        }
    }
    const doSetValueDropLoadCapacity =(voltage:number, load_current:number,calcKind:number) =>{
        let capacity = voltage * load_current
        if(calcKind == Constant.CALC_IK3){
            capacity *= Math.sqrt(3.0)
        }
        return capacity
    }
    const resetCurrentTopLeft = (partIndex: number) => {
        if (partIndex == 0){
            setValue("partSeatM.inputedCapacity1",false)
            setValue("partSeatM.inputedCurrent1",false)

            setValue('partSeatM.calcPoint.loadCurrent', BeamsNumber(dispInputDialog.load_current_0,5));
            setValue('partSeatM.calcPoint.loadCapacity', BeamsNumber(doSetValueDropLoadCapacity(getValues("voltageSecondary"),dispInputDialog.load_current_0,getValues("partSeatM.calcPoint.calcKind")),7));
            clearErrors('partSeatM.calcPoint.loadCapacity')
            clearErrors('partSeatM.calcPoint.loadCurrent')
            doCalcVoltageDrop1(true);
            doCalcVoltageDrop(true);
        } else {
            setValue("partSeatT.inputedCapacity1",false)
            setValue("partSeatT.inputedCurrent1",false)

            setValue('partSeatT.calcPoint.loadCurrent', BeamsNumber(dispInputDialog.load_current_1,5));
            setValue('partSeatT.calcPoint.loadCapacity', BeamsNumber(doSetValueDropLoadCapacity(getValues("voltageSecondary"),dispInputDialog.load_current_1,getValues("partSeatT.calcPoint.calcKind")),7));
            clearErrors('partSeatT.calcPoint.loadCapacity')
            clearErrors('partSeatT.calcPoint.loadCurrent')
            doCalcVoltageDrop1(false);
            doCalcVoltageDrop(false);
        }
    }

    const resetLeadLag = (partIndex: number) => {
        if (partIndex == 0){
            setValue("partSeatM.inputedPowerFactor1",false);
            setValue('partSeatM.calcPoint.isLead', dispInputDialog.is_lead_0);
            setValue('partSeatM.calcPoint.powerFactor', BeamsNumber(dispInputDialog.power_factor_0,5));
            clearErrors('partSeatM.calcPoint.powerFactor')
            doCalcVoltageDrop1(true);
            doCalcVoltageDrop(true);
        } else {
            setValue("partSeatT.inputedPowerFactor1",false);
            setValue('partSeatT.calcPoint.isLead', dispInputDialog.is_lead_1);
            setValue('partSeatT.calcPoint.powerFactor', BeamsNumber(dispInputDialog.power_factor_1,5));
            clearErrors('partSeatT.calcPoint.powerFactor')
            doCalcVoltageDrop1(false);
            doCalcVoltageDrop(false);
        }
    }
    
    const doCalcRatedCurrent = () => {
        let ratedCurrent = msTransScottGetRatedCurrent(
            getValues('capacity'),
            getValues('voltagePrimary'),
            true
        );
        setValue('ratedCurrent', BeamsNumber(ratedCurrent, 4));
    }
    
    const doCalcRatedCurrent1 = () => {
        let ratedCurrent1 = msTransScottGetRatedCurrent(
            getValues('capacity'),
            getValues('voltageSecondary')
        );
        setValue('ratedCurrent1', BeamsNumber(ratedCurrent1, 4));
    }

    const doCalcInrushValue = (isSeatM: boolean) => {
        if (isSeatM) {
            let inrushValue1 = msTransScottGetInrushValue(
                getValues('partSeatM.inrushTimes'),
                getValues('capacity'),
                getValues('voltageSecondary')
            );
            setValue(
                'partSeatM.inrushValue', 
                BeamsNumber(inrushValue1, 4)
            );
        } else {
            let inrushValue2 = msTransScottGetInrushValue(
                getValues('partSeatT.inrushTimes'),
                getValues('capacity'),
                getValues('voltageSecondary')
            );
            setValue(
                'partSeatT.inrushValue', 
                BeamsNumber(inrushValue2, 4)
            );
        }
    }
    
    const doCalcIntensityValue = (isSeatM: boolean) => {
        if (isSeatM) {
            let intensityValue = msTransScottGetIntensityValue(
                getValues('partSeatM.intensityTimes'),
                getValues('capacity'),
                getValues('voltageSecondary')
            );
            setValue(
                'partSeatM.intensityValue', 
                BeamsNumber(intensityValue, 4)
            );
        } else {
            let intensityValue = msTransScottGetIntensityValue(
                getValues('partSeatT.intensityTimes'),
                getValues('capacity'),
                getValues('voltageSecondary')
            );
            setValue(
                'partSeatT.intensityValue', 
                BeamsNumber(intensityValue, 4)
            );
        }
    }

    const doCalcLoadCurrent = (isSeatM: boolean) => {
        if (isSeatM) {
            let loadCurrent = msTrans1ScottGetLoadCurrent(
                Math.trunc(getValues('voltageSecondary')),
                getValues('partSeatM.calcPoint.loadCapacity')
            );
            console.log(loadCurrent,"loadCurrent")
            setValue(
                'partSeatM.calcPoint.loadCurrent',
                BeamsNumber(loadCurrent, 7)
            );
    
            doCalcVoltageDrop1(true);
            doCalcVoltageDrop(true);
        } else {
            let loadCurrent = msTrans1ScottGetLoadCurrent(
                getValues('voltageSecondary'),
                getValues('partSeatT.calcPoint.loadCapacity')
            );
            setValue(
                'partSeatT.calcPoint.loadCurrent',
                BeamsNumber(loadCurrent, 7)
            );
    
            doCalcVoltageDrop1(false);
            doCalcVoltageDrop(false);
        }
    }
    
    const doCalcLoadCapacity = (isSeatM: boolean) => {
        if (isSeatM) {
            let loadCapacity = msTrans1ScottGetLoadCapacity(
                Math.trunc(getValues('voltageSecondary')),
                getValues('partSeatM.calcPoint.loadCurrent')
            );
            setValue(
                'partSeatM.calcPoint.loadCapacity',
                BeamsNumber(loadCapacity, 7)
            );
        } else {
            let loadCapacity = msTrans1ScottGetLoadCapacity(
                getValues('voltageSecondary'),
                getValues('partSeatT.calcPoint.loadCurrent')
            );
            setValue(
                'partSeatT.calcPoint.loadCapacity',
                BeamsNumber(loadCapacity, 7)
            );
        }
    }

    const doCalcVoltageDrop1 = (isSeatM: boolean) => {
        let calcData = {
            isSeatM: isSeatM, // SeatM aka 1
            percentR1: getValues('partSeatM.percentR'),
            percentX1: getValues('partSeatM.percentX'),
            percentR2: getValues('partSeatT.percentR'),
            percentX2: getValues('partSeatT.percentX'),
            capacity: getValues('capacity'),
            voltageSecondary: getValues('voltageSecondary'),
            loadCurrent: 
                isSeatM 
                ? getValues('partSeatM.calcPoint.loadCurrent') 
                : getValues('partSeatT.calcPoint.loadCurrent'),
            powerFactor: 
                isSeatM 
                ? getValues('partSeatM.calcPoint.powerFactor') 
                : getValues('partSeatT.calcPoint.powerFactor'),
            leadLag: 
                isSeatM 
                ? getValues('partSeatM.calcPoint.isLead') 
                : getValues('partSeatT.calcPoint.isLead'),
        }

        let voltageDrop1 = msTransScottGetDropVoltDiff(calcData);
        if (isSeatM)
            setValue(
                'partSeatM.voltageDrop1', 
                BeamsNumber(voltageDrop1, 4)
            );
        else
            setValue(
                'partSeatT.voltageDrop1',
                BeamsNumber(voltageDrop1, 4)
            );
    }

    const doCalcVoltageDrop = (isSeatM: boolean) => {
        let calcData = {
            isSeatM: isSeatM, // SeatM aka 1
            percentR1: getValues('partSeatM.percentR'),
            percentX1: getValues('partSeatM.percentX'),
            percentR2: getValues('partSeatT.percentR'),
            percentX2: getValues('partSeatT.percentX'),
            capacity: getValues('capacity'),
            voltageSecondary: getValues('voltageSecondary'),
            loadCurrent: 
                isSeatM 
                ? getValues('partSeatM.calcPoint.loadCurrent') 
                : getValues('partSeatT.calcPoint.loadCurrent'),
            powerFactor: 
                isSeatM 
                ? getValues('partSeatM.calcPoint.powerFactor') 
                : getValues('partSeatT.calcPoint.powerFactor'),
            leadLag: 
                isSeatM 
                ? getValues('partSeatM.calcPoint.isLead') 
                : getValues('partSeatT.calcPoint.isLead'),
        }

        let voltageDrop = msTransScottGetDropPerUnit(calcData);
        if (isSeatM)
            setValue('partSeatM.voltageDrop', BeamsNumber(voltageDrop, 4));
        else
            setValue('partSeatT.voltageDrop', BeamsNumber(voltageDrop, 4));
    }

    const doCalcXr = (isSeatM: boolean) => {
        let xr = 0
        if (isSeatM){
            let percentX = Number(getValues('partSeatM.percentX'));
            let percentR = Number(getValues('partSeatM.percentR'));
            if (percentX == 0)
                xr = 0
            else if (percentR == 0)
                xr = MS_XR_MAX
            else
                xr = percentX / percentR

            setValue('partSeatM.xr', BeamsNumber(xr, 5));
        } else {
            let percentX = Number(getValues('partSeatT.percentX'));
            let percentR = Number(getValues('partSeatT.percentR'));
            if (percentX == 0)
                xr = 0
            else if (percentR == 0)
                xr = MS_XR_MAX
            else
                xr = percentX / percentR
            
            setValue('partSeatT.xr', BeamsNumber(xr, 5));
        }
    }

    const doCalcPowerNormal = (isSeatM: boolean) => {
        let voltageSecondary = getValues('voltageSecondary');
        let calcData = {
            voltageSecondary: voltageSecondary,
            capacity: getValues('capacity') / 2,
            percentR: 
                isSeatM 
                ? getValues('partSeatM.percentR') 
                : getValues('partSeatT.percentR'),
            percentX: 
                isSeatM 
                ? getValues('partSeatM.percentX') 
                : getValues('partSeatT.percentX'),
        }
        let newPower = msTransNormalCalculation(calcData);

        if (isSeatM){
            setValue('partSeatM.percentZ', BeamsNumber(newPower.percentZ, 5));
            setValue('partSeatM.ohmZ', BeamsNumber(newPower.ohmZ, 4));
            setValue('partSeatM.ohmR', BeamsNumber(newPower.ohmR, 4));
            setValue('partSeatM.ohmX', BeamsNumber(newPower.ohmX, 4));
        } else {
            setValue('partSeatT.percentZ', BeamsNumber(newPower.percentZ, 5));
            setValue('partSeatT.ohmZ', BeamsNumber(newPower.ohmZ, 4));
            setValue('partSeatT.ohmR', BeamsNumber(newPower.ohmR, 4));
            setValue('partSeatT.ohmX', BeamsNumber(newPower.ohmX, 4));
        }
    }
    const DoGetValueOhmR = ( dVoltSecondary:number,  dCapacity:number,  dPercentR:number) =>
    {
        if (dCapacity == 0 || dPercentR == 0) {
            return 0;
        }
        const dOhmR = dVoltSecondary * dVoltSecondary / dCapacity / 1000 * dPercentR * 10;
        return dOhmR;
    }
    const DoGetValueOhmX = ( dVoltSecondary:number,  dCapacity:number,  dPercentX:number) =>
    {
        if (dCapacity == 0 || dPercentX == 0) {
            return 0;
        }
        const dOhmX = dVoltSecondary * dVoltSecondary / dCapacity / 1000  * dPercentX * 10;
    
        return dOhmX;
    }
    const DoGetValueOhmZ = ( dOhmR:number,  dOhmX:number) =>
    {
        const dOhmZ = Math.sqrt(dOhmR * dOhmR + dOhmX * dOhmX);
    
        return dOhmZ;
    }
    const DoGetTextOhmZ = (nPartKind:number) =>
    {
        let dOhmR = 0;
        let dOhmX = 0;
        switch (nPartKind) {
        case MS_SEAT_MAIN:		// 主座変圧器
            dOhmR = DoGetValueOhmR(+getValues('voltageSecondary'), +getValues('capacity') / 2,+getValues('partSeatM.percentR'));
            dOhmX = DoGetValueOhmX(+getValues('voltageSecondary'), +getValues('capacity') / 2,+getValues('partSeatM.percentX'));
            break;
        case MS_SEAT_T:		// Ｔ座変圧器
            dOhmR = DoGetValueOhmR(+getValues('voltageSecondary'), +getValues('capacity') / 2,+getValues('partSeatT.percentR'));
            dOhmX = DoGetValueOhmX(+getValues('voltageSecondary'), +getValues('capacity') / 2, +getValues('partSeatT.percentX'));
            break;
        }
        let dOhmZ = DoGetValueOhmZ(dOhmR, dOhmX);
        return BeamsNumber(dOhmZ,4)
    }   
    const DoGetTextOhmR = (nPartKind:number) =>
    {
        let dOhmR = 0;
        switch (nPartKind) {
        case MS_SEAT_MAIN:		// 主座変圧器
            dOhmR = DoGetValueOhmR(+getValues('voltageSecondary'), +getValues('capacity') / 2, +getValues('partSeatM.percentR'));
            break;
        case MS_SEAT_T:		// Ｔ座変圧器
            dOhmR = DoGetValueOhmR(+getValues('voltageSecondary'), +getValues('capacity') / 2,+getValues('partSeatT.percentR'));
            break;
        }
        return BeamsNumber(dOhmR,4)
    }
    const DoGetTextOhmX = (nPartKind:number) =>
    {
        let dOhmX = 0;
        switch (nPartKind) {
        case MS_SEAT_MAIN:		// 主座変圧器
            dOhmX = DoGetValueOhmX(+getValues('voltageSecondary'), +getValues('capacity') / 2, +getValues('partSeatM.percentX'));
            break;
        case MS_SEAT_T:		// Ｔ座変圧器
            dOhmX = DoGetValueOhmX(+getValues('voltageSecondary'), +getValues('capacity') / 2, +getValues('partSeatT.percentX'));
            break;
        }
        return BeamsNumber(dOhmX,4);
    }
    const doCalcPowerFromXR_Z = (isSeatM: boolean,nControl:string) => {
        let voltageSecondary = getValues('voltageSecondary');
        if(isSeatM){
            if (nControl != 'xr') {
                setValue('partSeatM.xr',+getValues('partSeatM.xr'));					
            }
        
            if (nControl != 'percentZ') {
                setValue('partSeatM.percentZ',+getValues('partSeatM.percentZ'));		
            }
        
            if (nControl != 'percentR') {
                setValue('partSeatM.percentR',+getValues('partSeatM.percentR'));		
            }
        
            if (nControl != 'percentX') {
                setValue('partSeatM.percentX',+getValues('partSeatM.percentX'));		
            }
        
            setValue('partSeatM.ohmZ',DoGetTextOhmZ(MS_SEAT_MAIN));			
        
            setValue('partSeatM.ohmR',DoGetTextOhmR(MS_SEAT_MAIN));		
        
            setValue('partSeatM.ohmX',DoGetTextOhmX(MS_SEAT_MAIN));	
        }
        else{
            if (nControl != 'xr') {
                setValue('partSeatT.xr',+getValues('partSeatT.xr'));					
            }
        
            if (nControl != 'percentZ') {
                setValue('partSeatT.percentZ',+getValues('partSeatT.percentZ'));		
            }
        
            if (nControl != 'percentR') {
                setValue('partSeatT.percentR',+getValues('partSeatT.percentR'));		
            }
        
            if (nControl != 'percentX') {
                setValue('partSeatT.percentX',+getValues('partSeatT.percentX'));		
            }
        
            setValue('partSeatT.ohmZ',DoGetTextOhmZ(MS_SEAT_T));			
        
            setValue('partSeatT.ohmR',DoGetTextOhmR(MS_SEAT_T));		
        
            setValue('partSeatT.ohmX',DoGetTextOhmX(MS_SEAT_T));	
        }
        // let newPower = msTransFromXR_ZCalculation(
        //     voltageSecondary,
        //     isSeatM 
        //         ? getValues('partSeatM.percentZ') 
        //         : getValues('partSeatT.percentZ'),
        //     getValues('capacity') / 2,
        //     isSeatM 
        //         ? getValues('partSeatM.xr') 
        //         : getValues('partSeatT.xr')
        // );

        // if (isSeatM) {
        //     setValue('partSeatM.percentR', BeamsNumber(newPower.percentR, 5));
        //     setValue('partSeatM.percentX', BeamsNumber(newPower.percentX, 5));
        //     setValue('partSeatM.ohmZ', BeamsNumber(newPower.ohmZ, 4));
        //     setValue('partSeatM.ohmR', BeamsNumber(newPower.ohmR, 4));
        //     setValue('partSeatM.ohmX', BeamsNumber(newPower.ohmX, 4));
        // } else {
        //     if (newPower.percentR !== undefined)
        //         setValue('partSeatT.percentR', BeamsNumber(newPower.percentR, 5));
        //     if (newPower.percentX !== undefined)
        //         setValue('partSeatT.percentX', BeamsNumber(newPower.percentX, 5));
        //     setValue('partSeatT.ohmZ', BeamsNumber(newPower.ohmZ, 4));
        //     setValue('partSeatT.ohmR', BeamsNumber(newPower.ohmR, 4));
        //     setValue('partSeatT.ohmX', BeamsNumber(newPower.ohmX, 4));
        // }
    }
        const clearErrorsTransscott = () =>{
            if(getValues('partSeatM.xr') >= 0){
                clearErrors('partSeatM.xr')
            }
            if(getValues('partSeatM.percentZ') > 0){
                clearErrors('partSeatM.percentZ')
            }
            if(getValues('partSeatM.percentR') >= 0){
                clearErrors('partSeatM.percentR')
            }
            if(getValues('partSeatM.percentX') >= 0){
                clearErrors('partSeatM.percentX')
            }

            if(getValues('partSeatT.xr') >= 0){
                clearErrors('partSeatT.xr')
            }
            if(getValues('partSeatT.percentZ') > 0){
                clearErrors('partSeatT.percentZ')
            }
            if(getValues('partSeatT.percentR') >= 0){
                clearErrors('partSeatT.percentR')
            }
            if(getValues('partSeatT.percentX') >= 0){
                clearErrors('partSeatT.percentX')
            }
        }
    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectTab} onChange={handleChange}
                    variant="scrollable" 
                    scrollButtons 
                    allowScrollButtonsMobile 
                    sx={{
                        '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                            display: "none"
                        }
                    }}
                >
                    <Tab label='基本' {...a11yProps(0)} />
                    <Tab label='基本 - [主座変圧器]' {...a11yProps(1)} />
                    <Tab label='基本 - [T座変圧器]' {...a11yProps(2)} />
                    <Tab label='保護協調 - [主座変圧器]' {...a11yProps(3)} />
                    <Tab label='保護協調 - [T座変圧器]' {...a11yProps(4)} />
                    <Tab label='電圧降下 - [主座変圧器]' {...a11yProps(5)} />
                    <Tab label='電圧降下 - [T座変圧器]' {...a11yProps(6)} />
                </Tabs>
            </Box>
            <form onSubmit={handleSubmit(handleEditSubmit)}>
                <div style={{ height: '40vh', width: '100%', margin: 'auto', overflow: 'auto' }}>
                    {/* tab0 - 基本 */}
                    <TabPanel value={selectTab} index={0}>
                        <Grid container mt={1}>
                            <Grid item xs={7}>
                                <Stack spacing={0.3}>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="75%">デバイス名:</Typography>
                                        <Controller
                                            name='refNo'
                                            control={control}
                                            rules={{
                                                required: '必須項目です。入力してください。',
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type='text'
                                                    size='small'
                                                    error={!!errors?.refNo}
                                                    helperText={errors?.refNo?.message}
                                                    onChange={handleChangeFormat}
                                                    inputProps={{readOnly: m_bModePM || modeViewOnly}}
                                                    InputProps={{ 
                                                        sx: { 
                                                            height: 32,
                                                            background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                            color: m_bModePM || modeViewOnly?'grey':'black',
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="75%">一次側回路電圧(V):</Typography>
                                        <FormControl fullWidth size='small'>
                                            <Controller
                                                name='voltagePrimary'
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。' ,
                                                    pattern:{
                                                        value: /^[0-9\b]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                }}
                                                render={({ field }: any) => (
                                                    <TextFieldOptions
                                                        {...field}
                                                        options={initialValue.voltagePrimary > VOLT_SIDE_LOW_MAX ?initialValue.voltagePrimaryList:initialValue.voltageSecondaryList}
                                                        // type='number'
                                                        size='small'
                                                        setValue={(name: any, value: any) => {
                                                            handleChangeVoltagePrimary({target: {name: name, value: value}})
                                                        }}
                                                        height={32}
                                                        disabled={m_bModePM || modeViewOnly || getValues("isSourceVoltage") || processMode > MS_MODE_CHECKING}
                                                        maxLength={6}
                                                        error={!!errors?.voltagePrimary}
                                                        helperText={errors?.voltagePrimary?.message}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="75%">二次側回路電圧(V):</Typography>
                                        <FormControl fullWidth size='small'>
                                            <Controller
                                                name='voltageSecondary'
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。' ,
                                                    pattern:{
                                                        value: /^[0-9\b]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                }}
                                                render={({ field }: any) => (
                                                    <TextFieldOptions
                                                        {...field}
                                                        disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                        options={initialValue.voltageSecondaryList}
                                                        // type='number'
                                                        size='small'
                                                        setValue={(name: any, value: any) => {
                                                            handleChangeVoltageSecondary({target: {name: name, value: value}})
                                                        }}
                                                        height={32}
                                                        maxLength={6}
                                                        error={!!errors?.voltageSecondary}
                                                        helperText={errors?.voltageSecondary?.message}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="75%">容量(kVA):</Typography>
                                        <FormControl fullWidth size='small'>
                                            <Controller
                                                name='capacity'
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。' ,
                                                    pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                }}
                                                render={({ field }: any) => (
                                                    <TextFieldOptions
                                                        {...field}
                                                        disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                        options={initialValue.capacityList}
                                                        // type='number'
                                                        size='small'
                                                        setValue={(name: any, value: any) => {
                                                            field.onChange();
                                                            handleChangeCapacity({target: {name: name, value: value}});
                                                        }}
                                                        height={32}
                                                        maxLength={6}
                                                        error={!!errors?.capacity}
                                                        helperText={errors?.capacity?.message}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="75%">一次側定格電流(A):</Typography>
                                        <Controller
                                            name='ratedCurrent'
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type='number'
                                                    size='small'
                                                    inputProps={{ readOnly: true }}
                                                    style={{ backgroundColor: 'floralwhite' }}
                                                    InputProps={{ sx: { height: 32 ,color:'grey'} }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={5} pl={3}>
                                {/* Mode SP */}
                                {(userRole && !(userRole == Constant.ROLE_LP)) && <>
                                <Stack>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name='viewFlowResult'
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        disabled={m_bModePM || modeViewOnly}
                                                        size='small'
                                                        checked={field.value}
                                                        onChange={handleChangeCheckbox}
                                                        style={checkBoxStyles}
                                                    />
                                                )}
                                            />
                                        }
                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>潮流解析用の値を表示</Typography>}
                                    />
                                </Stack>
                                <Stack pl={2} sx={{ flexDirection: "row", alignItems:"center" }}>
                                    <Typography variant="body2" width="75%">負荷率:</Typography>
                                    <Controller
                                        name='loadFactor'
                                        control={control}
                                        rules={{
                                            pattern:{
                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            validate: value => value >= 0 && value <= 1 || '数字 0 ~ 1 を入力してください。' ,
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                // type='number'
                                                size='small'
                                                inputProps={{readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                InputProps={{ 
                                                    sx: { 
                                                        height: 32,
                                                        background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                        color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                    }
                                                }}
                                                error={!!errors?.loadFactor}
                                                helperText={errors?.loadFactor?.message}
                                            />
                                        )}
                                    />
                                </Stack>
                                <Stack pl={2} mt={0.3} sx={{ flexDirection: "row", alignItems:"center" }}>
                                    <Typography variant="body2" width="75%">力率(遅れ):</Typography>
                                    <Controller
                                        name='powerFactor'
                                        control={control}
                                        rules={{ required: '必須項目です。入力してください。',
                                            pattern:{
                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                message: '数字を入力してください。'
                                            },
                                            validate: value => value > 0 && value <= 1 || '0 < 数字 <= 1 を入力してください。' ,
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                // type='number'
                                                size='small'
                                                inputProps={{readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                InputProps={{ 
                                                    sx: { 
                                                        height: 32,
                                                        background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                        color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                    }
                                                }}
                                                error={!!errors?.powerFactor}
                                                helperText={errors?.powerFactor?.message}
                                            />
                                        )}
                                    />
                                </Stack>
                                </>}
                                {/* END ModeSP */}
                                <Stack>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name='viewResultText'
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        disabled={m_bModePM || modeViewOnly}
                                                        size='small'
                                                        checked={field.value}
                                                        onChange={handleChangeCheckbox}
                                                        style={checkBoxStyles}
                                                    />
                                                )}
                                            />
                                        }
                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>注釈を表示</Typography>}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>

                        {/* ModePM */}
                        {[Constant.ROLE_PM, Constant.ROLE_ADMIN].includes(userRole) && 
                        <>
                            <Stack mt={1}>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            name='makeDataInput'
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    size='small'
                                                    {...field}
                                                    disabled={m_bModePM || modeViewOnly}
                                                    checked={field.value}
                                                    onChange={handleChangeMakeDataInput}
                                                    style={{padding: '4px 4px 4px 9px'}}
                                                />
                                            )}
                                        />
                                    }
                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>銘板入力</Typography>}
                                />
                            </Stack>
                            <Stack>
                                <fieldset
                                    style={{ 
                                        width: '60%',
                                        color: !makeDataInput ? 'grey' : 'black', 
                                        border:'1px solid #AAAA'
                                    }}
                                >
                                    <legend><Typography variant="body2"   >銘板</Typography></legend>
                                    <Stack spacing={0.3}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="35%"   >製造者:</Typography>
                                            <FormControl fullWidth size='small'>
                                                <Controller
                                                    name='makerName'
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <TextFieldOptions
                                                            {...field}
                                                            disabled={m_bModePM || modeViewOnly || !makeDataInput}
                                                            options={initialValue.makerNameList}
                                                            type='string'
                                                            size='small'
                                                            setValue={(name: any, value: any) => {
                                                                handleChangeFormat({target: {name: name, value: value}})
                                                            }}
                                                            height= {32}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="35%"  >形式:</Typography>
                                            <Controller
                                                name='type'
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type='text'
                                                        size='small'
                                                        onChange={handleChangeFormat}
                                                        inputProps={{readOnly: m_bModePM || modeViewOnly || !makeDataInput}}
                                                        InputProps={{ 
                                                            sx: { 
                                                                height: 32,
                                                                background:m_bModePM || modeViewOnly || !makeDataInput?'floralwhite':'white',
                                                                color: m_bModePM || modeViewOnly || !makeDataInput?'grey':'black',
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="35%"   >製造年月:</Typography>
                                            <Grid container>
                                                <Grid item xs={7}>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <FormControl fullWidth size='small'>
                                                            <Controller
                                                                name='makeYear'
                                                                control={control}
                                                                render={({ field }: any) => (
                                                                    <TextFieldOptions
                                                                        {...field}
                                                                        disabled={m_bModePM || modeViewOnly || !makeDataInput}
                                                                        options={initialValue.makeYearList}
                                                                        type='string'
                                                                        size='small'
                                                                        setValue={(name: any, value: any) => {
                                                                            handleChangeFormat({target: {name: name, value: value}})
                                                                        }}
                                                                        height= {32}
                                                                        maxLength={8}
                                                                    />
                                                                )}
                                                            />
                                                        </FormControl>
                                                        <Typography variant="body2" pr={1.5} pl={0.5}>年</Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <FormControl fullWidth size='small'>
                                                            <Controller
                                                                name='makeMonth'
                                                                control={control}
                                                                render={({ field }: any) => (
                                                                    <TextFieldOptions
                                                                        {...field}
                                                                        disabled={m_bModePM || modeViewOnly || !makeDataInput}
                                                                        options={initialValue.makeMonthList}
                                                                        type='string'
                                                                        size='small'
                                                                        setValue={(name: any, value: any) => {
                                                                            handleChangeFormat({target: {name: name, value: value}})
                                                                        }}
                                                                        height= {32}
                                                                        maxLength={2}
                                                                    />
                                                                )}
                                                            />
                                                        </FormControl>
                                                        <Typography variant="body2" pl={0.5}>月</Typography>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Stack>

                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="35%"   >製造番号:</Typography>
                                            <Controller
                                                name='makeNumber'
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type='text'
                                                        size='small'
                                                        onChange={handleChangeFormat}
                                                        inputProps={{readOnly: m_bModePM || modeViewOnly || !makeDataInput}}
                                                        InputProps={{ 
                                                            sx: { 
                                                                height: 32,
                                                                background:m_bModePM || modeViewOnly || !makeDataInput?'floralwhite':'white',
                                                                color: m_bModePM || modeViewOnly || !makeDataInput?'grey':'black',
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Stack>
                                </fieldset>
                            </Stack>
                        </>
                        }
                        {/* End ModePM */}
                    </TabPanel>
                    {/* tab of each parts: [主座変圧器] & [T座変圧器] */}
                            {/* tab1-2 - 基本 */}
                            <TabPanel value={selectTab} index={1}>
                                <Grid container my={1}>
                                    <Grid item xs={7}>
                                        <Stack spacing={0.3}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">事故点名称:</Typography>
                                                <Controller
                                                    name={"partSeatM.pointText"}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='text'
                                                            size='small'
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">一次側回路電圧(V):</Typography>
                                                <Controller
                                                    name='voltagePrimary'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{ sx: { height: 32 ,color:'grey'} }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">二次側回路電圧(V):</Typography>
                                                <Controller
                                                    name='voltageSecondary'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{ sx: { height: 32,color:'grey' } }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">容量(kVA):</Typography>
                                                <Controller
                                                    name='capacity'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{ sx: { height: 32 ,color:'grey'} }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">二次側定格電流(A):</Typography>
                                                <Controller
                                                    name='ratedCurrent1'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{ sx: { height: 32,color:'grey' } }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={5} pl={3}>
                                        <Stack>
                                            <FormControlLabel
                                                control={
                                                    <Controller
                                                        name={"partSeatM.fault"}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Checkbox
                                                                {...field}
                                                                disabled={m_bModePM || modeViewOnly}
                                                                size='small'
                                                                checked={field.value}
                                                                onChange={handleChangeCheckbox}
                                                                style={checkBoxStyles}
                                                            />
                                                        )}
                                                    />
                                                }
                                                label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>短絡電流を計算</Typography>}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <fieldset style={{border:'1px solid #AAAA'}}>
                                    <legend ><Typography variant="body2">インピーダンス(機器容量ベース)</Typography></legend>
                                    <Stack spacing={0.3}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width="100%">X/R:</Typography>
                                                    <Controller
                                                        name={"partSeatM.xr"}
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                // type="number"
                                                                size="small"
                                                                onChange={(e) =>{field.onChange(e);handleChangeXR_Z(e)}}
                                                                sx={{ width: 200 }}
                                                                inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                    }
                                                                }}
                                                                error={!!errors?.partSeatM?.xr}
                                                                helperText={errors?.partSeatM?.xr?.message}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width='100%'>%インピーダンス(%):</Typography>
                                                    <Controller
                                                        name={"partSeatM.percentZ"}
                                                        control={control}
                                                        rules={{ required: '必須項目です。入力してください。',
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                // type="text"
                                                                size="small"
                                                                onChange={(e) =>{field.onChange(e);handleChangeXR_Z(e)}}
                                                                sx={{ width: 200 }}
                                                                inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                    }
                                                                }}
                                                            error={!!errors?.partSeatM?.percentZ}
                                                            helperText={errors?.partSeatM?.percentZ?.message}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                                <span>=</span>
                                            </Grid>
                                            <Grid item xs={2.5}>
                                                <Stack>
                                                    <Controller
                                                        name={"partSeatM.percentR"}
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type="text"
                                                                size="small"
                                                                onChange={(e) =>{field.onChange(e);handleChangeR_X(e)}}
                                                                inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                    }
                                                                }}
                                                            error={!!errors?.partSeatM?.percentR}
                                                            helperText={errors?.partSeatM?.percentR?.message}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                                <span>+ j</span>
                                            </Grid>
                                            <Grid item xs={2.5}>
                                                <Stack>
                                                    <Controller
                                                        name={"partSeatM.percentX"}
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type="text"
                                                                size="small"
                                                                onChange={(e) =>{field.onChange(e);handleChangeR_X(e)}}
                                                                inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                    }
                                                                }}
                                                                error={!!errors?.partSeatM?.percentX}
                                                                helperText={errors?.partSeatM?.percentX?.message}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width='100%'>インピーダンス(mΩ):</Typography>
                                                    <Controller
                                                        name={"partSeatM.ohmZ"}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                type="text"
                                                                size="small"
                                                                inputProps={{ readOnly: true }}
                                                                style={{ backgroundColor: 'floralwhite' }}
                                                                sx={{ width: 200 }}
                                                                InputProps={{sx: { height: 32 ,color:'grey'}}}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                                <span>=</span>
                                            </Grid>
                                            <Grid item xs={2.5}>
                                                <Stack>
                                                    <Controller
                                                        name={"partSeatM.ohmR"}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                inputProps={{ readOnly: true }}
                                                                style={{ backgroundColor: 'floralwhite' }}
                                                                InputProps={{sx: { height: 32 ,color:'grey'}}}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                                <span>+ j</span>
                                            </Grid>
                                            <Grid item xs={2.5}>
                                                <Stack>
                                                    <Controller
                                                        name={"partSeatM.ohmX"}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                inputProps={{ readOnly: true }}
                                                                style={{ backgroundColor: 'floralwhite' }}
                                                                InputProps={{sx: { height: 32 ,color:'grey'}}}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </fieldset>
                            </TabPanel>
                                
                            {/* partSeatT */}
                            <TabPanel value={selectTab} index={2}>
                                <Grid container my={1}>
                                    <Grid item xs={7}>
                                        <Stack spacing={0.3}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">事故点名称:</Typography>
                                                <Controller
                                                    name={"partSeatT.pointText"}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='text'
                                                            size='small'
                                                            inputProps={{readOnly: m_bModePM || modeViewOnly}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">一次側回路電圧(V):</Typography>
                                                <Controller
                                                    name='voltagePrimary'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{ sx: { height: 32 ,color:'grey'} }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">二次側回路電圧(V):</Typography>
                                                <Controller
                                                    name='voltageSecondary'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{ sx: { height: 32 ,color:'grey'} }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">容量(kVA):</Typography>
                                                <Controller
                                                    name='capacity'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{ sx: { height: 32 ,color:'grey'} }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">二次側定格電流(A):</Typography>
                                                <Controller
                                                    name='ratedCurrent1'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{ sx: { height: 32 ,color:'grey'} }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={5} pl={3}>
                                        <Stack>
                                            <FormControlLabel
                                                control={
                                                    <Controller
                                                        name={"partSeatT.fault"}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Checkbox
                                                                {...field}
                                                                disabled={m_bModePM || modeViewOnly}
                                                                size='small'
                                                                checked={field.value}
                                                                onChange={handleChangeCheckbox}
                                                                style={checkBoxStyles}
                                                            />
                                                        )}
                                                    />
                                                }
                                                label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>短絡電流を計算</Typography>}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <fieldset style={{border:'1px solid #AAAA'}}>
                                    <legend><Typography variant="body2">インピーダンス(機器容量ベース)</Typography></legend>
                                    <Stack spacing={0.3}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width="100%">X/R:</Typography>
                                                    <Controller
                                                        name={"partSeatT.xr"}
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                // type="number"
                                                                size="small"
                                                                onChange={(e) =>{field.onChange(e);handleChangeXR_Z(e)}}
                                                                sx={{ width: 200 }}
                                                                inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                    }
                                                                }}
                                                                error={!!errors?.partSeatT?.xr}
                                                                helperText={errors?.partSeatT?.xr?.message}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width='100%'>%インピーダンス(%):</Typography>
                                                    <Controller
                                                        name={"partSeatT.percentZ"}
                                                        control={control}
                                                        rules={{ required: '必須項目です。入力してください。',
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                // type="text"
                                                                size="small"
                                                                onChange={(e) =>{field.onChange(e);handleChangeXR_Z(e)}}
                                                                sx={{ width: 200 }}
                                                                inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                    }
                                                                }}
                                                                error={!!errors?.partSeatT?.percentZ}
                                                                helperText={errors?.partSeatT?.percentZ?.message}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                                <span>=</span>
                                            </Grid>
                                            <Grid item xs={2.5}>
                                                <Stack>
                                                    <Controller
                                                        name={"partSeatT.percentR"}
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type="text"
                                                                size="small"
                                                                onChange={(e) =>{field.onChange(e);handleChangeR_X(e)}}
                                                                inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly ||  processMode > MS_MODE_CHECKING}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly ||  processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly ||  processMode > MS_MODE_CHECKING?'grey':'black',
                                                                    }
                                                                }}
                                                                error={!!errors?.partSeatT?.percentR}
                                                                helperText={errors?.partSeatT?.percentR?.message}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                                <span>+ j</span>
                                            </Grid>
                                            <Grid item xs={2.5}>
                                                <Stack>
                                                    <Controller
                                                        name={"partSeatT.percentX"}
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type="text"
                                                                size="small"
                                                                onChange={(e) =>{field.onChange(e);handleChangeR_X(e)}}
                                                                inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                                    }
                                                                }}
                                                                error={!!errors?.partSeatT?.percentX}
                                                                helperText={errors?.partSeatT?.percentX?.message}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                    <Typography variant="body2" width='100%'>インピーダンス(mΩ):</Typography>
                                                    <Controller
                                                        name={"partSeatT.ohmZ"}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                type="text"
                                                                size="small"
                                                                inputProps={{ readOnly: true }}
                                                                style={{ backgroundColor: 'floralwhite' }}
                                                                sx={{ width: 200 }}
                                                                InputProps={{sx: { height: 32 ,color:'grey'}}}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                                <span>=</span>
                                            </Grid>
                                            <Grid item xs={2.5}>
                                                <Stack>
                                                    <Controller
                                                        name={"partSeatT.ohmR"}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                inputProps={{ readOnly: true }}
                                                                style={{ backgroundColor: 'floralwhite' }}
                                                                InputProps={{sx: { height: 32 ,color:'grey'}}}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                                <span>+ j</span>
                                            </Grid>
                                            <Grid item xs={2.5}>
                                                <Stack>
                                                    <Controller
                                                        name={"partSeatT.ohmX"}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                inputProps={{ readOnly: true }}
                                                                style={{ backgroundColor: 'floralwhite' }}
                                                                InputProps={{sx: { height: 32 ,color:'grey'}}}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                </fieldset>
                            </TabPanel>

                            {/* tab3-4 - 保護協調 */}
                            <TabPanel value={selectTab} index={3}>
                                <Stack mt={1} width={'75%'}>
                                    <fieldset style={{border:"1px solid #AAAA"}}>
                                        <legend><Typography variant="body2">励磁突入電流曲線</Typography></legend>
                                        <Stack spacing={0.3}>
                                            <Stack>
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name={"partSeatM.dispInrush"}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    {...field}
                                                                    disabled={m_bModePM || modeViewOnly}
                                                                    size='small'
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckbox}
                                                                    style={checkBoxStyles}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>グラフに表示する</Typography>}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">定格電流の最大倍率(倍):</Typography>
                                                <Controller
                                                    name={"partSeatM.inrushTimes"}
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type='number'
                                                            size='small'
                                                            onChange={(e) =>{field.onChange(e);handleChangeInrushTimes(e)}}
                                                            inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partSeatM?.inrushTimes}
                                                            helperText={errors?.partSeatM?.inrushTimes?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">最大電流(Arms):</Typography>
                                                <Controller
                                                    name={"partSeatM.inrushValue"}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{ sx: { height: 32,color:'grey' } }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">減衰時定数(ms):</Typography>
                                                <Controller
                                                    name={"partSeatM.dampingTime"}
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type='number'
                                                            size='small'
                                                            onChange={(e) =>{field.onChange(e);handleChangeFormat(e)}}
                                                            inputProps={{maxlength:4,readOnly: m_bModePM || modeViewOnly}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partSeatM?.dampingTime}
                                                            helperText={errors?.partSeatM?.dampingTime?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                                </Stack>
                                <Stack mt={2} width={'75%'}>
                                    <fieldset style={{border:"1px solid #AAAA"}}>
                                        <legend><Typography variant="body2">熱的機械的強度ポイント</Typography></legend>
                                        <Stack spacing={0.3}>
                                            <Stack>
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name={"partSeatM.dispIntensity"}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    {...field}
                                                                    disabled={m_bModePM || modeViewOnly}
                                                                    size='small'
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckbox}
                                                                    style={checkBoxStyles}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>グラフに表示する</Typography>}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">定格電流の最大倍率(倍):</Typography>
                                                <Controller
                                                    name={"partSeatM.intensityTimes"}
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type='number'
                                                            size='small'
                                                            onChange={(e) =>{field.onChange(e);handleChangeIntensityTimes(e)}}
                                                            inputProps={{maxlength:4,readOnly: m_bModePM || modeViewOnly}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partSeatM?.intensityTimes}
                                                            helperText={errors?.partSeatM?.intensityTimes?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">最大電流(Arms):</Typography>
                                                <Controller
                                                    name={"partSeatM.intensityValue"}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{ sx: { height: 32 ,color:'grey'} }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">時間(s):</Typography>
                                                <Controller
                                                    name={"partSeatM.intensityTime"}
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type='number'
                                                            size='small'
                                                            onChange={(e) =>{field.onChange(e);handleChangeFormat(e)}}
                                                            inputProps={{maxlength:4,readOnly: m_bModePM || modeViewOnly}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partSeatM?.intensityTime}
                                                            helperText={errors?.partSeatM?.intensityTime?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                                </Stack>
                            </TabPanel>

                            {/* partSeatT */}
                            <TabPanel value={selectTab} index={4}>
                                <Stack mt={1} width={'75%'}>
                                    <fieldset style={{border:"1px solid #AAAA"}}>
                                        <legend><Typography variant="body2">励磁突入電流曲線</Typography></legend>
                                        <Stack spacing={0.3}>
                                            <Stack>
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name={"partSeatT.dispInrush"}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    {...field}
                                                                    disabled={m_bModePM || modeViewOnly}
                                                                    size='small'
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckbox}
                                                                    style={checkBoxStyles}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>グラフに表示する</Typography>}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">定格電流の最大倍率(倍):</Typography>
                                                <Controller
                                                    name={"partSeatT.inrushTimes"}
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type='number'
                                                            size='small'
                                                            onChange={(e)=>{field.onChange(e);handleChangeInrushTimes(e)}}
                                                            inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partSeatT?.inrushTimes}
                                                            helperText={errors?.partSeatT?.inrushTimes?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">最大電流(Arms):</Typography>
                                                <Controller
                                                    name={"partSeatT.inrushValue"}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{ sx: { height: 32 ,color:'grey'} }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">減衰時定数(ms):</Typography>
                                                <Controller
                                                    name={"partSeatT.dampingTime"}
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type='number'
                                                            size='small'
                                                            onChange={(e) =>{field.onChange(e);handleChangeFormat(e)}}
                                                            inputProps={{maxlength:4,readOnly: m_bModePM || modeViewOnly}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partSeatT?.dampingTime}
                                                            helperText={errors?.partSeatT?.dampingTime?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                                </Stack>
                                <Stack mt={2} width={'75%'}>
                                    <fieldset style={{border:"1px solid #AAAA"}}>
                                        <legend><Typography variant="body2">熱的機械的強度ポイント</Typography></legend>
                                        <Stack spacing={0.3}>
                                            <Stack>
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name={"partSeatT.dispIntensity"}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    {...field}
                                                                    disabled={m_bModePM || modeViewOnly}
                                                                    size='small'
                                                                    checked={field.value}
                                                                    onChange={handleChangeCheckbox}
                                                                    style={checkBoxStyles}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>グラフに表示する</Typography>}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">定格電流の最大倍率(倍):</Typography>
                                                <Controller
                                                    name={"partSeatT.intensityTimes"}
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type='number'
                                                            size='small'
                                                            onChange={(e) =>{field.onChange(e);handleChangeIntensityTimes(e)}}
                                                            inputProps={{maxlength:4,readOnly: m_bModePM || modeViewOnly}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partSeatT?.intensityTimes}
                                                            helperText={errors?.partSeatT?.intensityTimes?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">最大電流(Arms):</Typography>
                                                <Controller
                                                    name={"partSeatT.intensityValue"}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' }}
                                                            InputProps={{ sx: { height: 32 ,color:'grey'} }}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                <Typography variant="body2" width="75%">時間(s):</Typography>
                                                <Controller
                                                    name={"partSeatT.intensityTime"}
                                                    control={control}
                                                    rules={{ required: '必須項目です。入力してください。',
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type='number'
                                                            size='small'
                                                            onChange={(e) =>{field.onChange(e);handleChangeFormat(e)}}
                                                            inputProps={{maxlength:4,readOnly: m_bModePM || modeViewOnly}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partSeatT?.intensityTime}
                                                            helperText={errors?.partSeatT?.intensityTime?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Stack>
                                    </fieldset>
                                </Stack>
                            </TabPanel>
                            {/* tab5-6 - 電圧降下 */}
                            <TabPanel value={selectTab} index={5}>
                                <Grid container>
                                    <Grid item xs={10}>
                                        <Controller
                                                name="partSeatM.dropRegular"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel
                                                        {...field}
                                                        checked={field.value}
                                                        disabled={m_bModePM || modeViewOnly}
                                                        label={<Typography variant="body2">定常時の電圧降下を計算</Typography>}
                                                        name='partSeatM.dropRegular'
                                                        onChange={(e) =>hanDleChangedropRegularr(e)} 
                                                        control={<Checkbox style={checkBoxStyles} />}
                                                    />
                                                )}
                                            />
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={7.5}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                <Typography variant="body2" width="90%" ml={5}>負荷容量 (VA):</Typography>  
                                                    <Controller
                                                        name="partSeatM.calcPoint.loadCapacity"
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type='number'
                                                                size='small'
                                                                onChange={(e) =>{field.onChange(e);handleChangeLoadCapacity(e)}}
                                                                inputProps={{readOnly: m_bModePM || modeViewOnly || !dropRegular1}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly || !dropRegular1?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly || !dropRegular1?'grey':'black',
                                                                    }
                                                                }}
                                                                error={!!errors?.partSeatM?.calcPoint?.loadCapacity}
                                                            helperText={errors?.partSeatM?.calcPoint?.loadCapacity?.message}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                        </Grid>
                                        <Grid item xs ={7.5}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                <Typography variant="body2" width="90%" ml={5}>負荷電流 IL(A):</Typography>  
                                                <Controller
                                                    name="partSeatM.calcPoint.loadCurrent"
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type='number'
                                                            size='small'
                                                            onChange={(e) =>{field.onChange(e);handleChangeLoadCurrent(e)}}
                                                            inputProps={{maxlength:7,readOnly: m_bModePM || modeViewOnly || !dropRegular1}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || !dropRegular1?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || !dropRegular1?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partSeatM?.calcPoint?.loadCurrent}
                                                            helperText={errors?.partSeatM?.calcPoint?.loadCurrent?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Grid container ml={6.5}>
                                        <fieldset style={{border:"1px solid #AAAA",width:"53%"}}>
                                            <legend><Typography variant="body2" color={'grey'}>負荷電流方向</Typography></legend>
                                            <Controller
                                                    name="partSeatM.currentTopLeft"
                                                    control={control}
                                                    render={({ field }) => (
                                                            <RadioGroup {...field}>
                                                                <FormControlLabel
                                                                    value='0'
                                                                    control={<Radio style={radioStyles1} />}
                                                                    label={<Typography variant="body2" color={'grey'}>上→下 or 左→右</Typography>}
                                                                    disabled

                                                                />
                                                                <FormControlLabel
                                                                    value='1'
                                                                    control={<Radio style={radioStyles1} />}
                                                                    label={<Typography variant="body2" color={'grey'}>下→上 or 右→左</Typography>}
                                                                    disabled

                                                                />
                                                            </RadioGroup>
                                                    )}
                                                />
                                            </fieldset>
                                            <Stack ml={1.2}>
                                                <Typography color="common.white">nothing</Typography>
                                                <Typography color="common.white">nothing</Typography>
                                                <Button
                                                    variant="outlined"
                                                    onClick={()=>resetCurrentTopLeft(0)} 
                                                    disabled={m_bModePM || modeViewOnly || !dropRegular1}
                                                >概算値</Button>
                                            </Stack>
                                    </Grid>
                                    <Grid container ml={3}>
                                        <fieldset style={{width:"76%",border:"1px solid #AAAA"}}>
                                            <legend><Typography variant="body2">回路の力率</Typography></legend>
                                            <Grid container>
                                                <Grid item xs={5}>
                                                    <Controller
                                                        name="partSeatM.calcPoint.isLead"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <RadioGroup {...field} row onChange={handleChangeCalcVoltDrop}>
                                                                <FormControlLabel
                                                                    value={true}
                                                                    control={<Radio style={radioStyles1} />}
                                                                    label={<Typography variant="body2" color={m_bModePM || !dropRegular1?'grey':'black'}>進み</Typography>}
                                                                    disabled={m_bModePM || modeViewOnly || !dropRegular1}
                                                                />
                                                                <FormControlLabel
                                                                    value={false}
                                                                    control={<Radio style={radioStyles1} />}
                                                                    label={<Typography variant="body2" color={m_bModePM || !dropRegular1?'grey':'black'}>遅れ</Typography>}
                                                                    disabled={m_bModePM || modeViewOnly || !dropRegular1}
                                                                />
                                                            </RadioGroup>
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid xs={1}/>
                                                <Grid xs={2.75} ml={1.4}>
                                                    <Controller
                                                        name="partSeatM.calcPoint.powerFactor"
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 && value <= 1 || '数字 0 ~ 1 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type='number'
                                                                size='small'
                                                                onChange={(e) =>{field.onChange(e);handleChangeCalcVoltDrop(e)}}
                                                                inputProps={{maxlength:7,readOnly: m_bModePM || modeViewOnly || !dropRegular1}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly || !dropRegular1?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly || !dropRegular1?'grey':'black',
                                                                    }
                                                                }}
                                                            error={!!errors?.partSeatM?.calcPoint?.powerFactor}
                                                            helperText={errors?.partSeatM?.calcPoint?.powerFactor?.message}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={2.5} ml={1.64}>
                                                    <Button
                                                        style={{margin:"0"}}
                                                        variant="outlined" 
                                                        disabled={m_bModePM || modeViewOnly || !dropRegular1}
                                                        onClick={() => resetLeadLag(0)}
                                                    >概算値</Button>
                                                </Grid>
                                            </Grid>
                                        </fieldset>
                                    </Grid>
                                    <Grid container  mt={0.25}>
                                        <Grid container flexDirection={"row"} ml={6} alignItems={"center"}>
                                            <Grid item xs={4} >
                                                <Typography variant="body2" width="100%" >単体での電圧降下(V):</Typography>  
                                            </Grid>
                                            <Grid item xs={4} ml={-2.6}>
                                                <Controller
                                                    name="partSeatM.voltageDrop1"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite' ,width:"70%",marginLeft:'20%'}}
                                                            InputProps={{ sx: { height: 32,color:'grey' } }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container flexDirection={"row"} ml={6} mt={0.25} alignItems={"center"}>
                                            <Grid item xs={4} >
                                                <Typography variant="body2" width="100%" >単体での電圧降下率(%):</Typography>  
                                            </Grid>
                                            <Grid item xs={4} ml={-2.6}>
                                                <Controller
                                                    name="partSeatM.voltageDrop"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite',width:"70%",marginLeft:'20%' }}
                                                            InputProps={{ sx: { height: 32 ,color:'grey'} }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </TabPanel>

                            <TabPanel value={selectTab} index={6}>
                                <Grid container>
                                    <Grid item xs={10}>
                                        <Controller
                                                name="partSeatT.dropRegular"
                                                control={control}
                                                render={({ field }: any) => (
                                                    <FormControlLabel
                                                        {...field}
                                                        disabled={m_bModePM || modeViewOnly}
                                                        checked={field.value}
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>定常時の電圧降下を計算</Typography>}
                                                        name='partSeatT.dropRegular'
                                                        onChange={hanDleChangedropRegularr} 
                                                        control={<Checkbox style={checkBoxStyles} />}
                                                    />
                                                )}
                                            />
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={7.5}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                <Typography variant="body2" width="90%" ml={5}>負荷容量 (VA):</Typography>  
                                                    <Controller
                                                        name="partSeatT.calcPoint.loadCapacity"
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0  || '数字 >= 0 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type='number'
                                                                size='small'
                                                                onChange={(e) =>{field.onChange(e);handleChangeLoadCapacity(e)}}
                                                                inputProps={{readOnly: m_bModePM || modeViewOnly || !dropRegular2}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly || !dropRegular2?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly || !dropRegular2?'grey':'black',
                                                                    }
                                                                }}
                                                            error={!!errors?.partSeatT?.calcPoint?.loadCapacity}
                                                            helperText={errors?.partSeatT?.calcPoint?.loadCapacity?.message}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                        </Grid>
                                        <Grid item xs ={7.5}>
                                            <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                                <Typography variant="body2" width="90%" ml={5}>負荷電流 IL(A):</Typography>  
                                                <Controller
                                                    name="partSeatT.calcPoint.loadCurrent"
                                                    control={control}
                                                    rules={{
                                                        pattern:{
                                                            value: /^[0-9]*[.]?[0-9]+$/,
                                                            message: '数字を入力してください。'
                                                        },
                                                        validate: value => value >= 0  || '数字 >= 0 を入力してください。' ,
                                                    }}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            // type='number'
                                                            size='small'
                                                            onChange={(e) =>{field.onChange(e);handleChangeLoadCurrent(e)}}
                                                            inputProps={{maxlength:7,readOnly: m_bModePM || modeViewOnly || !dropRegular2}}
                                                            InputProps={{ 
                                                                sx: { 
                                                                    height: 32,
                                                                    background:m_bModePM || modeViewOnly || !dropRegular2?'floralwhite':'white',
                                                                    color: m_bModePM || modeViewOnly || !dropRegular2?'grey':'black',
                                                                }
                                                            }}
                                                            error={!!errors?.partSeatT?.calcPoint?.loadCurrent}
                                                            helperText={errors?.partSeatT?.calcPoint?.loadCurrent?.message}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Grid container ml={6.5}>
                                        <fieldset style={{border:"1px solid #AAAA",width:"53%"}}>
                                            <legend><Typography variant="body2" color={'grey'}>負荷電流方向</Typography></legend>
                                            <Controller
                                                    name="partSeatT.currentTopLeft"
                                                    control={control}
                                                    render={({ field }) => (
                                                            <RadioGroup {...field}>
                                                                <FormControlLabel
                                                                    value='0'
                                                                    control={<Radio style={radioStyles1} />}
                                                                    label={<Typography variant="body2" color={'grey'}>上→下 or 左→右</Typography>}
                                                                    disabled
                                                                />
                                                                <FormControlLabel
                                                                    value='1'
                                                                    control={<Radio style={radioStyles1} />}
                                                                    label={<Typography variant="body2" color={'grey'}>下→上 or 右→左</Typography>}
                                                                    disabled
                                                                />
                                                            </RadioGroup>
                                                    )}
                                                />
                                            </fieldset>
                                            <Stack ml={1.2}>
                                                <Typography color="common.white">nothing</Typography>
                                                <Typography color="common.white">nothing</Typography>
                                                <Button
                                                    variant="outlined"
                                                    disabled={m_bModePM || modeViewOnly || !dropRegular2}
                                                    onClick={() => resetCurrentTopLeft(1)}
                                                >概算値</Button>
                                            </Stack>
                                    </Grid>
                                    <Grid container ml={3}>
                                        <fieldset style={{width:"76%",border:"1px solid #AAAA"}}>
                                            <legend><Typography variant="body2">回路の力率</Typography></legend>
                                            <Grid container>
                                                <Grid item xs={5}>
                                                    <Controller
                                                        name="partSeatT.calcPoint.isLead"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <RadioGroup {...field} row onChange={handleChangeCalcVoltDrop}>
                                                                <FormControlLabel
                                                                    value={true}
                                                                    control={<Radio style={radioStyles1} />}
                                                                    label={<Typography variant="body2" color={m_bModePM || !dropRegular2?'grey':'black'}>進み</Typography>}
                                                                    disabled={m_bModePM || modeViewOnly || !dropRegular2}
                                                                />
                                                                <FormControlLabel
                                                                    value={false}
                                                                    control={<Radio style={radioStyles1} />}
                                                                    label={<Typography variant="body2" color={m_bModePM || !dropRegular2?'grey':'black'}>遅れ</Typography>}
                                                                    disabled={m_bModePM || modeViewOnly || !dropRegular2}

                                                                />
                                                            </RadioGroup>
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid xs={1}/>
                                                <Grid xs={2.75} ml={1.4}>
                                                    <Controller
                                                        name="partSeatT.calcPoint.powerFactor"
                                                        control={control}
                                                        rules={{
                                                            pattern:{
                                                                value: /^[0-9]*[.]?[0-9]+$/,
                                                                message: '数字を入力してください。'
                                                            },
                                                            validate: value => value >= 0 && value <= 1  || '数字 0 ~ 1 を入力してください。' ,
                                                        }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                // type='number'
                                                                size='small'
                                                                onChange={(e) =>{field.onChange(e);handleChangeCalcVoltDrop(e)}}
                                                                inputProps={{maxlength:7,readOnly: m_bModePM || modeViewOnly || !dropRegular2}}
                                                                InputProps={{ 
                                                                    sx: { 
                                                                        height: 32,
                                                                        background:m_bModePM || modeViewOnly || !dropRegular2?'floralwhite':'white',
                                                                        color: m_bModePM || modeViewOnly || !dropRegular2?'grey':'black',
                                                                    }
                                                                }}
                                                                error={!!errors?.partSeatT?.calcPoint?.powerFactor}
                                                                helperText={errors?.partSeatT?.calcPoint?.powerFactor?.message}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={2.5} ml={1.64}>
                                                    <Button
                                                        style={{margin:"0"}}
                                                        variant="outlined"
                                                        disabled={ m_bModePM || modeViewOnly || !dropRegular2}
                                                        onClick={() => resetLeadLag(1)}
                                                    >概算値</Button>
                                                </Grid>
                                            </Grid>
                                        </fieldset>
                                    </Grid>
                                    <Grid container  mt={0.25}>
                                        <Grid container flexDirection={"row"} ml={6} alignItems={"center"}>
                                            <Grid item xs={4} >
                                                <Typography variant="body2" width="100%" >単体での電圧降下(V):</Typography>  
                                            </Grid>
                                            <Grid item xs={4} ml={-2.6}>
                                                <Controller
                                                    name="partSeatT.voltageDrop1"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite',width:"70%",marginLeft:'20%' }}
                                                            InputProps={{ sx: { height: 32,color:'grey' } }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container flexDirection={"row"} ml={6} mt={0.25} alignItems={"center"}>
                                            <Grid item xs={4} >
                                                <Typography variant="body2" width="100%" >単体での電圧降下率(%):</Typography>  
                                            </Grid>
                                            <Grid item xs={4} ml={-2.6}>
                                                <Controller
                                                    name="partSeatT.voltageDrop"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type='number'
                                                            size='small'
                                                            inputProps={{ readOnly: true }}
                                                            style={{ backgroundColor: 'floralwhite',width:"70%",marginLeft:'20%' }}
                                                            InputProps={{ sx: { height: 32,color:'grey' } }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </TabPanel>
    
                </div>
                <Stack direction='row' justifyContent='flex-end' spacing={2} mt={2}>
                    <Button variant='contained' type='submit' disabled={modeViewOnly}>OK</Button>
                    <Button color='inherit' variant='contained' onClick={handleCancel}>キャンセル</Button>
                </Stack>
            </form >
        </>
    );
};
export default MsTransScottEditDialog;