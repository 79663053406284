import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from 'ag-grid-react';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { AG_GRID_LOCALE_JP } from '../../../themes/localeJP';
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';
import { userGroupRoleOptions } from "../../../statics";
import { formatDatetimeYMDHMS2 } from "../../../utils/DataConverter";

type UserGroupMemberGridProps = {
    handleSelectMember: (e: any) => void;
};

export const UserGroupMemberGrid = forwardRef((props: UserGroupMemberGridProps, ref) => {
    const { handleSelectMember } = props;

    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState<any[]>([]);

    useImperativeHandle(ref, () => ({
        getRowData() {
            return rowData;
        },
        setRowData(value: any) {
            setRowData(value);
        },
    }));

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);

    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);
    
    const CellRoleRenderer = (props:any) => {
        const { permission } = props.data;
        return userGroupRoleOptions.find((item:any) => item.value == permission)?.label;
    }
    
    const cellRenderDateTime = (props: any) => {
        return formatDatetimeYMDHMS2(props.value);
    }
    
    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };

    // 列の個別定義
    const columnDefs: any = [
        {
            headerName: "メールアドレス",
            field: "email",
            rowDrag: false,
            minWidth: 180,
            flex: 2,
        },
        {
            headerName: "ユーザー名",
            field: "fullName",
            rowDrag: false,
            minWidth: 180,
            flex: 2,
        },
        {
            headerName: "権限",
            field: "permission",
            cellRenderer: CellRoleRenderer,
            rowDrag: false,
            minWidth: 120,
            flex: 1,
        },
        {
            headerName: "登録日",
            field: "joinedDatetime",
            cellRenderer: cellRenderDateTime,
            rowDrag: false,
            minWidth: 180,
            flex: 2,
        },
        {
            headerName: "更新日",
            field: "lastUpdateDatetime",
            cellRenderer: cellRenderDateTime,
            rowDrag: false,
            minWidth: 180,
            flex: 2,
        }
    ];
    const rowHeight = 48;
    
    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 320px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        domLayout={"normal"}
                        rowData={rowData}
                        rowHeight={rowHeight}
                        rowSelection={'single'}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        localeText={AG_GRID_LOCALE_JP}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                        onRowClicked={handleSelectMember}
                    />
                </div>
            </div>
        </>
    );
});

export default UserGroupMemberGrid;
