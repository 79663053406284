import Konva from "konva";
import { IRect } from "konva/lib/types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Layer, Line, Rect, Stage, Transformer } from "react-konva";
import { connect, useDispatch } from "react-redux";
import AWS from "aws-sdk";
import '../components/contextMenu/styles.css';
import '../components/Home.css';
import DashboardLeftSidebar from "../layouts/DashboardLeftSidebar";
import DashboardMainTopNavbar from "../layouts/DashboardMainTopNavbar";
import DashboardSecondaryTopNavbar from "../layouts/DashboardSecondaryTopNavbar";
import * as Constant from "../models/Constants";
import * as Element from "../models/ElementKind";
import { ElementKind } from "../models/ElementKind";
import * as Model from "../models/Index";
import { FetchState, actionCreators, selectors } from "../store";
import CloseIcon from '@mui/icons-material/Close';
import menu_left_1 from '../images/icon/Icon_Operation/menu_left_1.png'
import menu_right_1 from '../images/icon/Icon_Operation/menu_right_1.png'

import {
    unGroupAction,
    copyAction,
    cutAction,
    pasteAction,
    removeAction,
    saveAllControlsToDiagram,
    saveCurrentIdChartTab,
    saveMaxIdChartTab,
    setPointerPositionAction,
    setTransformData,
    saveElementTemplates,
    openChart,
    saveCurrentIdDiagramTab,
    saveElementMaxId,
    saveTypesMaxId,
    publishChartEvents,
    changeProcessMode,
    updatePropertiesOfTransCenter,
    deleteDiagramByTabId,
    setShapes,
    groupAction,
    tcGroupAction,
    addNewTCGroups,
    tcUnGroupAction,
    addLineToChart,
    setGraphData,
    createChartTab,
    changeModePM,
    deleteGraphScreen,
    undo,
    saveNewNominalVolt,
    saveChartZoom,
    saveListGraphName,
    isShowScreen,
    isDownloadDiagramPDF,
    deleteTcGroup,
    addUndoDataToChart,
	updateSelectControl,
    clearOptionMenu,
	saveListGraph,
    updateQuickProperties,
    rotate,
    openUserCurveEvents,
    addCalcPointToChart,
    isSelectTouchGroup,
    unSelectAllControls,
    setCopyData,
    setDiagramDataUpdate,
    setRepositionElement,
    changeModeViewOnly,
} from "../store/Actions";
import { deleteFileByIdsS3, getElements } from "./CallApi";
import { createElementFromResponseData, createText } from "./CreateElement";
import CreateGraph from "./graph/CreateGraph";
import FetchHelper from "./FetchHelper";
import ControlMenu from "./contextMenu/ControlMenu";
import CreateText from "./control/CreateText";
import ChartTab from "./tabs/ChartTab";
import { 
    VOLT_SIDE_LOW_MAX,
    VOLT_SIDE_PRIMARY,
    VOLT_SIDE_SECONDARY,
    VOLT_SIDE_SPECIAL_HIGH_MIN,
    scaleOptions,
    elementNotInTransCenter,
    GROUP_PERMISSION
} from '../statics/index';


import CreateMsHVCB from "./control/CreateMsHVCB";
import CreateMsMotor from "./control/CreateMsMotor";
import CreateMsSource from "./control/CreateMsSource";

// image1
import CreateMsGenerator from "./control/CreateMsGenerator";
import CreateMsTrans3 from "./control/CreateMsTrans3";
import CreateMs3Winding from "./control/CreateMs3Winding";
import CreateMsTrans1 from "./control/CreateMsTrans1";
import CreateMsTransscott from "./control/CreateMsTransscott";

//image2
import CreateMsDS from "./control/CreateMsDS";
import CreateMsWire from "./control/CreateMsWire";
import CreateMsBusbar from "./control/CreateMsBusbar";
import CreateMsBusduct from "./control/CreateMsBusduct";
import CreateMsReactor from "./control/CreateMsReactor";
import CreateMsImpedance from "./control/CreateMsImpedance";
import CreateMsLVCB from "./control/CreateMsLVCB";
import CreateMsThermalCT from "./control/CreateMsThermalCT";
import CreateMsLBS from "./control/CreateMsLBS";
import CreateMsMVCB from "./control/CreateMsMVCB";
import CreateMsContactor from "./control/CreateMsContactor";
import CreateMsFUSE from "./control/CreateMsFUSE";
import CreateMs2E from "./control/CreateMs2E";
import CreateMsZerocond from "./control/CreateMsZerocond";
import CreateMsConnect from "./control/CreateMsConnect";


// image3
import CreateMsMotorGroup from "./control/CreateMsMotorGroup";
import CreateMsLoad from "./control/CreateMsLoad";
import CreateMsLightBoard from "./control/CreateMsLightBoard";
import CreateMsPowerBoard from "./control/CreateMsPowerBoard";
import CreateMsCapacitor from "./control/CreateMsCapacitor";
import CreateMSTransCenter from "./control/CreateMSTransCenter";


//image4
import CreateMsVCT from "./control/CreateMsVCT";
import CreateMsVT from "./control/CreateMsVT";
import CreateMsArrestor from "./control/CreateMsArrestor";
import CreateMsEarth from "./control/CreateMsEarth";
import CreateMsCableHead from "./control/CreateMsCableHead";
import CreateMsCT from "./control/CreateMsCT";
import CreateMsZCT from "./control/CreateMsZCT";
import CreateMsINV from "./control/CreateMsINV";
import CreateMsRelay from "./control/CreateMsRelay";


import CreateMsThermal from "./control/CreateMsThermal";
import AlertDialog from "./common/AlertDialog";
import { Node, NodeConfig } from "konva/lib/Node";
import { FormControl, InputLabel, MenuItem, Select, Stack, CircularProgress, IconButton ,Grid} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import TransCenterInfo from "./details/TransCenterInfo";
import { doGetValuesTransCenter } from "../utils/TransCenterFunction";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getElementCoordinatesData, getElementKindValue, mapProjectDataFromResponse, mapElementsResponseToLocal, getElementNoKey, mapInfoCircuitFromResponse, mapInfoSkeletonFromResponse,mapElementGroupsResponse, mapReportFromResponse, mapInfoCoverFromResponse, getElementKindLabel } from "../utils/ElementFunction";

import SettingCircuitDialog from "./dialogs/SettingCircuitDialog";
import { SettingSkeletonDialog } from "./dialogs/SettingSkeletonDialog";
import SettingReportDialog from "./dialogs/SettingReportDialog";
import SettingDiagramDialog from "./dialogs/SettingTitleGraphDiagramDialog";
import { groupBy } from "../utils/groupBy";
import ReportSceen from "./report/ReportScreen";
import SettingCover2Dialog from "./dialogs/SettingCover2Dialog";
import ShowElementListDialog from "./dialogs/ShowElementListDialog";
import ShowPointListDialog from "./dialogs/ShowPointListDialog";
import { AppLoadedAction, GET_GRAPH, GET_GRAPH2, MAIN_DIAGRAM_PDF, UPDATE_QUICK_PROPERTIES } from "../store/AppStore";
import { doGetConnectElement } from "../utils/ElementList";
import WarningDialog from "./dialogs/WarningDialog";
import { doGetCircuitVolt, isSPhaseCircuitEle } from "../utils/element";
import Mode47Menu from "./contextMenu/Mode47Menu";
import * as Method from "../../src/store/Method";
import InfomationDialog from "./dialogs/InfomationDialog";
import { onFileDxfOutPut as onFileDxfOutPutDiagram } from "../utils/Mssv3View1";
import { onFileDxfOutPut as onFileDxfOutPutGraph } from "../utils/Mssv3View2";
import AddFileDXFDiaglog from "./dialogs/AddFileDXFDiaglog";
import { convertGraphDataFromBE } from "../utils/DataConverter";
import { downloadFile, openFileInNewTabByBlob } from "../utils/downloadFile";
import { getTopControl } from "../utils/CollisionDetection";
import CreateMsHiddenTopControlTransCenter from "./control/CreateMsHiddenTopControlTransCenter";
import { getTextWidth } from "../utils/TextUtil";
import theme from "../themes/globalStyles";
import DashboardRight from "../layouts/DashboardRight";
import VerticalScrollbar from "./scrollbar/VerticalScrollbar";
import HorizontalScrollbar from "./scrollbar/HorizontalScrollbar";
import SvgDotsGrid from "./grid/svgDotsGrid";
import MaintenanceSreen from "./maintenance/MaintenanceSreen";
import HistoryProjectDialog from "./dialogs/HistoryProjectDialog";

// Fetch ID
const OPEN_PROJECT = "OPEN_PROJECT"
const SAVE_FILE = "SAVE_FILE"
const SAVE_AS_FILE = "SAVE_AS_FILE"
const DISCARD_CHANGES_FILE = "DISCARD_CHANGES_FILE"
const SAVE_INFO_CIRCUIT_DB = "SAVE_INFO_CIRCUIT_DB"
const GET_TEMPLATES = "GET_TEMPLATES"
const CREATE_GROUP = "CREATE_GROUP"
const DELETE_GROUP = "DELETE_GROUP"
const PASTE_ELES_AND_GROUPS = "PASTE_ELES_AND_GROUPS"
const UNDO_ELES_AND_GROUPS = "UNDO_ELES_AND_GROUPS"
const SAVE_INFO_SKELETON_DB = "SAVE_INFO_SKELETON_DB"
const SAVE_INFO_COVER_DB = "SAVE_INFO_COVER_DB"
const SAVE_REPORT_DB = "SAVE_REPORT_DB"
const CREATE_GRAPH = "CREATE_GRAPH"
const UPDATE_GRAPH = "UPDATE_GRAPH"
const UPDATE_TITLE_GRAPH = "UPDATE_TITLE_GRAPH"
const GET_GROUP_LIST = "GET_GROUP_LIST"
const PLACEMENT_GROUP_LIST = "PLACEMENT_GROUP_LIST"
const RENUMBER_POINT_TEXT = "RENUMBER_POINT_TEXT"
const RENUMBER_SKELETON = "RENUMBER_SKELETON"
const UPDATE_SHAPE = "UPDATE_SHAPE"
const UPDATE_TRANS_CENTER = "UPDATE_TRANS_CENTER"
const UNDO_MODE_PM = "UNDO_MODE_PM"
const UNDO_SET_PARAM = "UNDO_SET_PARAM"
const UNDO_MODE = "UNDO_MODE"
const UNDO_PASTE = "UNDO_PASTE"
const UNDO_UPDATE_SCALE_GRAPH = "UNDO_UPDATE_SCALE_GRAPH"

//#region Init
const initPosition = {
    x: 20,
    y: 20,
}

const initialSelectGroupControl = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    visible: false,
}
//#endregion

//#region Props
export type HomeProps = 
    ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps>
//#endregion

export const Home = (props: HomeProps) => {
    //#region Fields
    const {
        currUser,
        userId,
        userGroupPermission,
        groupList,
        projectData,
        infoCircuit,
        infoSkeleton,
        infoCover,
        report,
        elementTemplates,
        elementGroups,
        tcGroups,
        diagramDataList,
        diagramData, 
        currentTabId, 
        processMode,
        currentIDChartTab,
        chartDataList, 
        isOpenChart,
        isAddLineToChart,
        isAddCalcPointToChart,
        pointerPosition, 
        copyShapes,
        copyEleGroups, 
        copyTCEleGroups,
        copyDiagramData,
        transformFlag, 
        transformData ,
        fetchOpenProjectState,
        fetchGetTemplatesState,
        fetchCreateGroupState,
        fetchDeleteGroupState,
        maxIDChartTab,
        m_bModePM,
        modeViewOnly,
        undoData,
        diagramState,
        listGraphName,
        appLoading,
        chartEvents,
        contextMenuFunction,
        openDialogEvents,
		clearMenuState,
		listGraph,
        diagramDataUpdate,
        isSelectTouchGroup,
        isShowMaintenanceScreen,
        elementReposition,
        storeProjectId,
    } = props;

    const {
        unSelectAllControls,
        placementGroupList,
        saveGroupList,
        getGroupList,
        // saveFile,
        // saveAsFile,
        discardChangesFile,
        resetDiagramAndProjectData,
        getOpenProjectData,
        saveOpenProjectData,
        saveInfoCircuit,
        saveInfoCircuitToDB,
        saveInfoSkeletonToDB,
        saveInfoCoverToDB,
        saveReportToDB,
        saveReport,
        saveInfoSkeleton,
        saveInfoCover,
        renumberPointText,
        renumberSkeleton,
        getElementTemplates, 
        saveElementTemplates,
        createElementPost,
        pasteElementAndGroup,
        undoElementAndGroup,
        saveAllControlsToDiagram,
        setShapes,
        updateTransCenterProps,
        setTransformData, 
        setOpenChart,
        setCopyData,
        copyAction, 
        cutAction, 
        pasteAction, 
        removeAction, 
        unGroupAction,
        tcUnGroupAction,
        saveElementGroups,
        saveTCGroups,
        groupAction,
        tcGroupAction,
        addNewTCGroups,
        setPointerPositionAction, 
        updateIdTabChart,
        saveMaxIdChartTab,
        publishChartEvents,
        saveCurrentTabId,
        saveElementMaxId,
        saveTypesMaxId,
        changeProcessMode,
        deleteDiagramByTabId,
        onShowMessage,
        checkZerocondConnect,
        createGraph,
        getGraph,
        getGraph2,
        setDiagramDataUpdate,
        updateTitleGraph,
        setGraphData,
        createChartTab,
        changeModePM,
        deleteGraphScreen,
        setUndoData,
        undoEditPropertiesAction,
        undoCreateElementAction,
        undoModePM,
        undoUpdateProperties,
        undoMode,
        undoPasteAction,
        undoCreateGroup,
        undoDeleteGroup,
        //undoUpdateScaleGraph,
        //setLineToChart,
        saveChartZoom,
        saveNewNominalVolt,
        updateGraphInfo,
        saveListGraphNameToStore,
        isShowScreen,
        getPDFReport,
        setDownloadDiagramPDF,
        deleteTcGroupAction,
        addDataUndoToChart,
		updateSelectControl,
        clearOptionMenu,
		saveListGraphToStore,
        onUpdateQuickProperties,
        createGroup,
        updateRotate,
        deleteGroup,
        setLineToChart,
        openUserCurveEvent,
        setCalcPointToChart,
        setSelectTouchGroup,
        setRepositionElement,
        setModeViewOnly,
        setProjectOpenTime,
        // onGetEditHistoryProject,
    } = props;

    const { projectId } = useParams() // original project
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation();
    const dataShareProject = location.state && location.state.data;
    const ownerProject = location.state && location.state.ownerProject;

    const [showMaintenanceScreen, setShowMaintenanceScreen] = useState(false)
    const [showReportScreen, setShowReportScreen] = React.useState(false);
    const [showGraphScreen, setShowGraphScreen] = React.useState(false);
    const [loadingFlag, setLoadingFlag] = React.useState(false);
    const [elementLoadingFlag, setElementLoadingFlag] = React.useState(false);
    const [templatesData, setTemplatesData] = React.useState<object>({});
    const [stdCapacityList,setStdCapacityList] = React.useState([])
    const [widthGrid, setWidthGrid] = React.useState(Constant.CANVAS_WIDTH);
    const [heightGrid, setHeightGrid] = React.useState(Constant.CANVAS_HEIGHT);
    const [widthGridPDF, setWidthGridPDF] = React.useState(Constant.CANVAS_WIDTH);
    const [heightGridPDF, setHeightGridPDF] = React.useState(Constant.CANVAS_HEIGHT);
    const [scaleGrid, setScaleGrid] = React.useState(1);
    const [scaleGridPDF, setScaleGridPDF] = React.useState(1);
    const [oldScaleGrid, setOldScaleGrid] = React.useState(1);
    const [selectGroupControl, setSelectGroupControl] = useState(initialSelectGroupControl);
    const [prePosition, setPrePosition] = useState<Model.Coordinates>(initPosition);
    const [maxId, setMaxId] = useState(projectData.noFault || 0);
    const [maxTabId, setMaxTabId] = useState(diagramDataList.at(-1).tabId || 1);
    const [placeHolderElement, setPlaceHolderElement] = useState(null)
    const [placeHolderIntoPoint, setPlaceHolderIntoPoint] = useState(null)
    
    const layerRef = useRef<Konva.Layer>(null);
    const layerRefPDF = useRef<Konva.Layer>(null);
    const groupLayerRef = useRef<Konva.Layer>(null);
    const stageRef = useRef<Konva.Stage>(null);
    const stageRefPDF = useRef<Konva.Stage>(null);
    const lineDiagramRef = useRef<any>(null);
    const lineDiagramRefPDF = useRef<any>(null);
    const selectGroupControlRef = useRef<Konva.Rect>(null);
    const transformRef = useRef<Konva.Transformer>(null);
    const groupingRef = useRef<Konva.Transformer>(null);
    // const refHelpPreview = useRef<any>();

    // let shapes = diagramData[0].shape;
    const [shapes, setShape]= React.useState<any[]>([]);
    const currentPosition = pointerPosition;
    var dragProcessedCount = 0; 
    var arrayValue:any = [];
    var displacement = {
        x: 0,
        y: 0
    }
    const [showProtectionDiagram, setShowProtectionDiagram] = useState(false);
    const [drawLineInfo, setDrawLineInfo] = useState({ lineType: Model.LineType.CONTROL_CURVE, lineId: 0, state:false })
    const [chartData, setChartData] = useState<Model.ControlModel[]>([]);
    const [transCenterSelected, setTransCenterSelected] = useState<Model.ControlModel>();
    
    const [listTextGraph, setListTextGraph] = useState<any>([]);
    const [DXFFilename, setDXFFilename] = useState("");
    const [openAddFileDXFDialog, setOpenAddFileDXFDialog] = useState(false);
    const [openInfomationDialog, setOpenInfomationDialog] = useState(false);
    const [openControlMenu, setOpenControlMenu] = useState(false);
    const [openMode47Menu, setOpenMode47Menu] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openOverlapDialog, setOpenOverlapDialog] = useState(false);
    // const [openSaveAsForm, setOpenSaveAsForm] = useState(false);
    const [openCircuitForm, setOpenCircuitForm] = useState(false);
    const [openSkeletonForm, setOpenSkeletonForm] = useState(false);
    const [openCoverForm, setOpenCoverForm] = useState(false);
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [openReportForm, setOpenReportForm] = useState(false);
    const [openElementListForm, setOpenElementListForm] = useState(false);
    const [openPointListForm, setOpenPointListForm] = useState(false);
    const [openSettingTitleGraphDialog, setOpenSettingTitleGraphDialog] = useState(false);
    const [openReNumberSkeletonConfirmDialog, setOpenReNumberSkeletonConfirmDialog] = useState(false);
    const [openReNumberPointTextConfirmDialog, setOpenReNumberPointTextConfirmDialog] = useState(false);
    const [initialValueGraph, setInitialValueGraph] = useState<any>();
    const [isAddNewGraph, setIsAddNewGraph] = useState<boolean>(false);
    const [undoActionFlag, setUndoActionFlag] = useState<boolean>(false);
	const [messageWarning, setMessageWarning] = useState("");
    const [undoCut, setUndoCut] = useState(false);
    const [undoGraphData, setUndoGraphData] = useState<any>()
    const [isReloadMainDiagramPDF, setIsReloadMainDiagramPDF] = useState(false)
    const [isReOpenProtectionDiagram, setIsReOpenProtectionDiagram] = useState(false)
    const [topTranscenterElement, setTopTranscenterElement] = useState<Model.ControlModel>()
    // const [openHelpPreview, setOpenHelpPreview] = useState(false)
    const [stageWidthPDF, setStageWidthPDF] = useState(1000)
    const [stageHeightPDF, setStageHeightPDF] = useState(1000)
    const [isOnOpenMenu, setIsOnOpenMenu] = useState<boolean>(false);
    const [isOnShiftKey, setIsOnShiftKey] = useState<boolean>(false);
    const [isClearGroup, setIsClearGroup] = useState<boolean>(false);
    const [isClickInDiagram, setIsClickInDiagram] = useState<boolean>(false);
    const [isDrawPDF, setIsDrawPDF] = useState(false);
    const groupsData = currentTabId === 1 ? elementGroups : tcGroups[transCenterSelected?.id ?? 1]
    const [isOpenLeftSideBar, setIsOpenLeftSideBar] = useState(true);
    const [isOpenRightSideBar, setIsOpenRightSideBar] = useState(true);
    const [isFirstRun, setIsFirstRun] = useState(true);
    const [dragStartPosition, setDragStartPosition] = useState({x:0,y:0});
    const [selectedControlStartPosition, setSelectedControlStartPosition] = useState<any>();
    const [isRolled, setIsRolled] = useState(false);
    const [openHistoryDialog ,setOpenHistoryDialog] = useState(false)
    //#endregion
    //#region UseEffect
    useEffect(() => {
        if (isFirstRun) {
            setIsFirstRun(false);
            return;
        }
        if (isShowMaintenanceScreen.show && !showMaintenanceScreen) {
            setShowMaintenanceScreen(true)
        }
    }, [isShowMaintenanceScreen])

    useEffect(()=>{
        setShape(diagramData[0].shape)
    },[diagramDataUpdate])

    useEffect(()=>{
        if(showProtectionDiagram)
        {
            if(isOnOpenMenu) {
                setIsOnOpenMenu(false)
            }
            else
            {
                setOpenControlMenu(false);
                setOpenMode47Menu(false);
            }
        }
    },[clearMenuState])

    useEffect(()=>{
        if(elementReposition?.id)
        {
            groupingRef.current?.nodes([])
            groupLayerRef.current?.destroyChildren()
            let controls = diagramData[0].shape.filter((item:any)=>item.isSelected)
            if(controls.length > 0)
                handleCheckGroupList(controls.map((x:any)=>x.id))
        }
      },[elementReposition])

    useEffect (() =>{
        let list_voltage: Model.OptionModel [] = []
        let list_source:Model.OptionModel[] = diagramData[0]["shape"].forEach((item:any,index:any) => { 
            if(item["properties"]["noSource"] > 0 ){
                if(!list_voltage.map(o => o.value).includes(item["properties"]["voltage"])){
                    let optionModel: Model.OptionModel = {
                        label: item["properties"]["voltage"].toString(),
                        value: item["properties"]["voltage"]
                    };
                list_voltage.push(optionModel)
                }
            }
        })
        infoCircuit.voltageList = list_voltage
    },[diagramData])

    useEffect(() => {
        setOpenControlMenu(false)
        setOpenMode47Menu(false)
        setIsReloadMainDiagramPDF(true)
    }, [processMode])

    useEffect(() => {
       setIsReloadMainDiagramPDF(true)
    }, [openDialogEvents])

    useEffect(() => {
        if(contextMenuFunction && contextMenuFunction == 6)
        {
            deleteGraphScreen(false,false)
            isShowScreen(Constant.CONTROL_GRAPH_SCREEN)
            setShowReportScreen(false)
            setShowGraphScreen(false)
            setIsReOpenProtectionDiagram(true)
            setShowProtectionDiagram(true)
        }
    },[contextMenuFunction])

    useEffect(() => {
        // get templates data
        if (Object.keys(elementTemplates).length === 0){
            setLoadingFlag(true);
            let params = {
                userId: userId
            }
            getElementTemplates(params) 
        } else {
            setTemplatesData(elementTemplates.templates)
            setStdCapacityList(elementTemplates.std_capacity_list)
            !fetchOpenProjectState.loading && setLoadingFlag(false);
        }

        // clear diagram data when component unmount
        return () => {
            resetDiagramAndProjectData();
        }
    }, [])

    useEffect(() => {
        switch(chartEvents.event) {
            case "SELECT_CONTROLS":
                if(!isSelectTouchGroup && isClearGroup)
                {
                    groupLayerRef.current?.destroyChildren()
                }
                if(!chartEvents.payload.id || chartEvents.payload.id.length == 0)
                {
                    setIsReloadMainDiagramPDF(true)
                    groupingRef.current?.nodes([])
                    groupLayerRef.current?.destroyChildren()
                }
                else if(chartEvents.payload.id.length == 1){
                    handleCheckGroup(chartEvents.payload.id[0],true,false);
                }
                else if(chartEvents.payload.id.length > 1)
                {
					handleCheckGroupList(chartEvents.payload.id);                
                }
                setIsClickInDiagram(false)
        }
    }, [chartEvents])

    useEffect(() => {
        setLoadingFlag(true);
        lineDiagramRef?.current?.focus();
        setIsReloadMainDiagramPDF(true)
        getOpenProjectData({
            requestPrms: {
                userId: userId,
                projectId,
                ownerProject: ownerProject,
                reportIndex : 1,//hard code 
            }
        })
    }, [projectId])
    
    useEffect(() => {
        // group list
        setLoadingFlag(true);
        let params = {
            userId: userId,
        }
        getGroupList(params) 
    }, [projectId])

    useEffect(() => {
        setMaxId(projectData.noFault)
    }, [projectData.noFault])

    useEffect(() => {
        setPointerPositionAction(initPosition);
        
        // TransCenter
        if (currentTabId != 1){
            let mainDiagramData = diagramDataList.find((e: any) => e.tabId === 1);
            let transCenter =
                mainDiagramData.shape.find(
                    (e: any) => e.properties?.tabId === currentTabId
                );
            let topControl = getTopControl(transCenter, mainDiagramData.shape)
			if(topControl.data == undefined)
            {
                setTopTranscenterElement(undefined)
            }
            else if(topControl.data.type == Element.MS_3WINDING || (topControl.data.type == Element.MS_ARRESTOR && topControl.data.properties.haveEarth)
                || topControl.data.type == Element.MS_CAPACITOR || topControl.data.type == Element.MS_EARTH 
                || topControl.data.type == Element.MS_LIGHTBOARD || topControl.data.type == Element.MS_LOAD 
                || topControl.data.type == Element.MS_MOTOR || topControl.data.type == Element.MS_MOTOR_GROUP
                || topControl.data.type == Element.MS_POWERBOARD || topControl.data.type == Element.MS_TRANSCENTER
                || topControl.data.type == Element.MS_TEXT)
            {
                setTopTranscenterElement(undefined)
            }
            else
            {
                setTopTranscenterElement(topControl.data)
            }
            if(diagramDataList.find((e: any) => e.tabId === currentTabId).shape.find((item:any) => item.isSelected == true) == undefined) setIsReloadMainDiagramPDF(true)
            
            setTransCenterSelected(transCenter);

            let xFrameCount = parseInt(transCenter.properties.xFrameCount)
            if (xFrameCount % 2 == 0) xFrameCount += 1
            let width = xFrameCount * Constant.POINTER_WIDTH;
            let height = transCenter.properties.yFrameCount * Constant.POINTER_HEIGHT;
            setWidthGrid(width);
            setHeightGrid(height);
            setWidthGridPDF(width);
            setHeightGridPDF(height);

            setOldScaleGrid(scaleGrid);
            stageRef.current?.scale({x: 1, y: 1});
            setScaleGrid(1);
            setScaleGridPDF(1);

        } else {
            if(Object.keys(infoSkeleton).length > 0){
                let width = infoSkeleton.squareCountHlizontal * Constant.POINTER_WIDTH;
                let height = infoSkeleton.squareCountVertical * Constant.POINTER_HEIGHT;
                if (oldScaleGrid > 1){
                    width *= oldScaleGrid;
                    height *= oldScaleGrid;
                }
                setWidthGrid(width);
                setHeightGrid(height);
                setWidthGridPDF(width);
                setHeightGridPDF(height);
            }

            stageRef.current?.scale({x: oldScaleGrid, y: oldScaleGrid});
            setScaleGrid(oldScaleGrid);
            setScaleGridPDF(oldScaleGrid);
            setOldScaleGrid(1);
        };
    }, [currentTabId]);

    useEffect(() => {
        if(transformFlag){
            transformRef.current?.nodes(transformData)
        }else{
            transformRef.current?.nodes([])
        }
    }, [transformFlag]);

    useEffect(() => {
        if (currentIDChartTab === 0) { //close chart view when close all tabs
            setShowProtectionDiagram(false);
            isShowScreen(Constant.CONTROL_SCREEN)
            setOpenChart(false);
        }   
    }, [currentIDChartTab]);

    useEffect(() => {
        if (isOpenChart.payload?.mode) {
            if (isOpenChart.payload.lineType === Model.LineType.CONTROL_CURVE) {
                let tabidMax = Number(maxIDChartTab) + 1;
                const params ={
                    userId: userId,
                    projectId: projectData.projectId,
                    graphNo: tabidMax,
                    ownerProject: ownerProject
                }
                setIsAddNewGraph(true)
                createGraph(params)
            } 
            else if (isOpenChart.payload.lineType === Model.LineType.USER_CURVE){
                if(Number(currentIDChartTab) === 0)
                {
                    let tabidMax = Number(maxIDChartTab) + 1;
                    const params ={
                        userId: userId,
                        projectId: projectData.projectId,
                        graphNo: tabidMax,
                        ownerProject: ownerProject
                    }
                    setIsAddNewGraph(true)
                    createGraph(params)
                }
                else
                {
                    setIsAddNewGraph(false)
                    const toDrawShapes = shapes.filter((item: Model.ControlModel) => item.isSelected === true);    
                    setChartData([...toDrawShapes]);
                    setDrawLineInfo({
                        lineType: isOpenChart.payload.lineType,
                        lineId: isOpenChart.payload.lineId,
                        state: true
                    })
                    setIsReOpenProtectionDiagram(false)
                    !showGraphScreen && !showProtectionDiagram && setShowProtectionDiagram(true)
                    !showGraphScreen && isShowScreen(Constant.CONTROL_GRAPH_SCREEN)
                }
            }
        }
    }, [isOpenChart])

    useEffect(() => {
        if (isAddLineToChart.payload?.mode) {
            let toDrawShapes;
            setIsAddNewGraph(false)
            // if(isAddLineToChart.payload.isUndo){
            //     toDrawShapes = shapes.filter((item: Model.ControlModel) => item.id == isAddLineToChart.payload.controlId);            
            // }else{
                const chartDataTemp = chartDataList.find((x:any)=> x.tabId === currentIDChartTab)!
                toDrawShapes = shapes.filter((item: Model.ControlModel) => item.isSelected === true && (chartDataTemp.shape.find((e: any)=> e.id === item.id ) == undefined || (chartDataTemp.shape.find((e: any)=> e.id === item.id ).chart && chartDataTemp.shape.find((e: any)=> e.id === item.id ).chart.find((e:any)=>e.lineType == Model.LineType.CONTROL_CURVE && !e.lineId.includes("CALC_POINT")) == undefined)));            
            // }
            setChartData([...toDrawShapes]);
            setDrawLineInfo({
                lineType: Model.LineType.CONTROL_CURVE,
                lineId: 0,
                state: true
            })
            setIsReOpenProtectionDiagram(false)
            setShowProtectionDiagram(true);
            isShowScreen(Constant.CONTROL_GRAPH_SCREEN)
        }
    }, [isAddLineToChart])

    useEffect(() => {
        if (isAddCalcPointToChart.payload?.mode) {
            setIsAddNewGraph(false)
            const toDrawShapes = shapes.filter((item: Model.ControlModel) => item.isSelected === true);            
            setChartData([...toDrawShapes]);
            setDrawLineInfo({
                lineType: Model.LineType.CONTROL_CURVE,
                lineId: 0,
                state:true
            })
            setIsReOpenProtectionDiagram(false)
            setShowProtectionDiagram(true);
            isShowScreen(Constant.CONTROL_GRAPH_SCREEN)
        }
    }, [isAddCalcPointToChart])

    useEffect(() => {
        if (fetchCreateGroupState.loading) {
            setLoadingFlag(true);
        } else if (!fetchCreateGroupState.loading && fetchCreateGroupState.requested) {
            setLoadingFlag(false);
        }
    }, [fetchCreateGroupState])

    useEffect(() => {
        if (fetchDeleteGroupState.loading) {
            setLoadingFlag(true);
        } else if (!fetchDeleteGroupState.loading && fetchDeleteGroupState.requested) {
            setLoadingFlag(false);
        }
    }, [fetchDeleteGroupState])
    
    useEffect(() => {
        if(m_bModePM || modeViewOnly){
            setOpenControlMenu(false);
        }
        
        if((m_bModePM || modeViewOnly) && processMode !== Model.ProcessMode.VOLTDROP){
            setOpenMode47Menu(false);
        }
        
    },[m_bModePM, modeViewOnly])
    //#endregion

    //#region Method handle mouse event
    const floorFunc = (num: number, scale: number) => {
        let tmp = Constant.POINTER_WIDTH * scale;
        return Math.floor(num / tmp) * tmp;
    }

    let isMovingDiagram = false
    let isLongPressDiagram = true

    const handleOnTouchEnd = useCallback((e:any) =>{
        isLongPressDiagram = false
        if(transformRef.current?._nodes && e.target?.parent?.attrs?.id && e.target?.parent?.attrs?.type && (e.evt.shiftKey || isSelectTouchGroup) && shapes.find((x:any)=>x.id == e.target.parent.attrs.id).isSelected)
        {
            let result = handleCheckGroup(e, false, true)
            let data = [...transformRef.current._nodes.filter((item:any)=>item.attrs.id != e.target.parent.attrs.id && !result.includes(item.attrs.id))]
            transformRef.current?.nodes(data as any)
        }
        else if(transformRef.current?._nodes && e.target?.parent?.attrs?.id && e.target?.parent?.attrs?.type && (e.evt.shiftKey || isSelectTouchGroup) && !transformRef.current?._nodes.includes(e.target.parent))
        {
            let result = handleCheckGroup(e, false, true)
            if(result.includes(e.target?.parent?.attrs?.id))
            {
                let item = stageRef.current?.find((node: any) => Constant.TYPE_LIST.includes(node.attrs.type)).filter((it: any)=> result.includes(it.attrs.id))
                transformRef.current?.nodes([...transformRef.current?._nodes,...item as any] as any)
            }
            else
            {
                transformRef.current?.nodes([...transformRef.current?._nodes,e.target.parent] as any)
            }
        }
        else if(transformRef.current?._nodes && e.target?.parent?.attrs?.id && e.target?.parent?.attrs?.type &&  (transformRef.current?._nodes.length == 0 || (transformRef.current?._nodes.length == 1 && transformRef.current?._nodes[0].attrs.id != e.target?.parent?.attrs?.id)))
        {
            transformRef.current?.nodes([e.target.parent] as any)
        }
    },[shapes, isSelectTouchGroup ])

    const handleOnTouchMove = useCallback((e:any) =>{
        isMovingDiagram = true
    },[shapes, isSelectTouchGroup])

    const handleOnTouchStart = useCallback((e:any) =>{
        setIsClickInDiagram(true)
        e.target?.parent?.attrs?.id && setSelectedControlStartPosition(e.target.parent.attrs.id)
        if((isRolled || isSelectTouchGroup) && shapes.find(x=>x.isSelected))
        {
            const selectedITem = diagramData[0].shape.filter((item: any)=> item.isSelected === true).map((i: any)=> i.id)
            const item = stageRef.current?.find((node: any) => Constant.TYPE_LIST.includes(node.attrs.type)).filter((it: any)=> selectedITem.includes(it.attrs.id))
            transformRef.current?.nodes(item as any)
            setIsRolled(false)
        }
        handleCheckGroup(e, false, false, true)
        clearOptionMenu(!clearMenuState)
        setOpenControlMenu(false);
        setOpenMode47Menu(false);
        if(!e.target?.parent?.attrs?.id && !isSelectTouchGroup)
        {
            isMovingDiagram = false
            isLongPressDiagram = true
            setTimeout(() => {
                if(isLongPressDiagram && !isMovingDiagram)
                {
                    handleContextMenu(e)
                }
            }, 1000)
        }
    },[shapes, isSelectTouchGroup])

    const handleOnTap = useCallback((e: any) => {
        lineDiagramRef.current.focus();
        let result = handleCheckGroup(e, false, true)
        if (!e.evt.shiftKey && !isSelectTouchGroup) {
            setIsReloadMainDiagramPDF(false)
            if(result != undefined && result != null && result.length > 0)
            {
                publishChartEvents({ 
                    event: 'SELECT_CONTROLS', 
                    payload: {
                        id: result,
                        state:"all"
                    }
                })
            }
            else
            {
                if(e.target?.parent?.attrs?.id ? e.target.parent.attrs.id : e.target.attrs.id)
                    publishChartEvents({ 
                        event: 'SELECT_CONTROLS', 
                        payload: {
                            id: e.target?.parent?.attrs?.id
                                ? [e.target.parent.attrs.id]
                                : [e.target.attrs.id],
                            state:"all"
                        }
                    })
            }
        }
      
        //setOpenControlMenu(false);
        //setOpenMode47Menu(false);
        if (e.target !== stageRef.current) {
            return;
        }
        let x1 = stageRef?.current?.getPointerPosition()?.x || 0;
        let y1 = stageRef?.current?.getPointerPosition()?.y || 0;
        let offsetX = stageRef?.current?.x() || 0;
        let offsetY = stageRef?.current?.y() || 0;
        x1 -= offsetX;
        y1 -= offsetY;

        setPointerPositionAction({
            x: floorFunc(x1, scaleGrid) / scaleGrid,
            y: floorFunc(y1, scaleGrid) / scaleGrid,
        })
        if (!e.evt.shiftKey && !isSelectTouchGroup) {
            // set all elements select status to false
            handleSaveDiagram(
                currentTabId,
                shapes.map((item: any) => {
                    return {
                        ...item,
                        isSelected: false
                    };
                }),
                false
            )
            setIsReloadMainDiagramPDF(true)
            publishChartEvents({ event: 'SELECT_CONTROLS', payload: {id:[],state:"all"}})
            
            // undraw group frame
            groupingRef.current?.nodes([])
            groupLayerRef.current?.destroyChildren()
        }
        
        if (e.evt.cancelable) e.evt.preventDefault();

        setPrePosition({
            x: x1 / scaleGrid,
            y: y1 / scaleGrid,
        })

    },[shapes, isSelectTouchGroup])

    const handleMouseDown = (e: any) => {
        e.target?.parent?.attrs?.id && setSelectedControlStartPosition(e.target.parent.attrs.id)
        if(isRolled && shapes.find(x=>x.isSelected))
        {
            const selectedITem = diagramData[0].shape.filter((item: any)=> item.isSelected === true).map((i: any)=> i.id)
            const item = stageRef.current?.find((node: any) => Constant.TYPE_LIST.includes(node.attrs.type)).filter((it: any)=> selectedITem.includes(it.attrs.id))
            transformRef.current?.nodes(item as any)
            setIsRolled(false)
        }
        
        setIsClickInDiagram(true)
        handleCheckGroup(e, false, false, true)
        setOpenControlMenu(false);
        setOpenMode47Menu(false);
        // =========== Mouse down on board ==============
        if (e.target !== stageRef.current) {
            return;
        }

        let x1 = stageRef?.current?.getPointerPosition()?.x || 0;
        let y1 = stageRef?.current?.getPointerPosition()?.y || 0;
        let offsetX = stageRef?.current?.x() || 0;
        let offsetY = stageRef?.current?.y() || 0;
        x1 -= offsetX;
        y1 -= offsetY;

        setPointerPositionAction({
            x: floorFunc(x1, scaleGrid) / scaleGrid,
            y: floorFunc(y1, scaleGrid) / scaleGrid,
        })
        if (!e.evt.shiftKey && !isSelectTouchGroup) {
            // set all elements select status to false
            //handleSaveDiagram(
            //     currentTabId,
            //     shapes.map((item: any) => {
            //         return {
            //             ...item,
            //             isSelected: false
            //         };
            //     }),
            //     false
            // )
            unSelectAllControls()
            setIsReloadMainDiagramPDF(true)
            publishChartEvents({ event: 'SELECT_CONTROLS', payload: {id:[],state:"all"}})
            
            // undraw group frame
            groupingRef.current?.nodes([])
            groupLayerRef.current?.destroyChildren()
        }
        e.evt.preventDefault();

        setPrePosition({
            x: x1 / scaleGrid,
            y: y1 / scaleGrid,
        })
        setSelectGroupControl({
            ...initialSelectGroupControl,
            visible: true,
        })
    }

    const handleMouseMove = (e: any) => {
        if(e.evt.shiftKey)
        {
            setIsOnShiftKey(true)
            return;
        }
        setIsOnShiftKey(false)
        if (!selectGroupControl.visible) {
            return;
        }
       
        e.evt.preventDefault();
        let x2 = stageRef?.current?.getPointerPosition()?.x || 0;
        let y2 = stageRef?.current?.getPointerPosition()?.y || 0;
        let offsetX = stageRef?.current?.x() || 0;
        let offsetY = stageRef?.current?.y() || 0;
        x2 -= offsetX;
        y2 -= offsetY;

        x2 /= scaleGrid;
        y2 /= scaleGrid;

        calcSizeSelectArea(x2, y2);
    }

    const calcSizeSelectArea = (x2: number, y2: number) => {
        const x = Math.min(prePosition.x, x2);
        const y = Math.min(prePosition.y, y2);
        const width = Math.abs(x2 - prePosition.x);
        const height = Math.abs(y2 - prePosition.y);
        setSelectGroupControl(
            {
                ...selectGroupControl,
                x: x,
                y: y,
                width: width,
                height: height,
            }
        )
    }

    // get all element id of the selected group
    const getCheckedGroupId = (select: Model.ControlModel[]) => {
        let checkedgroupsId = [] as any
        const checkedgroupsIdSet = new Set<number>([]);
        select.forEach((item: any)=> {
            const peekGroupId:any = groupsData?.byEleId[item.id as any]?.at(-1) ?? -1
            if (peekGroupId !== -1) {
                if(!checkedgroupsIdSet.has(peekGroupId)){
                    checkedgroupsIdSet.add(peekGroupId);
                    const elementIds = groupsData ? groupsData.byId[peekGroupId]?.elementIds ?? [] : -1;
                    if (elementIds !== -1) {
                        checkedgroupsId.push(...elementIds);
                    }
                }
            }
        })
        return checkedgroupsId
    }

    const handleMouseUp = (e: any) => {
        if(transformRef.current?._nodes && e.target?.parent?.attrs?.id && e.target?.parent?.attrs?.type && e.evt.shiftKey && shapes.find((x:any)=>Number(x.id) == Number(e.target.parent.attrs.id)).isSelected)
        {
            let result = handleCheckGroup(e, false, true)
            let data = [...transformRef.current._nodes.filter((item:any)=>item.attrs.id != e.target.parent.attrs.id && !result.includes(item.attrs.id))]
            transformRef.current?.nodes(data as any)
        }
        else if(transformRef.current?._nodes && e.target?.parent?.attrs?.id && e.target?.parent?.attrs?.type && e.evt.shiftKey && !transformRef.current?._nodes.includes(e.target.parent))
        {
            let result = handleCheckGroup(e, false, true)
            if(result.includes(e.target?.parent?.attrs?.id))
            {
                let item = stageRef.current?.find((node: any) => Constant.TYPE_LIST.includes(node.attrs.type)).filter((it: any)=> result.includes(it.attrs.id))
                transformRef.current?.nodes([...transformRef.current?._nodes,...item as any] as any)
            }
            else
            {
                transformRef.current?.nodes([...transformRef.current?._nodes,e.target.parent] as any)
            }
        }
        else if(transformRef.current?._nodes && e.target?.parent?.attrs?.id && e.target?.parent?.attrs?.type &&  (transformRef.current?._nodes.length == 0 || (transformRef.current?._nodes.length == 1 && transformRef.current?._nodes[0].attrs.id != e.target?.parent?.attrs?.id)))
        {
            transformRef.current?.nodes([e.target.parent] as any)
        }
        

        setOpenControlMenu(false);
        setOpenMode47Menu(false)
        if (!selectGroupControl.visible) {
            return;
        }

        e.evt.preventDefault();

        setSelectGroupControl({
            ...selectGroupControl,
            visible: false,
        })

        // let selectedRect = selectGroupControlRef.current?.getClientRect() as IRect;
        let selectedRect = selectGroupControl;
        
        const item = stageRef.current?.find((node: any) => Constant.TYPE_LIST.includes(node.attrs.type));
        let selected = [] as any;

        if (selectedRect.height > 1 && selectedRect.width > 1) {
            selected = diagramData[0].shape?.filter((sh: any) => {
                const isRotate = sh.rotation? !(sh.rotation === 0 || Math.abs(sh.rotate) === 180) : false
                const shWidth = sh.width? sh.width: getTextWidth(sh.properties.text, sh.properties.faceName, sh.properties.fontSize)
                return haveIntersection(selectedRect, {x: sh.x, y: sh.y, width: (isRotate? sh.height: shWidth), height: (isRotate? shWidth: sh.height)} )
            });
            // check grouping here
            let checkedGroupIds = [] as string[]
            if (selected.length > 0) {
                checkedGroupIds = getCheckedGroupId(selected)
            }
    
            // TODO: refactor
            const selectedControlIds = [] as any
            const checkSelectedExist = new Set();

            const itemSelectedNotInGroup = selected.filter((item:any) => !checkedGroupIds.includes(item.id));

            shapes?.filter((r: any) => checkedGroupIds.includes(r.id))
                .forEach((shapeItem: any) => {
                    if(!checkSelectedExist.has(shapeItem.id)){
                        checkSelectedExist.add(shapeItem.id);
                        selectedControlIds.push(shapeItem.id);
                        shapeItem.isSelected = true;
                    }
                });

            itemSelectedNotInGroup.forEach((shapeItem:any) => {
                if(!checkSelectedExist.has(shapeItem.id)){
                    checkSelectedExist.add(shapeItem.id);
                    selectedControlIds.push(shapeItem.id);
                    shapeItem.isSelected = true;
                }
            })


            const transFormControl = item?.filter((it:any)=> selectedControlIds.includes(it.attrs.id))
            transformRef.current?.nodes(transFormControl as any)
            setTransformData(transFormControl as any)

            if(selectedControlIds && selectedControlIds.length > 0)
            {
                setIsReloadMainDiagramPDF(false)
            } else {
                setIsReloadMainDiagramPDF(true)
            }
            if(selectedControlIds && selectedControlIds.length > 1) {
                drawRectByGroup()
            }

            publishChartEvents({ event: 'SELECT_CONTROLS', payload: {id:selectedControlIds,state:"all"}})
            handleSaveDiagram(currentTabId, shapes, false)        
        }
    }

    const handleMouseClick = (e : any) => {
        lineDiagramRef.current.focus();
        if (e.target === stageRef.current) {
            setIsReloadMainDiagramPDF(true)
            return
        };
        
        if (!e.target.parent.attrs.id && !(e.target.attrs.type === Element.MS_TEXT)) { 
            setIsReloadMainDiagramPDF(true)
            return
        }

        let result = handleCheckGroup(e, false, true)

        if (!e.evt.shiftKey || !isSelectTouchGroup) {
            setIsClearGroup(false)
            setIsReloadMainDiagramPDF(false)
            // transformRef.current?.nodes([e.target.parent])
            if(result != undefined && result != null && result.length > 0)
            {
                publishChartEvents({ 
                    event: 'SELECT_CONTROLS', 
                    payload: {
                        id: result,
                        state:"all"
                    }
                })
            }
            else
            {
                publishChartEvents({ 
                    event: 'SELECT_CONTROLS', 
                    payload: {
                        id: e.target.parent.attrs.id
                            ? [e.target.parent.attrs.id] // type != MS_TEXT
                            : [e.target.attrs.id],
                        state:"all"
                    }
                })
            }
        } else {
            setIsClearGroup(true)
            let selectedControlIds = shapes.filter((shape : any) => shape.isSelected === true).map((shape : any) => shape.id)
            if(selectedControlIds && selectedControlIds.length > 0)
            {
                setIsReloadMainDiagramPDF(false)
            }
            else
            {
                setIsReloadMainDiagramPDF(true)
            }
            if(result != undefined && result != null && result.length > 0)
            {
                selectedControlIds = Array.from(new Set([...selectedControlIds,...result]))
                chartEvents.payload && chartEvents.payload.id && chartEvents.payload.id.length > 0 && (selectedControlIds = selectedControlIds.filter((x:any)=> !chartEvents.payload.id.includes(x) || !result.includes(x)))
                //let controlId = e.target.parent.attrs.id? e.target.parent.attrs.id: e.target.attrs.id
                //if(selectedControlIds.includes(controlId))
                    //updateSelectControl(controlId, true)
            }
            const shapesOnBoard = stageRef.current?.find((node: any) => Constant.TYPE_LIST.includes(node.attrs.type));
            publishChartEvents({ event: 'SELECT_CONTROLS', payload: {id:selectedControlIds,state:"all"}})
            const selectedShapes = shapesOnBoard?.filter((shape : Node<NodeConfig>) => selectedControlIds.includes(shape.attrs.id))     
            transformRef.current?.nodes(selectedShapes as any)
            setTransformData(selectedShapes as any)
        }
    }

    const handleDragStart = (e: any) => {
        if(e.evt.shiftKey) return
        setOpenControlMenu(false);
        setOpenMode47Menu(false)
        dragProcessedCount = 0;
        arrayValue = [];

        if (e.target.attrs.id === undefined) {
            setIsReloadMainDiagramPDF(true)
            return
        }
        setIsReloadMainDiagramPDF(false)
        // let transItem = transformRef.current?.nodes().filter(r => r.attrs.id === e.target.attrs.id) as any
        // if (transItem?.length > 0) {
        //     return;
        // }
        const dragShape = diagramData[0].shape.find((shape : any) => shape.id === e.target.attrs.id);
        if (!dragShape) return
        e.evt.x && e.evt.y ? setDragStartPosition({x:e.evt.x, y:e.evt.y}) : setDragStartPosition({x:e.evt.changedTouches.clientX, y:e.evt.changedTouches.clientY})
        if (!dragShape.isSelected) {
            e.target?.parent?.attrs?.id && transformRef.current?.nodes([e.target.parent])
            publishChartEvents({ event: 'SELECT_CONTROLS', payload: {id:[e.target.attrs.id],state:"all"}})
            handleSaveDiagram(
                currentTabId,
                shapes.map((item: any) => {
                    if (item.id === e.target.attrs.id) {
                        return {
                            ...item,
                            isSelected: true
                        };
                    }
                    return { ...item, isSelected: false }
                }),
                false
            )
        }      
    }

    const handleDragEnd = (e: any) => {
        detectOverlapping(e)
        setOpenControlMenu(false);
        setOpenMode47Menu(false)
        if(Number(e.target.attrs.x) <0 ||Number(e.target.attrs.y) < 0) {
            setIsReloadMainDiagramPDF(true)
            return
        }
        let controls = diagramData[0].shape.filter((item:any)=> item.isSelected === true);
        const offsetX = e.evt?.x ? (e.evt.x - dragStartPosition.x) : (e.evt?.changedTouches?.clientX ? (e.evt.changedTouches.clientX - dragStartPosition.x) : 0)
        const offsetY = e.evt?.y ? (e.evt.y - dragStartPosition.y) : (e.evt?.changedTouches?.clientY ? (e.evt.changedTouches.clientY - dragStartPosition.y) : 0)
        let check = controls.find((item:any)=>item.x + offsetX < 0 || item.y + offsetY < 0)
        if(e.target.attrs.id && check && e.target.attrs.id == selectedControlStartPosition)
        {
            setRepositionElement(check.id,!(elementReposition?.state??true))
        }
        if (e.target.attrs.id && !check) {
            setIsReloadMainDiagramPDF(false)
            if(controls.length <= 1){
                const dragShape = shapes.find((shape : any) => shape.id === e.target.attrs.id)
                if (!dragShape) return
                if (e.target.attrs.id) {
                    let data = shapes.filter((x: any) => x.id === e.target.attrs.id)
                    if (data[0].isSelected) {
                        data[0].x = data[0].type === Element.MS_TEXT ? e.target.attrs.x : Math.round(e.target.attrs.x / Constant.POINTER_WIDTH) * Constant.POINTER_WIDTH;
                        data[0].y = data[0].type === Element.MS_TEXT ? e.target.attrs.y : Math.round(e.target.attrs.y / Constant.POINTER_HEIGHT) * Constant.POINTER_HEIGHT;
                    }
                    if (!dragShape.isSelected) {
                        const item = shapes.find((item:any) => item.id === e.target.attrs.id);
                        publishChartEvents({ event: 'SELECT_CONTROLS', payload: {id:[e.target.attrs.id],state:"all"}})
                        handleSetShapes(
                            currentTabId,
                            shapes.map((item: any) => {
                                return { ...item}
                            }),
                            [
                                {
                                    ...item,
                                    x: data[0].type === Element.MS_TEXT ? e.target.attrs.x : Math.round(e.target.attrs.x / Constant.POINTER_WIDTH) * Constant.POINTER_WIDTH,
                                    y: data[0].type === Element.MS_TEXT ? e.target.attrs.y : Math.round(e.target.attrs.y / Constant.POINTER_HEIGHT) * Constant.POINTER_HEIGHT,
                                    isSelected: true
                                },
                            ],
                            false
                        )
                        checkZerocondConnect()
                        return;
                    }
                    const item = shapes.find((item:any) => item.id === e.target.attrs.id);
                    handleSetShapes(
                        currentTabId,
                        shapes.map((item: any) => {
                            return { ...item}
                        }),
                        [
                            {
                                ...item,
                                x: data[0].type === Element.MS_TEXT ? e.target.attrs.x : Math.round(e.target.attrs.x / Constant.POINTER_WIDTH) * Constant.POINTER_WIDTH,
                                y: data[0].type === Element.MS_TEXT ? e.target.attrs.y : Math.round(e.target.attrs.y / Constant.POINTER_HEIGHT) * Constant.POINTER_HEIGHT,
                                isSelected: true
                            }
                        ],
                        false
                    )
                    checkZerocondConnect()
                }
            }else{
                let data = shapes.filter((x: any) => x.id === e.target.attrs.id);
                if(dragProcessedCount == 0) {
                    displacement = {
                        x: data[0].type === Element.MS_TEXT ? e.target.attrs.x - data[0]?.x: Math.round(e.target.attrs.x / Constant.POINTER_WIDTH) * Constant.POINTER_WIDTH - data[0]?.x,
                        y: data[0].type === Element.MS_TEXT ? e.target.attrs.y - data[0]?.y: Math.round(e.target.attrs.y / Constant.POINTER_HEIGHT) * Constant.POINTER_HEIGHT - data[0]?.y
                    }
                }
                dragProcessedCount += 1;
            }

            if (controls.length >1)
            {
                let cont = true
                if(dragProcessedCount > 1) return
                for(let i = 0; i< controls.length; i++) {
                    if(controls[i].x+ displacement.x < 0 || controls[i].y+ displacement.y < 0 ) {
                        cont = false
                    }
                }
                controls.forEach((element: any) => {
                    arrayValue.push({
                        x: element.x + displacement.x,
                        y: element.y + displacement.y,
                        id: element.id
                    })
                });
                
                for(const data of arrayValue){
                    const item = shapes.find((item:any) => item.id === data.id);
                    item.x = data.x;
                    item.y = data.y;
                }

                if(cont) {
                    handleSetShapes(
                        currentTabId,
                        shapes.map((item: any) => {
                            return { ...item}
                        }),
                        controls,
                        false
                    )
                    checkZerocondConnect()
                    drawRectByGroup()
                    return  
                }
            }
        }
    }

    // this function run when drag end control(s)
    const detectOverlapping = (e: any) => {
        let isOverlap = false;
        layerRef.current?.children?.every((group: any) => {   
            if (group.attrs.id === undefined) return true;
            if (transformData.includes(group)) return true;
            if (group.attrs.type === Element.MS_TEXT) return true;

            if (transformRef.current && transformData.length > 0) {  
                transformData.every((selected : any) => {
                    if (selected.attrs.type === Element.MS_TEXT) return true;
                    if (haveIntersection(selected.getClientRect(), group.getClientRect())) {
                        isOverlap = true;
                        return false;
                    }
                    return true;
                });              
                return true;
            }   
            
            if (e.target.attrs.id === undefined) return true;
            if (group === e.target) return true;
            if (haveIntersection(e.target.getClientRect(), group.getClientRect())) {
                isOverlap = true;
                return false;
            }
            return true;
        });

        isOverlap && setOpenOverlapDialog(true)
    }

    const haveIntersection = (r1: IRect, r2: IRect) => {       
        return !(
            r2.x > r1.x + r1.width - 11 ||
            r2.x + r2.width - 11 < r1.x ||
            r2.y > r1.y + r1.height - 11 ||
            r2.y + r2.height - 11 < r1.y
        );
      }

    const handleContextMenu = (e: any) => {
        if(e.evt.shiftKey || isSelectTouchGroup) return
        setIsOnOpenMenu(true)
        const selectedControlIds = diagramData[0].shape.filter((shape : any) => shape.isSelected === true).map((shape : any) => shape.id);
        const shapesOnBoard = stageRef.current?.find((node: any) => Constant.TYPE_LIST.includes(node.attrs.type));
        const selectedShapes = shapesOnBoard?.filter((shape : Node<NodeConfig>) => selectedControlIds.includes(shape.attrs.id));
        transformRef.current?.nodes(selectedShapes as any);
        clearOptionMenu(!clearMenuState)
        e.evt.preventDefault();
        if (diagramData[0].shape.find((r: any) => r.isSelected === true)) {
            setOpenControlMenu(false);
            setOpenMode47Menu(false);
        } else {
            handleCheckCopyData();
            setOpenControlMenu((processMode === Model.ProcessMode.DRAWING && !(m_bModePM || modeViewOnly)));
            setOpenMode47Menu((processMode === Model.ProcessMode.VOLTDROP  || (processMode === Model.ProcessMode.POWER_FLOW && !(m_bModePM || modeViewOnly))))
        }
    };

    const handleOKSettingProtectionDialog = (data:any) =>
    {
        setLoadingFlag(true);
        // TODO: CALL API
        setOpenSettingTitleGraphDialog(false);
        const param = {
            userId: userId,
            projectId: projectData.projectId,
            data:data,
            ownerProject: ownerProject
        }
        updateTitleGraph(param)
    }
    //#endregion

    //#region Methods
    const handleScaleGrid = (e: any) => {
        if (e.target.value){
            let newWidth = widthGrid;
            let newHeight = heightGrid;

            if (scaleGrid > 1){
                // old scale > 1
                newWidth /= scaleGrid;
                newHeight /= scaleGrid;
            }
            
            if (e.target.value > 1){
                // new scale > 1
                newWidth *= e.target.value;
                newHeight *= e.target.value;
            }

            setHeightGrid(newHeight);
            setWidthGrid(newWidth);
            // setWidthGridPDF(newWidth);
            // setHeightGridPDF(newHeight);

            stageRef.current?.scale({x: e.target.value, y: e.target.value});
            setScaleGrid(e.target.value);
            setScaleGridPDF(e.target.value);
        }
    }

    const doSetValuesTransCenter = (diagramData: any) => {
        // TransCenter
        let mainDiagramData = diagramDataList.find((e: any) => e.tabId === 1)
        let transCenter: Model.ControlModel =
            mainDiagramData.shape.find(
                (e: any) => e.properties?.tabId === currentTabId
            )

        if (transCenter) {
            let tmpCalc = doGetValuesTransCenter(
                transCenter.properties,
                diagramData,
            )
            transCenter.properties = tmpCalc.newProps
    
            setTransCenterSelected({...transCenter})
            updateTransCenterProps(transCenter.id, transCenter.properties)
        }
    }

    // TransCenter
    const handleCloseTransCenter = () => {
        handleSaveDiagram(
            currentTabId,
            shapes.map((item: any) => {
                return {
                    ...item,
                    isSelected: false
                };
            }),
            false
        )
        groupingRef.current?.nodes([])
        groupLayerRef.current?.destroyChildren()
        saveCurrentTabId(1); // main tab
        setIsReloadMainDiagramPDF(false)
        setUndoData({type:null,dataUndo:null} as Model.UndoModel)
    };

    const handleSaveDiagram = (
        currentTabId: number,
        shapes: Model.ControlModel[], 
        flagSavePreStateDiagram: boolean
    ) => {
        saveAllControlsToDiagram(
            {
                tabId: currentTabId, 
                shape: shapes,
            } as Model.DiagramModel, 
            flagSavePreStateDiagram
        );
    };

    const handleSetShapes = (currentTabId: number,
        shapes: Model.ControlModel[], 
        controlChange:Model.ControlModel[],
        flagSavePreStateDiagram: boolean) => {
        setShapes(
            {
                tabId: currentTabId, 
                shape: shapes,
            } as Model.DiagramModel, 
            controlChange,
            flagSavePreStateDiagram
        );
    }
    //#endregion

    //#region HANDLE KEY EVENTS
    const handleSelectAll = () => {
        let selected = stageRef.current?.find((node: any) => Constant.TYPE_LIST.includes(node.attrs.type));
        transformRef.current?.nodes(selected as any);
        setTransformData(selected as any)
        // TODO: refactor
        const selectedControlIds = [] as any
        shapes?.forEach((shapeItem: any) => {
            if(currentTabId !== 1)
            {
                if(shapeItem.type != Element.MS_INTO_POINT)
                {
                    shapeItem.isSelected = true;
                    selectedControlIds.push(shapeItem.id)
                }
            }
            else
            {
                shapeItem.isSelected = true;
                selectedControlIds.push(shapeItem.id)
            }
        });
        selectedControlIds && selectedControlIds.length > 0 ? setIsReloadMainDiagramPDF(false) : setIsReloadMainDiagramPDF(true)
        drawGroupFrames(selected)
        publishChartEvents({ event: 'SELECT_CONTROLS', payload: {id:selectedControlIds,state:"all"}})
        handleSaveDiagram(currentTabId, shapes, false)
        drawRectByGroup()
    }

    const handleDelete = () => {
        const selected = shapes.filter((shape: any) => shape.isSelected === true && shape.type != Element.MS_INTO_POINT);
        if(selected.length > 0){
            setOpenDeleteDialog(true)
        };
    }

    // コピ
    const handleCopy = () => {
        copyAction(currentTabId);
    }

    // 切り取り
    const handleCut = () => {
        let cutData = Method.handleCopy(diagramState, [...diagramDataList], currentTabId)
        
        setUndoData({ type:"CUT",dataUndo:{
            copyShapes: cutData.shapes,
            copyEleGroups: cutData.eleGroups,
            copyTCEleGroups: cutData.tcEleGroups,
            copyDiagramData: cutData.diagrams,
        }} as Model.UndoModel)
        cutAction(currentTabId);
        removeAction(currentTabId);

        const selected = shapes.filter((shape: any) => shape.isSelected === true)
        if (currentTabId !== 1){
            let diagramData = shapes.filter((e: any) => !selected.includes(e))
            doSetValuesTransCenter(diagramData)
        } else {
            // delete trans center
            selected.forEach((control: Model.ControlModel) => {
                if (control.type === Element.MS_TRANSCENTER){
                    deleteTcGroupAction(control.id);
                    deleteDiagramByTabId(control.properties?.tabId)
                }
            })
        }
        
        // Amazon S3: delete maintenance file
        deleteFileByIdsS3(
            currUser.userSub,
            projectData.projectId,
            selected.map((e: any) => e.id)
        )
        
        // TODO: FIX LOGIC ERROR CUT SUCCESS THEN REMOVE GROUP
        const shapesCurrent = diagramDataList.find((r: any) => r.tabId === currentTabId)?.shape || [];
        const controlsDeleted = shapesCurrent.filter((shape:any) => shape.isSelected === true);
        if(currentTabId == 1){
            // set elementgroup in Maindiagram
            const newElementGroups = {...elementGroups};
            for(const element of controlsDeleted){
                delete newElementGroups.byEleId[element.id];
            }
            for(const id of Object.keys(cutData.eleGroups)){
                delete newElementGroups.byId[id];
            }
            saveElementGroups(newElementGroups)
        }else{
            // set elementgroup in Transcenter screen
            const newTcElementGroups = {...tcGroups};
            const allTransCenterControl = diagramDataList.find((r: any) => r.tabId === 1)?.shape.filter((item:any) => item.type === "MS_TRANSCENTER").map((item:any)=>item) || [];
            const currentTransCenterId = allTransCenterControl.find((item:any) => item.properties.tabId === currentTabId).id;
            for(const element of controlsDeleted){
                delete newTcElementGroups[currentTransCenterId].byEleId[element.elementId];
            }
            for(const id of Object.keys(cutData.tcEleGroups)){
                delete newTcElementGroups[currentTransCenterId].byId[id];
            }
            saveTCGroups(newTcElementGroups)
        }
    }

    // 貼り付け
    const handlePaste = () => {
        setOpenControlMenu(false)
        setOpenMode47Menu(false)
        if (copyShapes.length > 0){
            let transCenterId = null as any
            if (currentTabId !== 1){
                // handle paste invalid control into trans center
                let invalid = false
                copyShapes.forEach((element: any) => {
                    if (elementNotInTransCenter.includes(element.type)){
                        invalid = true
                    }
                });
                if (invalid) return
                
                transCenterId = transCenterSelected?.id
            }

            let params = {
                userId: userId,
                projectId: projectData.projectId,
                elements: copyShapes,
                groups: copyEleGroups,
                tcGroups: copyTCEleGroups,
                tcDiagramList: copyDiagramData,
                x: currentPosition.x,
                y: currentPosition.y,
                transCenterId: transCenterId,
                ownerProject: ownerProject
            } as any
            setLoadingFlag(true)
            pasteElementAndGroup(params)
        }
    }

    const handleUndo = () => {
        let params;
        setOpenControlMenu(false)
        setOpenMode47Menu(false)
        if(undoData.type !== null && undoData.dataUndo !== null){
            setLoadingFlag(true);
            switch(undoData.type){
                // DRAG OR ROTATE OR EXPAND LENGTH OF SHAPE
                case "SET_SHAPE":
                    setUndoActionFlag(true);
                    handleSetShapes(
                        currentTabId,
                        shapes.map((item: any) => {
                            return { ...item}
                        }),
                        [],
                        true
                    )
                    break;
                // OPTION MENU WHEN MODE > DRAWING
                case "UPDATE_QUICK_PROPERTIES":
                    params = {
                        userId:userId,
                        projectId:projectData.projectId.toString(),
                        ownerProject: ownerProject
                    }
                    undoEditPropertiesAction(params);
                    break;
                case "CREATE_ELEMENT":
                    params = {
                        isMainDiagram : currentTabId == 1 ? true : false,
                        params:[],
                        userId: userId,
                        projectId: projectData.projectId.toString(),
                        listParrentGroupId:[],
                        ownerProject: ownerProject
                    }
                    undoCreateElementAction(params);
                    break;
                case "MODE_PM":
                    params = {
                        modePM: undoData.dataUndo,
                        userId: userId,
                        projectId: projectData.projectId.toString(),
                        ownerProject: ownerProject
                    }
                    undoModePM(params)
                    break;
                case "UPDATE_PROPERTIES_ELEMENT":
                    params = {
                        userId: userId,
                        projectId: projectData.projectId.toString(),
                        ownerProject: ownerProject
                    }
                    undoUpdateProperties(params)
                    break;
                case "CHANGE_MODE":
                    params = {
                        // data: undoData.dataUndo.elementsDataUndo,
                        projectData:[],
                        userId: userId,
                        projectId: projectData.projectId.toString(),
                        ownerProject: ownerProject
                    }
                    undoMode(params);
                    break;
                case "PASTE":
                    const data = {
                        isMainDiagram : currentTabId == 1 ? true : false,
                        params:[],
                        userId: userId,
                        projectId: projectData.projectId.toString(),
                        ownerProject: ownerProject,
                        listParrentGroupId:"",
                    }
                    undoPasteAction(data);
                    break;
                case "CUT":
                case "DELETE":{
                    if (undoData.dataUndo.copyShapes && undoData.dataUndo.copyShapes.length > 0){
                        
                        setUndoCut(true); 
                        setTimeout(()=>{
                            let params = {
                                userId: userId,
                                projectId: projectData.projectId,
                                ownerProject: ownerProject
                            } as any
                            undoElementAndGroup(params)
                        },1000)
                    }

                    }
                    break
                case "CREATE_GROUP":
                    setUndoActionFlag(true);
                    params = {  
                        isUndo: true,
                        userId:userId,
                        projectId:projectData.projectId.toString(),
                        ownerProject: ownerProject
                    }
                    if(currentTabId === 1)
                        undoCreateGroup(params,"delete-group");
                    else
                        undoCreateGroup(params,"delete-tcgroup");
                    break;
                case "DELETE_GROUP":
                    setUndoActionFlag(true);
                    params={
                        elements:undoData.dataUndo.elements,
                        groups:undoData.dataUndo.groups,
                        userId:userId,
                        projectId:projectData.projectId.toString(),
                        ownerProject: ownerProject,
                        tcId:''
                        
                    }
                    if(currentTabId === 1)
                        undoDeleteGroup(params,"create-group");
                    else
                    {
                        const mainDiagramData = diagramDataList.find((e: any) => e.tabId === 1);
                        const transCenter = mainDiagramData?.shape.find((e: any) => e.properties?.tabId === currentTabId);
                        params.tcId = transCenter?.id
                        undoDeleteGroup(params,"create-tcgroup");
                    }
                    break;
                // GRAPH
                case "UPDATE_GRAPH_TITLE":
                    setUndoActionFlag(true);
                    params = {
                        userId: userId,
                        projectId: projectData.projectId,
                        data:undoData.dataUndo,
                        ownerProject: ownerProject,
                        isUndo:true
                        
                    }
                    updateTitleGraph(params);
                    break;
                // case "UPDATE_SCALE_GRAPH":
                //     params = {
                //         requestPrms: {
                //             userId: userId,
                //             projectId: projectId,
                //             defXMin: Number(undoData.dataUndo.project.def_x_min),
                //             defXMax: Number(undoData.dataUndo.project.def_x_max),
                //             defYMin: Number(undoData.dataUndo.project.def_y_min),
                //             defYMax: Number(undoData.dataUndo.project.def_y_max)
                //             isUndo: true
                //         }
                //     }
                //     undoUpdateScaleGraph(params);
                //     break;
                case "CHANGE_ZOOM":
                    {
                        saveChartZoom(undoData.dataUndo.zoom, undoData.dataUndo.graphNo)
                        let temp = chartDataList.find((x:any) => x.tabId === undoData.dataUndo.graphNo);
                        let dNominalVolt = temp?.nominalVolt ?? 0;
                        let bSociety = temp?.isJapaneseElectricMode ?? false;
                        let param = {
                            userId: userId,
                            projectId : storeProjectId,
                            graphNo : undoData.dataUndo.graphNo,
                            nominalVolt : dNominalVolt,
                            zoom : undoData.dataUndo.zoom,
                            society : bSociety,
                            ownerProject: ownerProject
                        }
                        updateGraphInfo(param)
                    }
                    break;
                case "CHANGE_NOMINAL_VOLT":
                    {
                        saveNewNominalVolt(undoData.dataUndo.nominalVolt, undoData.dataUndo.graphNo)
                        let temp = chartDataList.find((x:any) => x.tabId === undoData.dataUndo.graphNo);
                        let dZoom = temp?.zoom ?? 100;
                        let bSociety = temp?.isJapaneseElectricMode ?? false;
                        let param = {
                            userId: userId,
                            projectId : storeProjectId,
                            graphNo : undoData.dataUndo.graphNo,
                            nominalVolt : undoData.dataUndo.nominalVolt,
                            zoom : dZoom,
                            society : bSociety,
                            ownerProject: ownerProject
                        }
                        updateGraphInfo(param)
                    }
                    break;
                case "SET_POSITION_FOR_GRAPH_LABREL":
                    setUndoGraphData('SET_POSITION_FOR_GRAPH_LABREL')
                    setLoadingFlag(false);
                    break;
                case "CREATE_GRAPH_TEXT":
                    setUndoGraphData('CREATE_GRAPH_TEXT')
                    setLoadingFlag(false);
                    break;
                case "DELETE_GRAPH_TEXT":
                    setUndoGraphData('DELETE_GRAPH_TEXT')
                    setLoadingFlag(false);
                    break;
                case "EDIT_GRAPH_TEXT":
                    setUndoGraphData('EDIT_GRAPH_TEXT')
                    setLoadingFlag(false);
                    break;
                case "DELETE_RELATED_GRAPH":
                    setUndoGraphData('DELETE_RELATED_GRAPH')
                    setLoadingFlag(false);
                    break;
                case "ADD_RELATED_GRAPH":
                    setUndoGraphData('ADD_RELATED_GRAPH')
                    setLoadingFlag(false);
                    break;
                case "UNDO_UPDATE_RELATED_GRAPH":
                    setUndoGraphData('UNDO_UPDATE_RELATED_GRAPH')
                    setLoadingFlag(false);
                    break;
                case "UPDATE_GRAPH_SCALE":
                    setUndoGraphData('UPDATE_GRAPH_SCALE')
                    setLoadingFlag(false);
                    break;
                default:
                    setLoadingFlag(false)
                    break;
            }
        }
    }
    const handleKeyDown = (event: any) => {
        setOpenMode47Menu(false)
      if(processMode == Model.ProcessMode.DRAWING && !(m_bModePM || modeViewOnly)){
        setOpenControlMenu(false);
        switch (event.key) {
            case 'Delete':
                event.preventDefault();
                handleDelete();
                break;
            case 'c':
                event.preventDefault();
                event.ctrlKey && handleCopy();
                break;
            case 'x':
                event.preventDefault();
                event.ctrlKey && handleCut();
                break;
            case 'v':
                event.preventDefault();
                event.ctrlKey && handlePaste();
                break;
            case 'a':
                event.preventDefault();
                event.ctrlKey && handleSelectAll();
                break;
            case 'z':
                event.preventDefault();
                event.ctrlKey && handleUndo();
                break;
        }
      }else{
        switch (event.key) {
            case 'a':
                event.preventDefault();
                event.ctrlKey && handleSelectAll();
                break;
            case 'z':
                event.preventDefault();
                event.ctrlKey && handleUndo();
                break;
        }
      }
    };

    const handleSuccessPaste = async (data: any) => {
        const tempData = JSON.parse(JSON.stringify(data));
        if(data && data.resultCode == 0){
            const result = await getElements(userId, projectData.projectId.toString(), data.data.element_ids,ownerProject)
            if (result.success) {
                handleSuccessPasteSub(tempData, result.data);
            } else {
                handleGetElementsError(result.success, result.data, result.error)
            }
        }
        if(undoCut){
            setUndoCut(false);
            setUndoData({type:null,dataUndo:null} as Model.UndoModel)
        }
    }
    
    const handleSuccessUndoDelete = async (data: any) => {
        const tempData = JSON.parse(JSON.stringify(data));
        if(data && data.resultCode == 0){
            
            const result = await getElements(userId, projectData.projectId.toString(), data.data.element_ids,ownerProject)
            if (result.success) {
                handleSuccessPasteSub(tempData, result.data);
            } else {
                handleGetElementsError(result.success, result.data, result.error)
            }
        }
        if(undoCut){
            setUndoCut(false);
            setUndoData({type:null,dataUndo:null} as Model.UndoModel)
        }
    }
    

    const handleSuccessPasteSub = async (data: any, elementsData: any) => {

        if (data.data.trans_center_id !== null){
            // paste into trans center
            const transCenterId = data.data.trans_center_id
            const mappedElements = mapElementsResponseToLocal(elementsData, diagramDataList);
            
            let tmpShapes = [...diagramData[0].shape, ...mappedElements]
            const tmpMappedTCGroups = mapElementGroupsResponse(mappedElements as any, data.data.element_groups);

            

            let tmpTcGroups = tcGroups
            tmpTcGroups[transCenterId] = {
                byId: {...tcGroups[transCenterId].byId, ...tmpMappedTCGroups.byId},
                byEleId: {...tcGroups[transCenterId].byEleId, ...tmpMappedTCGroups.byEleId}
            }

            // UNDO DATA
            if(!undoCut){
                let listParrentGroupId:any = [];
                mappedElements.forEach((item) => {
                    if(tmpTcGroups[transCenterId].byEleId[item.id])
                    listParrentGroupId.push(...tmpTcGroups[transCenterId].byEleId[item.id]);
                })
                listParrentGroupId = Array.from(new Set(listParrentGroupId));
                setUndoData({type:"PASTE",dataUndo:{params : mappedElements.map((item) => {
                    return { elementId : item.id, elementType: getElementKindValue(item.type!)};
                }),listParrentGroupId:listParrentGroupId}});
            }
            else
            {
                undoData.dataUndo.chartData && addDataUndoToChart(undoData.dataUndo.chartData)
            }

            const mappedProject = mapProjectDataFromResponse(data.data.new_project_props);

            saveAllControlsToDiagram(
                {
                    tabId: currentTabId,
                    shape: tmpShapes,
                } as Model.DiagramModel, 
                true
            )
            if(undoCut){
                data.data.element_ids.forEach((item:any) => {
                    addLineToChart(true,item);
                })
            }
            saveTCGroups(tmpTcGroups)
            saveOpenProjectData(mappedProject)

            setLoadingFlag(false)
        } else {
            handlePlacementGroupListSuccessSub(data, elementsData)
        }
    }

    const handleErrorPaste = (success : any, data : any, error : any) => {
        setLoadingFlag(false)
        onShowMessage({
            type: "error",
            title: "貼り付け",
            body: "貼り付けに失敗しました。",
        });
    }
    //#endregion HANDLE KEY EVENTS
    const handleSplitKey = (key : string) =>{
        const split_key = key.split(',')
        const rotation = split_key[1] !== undefined ? Number(split_key[1]): 0;
        return {key:split_key[0], rotation: rotation}

    }
    //#region CREATE || DELETE ELEMENTS
    const handleDrop = (e : any) => {        
        e.preventDefault();

        // register event position
        stageRef.current?.setPointersPositions(e);

        // get and convert event position
        const x1 = stageRef.current?.getPointerPosition()?.x || 0;
        const y1 = stageRef.current?.getPointerPosition()?.y || 0;
        const x = floorFunc(x1, scaleGrid) / scaleGrid;
        const y = floorFunc(y1, scaleGrid) / scaleGrid;

        // create element/ group list with key and position
        if (e.dataTransfer.getData("KEY") !== ''){
            const split = handleSplitKey(e.dataTransfer.getData("KEY"));
            handleCreate(split.key,split.rotation, {x, y})
        }
        else if (e.dataTransfer.getData("GROUPID") !== '')
            if (currentTabId === 1)
                handleCreateGroupList(e.dataTransfer.getData("GROUPID"), {x, y})
    }
    const handlePointerPositionAction = (newElement:any) =>{
       let number = 0
       if(newElement.type === Element.MS_TEXT){
            number = Constant.ELEMENT_SIZE
       }
       else if(newElement.rotation === 0){
            number = newElement.height
       }
       else if(newElement.type === Element.MS_HVCB){
            number = newElement.width - 40
       }
       else if([90,-90].includes(newElement.rotation) && !(newElement.type === Element.MS_ZEROCOND)){
            number = newElement.width - 20
       }
       else{
            number = newElement.width
       }
       return number
         
    }
    const handleCreate = (key: string,rotation: number = 0, createElePos : Model.Coordinates = {x: -1, y: -1}) => { 
        // get currentPosition when user click button, or passed position when user drag element
        if (createElePos.x === -1 && createElePos.y === -1) createElePos = {...currentPosition}
        setOpenControlMenu(false);
        setOpenMode47Menu(false);

        let data;
        let maxNo = projectData[getElementNoKey(key)] || 0

        if (Object.keys(templatesData).includes(key)){
            const tmpKey = key as (keyof typeof templatesData); // Type Casting
            data = templatesData[tmpKey];  
        } else {
            //TODO: No templates data yet
            data = { 
                elementType: key,
                data: {
                    no: maxNo + 1,
                    point: maxId + 1
                }
            };
        }

        data.data = {
            no: maxNo + 1,
            point: maxId + 1
        }
        
        let newElement;
        let intoPointElement;
        let transCenterData;
        // TransCenter
        if(key === Element.MS_TRANSCENTER){
            let tabId = maxTabId + 1
            setMaxTabId(tabId)
            newElement = createElementFromResponseData(key as ElementKind, maxId + 1, createElePos, data, tabId, shapes,currentTabId,infoCircuit,rotation);
            transCenterData = newElement
            intoPointElement = createElementFromResponseData(Element.MS_INTO_POINT, maxId + 2, createElePos, data, tabId, shapes,currentTabId,infoCircuit,rotation,transCenterData);
            saveAllControlsToDiagram({tabId: tabId, shape: [intoPointElement]} as Model.DiagramModel, true)
        } 
        else if (key === Element.MS_TEXT) {
            newElement = createText(maxId + 1, maxNo + 1, createElePos) //text doesn't need to call api
        }
        else {
            newElement = createElementFromResponseData(key as ElementKind, maxId + 1, createElePos, data, 0, shapes,currentTabId,infoCircuit,rotation);
        }
        
        // convert upTransKind/ downTransKind in newElement.properties
        let propsParams = {...newElement.properties}
        if (propsParams.hasOwnProperty('downTransKind')){
            propsParams.downTransKind = getElementKindValue(propsParams.downTransKind)
        }
        if (propsParams.hasOwnProperty('upTransKind')){
            propsParams.upTransKind = getElementKindValue(propsParams.upTransKind)
        }
        
        if (currentTabId !== 1){
            // TransCenter -- create element in TransCenter
            propsParams['parentTcID'] = transCenterSelected?.id
        }

        // into point
        let maxNoIntoPoint
        let intoPointParams
        if(key === Element.MS_TRANSCENTER && intoPointElement){
            let intoPointProps = {...intoPointElement.properties}
            intoPointProps['parentTcID'] = newElement.id
            maxNoIntoPoint = projectData[getElementNoKey(Element.MS_INTO_POINT)] || 0
            intoPointParams = {
                userId: userId,
                projectId: projectData.projectId,
                elementId: intoPointElement.id,
                elementType: getElementKindValue(intoPointElement.type),
                noFault: maxId + 2,
                noElementKind: maxNoIntoPoint + 1,
                param: intoPointProps,
                shape: getElementCoordinatesData(intoPointElement)
            }
            setPlaceHolderIntoPoint(intoPointElement as any)
        }

        // call create element api
        const params = {
            userId: userId,
            projectId: projectData.projectId,
            elementId: newElement.id,
            elementType: getElementKindValue(newElement.type),
            noFault: key === Element.MS_TRANSCENTER ? maxId + 2 : maxId + 1,
            noElementKind: maxNo + 1,
            intoPointParams: key === Element.MS_TRANSCENTER ? intoPointParams : undefined,
            noIntoPoint: key === Element.MS_TRANSCENTER ? maxNoIntoPoint + 1 : undefined,
            param: propsParams,
            shape: getElementCoordinatesData(newElement),
            ownerProject: ownerProject
        };
        createElementPost(createElementInfo.get(key)!, params)
        setLoadingFlag(true)
        setPlaceHolderElement(newElement as any)

        // new position for cursor
        setPointerPositionAction(
            {
                ...createElePos,
                x: createElePos.x +([90,-90].includes(newElement.rotation) && !(newElement.type === Element.MS_ZEROCOND) ? newElement.height:0),
                y: createElePos.y + handlePointerPositionAction(newElement)
            }
        );
        if (createElePos.y + newElement.height > heightGrid) {
            setHeightGrid(heightGrid + newElement.height);
        }
    };

    const handleCreateGroupList = (groupId: string, createPos: Model.Coordinates) => {
        let params = {
            userId: userId,
            projectId: projectData.projectId,
            element_group_id: parseInt(groupId),
            x: createPos.x,
            y: createPos.y,
            ownerProject: ownerProject
        }
        setLoadingFlag(true)
        placementGroupList(params)
    };

    const saveElement = (element: Model.ControlModel) => {
        // let isTransCenter = element.type === Element.MS_TRANSCENTER
        // TODO: doGetSourceVolt when create element
        const newImages = [...diagramData[0]["shape"]];
        newImages.map(r => r.isSelected = false);
        newImages.push(element);

        // let newMaxId = isTransCenter ? maxId + 2 : maxId + 1
        let newMaxId = maxId + 1
        saveElementMaxId(newMaxId)

        let typeNewId = 1
        const elementNoKey = getElementNoKey(element.type)
        if (projectData[elementNoKey] > 0)
            typeNewId = projectData[elementNoKey] + 1
        saveTypesMaxId(typeNewId, elementNoKey)

        // // into point
        // if (isTransCenter){
        //     let intoPointNewId = 1
        //     const elementNoKey = getElementNoKey(Element.MS_INTO_POINT)
        //     if (projectData[elementNoKey] > 0)
        //         intoPointNewId = projectData[elementNoKey] + 1
        //     saveTypesMaxId(intoPointNewId, elementNoKey)
        // }

        handleSaveDiagram(currentTabId, newImages, true);
        checkZerocondConnect()
        if (currentTabId !== 1){
            // TransCenter
            doSetValuesTransCenter(newImages)
        }
    };

    // createElementPostのAPI呼び出し成功時
    const handleCreateEleSuccess = (info: CreateElementInfo, data: any) => {
        console.log(info.kind, "--- CreateElement success")
        const newId = data.data
        
        // save to store
        saveElement({...placeHolderElement as any, id: "" + newId});
        if (info.kind === Element.MS_TRANSCENTER){
            addNewTCGroups(newId);
            saveElement({...placeHolderIntoPoint as any, id: "" + newId + 1});
        }
    
        const listElement = diagramData[0]["shape"];
        const pObjElement = listElement.find((item:any) => item.id == newId);

        // UNDO
        setUndoData({ type:"CREATE_ELEMENT", dataUndo: {}} as Model.UndoModel)
        
        let pLeft,pRight,pTop,pBottom;
        switch(pObjElement.type){
            case Element.MS_ZEROCOND:
                pLeft = doGetConnectElement(listElement,pObjElement,Constant.LEFT_CENTER,false)
                pRight = doGetConnectElement(listElement,pObjElement,Constant.RIGHT_CENTER,false)
                if(pLeft.code ==0 && pLeft.data.type === Element.MS_ZEROCOND || pRight.code == 0 && pRight.data.type === Element.MS_ZEROCOND){
                    setMessageWarning("母線同士が左右に接続されました。\n母線同士を右側または左側に接続すると、短絡電流が計算できなくなりますので、\n既存の母線を延伸してください。")
                    setOpenWarningDialog(true);
                }
                break;
            case Element.MS_3WINDING:
                pTop = doGetConnectElement(listElement,pObjElement,Constant.TOP_CENTER,false)
                pLeft = doGetConnectElement(listElement,pObjElement,Constant.LEFT_CENTER,false)
                pRight = doGetConnectElement(listElement,pObjElement,Constant.RIGHT_CENTER,false) 
                if(pObjElement && doGetCircuitVolt(pObjElement,VOLT_SIDE_PRIMARY) <= VOLT_SIDE_LOW_MAX){
                    setMessageWarning("低圧回路に高圧機器が接続されました。")
                    setOpenWarningDialog(true);
                }

                if(pTop.code == 0 && isSPhaseCircuitEle(pTop.data,VOLT_SIDE_SECONDARY,undefined).sPhase == true ||
                   pLeft.code == 0 && isSPhaseCircuitEle(pLeft.data,VOLT_SIDE_SECONDARY,undefined).sPhase == true ||
                   pRight.code == 0 && isSPhaseCircuitEle(pRight.data,VOLT_SIDE_PRIMARY,undefined).sPhase == true
                   ){
                    setMessageWarning("単相回路に三相機器が接続されました。\nこのままですと回路の整合性が取れなくなりますので、\nこの機器の配置をアンドウで取り消すか三相回路に配置しなおしてください。")
                    setOpenWarningDialog(true);
                }
                // TODO:
                if(pLeft.code == 0 && pLeft.data.type === Element.MS_3WINDING ||
                   pRight.code == 0 && pRight.data.type === Element.MS_3WINDING
                    ){
                    setMessageWarning("三巻線変圧器同士が左右に接続されました。\n三巻線変圧器同士を右側または左側に接続すると、短絡電流が計算できなくなりますので、\n三巻線変圧器の問に母線を接続してください。")
                    setOpenWarningDialog(true);
                }
                break;
            case Element.MS_ARRESTOR:
            case Element.MS_DS:
            case Element.MS_LBS:
            case Element.MS_TRANS3:
                if(pObjElement && doGetCircuitVolt(pObjElement,VOLT_SIDE_PRIMARY) <= VOLT_SIDE_LOW_MAX){
                    setMessageWarning("低圧回路に高圧機器が接続されました。")
                    setOpenWarningDialog(true);
                }
                pTop = doGetConnectElement(listElement,pObjElement,Constant.TOP_CENTER,false);
                pBottom = doGetConnectElement(listElement,pObjElement,Constant.BOTTOM_CENTER,false);
                if(pTop.code == 0 && isSPhaseCircuitEle(pTop.data,VOLT_SIDE_SECONDARY).sPhase == true ||
                   pBottom.code == 0 && isSPhaseCircuitEle(pBottom.data,VOLT_SIDE_PRIMARY).sPhase == true
                ){
                    setMessageWarning("単相回路に三相機器が接続されました。\nこのままですと回路の整合性が取れなくなりますので、\nこの機器の配置をアン ドゥで取り消すか三相回路に配置しなおしてください。")
                    setOpenWarningDialog(true);
                }
                break
            // DS_LEFT 
            // DS_RIGHT
            case Element.MS_EARTH:
            case Element.MS_FUSE:
            case Element.MS_HVCB:
            case Element.MS_REACTOR:
            case Element.MS_RELAY:
            case Element.MS_VCT:
            case Element.MS_VT:
            case Element.MS_ZCT:
                pTop = doGetConnectElement(listElement,pObjElement,Constant.TOP_CENTER,false);
                pBottom = doGetConnectElement(listElement,pObjElement,Constant.BOTTOM_CENTER,false);
                if(pTop.code == 0 && isSPhaseCircuitEle(pTop.data,VOLT_SIDE_SECONDARY).sPhase == true ||
                   pBottom.code == 0 && isSPhaseCircuitEle(pBottom.data,VOLT_SIDE_PRIMARY).sPhase == true
                ){
                    setMessageWarning("単相回路に三相機器が接続されました。\nこのままですと回路の整合性が取れなくなりますので、\nこの機器の配置をアン ドゥで取り消すか三相回路に配置しなおしてください。")
                    setOpenWarningDialog(true);
                }
                break
            case Element.MS_GENERATOR:
                pBottom = doGetConnectElement(listElement,pObjElement,Constant.BOTTOM_CENTER,false);
                if(pBottom.code == 0 && isSPhaseCircuitEle(pBottom.data,VOLT_SIDE_PRIMARY).sPhase == true
                ){
                    setMessageWarning("単相回路に三相機器が接続されました。\nこのままですと回路の整合性が取れなくなりますので、\nこの機器の配置をアン ドゥで取り消すか三相回路に配置しなおしてください。")
                    setOpenWarningDialog(true);
                }
                break
            case Element.MS_SOURCE:
                if(pObjElement && doGetCircuitVolt(pObjElement,VOLT_SIDE_PRIMARY) <= VOLT_SIDE_LOW_MAX){
                    // IDS_MES_NG_HV_ERR
                    setMessageWarning("低圧回路に高圧機器が接続されました。");
                    setOpenWarningDialog(true);
                }
                pBottom = doGetConnectElement(listElement,pObjElement,Constant.BOTTOM_CENTER,false);
                if(pBottom.code == 0 && isSPhaseCircuitEle(pBottom.data,VOLT_SIDE_PRIMARY).sPhase == true
                ){
                    // IDS_MES_NG_3PHASES
                    setMessageWarning("単相回路に三相機器が接続されました。\nこのままですと回路の整合性が取れなくなりますので、\nこの機器の配置をアン ドゥで取り消すか三相回路に配置しなおしてください。")
                    setOpenWarningDialog(true);
                }
                break
            case Element.MS_CAPACITOR:
            case Element.MS_MOTOR_GROUP:
                pTop = doGetConnectElement(listElement,pObjElement,Constant.TOP_CENTER,false)
                if(pTop.code == 0 && isSPhaseCircuitEle(pTop.data,VOLT_SIDE_SECONDARY,undefined).sPhase == true){
                    setMessageWarning("単相回路に三相機器が接続されました。\nこのままですと回路の整合性が取れなくなりますので、\nこの機器の配置をアン ドゥで取り消すか三相回路に配置しなおしてください。")
                    setOpenWarningDialog(true);
                }
                break;
            case Element.MS_LIGHTBOARD:
            case Element.MS_LOADCENTER:
            case Element.MS_LVCB:
            case Element.MS_POWERBOARD:
                if(pObjElement && doGetCircuitVolt(pObjElement,VOLT_SIDE_PRIMARY) > VOLT_SIDE_LOW_MAX){
                    setMessageWarning("特別高圧回路または高圧回路に低圧機器が接続されました。")
                    setOpenWarningDialog(true);
                }
                break;
            case Element.MS_THERMAL:
                if(doGetCircuitVolt(pObjElement,VOLT_SIDE_PRIMARY) >= VOLT_SIDE_SPECIAL_HIGH_MIN){
                    setMessageWarning("特別高圧回路または高圧回路に低圧機器が接続されました。")
                    setOpenWarningDialog(true);
                }
                break
            case Element.MS_TRANS1:
                if(doGetCircuitVolt(pObjElement,VOLT_SIDE_PRIMARY) > VOLT_SIDE_SPECIAL_HIGH_MIN){
                    // IDS_MES_NG_SHV_ERR
                    setMessageWarning("単相変圧器或いはスコツト結線変圧器が特高圧機器に接続されました。")
                    setOpenWarningDialog(true);
                }
                
                if(pObjElement && doGetCircuitVolt(pObjElement,VOLT_SIDE_SECONDARY) > VOLT_SIDE_LOW_MAX){
                    setMessageWarning("低圧回路に高圧機器が接続されました。")
                    setOpenWarningDialog(true);
                }

                pBottom = doGetConnectElement(listElement,pObjElement,Constant.BOTTOM_CENTER,false);
                if(pBottom.code == 0 && isSPhaseCircuitEle(pBottom.data,VOLT_SIDE_PRIMARY).sPhase != true){
                    setMessageWarning("単相回路に三相機器が接続されました。\nこのままですと回路の整合性が取れなくなりますので、\nこの機器の配置をアン ドゥで取り消すか三相回路に配置しなおしてください。")
                    setOpenWarningDialog(true);
                }
                break
            case Element.MS_TRANSSCOTT:
                if(doGetCircuitVolt(pObjElement,VOLT_SIDE_PRIMARY) > VOLT_SIDE_SPECIAL_HIGH_MIN){
                    // IDS_MES_NG_SHV_ERR
                    setMessageWarning("単相変圧器或いはスコツト結線変圧器が特高圧機器に接続されました。");
                    setOpenWarningDialog(true);
                }
                if(pObjElement && doGetCircuitVolt(pObjElement,VOLT_SIDE_PRIMARY) <= VOLT_SIDE_LOW_MAX){
                    // IDS_MES_NG_HV_ERR
                    setMessageWarning("低圧回路に高圧機器が接続されました。");
                    setOpenWarningDialog(true);
                }
                if(pObjElement && doGetCircuitVolt(pObjElement,VOLT_SIDE_SECONDARY) > VOLT_SIDE_LOW_MAX){
                    // IDS_MES_NG_LV_ERR
                    setMessageWarning("特別高圧回路または高圧回路に低圧機器が接続されました。");
                    setOpenWarningDialog(true);
                }
                pTop = doGetConnectElement(listElement,pObjElement,Constant.TOP_CENTER,false);
                if(pTop.code == 0 && isSPhaseCircuitEle(pTop.data,VOLT_SIDE_SECONDARY).sPhase == true){
                    // IDS_MES_NG_3PHASES
                    setMessageWarning("単相回路に三相機器が接続されました。\nこのままですと回路の整合性が取れなくなりますので、\nこの機器の配置をアン ドゥで取り消すか三相回路に配置しなおしてください。")
                    setOpenWarningDialog(true);
                }
                pBottom = doGetConnectElement(listElement,pObjElement,Constant.BOTTOM_CENTER,false);
                if(pBottom.code == 0 && isSPhaseCircuitEle(pBottom.data,VOLT_SIDE_PRIMARY).sPhase != true){
                    // IDS_MES_NG_3PHASES
                    setMessageWarning("単相回路に三相機器が接続されました。\nこのままですと回路の整合性が取れなくなりますので、\nこの機器の配置をアン ドゥで取り消すか三相回路に配置しなおしてください。")
                    setOpenWarningDialog(true);
                }
                break;
        }

        // remove multiple transform and grouping frame
        transformRef.current?.nodes([]);
        groupingRef.current?.nodes([]);        
        groupLayerRef.current?.destroyChildren()
        setTransformData([])
        setLoadingFlag(false)
        setPlaceHolderElement(null)
        setPlaceHolderIntoPoint(null)
    }

    // createElementPostのAPI呼び出しエラー時
    const handleCreateEleError = (info: CreateElementInfo, success: any, data: any, error: any) => {
        if (!data?.message?.startsWith("別のユーザーが編集しています。"))
            onShowMessage(
                {
                    type: "error",
                    title: `${info.kind}生成`,
                    body: `${info.kind}を生成出来ませんでした。`,
                }
            )
        setLoadingFlag(false)
        setPlaceHolderElement(null)
        setPlaceHolderIntoPoint(null)
    }

    const handleDeleteOK = () => {
        const selected = shapes.filter((shape: any) => shape.isSelected === true)
        let chartDataUndo:any[] = []
        chartDataList.map((chartTab:any) => {
            let temp = chartTab.shape.filter((control:any) => selected.find((x:any)=>Number(x.id) == Number(control.id)))
            temp && temp.length > 0 && chartDataUndo.push({data: temp, tabId: chartTab.tabId})
        })
        // // delete group (if any)
        // let newGroupStack = [...groupStack];
        // let newGroupIds = [] as any
        // const unGrouped = [] as any
        // selected.forEach((control: any) => {
        //     if (unGrouped.includes(control.id)) return;
        //     for (let i = groupStack.length - 1; i >= 0; --i) {
        //         const groupIds = groupStack[i];
        //         if (groupIds.includes(control.id)) {
        //             unGrouped.push(...groupIds)
        //             newGroupStack = [...newGroupStack.filter(prev => JSON.stringify(prev) !== JSON.stringify(groupIds))];
        //             newGroupIds.push(...groupIds.filter((id: string) =>
        //                 newGroupStack.every((item) => !item.includes(id))
        //             ));
        //             break;
        //         }
        //     }
        // })
        // unGroupAction(newGroupStack, [...new Set(newGroupIds) as any]);
        // groupLayerRef.current?.destroyChildren();
        // groupingRef.current?.nodes([])

        // TransCenter
        if (currentTabId !== 1){
            let diagramData = shapes.filter((e: any) => !selected.includes(e))
            doSetValuesTransCenter(diagramData)
        } else {
            // delete trans center
            selected.forEach((control: Model.ControlModel) => {
                if (control.type === Element.MS_TRANSCENTER){
                    deleteTcGroupAction(control.id);
                    deleteDiagramByTabId(control.properties?.tabId)
                }
            })
        }
        let deleteData = Method.handleCopy(diagramState, [...diagramDataList], currentTabId)
        setUndoData({ type:"DELETE",dataUndo:{
            copyShapes: deleteData.shapes,
            copyEleGroups: deleteData.eleGroups,
            copyTCEleGroups: deleteData.tcEleGroups,
            copyDiagramData: deleteData.diagrams,
            chartData:chartDataUndo
        }} as Model.UndoModel)

        
        removeAction(currentTabId);
        setOpenDeleteDialog(false);

        // Amazon S3: delete maintenance file
        deleteFileByIdsS3(
            currUser.userSub,
            projectData.projectId,
            selected.map((e: any) => e.id)
        )

        // TODO: FIX LOGIC ERROR CUT SUCCESS THEN REMOVE GROUP
        if(currentTabId == 1){
            // set elementgroup in Maindiagram
            const newElementGroups = {...elementGroups};
            for(const element of selected){
                delete newElementGroups.byEleId[element.id];
            }
            for(const id of Object.keys(deleteData.eleGroups)){
                delete newElementGroups.byId[id];
            }
            saveElementGroups(newElementGroups)
        }else{
            // set elementgroup in Transcenter screen
            const newTcElementGroups = {...tcGroups};
            const allTransCenterControl = diagramDataList.find((r: any) => r.tabId === 1)?.shape.filter((item:any) => item.type === "MS_TRANSCENTER").map((item:any)=>item) || [];
            const currentTransCenterId = allTransCenterControl.find((item:any) => item.properties.tabId === currentTabId).id;
            for(const element of selected){
                delete newTcElementGroups[currentTransCenterId].byEleId[element.id];
            }
            for(const id of Object.keys(deleteData.eleGroups)){
                delete newTcElementGroups[currentTransCenterId].byId[id];
            }
            saveTCGroups(newTcElementGroups)
        }
        setIsReloadMainDiagramPDF(true)
    }

    const handleDeleteCancel = () => {
        setOpenDeleteDialog(false);
    }
    //#endregion CREATE || DELETE ELEMENTS

    //#region PROJECT METADATA METHODS
    const handleGetTemplatesSuccess = (data: any) => {
        if (data.resultCode === 0){
            setStdCapacityList(data.std_capacity_list)
            setTemplatesData(data.templates)
            saveElementTemplates(data)
        }
        !fetchOpenProjectState.loading && setLoadingFlag(false);
    };

    // 検索エラー時
    const handleErrorGetTemplate = (error: any) => {
        console.log('>>> handleErrorGetTemplate');
        onShowMessage({
            type: "error",
            title: `データ生成`,
            body: `データを生成出来ませんでした。`,
        })
        setLoadingFlag(false);
        navigate(`/projects`)
    };

    const handleSuccessOpenProject = async (data : any) => {
        const project = data.data.project;
        const editProjectId = project.project_id;
        const elementIds = data.data.element_ids;
        const result = await getElements(userId, editProjectId, elementIds,ownerProject);
        if (result.success) {
            const elements = result.data;
            openProject(data, elements);
        } else {
            handleGetElementsError(result.success, result.data, result.error)
        }
    }

    const openProject  = (data : any, elementsData: any) => {
        setElementLoadingFlag(true);
        const project = data.data.project
        const elements = elementsData
        const elementGroups = data.data.element_groups
        const tcGroups = data.data.tc_groups
        const infoCircuit = data.data.info_circuit
        const infoSkeleton = data.data.info_skeleton
        const infoCover = data.data.info_cover

        const maxIDChartTab = data.data.maxIDChartTab[0]
        saveMaxIdChartTab(maxIDChartTab, false);
        
        const report = data.data.report

        const mappedProject = mapProjectDataFromResponse(project);
        const mappedInfoCircuit = mapInfoCircuitFromResponse(infoCircuit);
        const mappedInfoSkeleton = mapInfoSkeletonFromResponse(infoSkeleton);
        const mappedInfoCover = mapInfoCoverFromResponse(infoCover);
        const mappedReport = mapReportFromResponse(report);

        const mappedElements = mapElementsResponseToLocal(elements, diagramDataList);

        const elementsOutOfTC = mappedElements.filter((e) => e.parentTcID === null)
        const mappedElementGroups = mapElementGroupsResponse(elementsOutOfTC as any, elementGroups);

        // TransCenter -- Add tab per transCenter, edit props.tabId and add children to transCenter's subTab
        let transCenterList = mappedElements.filter((e) => e.type === Element.MS_TRANSCENTER)
        let eleOfTransCenter = mappedElements.filter((e) => e.parentTcID !== null)
        let groupByTcID = groupBy(eleOfTransCenter, 'parentTcID')
        const tcGroupsGroupedById = groupBy(tcGroups, 'tc_id');
        const mappedTCGroups : any = {}
        if (transCenterList.length > 0){
            let newMaxTabId = maxTabId
            transCenterList.forEach((transCenter: any) => {
                newMaxTabId++
                let shape = []
                if (groupByTcID[transCenter.id] !== undefined)
                    shape = groupByTcID[transCenter.id]
                saveAllControlsToDiagram({tabId: newMaxTabId, shape: shape} as Model.DiagramModel, true)
                transCenter.properties.tabId = newMaxTabId

                if (shape.length > 0 && tcGroupsGroupedById[transCenter.id]) {
                    mappedTCGroups[transCenter.id] = mapElementGroupsResponse(shape, tcGroupsGroupedById[transCenter.id])
                } else {
                    mappedTCGroups[transCenter.id] = {byId:{}, byElId:{}}
                }
            })
            setMaxTabId(newMaxTabId)
        }

        // remove eleOfTransCenter in mappedElements
        const mainDiagramShape = mappedElements.filter((e) => !eleOfTransCenter.map(e2 => e2.id).includes(e.id))
        saveAllControlsToDiagram(
            {
                tabId: currentTabId, 
                shape: mainDiagramShape,
            } as Model.DiagramModel, 
            false
        )
        saveElementGroups(mappedElementGroups)
        saveTCGroups(mappedTCGroups)
        saveInfoCircuit(mappedInfoCircuit)
        
        saveInfoCover(mappedInfoCover)

        saveReport(mappedReport)

        // saveReport()

        saveOpenProjectData(mappedProject)
        changeModePM(mappedProject.modePM)

        handleCheckEditable(data.data)
        
        setWidthGrid(mappedInfoSkeleton.squareCountHlizontal * Constant.POINTER_WIDTH)
        setHeightGrid(mappedInfoSkeleton.squareCountVertical * Constant.POINTER_HEIGHT)

        setWidthGridPDF(mappedInfoSkeleton.squareCountHlizontal * Constant.POINTER_WIDTH)
        setHeightGridPDF(mappedInfoSkeleton.squareCountVertical *  Constant.POINTER_HEIGHT)
        saveInfoSkeleton(mappedInfoSkeleton)

        handleCheckCopyData();
        if (isShowMaintenanceScreen && isShowMaintenanceScreen.show) {
            setTimeout(() => {setShowMaintenanceScreen(true)}, 1000)
        }
        !fetchGetTemplatesState.loading && setLoadingFlag(false);
        setElementLoadingFlag(false);
    }

    const handleCheckEditable = (data: any) => {
        if (currUser?.expired) setModeViewOnly(true)
        else {
            setModeViewOnly(!data.editable)
            setProjectOpenTime(data.project_open_time || "")
        }
    }

    const handleCheckCopyData = async () => {
        // navigator.clipboard need the tab to be focused
        if (document.hasFocus()){
            const clipText = await navigator.clipboard.readText();
            handleSetCopyData(clipText);
            return;
        }
        // => if tab is not focused then addEventListener to wait for tab to be focused
        return new Promise((resolve, reject) => {
            const _asyncCopyFn = (async () => {
                try {
                    const clipText = await navigator.clipboard.readText();
                    handleSetCopyData(clipText);
                    resolve(clipText);
                } catch (e) {
                    reject(e);
                }
                window.removeEventListener("focus", _asyncCopyFn);
            });
            
            window.addEventListener("focus", _asyncCopyFn);
        });
    }

    const handleSetCopyData = (clipText: any) => {
        try {
            const copyData = JSON.parse(clipText)
            if (
                copyData.hasOwnProperty('shapes')
                && copyData.hasOwnProperty('eleGroups')
                && copyData.hasOwnProperty('tcEleGroups')
                && copyData.hasOwnProperty('diagrams')
            ){
                setCopyData(copyData);
            }
        } catch (e) { }
    }
    
    const handleErrorOpenProject = (success: boolean, data: any, error: any) => {
        console.log('>>> handleErrorOpenProject');
        onShowMessage({
            type: "error",
            title: "プロジェクトオープン",
            body: "プロジェクトオープンできませんでした。",
        })
        setLoadingFlag(false);
        navigate(`/projects`)
    }

    const handleOKInfoCircuitForm = (data : Model.InfoCircuit) => {
        setLoadingFlag(true); 
        saveInfoCircuit(data);
        saveInfoCircuitToDB({
            userId: userId,
            projectId: projectData.projectId,
            ownerProject: ownerProject,
            ...data
        });

        setOpenCircuitForm(false)
    }
    
    const handleOKSkeletonFrom =(data: Model.InfoSkeleton) =>{
        setLoadingFlag(true); 
        saveInfoSkeleton(data);
        //TODO
        saveInfoSkeletonToDB({
            userId: userId,
            projectId: projectData.projectId,
            ownerProject: ownerProject,
            ...data,
        });
        setOpenSkeletonForm(false);
    }

    const handleOKCoverFrom =(data: Model.InfoCover) =>{
        setLoadingFlag(true); 
        saveInfoCover(data);
        //TODO
        saveInfoCoverToDB({
            userId: userId,
            projectId: projectData.projectId,
            ownerProject: ownerProject,
            ...data
        });
        setOpenCoverForm(false)

    }
    ///------------------------------------------------------------------------
    const handleOKReportFrom =(data: Model.Report) =>{
        setLoadingFlag(true); 
        saveReport(data);
        //TODO
        saveReportToDB({
            userId: userId,
            projectId: projectData.projectId,
            ...data,
            reportIndex: 1,
            ownerProject: ownerProject
        });
        setOpenReportForm(false)

    }

    const handleSuccessSetShape = async (data: any) => {
        if (data && data.resultCode == 0){
            if(data.data){
                let newGridSize = data.data.new_grid_size
                let parentTcId = data.data.parent_tc_id
    
                let newWidth = newGridSize.square_count_hlizontal*Constant.POINTER_WIDTH
                let newHeight = newGridSize.square_count_vertical*Constant.POINTER_HEIGHT
                if (parentTcId != null){
                    if (newGridSize.square_count_hlizontal % 2 === 0)
                        newWidth += 1*Constant.POINTER_WIDTH
                }
                if (scaleGrid > 1){
                    newWidth *= scaleGrid
                    newHeight *= scaleGrid
                }
                setWidthGrid(newWidth)
                setHeightGrid(newHeight)

                setWidthGridPDF(newWidth)
                setHeightGridPDF(newHeight)
    
                if (parentTcId == null){
                    const newInfoSkeleton: any = {
                        ...infoSkeleton,
                        squareCountHlizontal: newGridSize.square_count_hlizontal,
                        squareCountVertical: newGridSize.square_count_vertical
                    }
                    saveInfoSkeleton(newInfoSkeleton)
                } else {
                    const newTransCenterSelected: any = {
                        ...transCenterSelected,
                        properties:{
                            ...transCenterSelected!.properties,
                            xFrameCount: newGridSize.square_count_hlizontal,
                            yFrameCount: newGridSize.square_count_vertical,
                        }
                    }
                    setTransCenterSelected(newTransCenterSelected)
                    updateTransCenterProps(parentTcId, newTransCenterSelected.properties)
    
                    let newTCGridSize = data.data.tc_new_grid_size
                    let tcDiagramList: any[] = []
                    diagramData[0].shape.forEach((e: any) => {
                        e.x += newTCGridSize.movement*Constant.POINTER_WIDTH
                        tcDiagramList.push(e)
                    })
                    saveAllControlsToDiagram(
                        {
                            tabId: currentTabId, 
                            shape: tcDiagramList,
                        } as Model.DiagramModel, 
                        true
                    )
                }
            }
            if(!undoActionFlag){
                setUndoData({type:"SET_SHAPE",dataUndo:{}} as Model.UndoModel)
            }
            else if(data.element_ids && undoActionFlag){
                const result = await getElements(userId, projectData.projectId.toString(), data.element_ids,ownerProject)
                if (result.success) {
                    const elements = result.data;
                    handleSuccessUndo(elements);
                }else{
                    handleGetElementsError(result.success, result.data, result.error)
                }
            }
        }
        setLoadingFlag(false);
    }

    const handleSuccessUndo = (elementsData: any) => {
        setLoadingFlag(false);
        const mappedElements = mapElementsResponseToLocal(elementsData, diagramDataList);
        let array:any = [];
        // element affected
        //  TRANSCENTER DIAGRAM OR MAIN DIAGRAM
        array = [...diagramData.filter((item:any) => item.tabId === currentTabId)[0].shape].map((item:any) => {
            for(const element of mappedElements){
                if(item.id == element.id){
                    return element;
                }
            }
            return item;
        });
        saveAllControlsToDiagram(
            {
                tabId: currentTabId, 
                shape: array,
            } as Model.DiagramModel, 
            false
        )
        checkZerocondConnect();
        setUndoActionFlag(false);
        setUndoData({type:null,dataUndo:null} as Model.UndoModel)
    }

    const handleSuccessUndoUpdateProperties = (elementsData: any) => {
        const mappedElements = mapElementsResponseToLocal(elementsData, diagramDataList);
        // TransCenter -- Add tab per transCenter, edit props.tabId and add children to transCenter's subTab
        let transCenterList = mappedElements.filter((e) => e.type === Element.MS_TRANSCENTER)
        let eleOfTransCenter = mappedElements.filter((e) => e.parentTcID !== null)
        let groupByTcID = groupBy(eleOfTransCenter, 'parentTcID')
        if (transCenterList.length > 0){
            transCenterList.forEach((transCenter: any) => {
                const tabId = diagramDataList.find((d:any) => d.tabId === 1)?.shape.find((e:any) => e.id === transCenter.id)?.properties.tabId || 0
                let shape = []
                if (groupByTcID[transCenter.id] !== undefined)
                    shape = groupByTcID[transCenter.id]
                saveAllControlsToDiagram({ tabId, shape } as Model.DiagramModel, false)
                transCenter.properties.tabId = tabId
            })
        }
        // remove eleOfTransCenter in mappedElements
        const mainDiagramShape = mappedElements.filter((e) => !eleOfTransCenter.map(e2 => e2.id).includes(e.id))
        saveAllControlsToDiagram(
            {
                tabId: 1, 
                shape: mainDiagramShape,
            } as Model.DiagramModel, 
            false
        )
        checkZerocondConnect();
        setTimeout(()=>{
            setDiagramDataUpdate()
        },1200)
        setUndoActionFlag(false);
        setUndoData({type:null,dataUndo:null} as Model.UndoModel)
    }

    const handleErrorSetShape = (error: any) => {
        setLoadingFlag(false)
    }

    const handleSuccessSaveInfoSkeleton = (data: any) => {
        if (data.data){
            if (currentTabId === 1) {
                let newWidth = data.data.square_count_hlizontal*Constant.POINTER_WIDTH
                let newHeight = data.data.square_count_vertical*Constant.POINTER_HEIGHT

                if (scaleGrid > 1){
                    newWidth *= scaleGrid
                    newHeight *= scaleGrid
                }
                setWidthGrid(newWidth)
                setHeightGrid(newHeight)

                setWidthGridPDF(newWidth)
                setHeightGridPDF(newHeight)
            }
            
            if (
                data.data.square_count_hlizontal != infoSkeleton.squareCountHlizontal
                || data.data.square_count_vertical != infoSkeleton.squareCountVertical
            ) {
                const newInfoSkeleton: any = {
                    ...infoSkeleton,
                    squareCountHlizontal: data.data.square_count_hlizontal,
                    squareCountVertical: data.data.square_count_vertical
                }
                saveInfoSkeleton(newInfoSkeleton)
            }
        }
        setLoadingFlag(false)
    }

    const handleErrorSaveInfoSkeleton = (error: any) => {
        setLoadingFlag(false)
        onShowMessage({
            type: "error",
            title: "単線結線図の設定",
            body: "単線結線図の設定の保存に失敗しました",
        });
    }
    //#endregion PROJECT METADATA METHODS

    //#region HANDLE MENU OPTIONS
    const handleMenuOptions = (key : Constant.MenuOptions) => {
        switch(key) {
            // File options
            // case Constant.MenuOptions.FILE_SAVE:
            //     handleFileSave();
            //     break;
            // case Constant.MenuOptions.FILE_SAVE_AS:
            //     setOpenSaveAsForm(true);
            //     break;
            case Constant.MenuOptions.FILE_DXF:
                handleOpenDXFDiaglog();
                break;
            case Constant.MenuOptions.FILE_CIRCUIT:
                setOpenCircuitForm(true);
                break;
            case Constant.MenuOptions.FILE_PDF_MAIN_DIAGRAM:
                setIsDrawPDF(true)
                setTimeout(() => {
                    handleDownloadMainDiagramPDF(false)
                }, 1000);
                break;
            case Constant.MenuOptions.FILE_PDF_LINE_DIAGRAM:
                setDownloadDiagramPDF(true,1, '')
                break;
            case Constant.MenuOptions.FILE_PDF_MAIN_AND_LINE_DIAGRAM:
                setIsDrawPDF(true)
                setTimeout(() => {
                    handleDownloadMainDiagramPDF(true)
                }, 1000);
                break;
            case Constant.MenuOptions.EDIT_HISTORY:
                setOpenHistoryDialog(true)
                break;
            
            // Edit options
            case Constant.MenuOptions.EDIT_UNDO:
                handleUndo();
                break;
            case Constant.MenuOptions.EDIT_CUT:
                handleCut();
                break;
            case Constant.MenuOptions.EDIT_COPY:
                handleCopy();
                break;
            case Constant.MenuOptions.EDIT_PASTE:
                handlePaste();
                break;
            case Constant.MenuOptions.EDIT_DELETE:
                handleDelete();
                break;
            case Constant.MenuOptions.EDIT_SEL_ALL:
                handleSelectAll();
                break;

            case Constant.MenuOptions.GRAPH_SETTING:
                handleOpenSettingTitleGraphDialog();
                break;
            case Constant.MenuOptions.GRAPH_DELETE:
                handleDeleteGraphNoDialog();
                break;

            //diagram
            case Constant.MenuOptions.DIAGRAM_SETTING:
                setOpenSkeletonForm(true)
                break;
            case Constant.MenuOptions.DIAGRAM_RE_POINT_NAME:
                setOpenReNumberPointTextConfirmDialog(true)
                // handleRenumberPointText()
                break;
            case Constant.MenuOptions.DIAGRAM_RE_DEVICE_NAME:
                setOpenReNumberSkeletonConfirmDialog(true);
                // handleRenumberSkeleton()
                break;
            // cover
            // case Constant.MenuOptions.REPORT_SETTING:
            //     setOpenCoverForm(true)
            //     break;

            //report
            // case Constant.MenuOptions.REPORT_SETTING:
            //     setOpenReportForm(true)
            //     break;

            case Constant.MenuOptions.DISP_DIAGRAM:
                handleDisplayDiagram()
                break;

            case Constant.MenuOptions.DISP_GRAPH:
                handleDisplayGraph()
                break;

            case Constant.MenuOptions.DISP_REPORT:
                handleDisplayReport()
                break;

            case Constant.MenuOptions.EDIT_ELE_LIST:
                setOpenElementListForm(true)
                break;

            case Constant.MenuOptions.EDIT_POINT_LIST:
                setOpenPointListForm(true)
                break;

            case Constant.MenuOptions.HELP_HELP:
                handleHelpHelp();
                break;
            case Constant.MenuOptions.HELP_VERSION:
                setOpenInfomationDialog(true)
                break;

            default:
                break;
        }
    }

    AWS.config.update({
        region: process.env.REACT_APP_AUTH_REGION,
        accessKeyId: process.env.REACT_APP_ACCESS_ID,
        secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
    });

    const handleHelpHelp = () => {
        // setOpenHelpPreview(true);
        setLoadingFlag(true);
        const s3 = new AWS.S3();
        const params = {
            Bucket: process.env.REACT_APP_INTERNAL_BUCKET_NAME || "mssv3-contents-test",
            Key: process.env.REACT_APP_INTERNAL_KEY_NAME || "クラウドMSSV3マニュアル.pdf",
        };
        
        s3.getObject(params, (err, data) => {
            if (err) {
                console.log(err, err.stack);
                onShowMessage({
                    type: "error",
                    title: "MSSV3のヘルプ",
                    body: err.stack || "MSSV3のヘルプファイルを開くことができませんでした",
                });
            } else {
                if (data && data.Body) {
                    let pdfBlob = new Blob(
                        [data.Body as Uint8Array],
                        {type: data.ContentType,}
                    );
                    openFileInNewTabByBlob(pdfBlob)
                    // refHelpPreview.current.src = URL.createObjectURL(pdfBlob);
                }
            }
            setLoadingFlag(false);
        });
    }

    const handleDisplayDiagram = () => {
        deleteGraphScreen(false,false)
        setIsReOpenProtectionDiagram(false)
        setShowProtectionDiagram(false)
        isShowScreen(Constant.CONTROL_SCREEN)
        setShowReportScreen(false)
        setShowGraphScreen(false)
        setShowMaintenanceScreen(false)
    }

    const handleDisplayGraph = () => {
        if(maxIDChartTab && currentIDChartTab === 0) {
            updateIdTabChart(maxIDChartTab)
        }
        else if(currentIDChartTab === 0){
            updateIdTabChart(0)
        }
        deleteGraphScreen(true,false)
        setIsReOpenProtectionDiagram(false)
        setShowProtectionDiagram(false)
        isShowScreen(Constant.GRAPH_SCREEN)
        setShowReportScreen(false)
        setShowGraphScreen(true)
        setShowMaintenanceScreen(false)

    }

    const handleDisplayReport = () => {
        deleteGraphScreen(false,false)
        setIsReOpenProtectionDiagram(false)
        setShowProtectionDiagram(false)
        isShowScreen(Constant.REPORT_SCREEN)
        setShowGraphScreen(false)
        setShowReportScreen(true)
        setShowMaintenanceScreen(false)
    }

    // save file
    // const handleFileSave = () => {
    //     setLoadingFlag(true)
    //     saveFile({
    //         requestPrms: {
    //             userId: userId,
    //             projectId,
    //         }
    //     })
    // }

    const handleCalcSizeImageForPDF = (isGetImage: boolean) => {
        let listShapes = [...shapes].filter((element: Model.ControlModel) => {if(element.isSelected) return element})
        let minX = 0, minY = 0, maxX = 0, maxY = 0
        if(listShapes && listShapes.length > 0 && !isGetImage)
        {
            minX = listShapes[0].x;
            minY = listShapes[0].y;
            maxX = listShapes[0].rotation == 90 || listShapes[0].rotation == -90 ? listShapes[0].x + listShapes[0].height : listShapes[0].x + listShapes[0].width ;
            maxY = listShapes[0].rotation == 90 || listShapes[0].rotation == -90 ? listShapes[0].y + listShapes[0].width : listShapes[0].y + listShapes[0].height ;

            listShapes.map((element: Model.ControlModel)=>{
                if(element.x < minX) minX = element.x
                if(element.y < minY) minY = element.y
                let eleMaxX = element.rotation == 90 || element.rotation == -90 ? element.x + element.height : element.x + element.width
                let eleMaxY = element.rotation == 90 || element.rotation == -90 ? element.y + element.width : element.y + element.height
                if(maxX < eleMaxX) maxX = eleMaxX
                if(maxY < eleMaxY) maxY = eleMaxY
            })
        } else {
            minX = diagramData[0].shape[0].x;
            minY = diagramData[0].shape[0].y;
            maxX = diagramData[0].shape[0].rotation == 90 || diagramData[0].shape[0].rotation == -90 ? diagramData[0].shape[0].x + diagramData[0].shape[0].height : diagramData[0].shape[0].x + diagramData[0].shape[0].width ;
            maxY = diagramData[0].shape[0].rotation == 90 || diagramData[0].shape[0].rotation == -90 ? diagramData[0].shape[0].y + diagramData[0].shape[0].width : diagramData[0].shape[0].y + diagramData[0].shape[0].height ;
            shapes.map((element: Model.ControlModel)=>{
                if(element.x < minX) minX = element.x
                if(element.y < minY) minY = element.y
                let eleMaxX = element.rotation == 90 || element.rotation == -90 ? element.x + element.height : element.x + element.width
                let eleMaxY = element.rotation == 90 || element.rotation == -90 ? element.y + element.width : element.y + element.height
                if(maxX < eleMaxX) maxX = eleMaxX
                if(maxY < eleMaxY) maxY = eleMaxY
            })
        }
        return {listShapes, minX, minY, maxX, maxY}
    }

    const handleDownloadMainDiagramPDF = (isGetImage:boolean) => {
        if(document.getElementById('mainDiagramPDF'))
        {
            if(layerRefPDF.current && stageRefPDF.current)
            {
                setLoadingFlag(true)
                setIsHandleDownloadPDF(true)
                setImagePDF([])
                const info = handleCalcSizeImageForPDF(isGetImage)
                let transCenterOffSet = 120
                let arrOffSetX = currentTabId != 1 && transCenterSelected? [660, 600, 540, 450, 400] : [600, 550, 500, 450, 400]
                let arrOffSetY = [260, 220, 200, 150]
                let originArrOffSetX = currentTabId != 1 && transCenterSelected? [160, 120, 80, 40] : [120, 70, 40, 20]
                let originArrOffSetY = [140, 120, 100]
                let scalePDF = 5
                let initDrawLength = 5000
                if(info.maxX - info.minX > 400 || info.maxY - info.minY > 300)
                {
                    initDrawLength = (initDrawLength / scalePDF) * 4
                    scalePDF = 4
                    transCenterOffSet = 130
                    arrOffSetX = currentTabId != 1 && transCenterSelected? [460, 380, 240, 100] : [400, 300, 200, 100]
                    arrOffSetY = [ 240, 200, 160, 140]
                }
                if(info.maxX - info.minX > 1000 || info.maxY - info.minY > 1000)
                {
                    initDrawLength = (initDrawLength / scalePDF) * 3
                    scalePDF = 3
                    transCenterOffSet = 130
                    arrOffSetX = currentTabId != 1 && transCenterSelected? [360, 280, 140, 80] : [300, 200, 100, 50]
                    arrOffSetY = [220, 180, 140]
                }
                if(info.maxX - info.minX  > 1500 || info.maxY - info.minY > 1500)
                {
                    initDrawLength = (initDrawLength / scalePDF) * 2
                    scalePDF = 2
                    transCenterOffSet = 150
                    arrOffSetX = currentTabId != 1 && transCenterSelected? [260, 180, 140, 100] : [200, 100, 50]
                    arrOffSetY = [180, 140, 120]
                }
                if(info.maxX - info.minX  > 2000 || info.maxY - info.minY > 2000)
                {
                    initDrawLength = (initDrawLength / scalePDF) * 1
                    scalePDF = 1
                    transCenterOffSet = 150
                    arrOffSetX = currentTabId != 1 && transCenterSelected? [160, 120, 80, 40] : [120, 70, 40, 20]
                    arrOffSetY = [80, 60, 40]
                }
                if(info.maxX - info.minX  > 9000 || info.maxY - info.minY > 9000)
                {
                    let maxLength = (info.maxX - info.minX) > (info.maxY - info.minY) ? info.maxX - info.minX : info.maxY - info.minY
                    maxLength = Math.floor((maxLength - 10000) / 1000) > 0 ? Math.floor((maxLength - 10000) / 1000) : 1
                    scalePDF = scalePDF / maxLength
                    transCenterOffSet = transCenterOffSet > 0 ? transCenterOffSet - 10 * maxLength / 2 + 100 : 0
                    arrOffSetX = currentTabId != 1 && transCenterSelected? [160/maxLength, 120/maxLength, 80/maxLength, 40/maxLength] : [120/maxLength, 70/maxLength, 40/maxLength, 20/maxLength]
                    arrOffSetY = [80/maxLength, 60/maxLength, 40/maxLength]
                }

                stageRefPDF.current?.scale({x: scalePDF, y: scalePDF});
                setScaleGridPDF(scalePDF)
               
                let minX = info.minX * scalePDF
                let minY = info.minY * scalePDF
                let maxX = info.maxX * scalePDF - minX
                let maxY = (info.maxY + (currentTabId != 1 && transCenterSelected ? transCenterOffSet : 0)) * scalePDF - minY

                let originMinX = info.minX 
                let originMinY = info.minY 
                let originMaxX = info.maxX - originMinX
                let originMaxY = (info.maxY + (currentTabId != 1 && transCenterSelected ? transCenterOffSet : 0)) - originMinY
                
                let addX = 0, addY = 0, originAddX = 0, originAddY = 0
                for (let i = 0; i < arrOffSetX.length; i++) {
                    //if(minX - arrOffSetX[i] >= 0)
                    //{
                        minX = minX - arrOffSetX[i];
                        originMinX = originMinX - originArrOffSetX[i];
                        addX = arrOffSetX[i];
                        originAddX = originArrOffSetX[i];
                        break;
                    //}
                }
                for (let i = 0; i < arrOffSetY.length; i++) {
                    if(minY - arrOffSetY[i] >= 0 || (currentTabId != 1 && transCenterSelected))
                    {
                        minY = minY - arrOffSetY[i];
                        originMinY = originMinY - originArrOffSetY[i];
                        addY = arrOffSetY[i];
                        originAddY = originArrOffSetY[i];
                        break;
                    }
                }
                for (let i = 0; i < arrOffSetX.length; i++) {
                    if(maxX + arrOffSetX[i] <= widthGridPDF * scalePDF)
                    {
                        maxX = maxX + arrOffSetX[i] + addX;
                        originMaxX = originMaxX + originArrOffSetX[i] + originAddX;
                        break;
                    }
                }
                for (let i = 0; i < arrOffSetY.length; i++) {
                    if(maxY + arrOffSetY[i] <= heightGridPDF * scalePDF)
                    {
                        maxY = maxY + arrOffSetY[i] + addY;
                        originMaxY = originMaxY + originArrOffSetY[i] + originAddY;
                        break;
                    }
                }

                if(currentTabId != 1 && transCenterSelected && isReloadMainDiagramPDF)
                {
                    const intoPointControl = shapes.find((x:any)=>x.type == Element.MS_INTO_POINT)
                    const connectControl = shapes.find((x:any)=>x.x == intoPointControl.x && x.y == 0)
                    const lenX = connectControl && connectControl.width > intoPointControl.width ? intoPointControl.x + connectControl.width : intoPointControl.x + intoPointControl.width
                    
                    if(shapes.find((x:any)=>x.type == Element.MS_INTO_POINT).x * scalePDF == minX)
                    {
                        minX = minX - maxX
                        maxX = maxX + maxX
                        originMinX = originMinX - originMaxX
                        originMaxX = originMaxX + originMaxX
                    }
                    else if(lenX == (maxX + minX) / scalePDF)
                    {
                        maxX = maxX + maxX
                        originMaxX = originMaxX + originMaxX
                    }
                    else
                    {
                        const lenInLeft = intoPointControl.x * scalePDF - minX
                        const lenInRight = (maxX + minX) - intoPointControl.x * scalePDF
                        const originLenInLeft = intoPointControl.x - originMinX
                        const originLenInRight = (originMaxX + originMinX) - intoPointControl.x
                        if(lenInLeft > lenInRight)
                        {
                            maxX = maxX + lenInLeft - lenInRight
                            originMaxX = originMaxX + originLenInLeft - originLenInRight
                        }
                        else
                        {
                            minX = minX - (lenInRight - lenInLeft)
                            maxX = maxX + (lenInRight - lenInLeft)
                            originMinX = originMinX - (originLenInRight - originLenInLeft)
                            originMaxX = originMaxX + (originLenInRight - originLenInLeft)
                        }
                    }
                }

                setOriginSizeForGetShapes({minX: originMinX, minY: originMinY, maxX: originMaxX, maxY: originMaxY})
                let width = initDrawLength, height = initDrawLength
                let originWidth = initDrawLength / scalePDF, originHeight = initDrawLength / scalePDF
                maxX < width && (width = maxX)
                maxY < height && (height = maxY)
                currentTabId != 1 && transCenterSelected && (height += transCenterOffSet)
                originMaxX < originWidth && (originWidth = originMaxX)
                originMaxY < originHeight && (originHeight = originMaxY)
                currentTabId != 1 && transCenterSelected && (originHeight += transCenterOffSet)
                // currentTabId != 1 && transCenterSelected && (minY += 20)
                const countX = Math.ceil(maxX / initDrawLength)
                const countY = Math.ceil(maxY / initDrawLength)
                setDownloadInfo({scalePDF: scalePDF, drawWidth: width, drawHeight: height, originDrawWidth: originWidth, originDrawHeight: originHeight, isGetImage: isGetImage, countX: countX, countY: countY, minX: minX, minY: minY, maxX: maxX, maxY: maxY})
                setStageWidthPDF(width)
                setStageHeightPDF(height)
                transformStageRefPDF(minX,minY)
                setDrawAreaForPDF([originMinX, originMinY, originMinX + originWidth, originMinY + originHeight])
                setDrawAreaForPDFStore([minX, minY, minX + width, minY + height])
            }
        }
    }

    const handleOpenDXFDiaglog = () => {
        if (showGraphScreen) {
            let fileName = "保護協調図";
            if (listGraphName){
                let tmpArr = [...listGraphName.list]
                let graphName = tmpArr.find((e: any) => e.graph_no === currentIDChartTab);
                if (graphName) fileName = graphName.graph_name;
            }
            setDXFFilename(fileName);
        }
        else setDXFFilename("図面");
        setOpenAddFileDXFDialog(true);
    }

    const handleAddFileDXF = (fileName: string) => {
        if (showGraphScreen) {
            // graph
            setDXFFilename(fileName)
            const params = {
                userId: userId,
                projectId: projectData.projectId,
                graphNo: currentIDChartTab,
                ownerProject: ownerProject
            }
            getGraph2(params)
        } else {
            // diagram
            const params = {
                fileName: fileName,
                user: currUser,
                currentTabId: currentTabId,
                diagramData: diagramData[0].shape,
                infoSkeleton: infoSkeleton,
                infoCircuit: infoCircuit,
                projectData: projectData,
                transCenter: currentTabId != 1 ? transCenterSelected : null,
            }
            onFileDxfOutPutDiagram(params)
        }
    }

    const handleSuccessGetGraph2 = (data : any) => {
        if(data.data){
            let pObjGraph = convertGraphDataFromBE(data.data, infoCircuit)
            const params = {
                fileName: DXFFilename,
                pObjGraph: pObjGraph,
                chartData: chartData,
                diagramData: diagramData[0].shape,
                infoCircuit: infoCircuit,
                projectData: projectData,
                listTextGraph: listTextGraph,
            }
            onFileDxfOutPutGraph(params)
        }
        setLoadingFlag(false)
    }

    const handleSuccessSaveFile = (data : any, isSaveAs = false) => {
        onShowMessage({
            type: 'info',
            title: !isSaveAs ? 'ファイル保存' : `プロジェクト別名保存`,
            body: !isSaveAs ? 'ファイルを保存しました' : `プロジェクト別名保存しました`,
        })
        // re-open project with new original id
        navigate(`/home/${projectData.projectId}`)
        saveCurrentTabId(1)
        setUndoData({type:null,dataUndo:null} as Model.UndoModel)
    }

    const handleErrorSaveFile = (success : any, data : any, error : any) => {
        setLoadingFlag(false)
        onShowMessage({
            type: 'error',
            title: 'ファイル保存',
            body: 'ファイルを保存できませんでした',
        })
    }
    // save as file
    // const handleSaveAs = (data : any) => {
    //     setLoadingFlag(true)
    //     setOpenSaveAsForm(false)
    //     saveAsFile({
    //         requestPrms: {
    //             userId: userId,
    //             projectId,
    //             projectName: data.projectName
    //         }
    //     })
    // }

    // discard changes file
    const handleDiscardChanges = () => {
        setLoadingFlag(true)
        discardChangesFile({
            requestPrms: {
                userId: userId,
                projectId,
            }
        })
    }

    const handleOpenSettingTitleGraphDialog = () => {
        const params = {
            userId: userId,
            projectId: projectData.projectId,
            graphNo: 0,
            ownerProject: ownerProject
        }
        if(currentIDChartTab > 0){
            params.graphNo = currentIDChartTab;
            setLoadingFlag(true);
            getGraph(params)
        }else{
            let maxValue:any = Math.max.apply(null,
                listGraphName.list.map(function (o:any) { return o.graph_no; }));
            params.graphNo = maxValue;
            setLoadingFlag(true);
            getGraph(params)
        }
    }

    const handleDeleteGraphNoDialog = () => {
        deleteGraphScreen(true,true)
    }

    const handleSuccessGetGraph = (data : any) => {
        if(data.data){
            const newData = convertGraphDataFromBE(data.data, infoCircuit)
            setInitialValueGraph(newData)
        }
        setLoadingFlag(false);
        setOpenSettingTitleGraphDialog(true);
    }

    const handleErrorGetGraph = (success : any, data : any, error : any) => {
        setLoadingFlag(false);
    }

    const handleSuccessUpdateGraph = (data:any) => {
        setLoadingFlag(false);
        // TODO: NOTIFICATION
    }

    const handleSuccessUpdateTitleGraph = (data:any) => {
        setLoadingFlag(false);
        if(data && data.resultCode == 0){
            setGraphData({type: "GRAPH_NAME", data: [{graphNo: data.graphNo,graphName:data.graphName}]})
            if(undoActionFlag){
                setUndoData({type:null,dataUndo:null} as Model.UndoModel)
                setUndoActionFlag(false);   
            }else{
                setUndoData({type: "UPDATE_GRAPH_TITLE", dataUndo: {}} as Model.UndoModel)
            }
        }
        // TODO: NOTIFICATION
    }

    const handleErrorUpdateGraph = (success : any, data : any, error : any) => {
        setLoadingFlag(false);
        // TODO: NOTIFICATION
    }

    const handleRenumberPointText = () => {
        setLoadingFlag(true)
        renumberPointText({
            userId: userId,
            projectId: projectData.projectId,
            ownerProject: ownerProject
        })
    }

    const handleRenumberSkeleton = () => {
        setLoadingFlag(true)
        renumberSkeleton({
            userId: userId,
            projectId: projectData.projectId,
            ownerProject: ownerProject
        })
    }

    const handleSuccessRenumber = async (data : any) => {
        if (data.data){
            const result = await getElements(userId, projectData.projectId, data.data.element_ids,ownerProject)
            if (result.success) {
                handleSuccessRenumberSub(data, result.data)
            } else {
                handleGetElementsError(result.success, result.data, result.error)
            }
        }
    }

    const handleSuccessRenumberSub = (data : any, elementsData: any) => {
        const mappedProject = mapProjectDataFromResponse(data.data.project);
        const mappedElements = mapElementsResponseToLocal(elementsData, diagramDataList);

        // TransCenter -- Add tab per transCenter, edit props.tabId and add children to transCenter's subTab
        let transCenterList = mappedElements.filter((e) => e.type === Element.MS_TRANSCENTER)
        let eleOfTransCenter = mappedElements.filter((e) => e.parentTcID !== null)
        let groupByTcID = groupBy(eleOfTransCenter, 'parentTcID')
        if (transCenterList.length > 0){
            transCenterList.forEach((transCenter: any) => {
                const tabId = diagramDataList.find((d : any) => d.tabId === 1)?.shape.find((e : any) => e.id === transCenter.id)?.properties.tabId || 0
                let shape = []
                if (groupByTcID[transCenter.id] !== undefined)
                    shape = groupByTcID[transCenter.id]
                saveAllControlsToDiagram({ tabId, shape } as Model.DiagramModel, false)
                transCenter.properties.tabId = tabId
            })
        }
        // remove eleOfTransCenter in mappedElements
        const mainDiagramShape = mappedElements.filter((e) => !eleOfTransCenter.map(e2 => e2.id).includes(e.id))
        saveAllControlsToDiagram(
            {
                tabId: 1, 
                shape: mainDiagramShape,
            } as Model.DiagramModel, 
            false
        )

        saveOpenProjectData(mappedProject)
        
        setOpenReNumberSkeletonConfirmDialog(false);
        setOpenReNumberPointTextConfirmDialog(false);
        setLoadingFlag(false)
    }

    const handleGetElementsError = (success : any, data : any, error : any) => {
        setLoadingFlag(false)
        onShowMessage({
            type: "error",
            title: "要素の読み込み",
            body: "要素の読み込みに失敗しました。",
        });
    }

    const handleErrorRenumberSkeleton = (success : any, data : any, error : any) => {
        setLoadingFlag(false)
        onShowMessage({
            type: "error",
            title: "デバイス名の再採番",
            body: "デバイス名の再採番に失敗しました。",
        });
    }

    const handleErrorRenumberPointText = (success : any, data : any, error : any) => {
        setLoadingFlag(false)
        onShowMessage({
            type: "error",
            title: "事故点名称の再採番",
            body: "事故点名称の再採番に失敗しました。",
        });
    }
    //#endregion HANDLE MENU OPTIONS

    // #region HANDLE GROUP ELEMENTS
    const handleCreateGroupSuccess = (response : any) => {
        if(undoActionFlag){
            setUndoData({type:null,dataUndo:null} as Model.UndoModel);
            setUndoActionFlag(false);
        }else
            setUndoData({type:"CREATE_GROUP",dataUndo:{}} as Model.UndoModel)
        let elementIds : any = []
        if (currentTabId === 1) { // ele groups
            const {
                elements,
                explan,
                group_id: groupId,
                group_name: groupName,
                groups,
                // owner_id
                parent_group_id: parentGroupId
                // root_group_id
            } = response.data        
            elementIds = [...elements]
            groups.forEach((id : any) => {
                elementIds.push(...groupsData.byId[id]?.elementIds ?? [])
            })
            groupAction({ groupId, groupName, explan, parentGroupId, elementIds, groups }, elementIds);
        } else { // tc groups
            const {
                elements,
                explan,
                tc_id: tcId,
                tc_group_id: groupId,
                group_name: groupName,
                groups,
                // owner_id
                parent_tc_group_id: parentGroupId
                // root_group_id
            } = response.data        
            elementIds = [...elements]
            groups.forEach((id : any) => {
                elementIds.push(...groupsData.byId[id]?.elementIds ?? [])
            })
            tcGroupAction({ groupId, groupName, explan, parentGroupId, elementIds, groups }, elementIds, tcId);
        }

        const items = stageRef.current?.find((node: any) => Constant.TYPE_LIST.includes(node.attrs.type))
        const groupShapes = items?.filter(item => elementIds.includes(item.attrs.id))    
        groupingRef.current?.nodes(groupShapes as any)
        groupLayerRef.current?.destroyChildren()
        drawRectByGroup();
    }

    const handleDeleteGroupSuccess = (response : any) => {
        if(undoActionFlag){
            setUndoData({type:null,dataUndo:null} as Model.UndoModel)
            setUndoActionFlag(false);
        }else{
            const lastStageGroupData = JSON.parse(JSON.stringify(groupsData));
            const group = Object.values(lastStageGroupData.byId).filter((item:any) => item.parentGroupId == response.data);
            const listGroupId = group.map((item:any) => item.groupId);
            let elementIdsInGroup:any[] = [];
            const elementIdsNotInGroup = [];
            group.forEach((item:any) => {
                elementIdsInGroup = elementIdsInGroup.concat(item.elementIds);
            })
            if(response.data && !response.data.hasOwnProperty('tc_group_ids'))
            {
                for(const id of response.data){
                    for(const elementId of lastStageGroupData.byId[id].elementIds){
                        if(!elementIdsInGroup.includes(elementId)){
                            elementIdsNotInGroup.push(elementId);
                        }
                    }
                }
            }
            else
            {
                for(const tc_id of response.data.tc_group_ids){
                    for(const elementId of lastStageGroupData.byId[tc_id].elementIds){
                        if(!elementIdsInGroup.includes(elementId)){
                            elementIdsNotInGroup.push(elementId);
                        }
                    }
                }
            }
            
            setUndoData({type:"DELETE_GROUP",dataUndo:{
                elements:elementIdsNotInGroup,
                groups: listGroupId
            }} as Model.UndoModel)
        }

        if (currentTabId === 1) { // element groups
            const deletedGroupIds = response.data
            unGroupAction(deletedGroupIds);
        } else { // tc groups
            const { tc_group_ids, tc_id } = response.data
            tcUnGroupAction(tc_group_ids, tc_id);
        }


        groupingRef.current?.nodes([])
        groupLayerRef.current?.destroyChildren()
        const selectedIds = shapes.filter((item: any) => item.isSelected === true).map((item: any) => item.id)
        const items = stageRef.current?.find((node: any) => Constant.TYPE_LIST.includes(node.attrs.type))
        const groupShapes = items?.filter(item => selectedIds.includes(item.attrs.id))    
        transformRef.current?.nodes(groupShapes as any)      
        setTransformData(groupShapes as any)
        drawGroupFrames(groupShapes)
    }

    const handleGetGroupListSuccess = (data: any) => {
        if (data.resultCode === 0){
            saveGroupList(data.data)
        }
        setLoadingFlag(false);
    };

    const handleErrorGetGroupList = (error: any) => {
        console.log('>>> handleErrorGetGroupList' + error);
        setLoadingFlag(false);
    };

    const getRectProperties= ( controlSelected : Model.ControlModel[]) => {
        if (controlSelected.length === 0) {
            return null
        }
        let minX = controlSelected[0].x;
        let minY = controlSelected[0].y;
        let maxX = controlSelected[0].x + (controlSelected[0].rotation === 0 || Math.abs(controlSelected[0].rotation) === 180? controlSelected[0].width : controlSelected[0].height);
        let maxY = controlSelected[0].y + (controlSelected[0].rotation === 0 || Math.abs(controlSelected[0].rotation) === 180? controlSelected[0].height : controlSelected[0].width);
        
    
        for (let i = 1; i < controlSelected.length; i++) {
            const point = controlSelected[i];
            const isRotate = !(point.rotation === 0 || Math.abs(point.rotation) === 180)
            if (point.x < minX) {
                minX = point.x;
            }
            if (point.x + (isRotate? point.height: point.width) > maxX) {
                
                maxX = point.x + (isRotate? point.height: point.width);
            } 
            if (point.y < minY) {
                minY = point.y;
            }
            if (point.y + (isRotate? point.width : point.height) > maxY) {
                
                maxY = point.y + (isRotate? point.width : point.height);
            } 
        }
        const minPoint = {
            x: minX,
            y: minY
        }
        const height = maxY - minY 
        const width = maxX - minX 
        return { minPoint, width, height };
    }

    let OldRectPosition: Map<string,any> = new Map() // 


    const handleDragRectStart = (e: any, rect: any) => {
        OldRectPosition.set(rect.id, rect.rect.position())
    }

    const handleDragMove= (e: any, item: any, rect: any) => {
            const {x, y} = e.target.position()
            let rectDisplacement = {
                x: 0, y:0
            }
           
            rectDisplacement.x = Math.round(item.x) - OldRectPosition.get(rect.id).x
            rectDisplacement.y = Math.round(item.y) - OldRectPosition.get(rect.id).y
          
            rect.rect.position({x: x - rectDisplacement.x, y: y - rectDisplacement.y})
    }

    const handleDragRectEnd = () => {
        OldRectPosition = new Map()
    }

    const drawRectByGroup = (selectedIds?:any, selectByChart?:boolean) => {
        groupLayerRef.current?.destroyChildren();
        let selectedControl:any
        if(selectedIds && selectedIds.length > 0 && (isClearGroup||selectByChart) && (!isSelectTouchGroup||selectByChart))
        {
            selectedControl = diagramData[0].shape.filter((item:any)=> selectedIds.includes(item.id) || item.isSelected);
        }
        else
        {
            selectedControl = diagramData[0].shape.filter((item:any)=> item.isSelected === true);
        }
        const groupControlObj = selectedControl.reduce((acc: any, item: any) => {
            const peekGroupId = groupsData?.byEleId[item.id as any]?.at(-1) ?? -1
            if (selectedControl.parentGroupId === null) {
              acc.null.push([item]);
            } else {
              if (!acc[peekGroupId]) {
                acc[peekGroupId] = [];
              }
              acc[peekGroupId].push(item);
            }
            return acc;
            }, { null: [] });
        const groupControl = [...Object.values(groupControlObj).filter((group: any) => group.length > 1 && group.find((g: any)=> g.parentGroupId === null) === undefined),...groupControlObj.null.map((item: any)=> [item])]
        groupControl.forEach((gr: any)=> {
            if(gr.length > 1) {
                const rectObj = drawRect(gr);
                var i = 1
                gr.forEach((item: any)=>{
                    const node: Node<NodeConfig> | undefined = stageRef.current?.find((node: any) => Constant.TYPE_LIST.includes(node.attrs.type) && item.id === node.attrs.id)![0]
                    node && node.on("dragstart", (e: any)=> handleDragRectStart(e,  rectObj))
                    node && node.on("dragmove", (e: any)=> handleDragMove(e, item, rectObj))
                    node && node.on("dragend", (e: any)=> handleDragRectEnd()) 
                })
            }
        })
    }

    const { v4: uuidv4 } = require('uuid');

    const drawRect = (group: Model.ControlModel[]) => {
        const rectProperties = getRectProperties(group);    
        const rect = new Konva.Rect(
            {
                x: rectProperties?.minPoint.x,    
                y: rectProperties?.minPoint.y,
                width: rectProperties?.width,
                height: rectProperties?.height,
                fillEnabled: false,
                stroke:'black',
                strokeWidth :1,
                dash:[2, 2]
            }
        )
        const rectObj: any  = {
            id: uuidv4(),
            rect: rect
        }
        groupLayerRef.current?.add(rectObj.rect)
        return rectObj;
    }

    const drawGroupFrames = (selected: any) => {
        groupLayerRef.current?.destroyChildren()
        const checkedGroupIds: string[] = [];
        
        selected.forEach((item: any) => {
            if (checkedGroupIds.includes(item.attrs.id)) return; 
            const peekGroupId = groupsData ? groupsData.byEleId[item.attrs.id as any]?.at(-1) ?? -1 : -1;
            if (peekGroupId !== -1) {
                const elementIds = groupsData ? groupsData.byId[peekGroupId]?.elementIds ?? [] : -1;
                checkedGroupIds.push(...elementIds)
                const tr = new Konva.Transformer({
                    rotateEnabled: false,
                    resizeEnabled: true,
                    keepRatio: false,
                    enabledAnchors: [],
                    borderDash: [0.5, 0.5],
                    anchorStroke:"black",
                    borderStroke:"black",
                    anchorFill:"black",
                    borderStrokeWidth: 0,
                    visible: true,
                })
                groupLayerRef.current?.add(tr);
                const items = stageRef.current?.find((node: any) => Constant.TYPE_LIST.includes(node.attrs.type))
                const groupShapes = items?.filter(item => elementIds.includes(item.attrs.id))
                tr.nodes(groupShapes as any);
            }
        })
        
        return checkedGroupIds;
    }

    const handleCheckGroup = (e: any, state: boolean, isReturn:boolean, isTap?:boolean) => {
        isTap && clearOptionMenu(!clearMenuState)
        let elementId:any
        let selectByChart = false
        if(!state)
        {
            // event must occurs on element
            if (!(e.target.parent && (e.target.parent.attrs.id || e.target.attrs.type === Element.MS_TEXT))) 
                {
                    transformRef.current?.nodes([]);
                    return;
                }
            elementId = e.target.parent.attrs.id ?? e.target.attrs.id;
        }
        else
        {
            elementId = e
            selectByChart = true
        }
        // get clicked element
        const element = shapes.find((el : any) => el.id === elementId)
        // get id of largest group of clicked element
        const peekGroupId = groupsData && elementId?  (groupsData?.byEleId[elementId as any]?.at(-1) ?? -1): -1
        // get all element ids of largest group of clicked element
        const elementIds = groupsData?.byId[peekGroupId]?.elementIds ?? []
        if(isReturn)
        {
            return elementIds
        }
        if (peekGroupId !== -1) { // if mouse down on group
            // selected ids for drawing group frame
            const selectedIds : any[] = []
            // if clicked element is already selected
            if (element.isSelected) {
                handleSaveDiagram(
                    currentTabId,
                    shapes.map((item: any) => {
                        if (elementIds.includes(item.id)) {
                            if (!state && ((e.evt.shiftKey || isSelectTouchGroup ) && isTap)) {
                                selectedIds.push(item.id)
                                return {...item, isSelected: false}
                            }
                            selectedIds.push(item.id)
                            return item
                        }
                        if (groupsData && groupsData.byEleId[item.id] && item.isSelected) {
                            selectedIds.push(item.id)
                        }
                        return item
                    }),
                    false
                )
            }
            else { // if clicked element is not selected yet
                selectedIds.push(...elementIds)
                if (!state && (e.evt.shiftKey || isSelectTouchGroup)) {
                    handleSaveDiagram(
                        currentTabId,
                        shapes.map((item: any) => {
                            if (elementIds.includes(item.id)) {
                                return {...item, isSelected: true}
                            }
                            if (groupsData && groupsData.byEleId[item.id] && item.isSelected) {
                                selectedIds.push(item.id)
                            }
                            return item
                        }),
                        false
                    )
                } 
                else {
                    handleSaveDiagram(
                        currentTabId,
                        shapes.map((item: any) => {
                            if (elementIds.includes(item.id)) {
                                return {...item, isSelected: true}
                            }
                            return {...item, isSelected: false}
                        }),
                        false
                    )
                }
            }
            if(!((e?.evt?.shiftKey || isSelectTouchGroup) && element.isSelected))
            {
                const items = stageRef.current?.find((node: any) => Constant.TYPE_LIST.includes(node.attrs.type))
                const groupShapes = items?.filter(item => selectedIds.includes(item.attrs.id))
                groupingRef.current?.nodes(groupShapes as any)
                drawGroupFrames(groupShapes)
            }
            drawRectByGroup(selectedIds, selectByChart)
        } else { // mouse down on element not in any group
            // if this element is not selected yet and not shiftKey, undraw all group frames
            if(isReturn)
            {
                return undefined
            }
            if(state)
            {
                if (element == undefined) {
                    groupLayerRef.current?.destroyChildren()
                }
            }
            else if (!element.isSelected && !e.evt.shiftKey && !isSelectTouchGroup) {
                groupLayerRef.current?.destroyChildren();
            }
        }
    }

    const handleCheckGroupList = (e:any) => {
        let idsSelected = e;
        const checkGroupId = new Set();
        let selectedIds:any = [];
        idsSelected.forEach((id:any) => {
            const peekGroupId = groupsData?.byEleId[id as any]?.at(-1) ?? -1
            if(peekGroupId !== -1){
                if(!checkGroupId.has(peekGroupId)){
                    checkGroupId.add(peekGroupId);
                    const elementIds = groupsData?.byId[peekGroupId]?.elementIds ?? []
                    selectedIds = selectedIds.concat(elementIds)
                }
            }else{
                selectedIds.push(id);
            }
        })
        const items = stageRef.current?.find((node: any) => Constant.TYPE_LIST.includes(node.attrs.type))
        const groupShapes = items?.filter(item => selectedIds.includes(item.attrs.id))
        if(!transformFlag)
        {
            groupingRef.current?.nodes(groupShapes as any)
        }
        drawGroupFrames(groupShapes)
        drawRectByGroup(selectedIds, !isClickInDiagram)
    }

    //#endregion HANDLE GROUP ELEMENTS

    // #region HANDLE GROUP LIST ELEMENTS
    const handleCreateGroupListSuccess = (data: any) => {
        let newGroupList = [data.data, ...groupList]
        saveGroupList(newGroupList)
    }
    
    const handleCreateGroupListError = (error: any) => {
        console.log('>>> handleCreateGroupListError' + error)
    }

    const handlePlacementGroupListSuccess = async (data: any) => {
        if(data && data.resultCode == 0){
            const result = await getElements(userId, projectData.projectId.toString(), data.data.element_ids,ownerProject)
            if (result.success) {
                handlePlacementGroupListSuccessSub(data, result.data)
            } else {
                handleGetElementsError(result.success, result.data, result.error)
            }
        }
    }
    
    const handlePlacementGroupListSuccessSub = (data: any, elementsData: any) => {
        const project = data.data.new_project_props
        const elements = elementsData
        let newElementGroups = data.data.element_groups.filter((e: any) => !Object.keys(e).includes("tc_id"))
        let newTCGroups = data.data.element_groups.filter((e: any) => Object.keys(e).includes("tc_id"))

        const mappedProject = mapProjectDataFromResponse(project);
        const mappedElements = mapElementsResponseToLocal(elements, diagramDataList);
        if(!undoCut){
            setUndoData({type:"PASTE",dataUndo:{params : mappedElements.map((item) => {
                return { elementId : item.id, elementType: getElementKindValue(item.type!)};
            }),listParrentGroupId:data.data.element_groups.map((item:any) => item.group_id)}})
        }
        const elementsOutOfTC = mappedElements.filter((e) => e.parentTcID === null)
        const newMappedElementGroups = mapElementGroupsResponse(elementsOutOfTC as any, newElementGroups);

        // TransCenter -- Add tab per transCenter, edit props.tabId and add children to transCenter's subTab
        let transCenterList = mappedElements.filter((e) => e.type === Element.MS_TRANSCENTER)
        let eleOfTransCenter = mappedElements.filter((e) => e.parentTcID !== null)
        let groupByTcID = groupBy(eleOfTransCenter, 'parentTcID')
        const tcGroupsGroupedById = groupBy(newTCGroups, 'tc_id');
        const tmpMappedTCGroups = tcGroups
        if (transCenterList.length > 0){
            let newMaxTabId = maxTabId
            transCenterList.forEach((transCenter: any) => {
                newMaxTabId++
                let shape = []
                if (groupByTcID[transCenter.id] !== undefined)
                    shape = groupByTcID[transCenter.id]
                saveAllControlsToDiagram({tabId: newMaxTabId, shape: shape} as Model.DiagramModel, true)
                transCenter.properties.tabId = newMaxTabId

                if (shape.length > 0 && tcGroupsGroupedById[transCenter.id]) {
                    tmpMappedTCGroups[transCenter.id] = mapElementGroupsResponse(shape, tcGroupsGroupedById[transCenter.id])
                } else {
                    tmpMappedTCGroups[transCenter.id] = {byId:{}, byElId:{}}
                }
            })
            setMaxTabId(newMaxTabId)
        }

        const newShape = [...diagramData[0].shape, ...elementsOutOfTC]
        saveAllControlsToDiagram(
            {
                tabId: currentTabId, 
                shape: newShape,
            } as Model.DiagramModel, 
            false
        )

        if(undoCut){
            undoData.dataUndo.chartData && addDataUndoToChart(undoData.dataUndo.chartData)
        }

        let tmpElementGroups = {
            byId: {
                ...elementGroups.byId,
                ...newMappedElementGroups.byId
            },
            byEleId: {
                ...elementGroups.byEleId,
                ...newMappedElementGroups.byEleId
            }
        }
        saveElementGroups(tmpElementGroups)
        saveTCGroups(tmpMappedTCGroups)
        saveOpenProjectData(mappedProject)

        setLoadingFlag(false)
    }
    
    const handlePlacementGroupListError = (error: any) => {
        setLoadingFlag(false)
        console.log('>>> handlePlacementGroupListError' + error)
    }
    //#endregion HANDLE GROUP ELEMENTS

    const handleSuccessUpdateQuickProperties = async (data:any) => {
        dispatch({type: "APP_LOADED",} as AppLoadedAction);
        if(data && data.resultCode == 0 && data.data.element_ids){
            setLoadingFlag(true)
            setUndoData({ type: "UPDATE_QUICK_PROPERTIES",dataUndo:{}} as Model.UndoModel)
            const result = await getElements(userId, projectData.projectId.toString(), data.data.element_ids,ownerProject)
            if (result.success) {
                handleSuccessUpdateQuickPropertiesSub(result.data)
            } else {
                handleGetElementsError(result.success, result.data, result.error)
            }
            setLoadingFlag(false)
        }
        
        if(data && data.resultCode == 0 && data.data.project_data){
            const project = data.data.project_data
            const mappedProject = mapProjectDataFromResponse(project);
            saveOpenProjectData(mappedProject);
            changeProcessMode(mappedProject.opeMode);
        }
    }

    const handleSuccessUpdateQuickPropertiesSub = (elementsData: any) => {
        const mappedElements = mapElementsResponseToLocal(elementsData, diagramDataList);
        // TransCenter -- Add tab per transCenter, edit props.tabId and add children to transCenter's subTab
        let transCenterList = mappedElements.filter((e) => e.type === Element.MS_TRANSCENTER)
        let eleOfTransCenter = mappedElements.filter((e) => e.parentTcID !== null)
        let groupByTcID = groupBy(eleOfTransCenter, 'parentTcID')
        if (transCenterList.length > 0){
            transCenterList.forEach((transCenter: any) => {
                const tabId = diagramDataList.find((d:any) => d.tabId === 1)?.shape.find((e:any) => e.id === transCenter.id)?.properties.tabId || 0
                let shape = []
                if (groupByTcID[transCenter.id] !== undefined)
                    shape = groupByTcID[transCenter.id]
                saveAllControlsToDiagram({ tabId, shape } as Model.DiagramModel, false)
                transCenter.properties.tabId = tabId
            })
        }
        // remove eleOfTransCenter in mappedElements
        const mainDiagramShape = mappedElements.filter((e) => !eleOfTransCenter.map(e2 => e2.id).includes(e.id))
        saveAllControlsToDiagram(
            {
                tabId: 1, 
                shape: mainDiagramShape,
            } as Model.DiagramModel, 
            false
        )

        setTimeout(()=>{
            setDiagramDataUpdate()
        },1200)
    }

    const handleErrorUpdateQuickProperties = (data:any,error:any) => {
        dispatch({
            type: "APP_LOADED",
          } as AppLoadedAction);
    }

    const handleCreateGraphSuccess = (data: any) =>{
        if(data && data.resultCode == 0){
            const new_graph_data = data.graph_current;
            const new_list_graph = [...listGraph,{
                approver:new_graph_data.approver,
                checker:new_graph_data.checker,
                colorInFrame:new_graph_data.color_in_frame,
                comments:new_graph_data.comments,
                dateApproved:new_graph_data.date_approved,
                dateChecked:new_graph_data.date_checked,
                dateDesigned:new_graph_data.date_designed,
                dateDrawn:new_graph_data.date_drawn,
                designer:new_graph_data.designer,
                dispBand:new_graph_data.disp_band,
                drawner:new_graph_data.drawner,
                figNumber:new_graph_data.fig_number,
                figSubTitle:new_graph_data.fig_sub_title,
                figTitle:new_graph_data.fig_title,
                graphKind:new_graph_data.graph_kind,
                graphName:new_graph_data.graph_name,
                graphNo:new_graph_data.graph_no,
                kindInFrame:new_graph_data.kind_in_frame,
                marginBottom:new_graph_data.margin_bottom,
                marginLeft:new_graph_data.margin_left,
                marginRight:new_graph_data.margin_right,
                marginTop:new_graph_data.margin_top,
                nominalVolt:new_graph_data.nominal_volt,
                normalXMax:new_graph_data.normal_x_max,
                normalXMin:new_graph_data.normal_x_min,
                normalYMax:new_graph_data.normal_y_max,
                normalYMin:new_graph_data.normal_y_min,
                orientation:new_graph_data.orientation,
                ownerId:new_graph_data.owner_id,
                printDetailHead:new_graph_data.print_detail_head_view2,
                projectId:new_graph_data.project_id,
                reference:new_graph_data.reference,
                revision1:new_graph_data.revision1,
                revision2:new_graph_data.revision2,
                revision3:new_graph_data.revision3,
                revision4:new_graph_data.revision4,
                revision5:new_graph_data.revision5,
                society:new_graph_data.society,
                societyXMax:new_graph_data.society_x_max,
                societyXMin:new_graph_data.society_x_min,
                societyYMax:new_graph_data.society_y_max,
                societyYMin:new_graph_data.society_y_min,
                thickInFrame:new_graph_data.thick_in_frame,
                titleApproved:new_graph_data.title_approved,
                titleChecked:new_graph_data.title_checked,
                titleComment:new_graph_data.title_comment,
                titleDateApproved:new_graph_data.title_date_approved,
                titleDateChecked:new_graph_data.title_date_checked,
                titleDateDesigned:new_graph_data.title_date_designed,
                titleDateDrawn:new_graph_data.title_date_drawn,
                titleDesigned:new_graph_data.title_designed,
                titleDrawn:new_graph_data.title_drawn,
                titleMode:new_graph_data.title_mode,
                titleNumber:new_graph_data.title_number,
                titleReference:new_graph_data.title_reference,
                zoom:new_graph_data.zoom,
            }]
            saveListGraphToStore(new_list_graph);
            const new_list = [...listGraphName.list,{graph_no:data.new_graph_no,graph_name:`保護協調図${data.new_graph_no}`}];
            saveListGraphNameToStore(projectData.projectId,new_list);
        }
        if (isOpenChart.payload.lineType === Model.LineType.CONTROL_CURVE) {
            //let tabidMax = chartDataList.length > 0 ? chartDataList[chartDataList.length - 1].tabId + 1 : 1;
            let tabidMax = Number(maxIDChartTab) + 1;
            updateIdTabChart(tabidMax);
            saveMaxIdChartTab(tabidMax, true);
        } 
        else if (isOpenChart.payload.lineType === Model.LineType.USER_CURVE){
            if (chartDataList.length === 0) {
                updateIdTabChart(1);
                saveMaxIdChartTab(1, true);
            }
            let tabidMax = Number(maxIDChartTab) + 1;
            updateIdTabChart(tabidMax);
            saveMaxIdChartTab(tabidMax, true);
        }
        const toDrawShapes = shapes.filter((item: Model.ControlModel) => item.isSelected === true);       
        setChartData([...toDrawShapes]);
        setDrawLineInfo({
            lineType: isOpenChart.payload.lineType,
            lineId: isOpenChart.payload.lineId,
            state:true,
        })
        setIsReOpenProtectionDiagram(false)
        setShowProtectionDiagram(true);
        isShowScreen(Constant.CONTROL_GRAPH_SCREEN)
    }

    const handleCreateGraphError = (error: any) =>{}

    const handleUpdateTCSuccess = (data: any) => {
        if (data.data.tc_new_grid_size){
            let newGridSize = data.data.tc_new_grid_size
            if (newGridSize.movement !== 0){
                let mainDiagramData = diagramDataList.find((e: any) => e.tabId === 1)
                let tcTabId = mainDiagramData.shape.find(
                    (e: any) => e.id == newGridSize.trans_center_id
                ).properties.tabId
                let tcDiagramList = diagramDataList.find((e: any) => e.tabId === tcTabId).shape
                tcDiagramList.forEach((e: any) => {
                    e.x += newGridSize.movement*Constant.POINTER_WIDTH
                })
                saveAllControlsToDiagram(
                    {
                        tabId: tcTabId, 
                        shape: tcDiagramList,
                    } as Model.DiagramModel, 
                    true
                )
            }
        }
        setLoadingFlag(false);
    }
    const handleUpdateTCError = (error: any) => {setLoadingFlag(false);}

    const handleGroup = () => {
        let controls = shapes.filter((item:any)=> item.isSelected === true);
        const toGroupEleIds = controls.map((item: any) => item.id)
        const checkedIds: string[] = [];
        
        const groups : any = []
        const elements : any = []
        toGroupEleIds.forEach((id: string) => {            
            if (checkedIds.includes(id)) return;
            const peekGroupId = groupsData.byEleId[id as any]?.at(-1) ?? -1
            if (peekGroupId !== -1) {
                const elementIds = groupsData.byId[peekGroupId]?.elementIds ?? []
                checkedIds.push(...elementIds);
                groups.push(peekGroupId)
            } else {
                checkedIds.push(id);
                elements.push(id);
            }
        })
        if (toGroupEleIds.length > 0) {
            if (currentTabId === 1) {
                const param = {
                    userId: userId,
                    projectId: projectData.projectId,
                    elements,
                    groups,
                    ownerProject: ownerProject
                }
                createGroup(param, `create-group`)
            } else {
                const mainDiagramData = diagramDataList.find((e: any) => e.tabId === 1);
                const transCenter = mainDiagramData?.shape.find(
                (e: any) => e.properties?.tabId === currentTabId
                );
                const paramTc = {
                    userId: userId,
                    projectId: projectData.projectId,
                    elements,
                    groups,
                    tcId: transCenter?.id,
                    ownerProject: ownerProject
                }
                createGroup(paramTc, `create-tcgroup`)
            }
        }
    };

    const handleUnGroup = () => {
        const selected = shapes.filter((shape: any) => shape.isSelected === true)    
        const toDeleteGroupIds : any = []
        const checkedIds : any = []
        selected.forEach((control: any) => {
            if (checkedIds.includes(control.id)) return;
            const peekGroupId = groupsData.byEleId[control.id as any]?.at(-1) ?? -1
            if (peekGroupId !== -1) {
                const elementIds = groupsData.byId[peekGroupId]?.elementIds ?? []
                checkedIds.push(...elementIds)
                toDeleteGroupIds.push(peekGroupId)
            } else {
                checkedIds.push(control.id)
            }
        })
    
        if (currentTabId === 1) {
            const param = {
                userId: userId,
                projectId: projectData.projectId,
                groupIds: toDeleteGroupIds,
                ownerProject: ownerProject
            }
            deleteGroup(param, `delete-group`);
        } else {
            const mainDiagramData = diagramDataList.find((e: any) => e.tabId === 1);
            const transCenter = mainDiagramData?.shape.find((e: any) => e.properties?.tabId === currentTabId);
            const param = {
                userId: userId,
                projectId: projectData.projectId,
                groupIds: toDeleteGroupIds,
                tcId: transCenter?.id,
                ownerProject: ownerProject
            }
            deleteGroup(param, `delete-tcgroup`);
        }
    };

    // #region Method for UNDO


    // handle API receive
    // SUCCESS SAVED TO DATABASE AND MAP TO FRONTEND
    const handleSuccessUpdatePropertiesUndo = async (data:any) => {
        if(data && data.resultCode == 0){
            let updateRes = 0
            if(data.data.element_ids.length > 1)
            {
                updateRes = 1
            }

            // MAP ELEMENTS TO FRONTEND
            const result = await getElements(userId, projectData.projectId, data.data.element_ids,ownerProject)
            if (result.success) {
                const elements = result.data;
                updateRes == 1 ? handleSuccessUndoUpdateProperties(elements) : handleSuccessUndo(elements);
            } else {
                handleGetElementsError(result.success, result.data, result.error)
            }
        }
        setLoadingFlag(false)
        setUndoData({type:null,dataUndo:null} as Model.UndoModel)
    }

    // ERROR SAVED TO DATABASE
    const handleErrorUpdatePropertiesUndo = (data:any,error:any) => {
        setLoadingFlag(false);
    }

    const handleSuccessCreateElementUndo = (data:any) => {
        setLoadingFlag(false);
        if(data && data.resultCode == 0){
            saveAllControlsToDiagram(
                {
                    tabId: currentTabId, 
                    shape: [...diagramData.filter((item:any) => item.tabId === currentTabId)[0].shape].filter((item:Model.ControlModel) => item.id != data.data_undo.element[0].elementId),
                } as Model.DiagramModel, 
                false
            )
            setUndoData({type:null,dataUndo:null} as Model.UndoModel)
            if(data.data_undo.element[0].elementType == getElementKindValue(Element.MS_TRANSCENTER)){
                // SET INTO POINT
                let typeNewId:any;
                const elementLabel:any = Element.MS_INTO_POINT;
                const elementNoKey = getElementNoKey(elementLabel);
                if (projectData[elementNoKey] > 0)
                        typeNewId = projectData[elementNoKey] - 1
                saveTypesMaxId(typeNewId!, elementNoKey)
                setMaxId(maxId-1);
            }
            // SET ELEMENT NO
            let typeNewId:any;
            if(data.data_undo.element[0].elementType){
                const elementLabel:any = getElementKindLabel(data.data_undo.element[0].elementType);
                const elementNoKey = getElementNoKey(elementLabel);
                if(elementNoKey){
                    if (projectData[elementNoKey] > 0)
                        typeNewId = projectData[elementNoKey] - 1
                    saveTypesMaxId(typeNewId!, elementNoKey)
                    setMaxId(maxId-1);
                }
            }
        }
    }

    const handleErrorCreateElementUndo = (data:any,error:any) => {
        setLoadingFlag(false);
    }

    const handleSuccessUndoModePM = (data:any) => {
        setLoadingFlag(false);
        if(data && data.resultCode == 0){
            changeModePM(data.data);
            setUndoData({ type:null,dataUndo:null} as Model.UndoModel)
        }
    }

    const handleErrorUndoModePM = (data:any,error:any) => {
        setLoadingFlag(false);
    }

    const handleSuccessUndoSetParam = async (data:any) => {
        if(data && data.resultCode == 0){
            const result = await getElements(userId, projectData.projectId.toString(), data.element_ids,ownerProject)
            if (result.success) {
                handleSuccessUndoUpdateProperties(result.data)
            } else {
                handleGetElementsError(result.success, result.data, result.error)
            }
            setLoadingFlag(false)
        }
    }

    const handleErrorUndoSetParam = (data:any,error:any) => {
        setLoadingFlag(false);
    }

    const handleSuccessUndoMode = async (data:any) => {
        if(data && data.resultCode == 0){
            const result = await getElements(userId, projectData.projectId.toString(), data.element_ids,ownerProject)
            if (result.success) {
                handleSuccessUndoUpdateProperties(result.data); //handleSuccessUndo(result.data);
                const mappedProject = mapProjectDataFromResponse(data.project_data);
                saveOpenProjectData(mappedProject);
                changeProcessMode(mappedProject.opeMode);
            } else {
                handleGetElementsError(result.success, result.data, result.error)
            }
            setLoadingFlag(false);
        }
    }

    const handleErrorUndoMode = (data:any,error:any) => {
        setLoadingFlag(false);
    }

    const handleSuccessUndoPaste = (data:any) => {
        setLoadingFlag(false);
         if(data && data.resultCode == 0){
            // shapes after undo
            const remainElement = [...diagramData.filter((item:any) => item.tabId === currentTabId)[0].shape].filter((item:Model.ControlModel) => {
                for(const element of data.data_undo.element){
                    if(element.elementId == item.id){
                        return false;
                    }
                }
                return true;
            })
            if(currentTabId == 1){
                // set elementgroup in Maindiagram
                const newElementGroups = {...elementGroups};
                for(const element of data.data_undo.element){
                    delete newElementGroups.byEleId[element.elementId];
                }
                for(const id of data.data_undo.listParrentGroupId.groups){
                    delete newElementGroups.byId[id];
                }
                saveElementGroups(newElementGroups)
            }else{
                // set elementgroup in Transcenter screen
                const newTcElementGroups = {...tcGroups};
                const allTransCenterControl = diagramDataList.find((r: any) => r.tabId === 1)?.shape.filter((item:any) => item.type === "MS_TRANSCENTER").map((item:any)=>item) || [];
                const currentTransCenterId = allTransCenterControl.find((item:any) => item.properties.tabId === currentTabId).id;
                for(const element of data.data_undo.element){
                    delete newTcElementGroups[currentTransCenterId].byEleId[element.elementId];
                }
                for(const id of data.data_undo.listParrentGroupId.tcGroups){
                    delete newTcElementGroups[currentTransCenterId].byId[id];
                }
                saveTCGroups(newTcElementGroups)
            }
            
            saveAllControlsToDiagram(
                {
                    tabId: currentTabId, 
                    shape: remainElement,
                } as Model.DiagramModel, 
                false
            )
            setUndoData({type:null,dataUndo:null} as Model.UndoModel)
        }
    }

    const handleErrorUndoPaste = (data:any,error:any) => {
        setLoadingFlag(false);
    }

    const handleSuccessUndoScaleGraph = (data:any) => {
        setLoadingFlag(false);
        if(data && data.resultCode == 0){
            setUndoData({ type: null,dataUndo: null } as Model.UndoModel)
            const mappedProject = mapProjectDataFromResponse(data.data.project);
            saveOpenProjectData(mappedProject);
        }
    }

    const handleErrorUndoScaleGraph = (data:any,error:any) => {
        setLoadingFlag(false);
    }
    // #endregion

    const handleSucessCalcVoltDrop = async (data:any) => {
        dispatch({type:"APP_LOADED"} as AppLoadedAction)
        if(data && data.resultCode == 0){
            setLoadingFlag(true);
            const result = await getElements(userId, projectData.projectId.toString(), data.element_ids,ownerProject)
            if (result.success) {
                setLoadingFlag(false);
                const mappedElements = mapElementsResponseToLocal(result.data, diagramDataList);
                let array:any = [];
                // element affected
                //  TRANSCENTER DIAGRAM OR MAIN DIAGRAM
                array = [...diagramData.filter((item:any) => item.tabId === currentTabId)[0].shape].map((item:any) => {
                    for(const element of mappedElements){
                        if(item.id == element.id){
                            return element;
                        }
                    }
                    return item;
                });
                saveAllControlsToDiagram(
                    {
                        tabId: currentTabId, 
                        shape: array,
                    } as Model.DiagramModel, 
                    false
                )
            } else {
                handleGetElementsError(result.success, result.data, result.error)
            }
        }
    }

    const handleErrorCalcVoltDrop = (data:any,error:any) => {
		setLoadingFlag(false);
    }

    const handleGetMainDiagramPDFSuccess = (data: any) => {
        if (data.data) 
        {
            downloadFile(data.data);
        }
        setLoadingFlag(false)
        setIsDrawPDF(false)
      };

    const handleGetMainDiagramPDFError = (error: any) => {
        setLoadingFlag(false)
        setIsDrawPDF(false)
      };

    const GetDispDiagramWidth = () => {
        if (document.getElementById("dispDiagram")?.offsetWidth)
        {
            return document.getElementById("dispDiagram")?.offsetWidth as number;
        }
        return 0;
    };

    const GetDispDiagramHeight = () => {
        if (document.getElementById("dispDiagram")?.offsetHeight)
        {
            let height = document.getElementById("dispDiagram")?.offsetHeight as number;
            if (currentTabId != 1) height -= Constant.TC_INFO_TABLE_HEIGHT;
            return height;
        }
        return 0;
    };
    
    // #region  HANDLE SCROLL
    // ===============================================
    // React Konvaのパフォーマンス向上の対応
    // 1.Stageは小さくする
    // 2.Shapeは必要なときだけ描画する(機器・Dots)
    // ===============================================

    // dispDiagramの参照
    const dispDiagramRef:any = useRef(null);
    // 描画する機器
    const [drawShapes, setDrawShapes] = useState<any>([]);
    // 表示領域 [minX, minY, maxX, maxY]
    // ※表示する機器の取りこぼしがないように実際のウィンドウサイズより広く設定
    const [drawArea, setDrawArea] = useState([0, 0, 0, 0]);

    const [drawShapesPDF, setDrawShapesPDF] = useState<any>();
    const [drawAreaPDF, setDrawAreaForPDF] = useState([0, 0, 0, 0]);
    const [drawAreaPDFStore, setDrawAreaForPDFStore] = useState([0, 0, 0, 0]);
    const [countLoop, setCountLoop] = useState<any>({loop: 0, x: 0, y: 0});
    const [downloadInfo, setDownloadInfo] = useState<any>({scalePDF: 1, isGetImage: false, originDrawWidth: 1000, originDrawHeight: 1000, drawWidth: 1000, drawHeight: 1000, countX: 0, countY: 0, minX: 0, minY: 0, maxX: 0, maxY: 0});
    const [isHandleDownloadPDF, setIsHandleDownloadPDF] = useState<any>(false);
    const [imagePDF, setImagePDF] = useState<any>([]);
    const [originSizeForGetShapes, setOriginSizeForGetShapes] = useState<any>({minX: 0, minY: 0, maxX: 0, maxY: 0});

    // 表示する機器をフィルター
    const filteringDrawShapes = (minX: number, minY: number, maxX: number, maxY: number) => {
        const targetTexts = shapes.filter((item: Model.ControlModel) =>
            item.type == Element.MS_TEXT
            && minX < item.x
            && maxX > item.x
            && minY < item.y
            && maxY > item.y
        ).map((item: any) => item) || [];

        const targetShapes = shapes.filter((item: Model.ControlModel) =>
            item.type != Element.MS_TEXT
            && minX < (item.x + item.width + item.height)
            && maxX > (item.x - item.width - item.height)
            && minY < (item.y + item.width + item.height)
            && maxY > (item.y - item.width - item.height)
        ).map((item: any) => item) || [];

        setDrawShapes([...targetTexts, ...targetShapes]);
    };

    const filteringDrawShapesForPDF = (minX: number, minY: number, maxX: number, maxY: number) => {
        const targetTexts = shapes.filter((item: Model.ControlModel) =>
            item.type == Element.MS_TEXT
            && minX < item.x
            && maxX > item.x
            && minY < item.y
            && maxY > item.y
        ).map((item: any) => item) || [];

        const targetShapes = shapes.filter((item: Model.ControlModel) =>
            item.type != Element.MS_TEXT
            && minX < (item.x + item.width + item.height)
            && maxX > (item.x - item.width - item.height)
            && minY < (item.y + item.width + item.height)
            && maxY > (item.y - item.width - item.height)
        ).map((item: any) => item) || [];

        setDrawShapesPDF([...targetTexts, ...targetShapes]);
    };

    let scrollTimerPDF: any = null;

    useEffect(() => {
        clearTimeout(scrollTimerPDF);
        scrollTimerPDF = setTimeout(() => {
            if(isHandleDownloadPDF)
                {
                    if(countLoop.x < downloadInfo.countX)
                    {
                        setImagePDF([...imagePDF,stageRefPDF.current!.toCanvas()])
                        let xMin = 0, yMin = 0, xMax = 0, yMax = 0
                        let originXMin = 0, originYMin = 0, originXMax = 0, originYMax = 0
                        if(countLoop.y < downloadInfo.countY - 1)
                        {
                            xMin = drawAreaPDFStore[0]
                            xMax = drawAreaPDFStore[2]
                            yMin = drawAreaPDFStore[3]
                            yMax = drawAreaPDFStore[3] + downloadInfo.drawHeight > downloadInfo.maxY + downloadInfo.minY ? (downloadInfo.maxY + downloadInfo.minY) : (drawAreaPDFStore[3] + downloadInfo.drawHeight)

                            originXMin = drawAreaPDF[0]
                            originXMax = drawAreaPDF[2]
                            originYMin = drawAreaPDF[3]
                            originYMax = drawAreaPDF[3] + downloadInfo.originDrawHeight > originSizeForGetShapes.maxY + originSizeForGetShapes.minY ? (originSizeForGetShapes.maxY + originSizeForGetShapes.minY) : (drawAreaPDF[3] + downloadInfo.originDrawHeight)
                            
                            transformStageRefPDF(xMin,yMin)
                            setDrawAreaForPDF([originXMin,originYMin,originXMax,originYMax])
                            setDrawAreaForPDFStore([xMin,yMin,xMax,yMax])
                          
                            setCountLoop({loop: countLoop.loop + 1, x:  countLoop.x, y:  countLoop.y + 1})
                            setStageHeightPDF(downloadInfo.maxY - yMin > 0 && downloadInfo.maxY - yMin < downloadInfo.drawHeight ? downloadInfo.maxY - yMin + (scaleGridPDF > 1 && !transCenterSelected ? 20 * scaleGridPDF : 0): downloadInfo.drawHeight + (scaleGridPDF > 1 && !transCenterSelected ? 20 * scaleGridPDF : 0))
                        }
                        else
                        {
                            xMin = drawAreaPDFStore[2]
                            xMax = drawAreaPDFStore[2] + downloadInfo.drawWidth > downloadInfo.maxX + downloadInfo.minX? downloadInfo.maxX + downloadInfo.minX : (drawAreaPDFStore[2] + downloadInfo.drawWidth)
                            yMin = downloadInfo.minY
                            yMax = downloadInfo.drawHeight + downloadInfo.minY

                            originXMin = drawAreaPDF[2]
                            originXMax = drawAreaPDF[2] + downloadInfo.originDrawWidth > originSizeForGetShapes.maxX + originSizeForGetShapes.minX ? originSizeForGetShapes.maxX + originSizeForGetShapes.minX: (drawAreaPDF[2] + downloadInfo.originDrawWidth)
                            originYMin = originSizeForGetShapes.minY
                            originYMax = downloadInfo.originDrawHeight + originSizeForGetShapes.minY
                          
                            transformStageRefPDF(xMin,yMin)
                            setDrawAreaForPDF([originXMin,originYMin,originXMax,originYMax])
                            setDrawAreaForPDFStore([xMin,yMin,xMax,yMax])
                   
                            setCountLoop({loop: countLoop.loop + 1, x:  countLoop.x + 1, y: 0})
                            setStageWidthPDF( downloadInfo.maxX < downloadInfo.drawWidth? downloadInfo.maxX : downloadInfo.drawWidth)
                            setStageHeightPDF(downloadInfo.maxY < downloadInfo.drawHeight ? downloadInfo.maxY + (scaleGridPDF > 1 && !transCenterSelected ? 20 * scaleGridPDF : 0): downloadInfo.drawHeight + (scaleGridPDF > 1 && !transCenterSelected ? 20 * scaleGridPDF : 0))
                        }
                    }
                    else
                    {
                        setCountLoop({loop: 0, x: 0, y: 0})
                        setIsHandleDownloadPDF(false)
                        let resultCanvas:any
                        let resultCtx:any
                        if(imagePDF.length > 1)
                        {
                            resultCanvas = document.createElement('canvas')
                            resultCtx = resultCanvas.getContext('2d')
                            resultCanvas.width = downloadInfo.maxX
                            resultCanvas.height = downloadInfo.maxY
                        
                            let x = 0, y = 0, width = 0, height = 0, calcHeight = 0
                            for(let i = 0; i < downloadInfo.countX * downloadInfo.countY; i++)
                            {
                                if(y < downloadInfo.countY - 1)
                                {
                                    resultCtx?.drawImage(imagePDF[i], width, height)
                                    y += 1
                                    height += imagePDF[i].height
                                }
                                else
                                {
                                    resultCtx?.drawImage(imagePDF[i], width, height)
                                    x += 1
                                    y = 0
                                    width += imagePDF[i].width
                                    height = 0
                                }
                            }
                            for(let i = 0; i < downloadInfo.countY; i++)
                            {
                                calcHeight += imagePDF[i].height
                            }
                            if(!transCenterSelected)
                            {
                                resultCtx!.fillStyle= "white";
                                resultCtx!.fillRect(0, calcHeight - 2, downloadInfo.maxX, calcHeight + 2);
                            }
                        }

                        setLoadingFlag(false)
                        // const link = document.createElement('a');
                        // link.download = 'lineDiagramFilePDF.png';
                        // link.href = resultCanvas ? resultCanvas.toDataURL() : imagePDF[0].toDataURL() 
                        // link.click();
                        if(!downloadInfo.isGetImage)
                        {
                            let nameTransCenter = ''
                            if(currentTabId != 1 && transCenterSelected)
                            {
                                const transCenter = diagramDataList.find((e: any) => e.tabId === 1).shape.find(
                                    (e: any) => e.properties?.tabId === currentTabId
                                );
                                transCenter && (nameTransCenter = 'スマ托ソスF' + transCenter.properties.refNo)
                            }
                        
                            const params = {
                                userId: userId,
                                projectId: Number(projectData.projectId),
                                data: resultCanvas? resultCanvas.toDataURL() : imagePDF[0].toDataURL(),
                                reportIndex : 2,
                                name: nameTransCenter,
                                ownerProject: ownerProject
                            };
                            setLoadingFlag(true)
                            getPDFReport(params);
                            stageRefPDF.current?.scale({x: scaleGrid, y: scaleGrid});
                            setScaleGridPDF(scaleGrid)
                        }
                        else
                        {
                            if(resultCtx != undefined && resultCanvas != undefined)
                            {
                                resultCtx!.lineWidth = 6
                                resultCtx!.strokeRect(0, 0, downloadInfo.maxX, downloadInfo.maxY);
                                stageRefPDF.current?.scale({x: scaleGrid, y: scaleGrid});
                                setScaleGridPDF(scaleGrid)
                                setDownloadDiagramPDF(true, 2, {data: resultCanvas.toDataURL(), width: resultCanvas.width, height: resultCanvas.height})
                            }
                            else
                            {
                                const ctx = imagePDF[0].getContext('2d')
                                ctx!.lineWidth = Math.round(6 * (5 / downloadInfo.scalePDF))
                                ctx!.strokeRect(0, 0, downloadInfo.maxX, downloadInfo.maxY);
                                stageRefPDF.current?.scale({x: scaleGrid, y: scaleGrid});
                                setScaleGridPDF(scaleGrid)
                                setDownloadDiagramPDF(true, 2, {data: imagePDF[0].toDataURL(), width: imagePDF[0].width, height: imagePDF[0].height})
                            }
                        }

                        stageRefPDF.current?.scale({x: 1, y: 1});
                        setScaleGridPDF(1)
                        setDownloadInfo({scalePDF: 1, isGetImage: false, originDrawWidth: 1000, originDrawHeight: 1000, drawWidth: 1000, drawHeight: 1000, countX: 0, countY: 0, minY: 0, maxX: 0, maxY: 0})
                        transformStageRefPDF(0,0)
                        setDrawAreaForPDF(drawArea)
                        setDrawAreaForPDFStore(drawArea)
                        setOriginSizeForGetShapes({minX: 0, minY: 0, maxX: 0, maxY: 0})
                        setImagePDF([])
                        setStageWidthPDF(1000)
                        setStageHeightPDF(1000)
                       
                    }
                } 
        }, 400);
        
    }, [drawShapesPDF]);

    useEffect(() => {
        filteringDrawShapes(drawArea[0], drawArea[1], drawArea[2], drawArea[3]);
    }, [shapes, drawArea]);

    useEffect(() => {
        filteringDrawShapesForPDF(drawAreaPDF[0], drawAreaPDF[1], drawAreaPDF[2], drawAreaPDF[3]);
    }, [drawAreaPDF, shapes]);

    // Stageの表示位置を移動
    const repositionStage = () => {
        if (dispDiagramRef.current) {
            const div = dispDiagramRef.current as HTMLDivElement;
            const dx = div.scrollLeft;
            let dy = div.scrollTop;

            // Stageの表示領域の移動
            // --------------------------
            const stage = stageRef.current;
            if (stage) {
                // １．スクロール量だけStageを+x,+y方向に移動し、画面中央に維持する
                if (currentIDChartTab != 1){
                    dy = Math.max(dy - Constant.TC_INFO_TABLE_HEIGHT, 0);
                }
                stage.container().style.transform = 'translate(' + dx + 'px, ' + dy + 'px)';
                transformStageRefPDF(dx, dy);
                // ２．Stageのコンテンツを-x,-y方向に移動し、Stage内で疑似的にスクロールする
                stage.x(-dx);
                stage.y(-dy);
            }

            // Div 幅、高さ
            const clientWidth = div.clientWidth;
            let clientHeight = div.clientHeight;
            if (currentIDChartTab != 1){
                clientHeight += Constant.TC_INFO_TABLE_HEIGHT;
            }
            // Div 表示範囲
            const margin = 100; // ※機器の取りこぼしがないように、実際よりも広めの範囲を設定する
            let minX = dx < margin ? 0 : dx - margin;
            let minY = dy < margin ? 0 : dy - margin;
            let maxX = minX + clientWidth + margin;
            let maxY = minY + clientHeight + margin;
            minX /= scaleGrid;
            minY /= scaleGrid;
            maxX /= scaleGrid;
            maxY /= scaleGrid;

            // 表示領域の更新
            setDrawArea([minX, minY, maxX, maxY]);
            setDrawAreaForPDF([minX, minY, maxX, maxY]);
            setDrawAreaForPDFStore([minX, minY, maxX, maxY]);
        }
    };

    useEffect(() => {
        repositionStage();

        // ウィンドウサイズが変更されたときにイベントリスナーを追加
        window.addEventListener('resize', repositionStage);
    
        // コンポーネントがアンマウントされるときにイベントリスナーを削除
        return () => {
          window.removeEventListener('resize', repositionStage);
        };
    }, []);

    const [scrolling, setScrolling] = useState(false);
    let scrollTimer: any = null;

    // スクロールイベントハンドル
    const handleScroll = () => {
        if (!scrolling) {
            setScrolling(true);
            setIsRolled(true)
        } else {
            // スクロールの停止までは処理しない
            return;
        }

        clearTimeout(scrollTimer);
        scrollTimer = setTimeout(() => {
            // スクロールが停止したときにStageを移動する
            setScrolling(false);
            repositionStage();
            
            // const selectedITem = diagramData[0].shape.filter((item: any)=> item.isSelected === true).map((i: any)=> i.id)
            // const item = stageRef.current?.find((node: any) => Constant.TYPE_LIST.includes(node.attrs.type)).filter((it: any)=> selectedITem.includes(it.attrs.id))
            // transformRef.current?.nodes(item as any)
        }, 300); // スクロールが停止したとみなすための遅延時間
    };

    const scrollToControl = (controlId: any) => {
        if (dispDiagramRef.current) {
            let control: any = shapes.find((e: Model.ControlModel) => e.id == controlId);
            if (control){
                const div = dispDiagramRef.current as HTMLDivElement;
                div.scrollTo((control.x * scaleGrid) - window.innerWidth/2, (control.y * scaleGrid) - window.innerHeight/2)
            }
        }
    }
    // #endregion HANDLE SCROLL

    // #region HANDLE PDF OUTPUT
    const transformStageRefPDF = (dx: number, dy: number) => {
        if (stageRefPDF?.current) {
            stageRefPDF.current.container().style.transform = 'translate(' + dx + 'px, ' + dy + 'px)';
            stageRefPDF.current.x(-dx);
            stageRefPDF.current.y(-dy);
        }
    }
    
    // #endregion HANDLE PDF OUTPUT
    const handleCloseLeftSideBar = () =>{
            setIsOpenLeftSideBar(false)
    }
    const handleOpenLeftSideBar= () =>{
        setIsOpenLeftSideBar(true)
    }
    const handleCloseRightSideBar = () =>{
        setIsOpenRightSideBar(false)
    }
    const handleOpenRightSideBar = () =>{
        setIsOpenRightSideBar(true)
    }
	return (
        <>
            {(loadingFlag || appLoading || elementLoadingFlag) && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            
            {/* <PreviewDialog refIframe={refHelpPreview} openDialog={openHelpPreview} setOpenDialog={setOpenHelpPreview}/> */}

            <AddFileDXFDiaglog
                defaultFilename={DXFFilename}
                openDialog={openAddFileDXFDialog}
                setOpenDialog={setOpenAddFileDXFDialog}
                onOK={handleAddFileDXF}
            />

            <InfomationDialog openDialog={openInfomationDialog} setOpenDialog={setOpenInfomationDialog}/>

            {openDeleteDialog ?
                <AlertDialog title={"MSSV3"} message={"選択されている機器を削除しますか?"} isOpen={true} onOK={handleDeleteOK} onCancel={handleDeleteCancel} /> :
                <div />
            }

            {openReNumberSkeletonConfirmDialog ?
                <AlertDialog title={"MSSV3"} message={"デバイス名の再採番を実行しますか?"} isOpen={true} onOK={handleRenumberSkeleton} onCancel={() => setOpenReNumberSkeletonConfirmDialog(false)} /> :
                <div />
            }
            {
                openReNumberPointTextConfirmDialog ?
                <AlertDialog title={"MSSV3"} message={"事故点名称の再採番を実行しますか?"} isOpen={true} onOK={handleRenumberPointText} onCancel={() => setOpenReNumberPointTextConfirmDialog(false)} /> :
                <div />
            }
            {openOverlapDialog &&
                <AlertDialog title={"MSSV3"} message={"すでに配置されている機器の上に、 別の機器をドロップしました。機器同士が重なっています"} isOpen={true} onOK={() => setOpenOverlapDialog(false)} /> 
            }
            {/*
            {openSaveAsForm &&
                <AddNewProjectDialog data={{projectName: `${projectData.projectName}_new`}} title={`プロジェクト別名保存`} onOK={handleSaveAs} onCancel={() => setOpenSaveAsForm(false)} />
            }
            */}
            {openCircuitForm &&
                <SettingCircuitDialog modeViewOnly={modeViewOnly}  data={infoCircuit} onOK={handleOKInfoCircuitForm} onCancel={() => setOpenCircuitForm(false)} />
            }
            {openSkeletonForm &&
                <SettingSkeletonDialog infoCircuit={infoCircuit} data={infoSkeleton} onOK={handleOKSkeletonFrom} onCancel={() => setOpenSkeletonForm(false)}  />
            }
            {openCoverForm &&
                <SettingCover2Dialog modeViewOnly={modeViewOnly} title={''} infoCircuit={infoCircuit} data={infoCover} onOK={handleOKCoverFrom} onCancel={() => setOpenCoverForm(false)}  />

            }
            {openReportForm &&
                <SettingReportDialog modeViewOnly={modeViewOnly} title={''} infoSkeleton={infoSkeleton} infoCircuit={infoCircuit} data={report} onOK={handleOKReportFrom} onCancel={() => setOpenReportForm(false)}  />
            }

            {openSettingTitleGraphDialog &&
                <SettingDiagramDialog data={initialValueGraph} onOK={handleOKSettingProtectionDialog} onCancel={() => setOpenSettingTitleGraphDialog(false)} />
            }

            {openElementListForm &&
                <ShowElementListDialog scrollToControl={scrollToControl} elementGroups={elementGroups} tcGroups={tcGroups} transCenterSelected={transCenterSelected} onCancel={() => setOpenElementListForm(false)} onSelectGroup={(data:any)=> handleCheckGroup(data, true, false)} setLoadingFlag={setLoadingFlag}/>
            }

            {openPointListForm &&
                <ShowPointListDialog userId={userId} onCancel={() => setOpenPointListForm(false)} scrollToControl={scrollToControl} elementGroups={elementGroups} tcGroups={tcGroups} transCenterSelected={transCenterSelected} onSelectGroup={(data:any)=> handleCheckGroup(data, true, false)} setLoadingFlag={setLoadingFlag}/>
            }

            {
                openWarningDialog &&
                <WarningDialog title="MSSV3" message={messageWarning} isOpen={true} onOK={() => setOpenWarningDialog(false)} />
            }
            {
                openHistoryDialog &&
                < HistoryProjectDialog userId={userId} projectId={projectId} onCancel={() => setOpenHistoryDialog(false)} />
            }

            <div  style={{ width: '100%', height: '100%', display: 'inline-flex'}}>
                <div style={{ height: '100%' }}>
                    <div style={{ width: !showProtectionDiagram ? '100vw' : '60vw' }}>
                        <div>
                            <DashboardMainTopNavbar
                                userId={userId}
                                modeHome={true}
                                ownerProject ={ownerProject}
                                showGraphScreen={showGraphScreen}
                                showReportSceen={showReportScreen}
                                showMaintenanceScreen={showMaintenanceScreen}
                                showHistoryDialog={openHistoryDialog}
                                setShowReportSceen={setShowReportScreen}
                                handleMenuOptions={handleMenuOptions}
                                handleDisplayGraph={handleDisplayGraph}
                                handleDisplayDiagram={handleDisplayDiagram}
                                handleCheckCopyData={handleCheckCopyData}
                                handleOpenHistoryDialog={() => setOpenHistoryDialog(true)}
                            />
                        </div>
                    </div>
                    {showGraphScreen &&
                    <>
                        <div style={{ width: '100vw', minWidth:'500px', display: 'flex', flexDirection: 'column' }}>
                            <DashboardSecondaryTopNavbar userId={userId} isOpenGraphScreen={true} modeViewOnly={modeViewOnly} onClose={()=>{}}/>
                            <div className="graph-screen" style={{ flex: 1, display: 'flex', flexDirection: 'column' , maxWidth: '100%', aspectRatio: '10/9.5', alignSelf: 'center', minWidth:"500px"}}>
                                <CreateGraph
                                    listObjectText={listTextGraph}
                                    setListObjectText={setListTextGraph}
                                    undoGraphData={undoGraphData}
                                    shapes={chartData}
                                    drawLineInfo={drawLineInfo}
                                    addNewGraph={isAddNewGraph}
                                    isReOpenGraphTemporary={false}
                                    isOpenGraphPage={true}
                                    undoCut={undoCut}
                                    isOpenGraphTemporary={false}
                                    onFinishDrawLineInfo={(data:boolean)=>{
                                        setDrawLineInfo({...drawLineInfo,state:data})
                                    }}
                                    onDrawLines={(items: any[], userCurve: boolean)=>{ 
                                        if(userCurve === false)
                                        {
                                            let toDrawShapes:any
                                            if(shapes.find((x:any)=>x.type === Element.MS_TRANSCENTER) !== undefined)
                                            {
                                                toDrawShapes = []
                                                items.forEach((x:any)=>{
                                                    diagramDataList.map((diagram:any)=>{
                                                        if(diagram.shape.find((data:any)=>x.elementId === Number(data.id) && x.userCurveId === 0))
                                                        {
                                                            let item = diagram.shape.find((data:any)=>x.elementId === Number(data.id) && x.userCurveId === 0)
                                                            if(!toDrawShapes.includes(item))
                                                            {
                                                                toDrawShapes.push(item)
                                                            }
                                                        }
                                                    })
                                                })
                                            }
                                            else
                                            {
                                                toDrawShapes = shapes.filter((item: Model.ControlModel) => items.find(x=>x.elementId === Number(item.id) && x.userCurveId === 0));   
                                            }
                                            createChartTab(currentIDChartTab)
                                            setDrawLineInfo({
                                                lineType: Model.LineType.CONTROL_CURVE,
                                                lineId: 0,
                                                state: true
                                            })
                                            setChartData([...toDrawShapes]);
                                        } 
                                        else 
                                        {
                                            let toDrawShapes
                                            if(shapes.find((x:any)=>x.type === Element.MS_TRANSCENTER) !== undefined)
                                            {
                                                toDrawShapes = []
                                                items.forEach((x:any)=>{
                                                    diagramDataList.map((diagram:any)=>{
                                                        if(diagram.shape.find((data:any)=>x.elementId === Number(data.id)))
                                                        toDrawShapes.push(diagram.shape.find((data:any)=>x.elementId === Number(data.id)))
                                                    })
                                                })
                                            }
                                            else
                                            {
                                                toDrawShapes = shapes.filter((item: Model.ControlModel) => items.find(x=>x.elementId === Number(item.id)));   
                                            } 
                                            setDrawLineInfo({
                                                lineType: Model.LineType.USER_CURVE,
                                                lineId: items[0].userCurveId,
                                                state:true
                                            })
                                            setChartData([...toDrawShapes]);
                                        }
                                    }}
                                    onSetUndoData={(data:any)=>{
                                        setUndoGraphData(data)
                                    }}
                                />
                            </div>
                        </div>
                    </>
                    }
                    {showReportScreen ?
                        <ReportSceen ownerProject={ownerProject} diagramDataList={diagramDataList} projectData={projectData}/>
                        :
                        showMaintenanceScreen
                        ?   <MaintenanceSreen
                                dataShareProject={dataShareProject}
                                controlId={isShowMaintenanceScreen.controlId}
                                handleDisplayDiagram={handleDisplayDiagram}
                            />
                        : <>
                            <div
                                className="prevent-select"
                                style={{ 
                                    display: `${showGraphScreen? 'none' : 'flex'}`,
                                    width: !showProtectionDiagram ? '100vw' : '60vw' 
                                }}
                                ref={lineDiagramRef}
                                tabIndex={0}
                                onKeyDown={handleKeyDown}
                            >
                                {isOpenLeftSideBar? 
                                <Stack>
                                    <Stack sx={{ backgroundColor: theme.palette.secondary.light, width:'100%', alignItems:'end'}}>
                                        <IconButton sx={{width:'36px', height:'36px', marginRight:'12px', marginTop:'4px'}} onClick={handleCloseLeftSideBar}>
                                            <CloseIcon sx={{fontSize:'36px'}}/>
                                        </IconButton>
                                    </Stack>
                                    <DashboardLeftSidebar lineDiagramRef={lineDiagramRef} onCreate={handleCreate} currentTabId={currentTabId} stdCapacityList ={stdCapacityList}/>
                                </Stack>
                                :
                                <Stack sx={{position: 'absolute', left: '0', zIndex: '1', marginLeft:'8px', backgroundColor:'white', opacity:'0.8'}}>
                                    <IconButton sx={{width:'36px', }} onClick={handleOpenLeftSideBar}>
                                        <img src={menu_left_1} style={{width: '36px', height: '36px'}}/>
                                    </IconButton>
                                </Stack>
                                }
                                <div style={{ width: isOpenLeftSideBar ?'calc(100% - 208px)':'100%'}} >
                                    <div style={{ borderLeft: '1px solid #cccfcd', position: 'relative'}}>
                                        {
                                            currentTabId == 1 ?
                                                (
                                                    // scale options
                                                    isOpenRightSideBar ?
                                                        (m_bModePM || modeViewOnly)
                                                        ? 
                                                        (
                                                        <Grid spacing={0.3} sx={{
                                                            width: Constant.DASH_BOARD_RIGHT_WIDTH,
                                                            height: "110px",
                                                            position: 'absolute',
                                                            right: '17px',
                                                            backgroundColor: theme.palette.secondary.light,
                                                            zIndex: '1'
                                                        }}>
                                                            <Stack sx={{ backgroundColor: theme.palette.secondary.light, width:'100%', alignItems:'end'}}>
                                                                <IconButton sx={{width:'36px', height:'36px', margin:'4px'}} onClick={handleCloseRightSideBar}>
                                                                    <CloseIcon sx={{fontSize:'36px'}}/>
                                                                </IconButton>
                                                            </Stack>
                                                            <Grid width={'90px'} mt={0.5} sx={{ background: 'white'}} marginLeft={2}>
                                                                <FormControl fullWidth size='small'>
                                                                    <InputLabel>Zoom</InputLabel>
                                                                    <Select
                                                                        label='Zoom'
                                                                        onChange={handleScaleGrid}
                                                                        value={scaleGrid}
                                                                    >
                                                                        {scaleOptions.map(item => (
                                                                            <MenuItem key={item.value} value={item.value}>
                                                                                {item.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                        )
                                                        : 
                                                        ( 
                                                            <Grid spacing={0.3} sx={{
                                                                width:Constant.DASH_BOARD_RIGHT_WIDTH,
                                                                overflowY: 'auto',
                                                                height: 'calc(100vh - 140px)',
                                                                position: 'absolute',
                                                                right:'17px',
                                                                backgroundColor: theme.palette.secondary.light,
                                                                zIndex: '1'
                                                            }}>
                                                                <Stack sx={{backgroundColor: theme.palette.secondary.light, width:'100%', alignItems:'end'}}>
                                                                    <IconButton sx={{width:'36px', height:'36px', margin:'4px'}} onClick={handleCloseRightSideBar}>
                                                                        <CloseIcon sx={{fontSize:'36px'}}/>
                                                                    </IconButton>
                                                                </Stack>
                                                                <Grid width={'90px'} mt={0.5} sx={{ background: 'white'}} marginLeft={2}>
                                                                    <FormControl fullWidth size='small'>
                                                                        <InputLabel>Zoom</InputLabel>
                                                                        <Select
                                                                            label='Zoom'
                                                                            onChange={handleScaleGrid}
                                                                            value={scaleGrid}
                                                                        >
                                                                            {scaleOptions.map(item => (
                                                                                <MenuItem key={item.value} value={item.value}>
                                                                                    {item.label}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>
                                                                <DashboardRight 
                                                                    lineDiagramRef={lineDiagramRef}
                                                                    onUpdateQuickProperties={(field:string,value:boolean) => {onUpdateQuickProperties(field,value);}}
                                                                    onGroup={handleGroup}
                                                                    onUnGroup={handleUnGroup}
                                                                    onRotate={() => {updateRotate(currentTabId)}}
                                                                    onSetLineToChart={(value,controlId) => {setLineToChart(value,controlId)}}
                                                                    onOpenChart={() => {setOpenChart(true)}}
                                                                    onOpenUserCurve = {(controlId) =>{openUserCurveEvent(controlId)}}
                                                                    onSetCalcPointToChart = {(value,controlId) => {setCalcPointToChart(value,controlId)}}
                                                                    onSelectTouchGroup= {() => {
                                                                        setSelectTouchGroup(!isSelectTouchGroup)
                                                                        setIsOnShiftKey(!isOnShiftKey)
                                                                    }}
                                                                />
                                                            </Grid> 
                                                        )
                                                    :
                                                    <Stack sx={{position: 'absolute', right: '0', zIndex: '1', marginRight:'24px', backgroundColor:'white', opacity:'0.8'}}>
                                                        <IconButton sx={{width:'36px'}} onClick={handleOpenRightSideBar}>
                                                            <img src={menu_right_1} style={{width: '36px', height: '36px'}}/>
                                                        </IconButton>
                                                    </Stack>
                                                ) : 
                                                (
                                                    // TransCenter - close button
                                                    (m_bModePM || modeViewOnly)
                                                    ? ( <Stack
                                                            mt={1}
                                                            mr={3} 
                                                            sx={{position: 'absolute', right: '0', zIndex: '1', background: 'white'}}
                                                        >
                                                            <IconButton color='error' onClick={handleCloseTransCenter}>
                                                                <Cancel sx={{fontSize:'32px'}}/>
                                                            </IconButton>
                                                        </Stack>
                                                    )
                                                    : (  
                                                        <Stack>
                                                            {isOpenRightSideBar ?
                                                                <Grid spacing={0.3} sx={{
                                                                    width:Constant.DASH_BOARD_RIGHT_WIDTH,
                                                                    overflowY: 'auto', height: 'calc(100vh - 140px)',
                                                                    position: 'absolute',
                                                                    right:'17px',
                                                                    backgroundColor: theme.palette.secondary.light,
                                                                    zIndex: '1'
                                                                }}>
                                                                     <Grid mt={1}>
                                                                        <Stack sx={{ flexDirection: "row", justifyContent: 'space-around'}}>
                                                                            <Stack>
                                                                                <IconButton sx={{width:'36px', height:'36px'}} color='error' onClick={handleCloseTransCenter}>
                                                                                    <Cancel sx={{fontSize:'36px'}}/>
                                                                                </IconButton>
                                                                            </Stack>
                                                                            <IconButton sx={{width:'36px', height:'36px'}} onClick={handleCloseRightSideBar}>
                                                                                <CloseIcon sx={{fontSize:'36px'}}/>
                                                                            </IconButton>
                                                                        </Stack>
                                                                    </Grid>
                                                                    <DashboardRight 
                                                                        lineDiagramRef={lineDiagramRef}
                                                                        onUpdateQuickProperties={(field:string,value:boolean) => {onUpdateQuickProperties(field,value);}}
                                                                        onGroup={() => {handleGroup()}}
                                                                        onUnGroup={() => {handleUnGroup()}}
                                                                        onRotate={() => {updateRotate(currentTabId)}}
                                                                        onSetLineToChart={(value,controlId) => {setLineToChart(value,controlId)}}
                                                                        onOpenChart={() => {setOpenChart(true)}}
                                                                        onOpenUserCurve = {(controlId) =>{openUserCurveEvent(controlId)}}
                                                                        onSetCalcPointToChart = {(value,controlId) => {setCalcPointToChart(value,controlId)}}
                                                                        onSelectTouchGroup= {() => {
                                                                            setSelectTouchGroup(!isSelectTouchGroup)
                                                                            setIsOnShiftKey(!isOnShiftKey)
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                :
                                                                <Grid sx={{position: 'absolute', right: '0', zIndex: '1', marginRight:'24px'}}>
                                                                    <Stack sx={{ flexDirection: "row"}}>
                                                                        <Stack sx={{width:'36px', marginRight:'8px'}}>
                                                                            <IconButton color='error' onClick={handleCloseTransCenter} >
                                                                                <Cancel sx={{fontSize:'36px'}}/>
                                                                            </IconButton>
                                                                        </Stack>
                                                                        <IconButton sx={{width:'36px'}} onClick={handleOpenRightSideBar} >
                                                                            <img src={menu_right_1} style={{width: '36px', height: '36px'}}/>
                                                                        </IconButton>
                                                                    </Stack>
                                                                </Grid>
                                                            }
                                                        </Stack>
                                                    )
                                                )                                                
                                        }
                                        {/* Diagram */}
                                        <div className="custom-scrollbars__container">
                                            <div
                                                id="dispDiagram"
                                                ref={dispDiagramRef}
                                                className="custom-scrollbars__content"
                                                onDragOver={(e) => e.preventDefault()}
                                                onDrop={handleDrop}
                                                onScroll={handleScroll}
                                            >
                                                {(currentTabId != 1 && transCenterSelected) &&
                                                    <TransCenterInfo
                                                        widthGrid={widthGrid}
                                                        setWidthGrid={setWidthGrid}
                                                        setHeightGrid={setHeightGrid}
                                                        offsetY={stageRef?.current?.y() ?? 0}
                                                        loadingFlag={loadingFlag}
                                                        setLoadingFlag={setLoadingFlag}
                                                        isOpenLeftSideBar={isOpenLeftSideBar}
                                                    />
                                                }
                                                <div
                                                    id="largeContainer"
                                                    style={{ 
                                                        height: heightGrid >= GetDispDiagramHeight() ? heightGrid : GetDispDiagramHeight(),
                                                        width: widthGrid >= GetDispDiagramWidth() ? widthGrid : GetDispDiagramWidth(),
                                                        overflow: 'hidden',
                                                        paddingRight: m_bModePM || modeViewOnly ? '0px' : isOpenRightSideBar? Constant.DASH_BOARD_RIGHT_WIDTH:0,
                                                        position:"relative"
                                                    }
                                                }>
            
                                                    {processMode === Model.ProcessMode.DRAWING && !m_bModePM && <SvgDotsGrid scaleGrid={scaleGrid} shapes={shapes} widthGrid={widthGrid} heightGrid={heightGrid} />}
                                                  
                                                    <Stage
                                                        width={window.innerWidth} // Stageは表示範囲と同じ大きさにする
                                                        height={window.innerHeight + 60}
                                                        ref={stageRef}
                                                        onClick={handleMouseClick}
                                                        onMouseDown={handleMouseDown}
                                                        onMouseMove={handleMouseMove}
                                                        onMouseUp={handleMouseUp}
                                                        onDragStart={handleDragStart}
                                                        onDragEnd={handleDragEnd}
                                                        onContextMenu={handleContextMenu}
                                                        onTap={handleOnTap}
                                                        onTouchStart={handleOnTouchStart}
                                                        onTouchMove={handleOnTouchMove}
                                                        onTouchEnd={handleOnTouchEnd}
                                                    >

                                                        <Layer>
                                                            {openMode47Menu &&
                                                                <Mode47Menu
                                                                    x={((stageRef?.current?.getPointerPosition()?.x ?? 0) - (stageRef?.current?.x() ?? 0)) / scaleGrid}
                                                                    y={((stageRef?.current?.getPointerPosition()?.y ?? 0) - (stageRef?.current?.y() ?? 0)) / scaleGrid}
                                                                    processMode ={processMode}
                                                                    m_bModePM ={m_bModePM}
                                                                    modeViewOnly={modeViewOnly}
                                                                    dropMode={projectData.dropMode}
                                                                    flowViewMethod={projectData.flowViewMethod}

                                                                />
                                                            }
                                                        </Layer>
                                                        <Layer>
                                                            {openControlMenu &&
                                                                <ControlMenu
                                                                    x={((stageRef?.current?.getPointerPosition()?.x ?? 0) - (stageRef?.current?.x() ?? 0)) / scaleGrid}
                                                                    y={((stageRef?.current?.getPointerPosition()?.y ?? 0) - (stageRef?.current?.y() ?? 0)) / scaleGrid}
                                                                    offsetX={stageRef?.current?.x() ?? 0}
                                                                    offsetY={stageRef?.current?.y() ?? 0}
                                                                    lineDiagramRef={lineDiagramRef}
                                                                    onCreate={handleCreate}
                                                                    onPaste={handlePaste}
                                                                    isDisabledCopy={copyShapes.length === 0}
                                                                />
                                                            }
                                                            {processMode === Model.ProcessMode.DRAWING && !m_bModePM && <Rect id="currentMouse" {...pointerPosition} width={Constant.POINTER_WIDTH} height={Constant.POINTER_HEIGHT} fill="gray" />}
                                                        </Layer>
                                                        {/* {processMode === Model.ProcessMode.DRAWING && !m_bModePM && <DotsGrid cols={widthGrid} rows={heightGrid} dotSize={1} dotColor="gray" minX={drawArea[0]} minY={drawArea[1]} maxX={drawArea[2]} maxY={drawArea[3]} />} */}
                                                        
                                                        <Layer ref={layerRef}>
                                                            {drawShapes?.map((element: Model.ControlModel) => {
                                                                switch (element.type) {
                                                                    case Element.MS_HVCB:
                                                                        return (
                                                                            <CreateMsHVCB
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsHVCBModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_SOURCE:
                                                                        return (
                                                                            <CreateMsSource
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsSourceModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                chartDataList={chartDataList}
                                                                                currentIDChartTab={currentIDChartTab}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_MOTOR:
                                                                        return (
                                                                            <CreateMsMotor
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsMotorModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_GENERATOR:
                                                                        return (
                                                                            <CreateMsGenerator
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsGeneratorModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_TRANS3:
                                                                        return (
                                                                            <CreateMsTrans3
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsTrans3Model}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_3WINDING:
                                                                        return (
                                                                            <CreateMs3Winding
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.Ms3WindingModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_TRANS1:
                                                                        return (
                                                                            <CreateMsTrans1
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsTrans1Model}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_TRANSSCOTT:
                                                                        return (
                                                                            <CreateMsTransscott
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsTransScottModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_DS:
                                                                        return (
                                                                            <CreateMsDS
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsDSModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_LBS:
                                                                        return (
                                                                            <CreateMsLBS
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsLBSModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_MVCB:
                                                                        return (
                                                                            <CreateMsMVCB
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsMVCBModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_CONTACTOR:
                                                                        return (
                                                                            <CreateMsContactor
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsContactorModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_FUSE:
                                                                        return (
                                                                            <CreateMsFUSE
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsFUSEModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_2E:
                                                                        return (
                                                                            <CreateMs2E
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.Ms2EModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_ZEROCOND:
                                                                        return (
                                                                            <CreateMsZerocond
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsZerocondModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_CONNECT:
                                                                        return (
                                                                            <CreateMsConnect
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                properties={element.properties as Model.ConnectModel}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_WIRE:
                                                                        return (
                                                                            <CreateMsWire
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsWireModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_BUSBAR:
                                                                        return (
                                                                            <CreateMsBusbar
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsBusbarModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_BUSDUCT:
                                                                        return (
                                                                            <CreateMsBusduct
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsBusductModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_REACTOR:
                                                                        return (
                                                                            <CreateMsReactor
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsReactorModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_IMPEDANCE:
                                                                        return (
                                                                            <CreateMsImpedance
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsImpedanceModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_LVCB:
                                                                        return (
                                                                            <CreateMsLVCB
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsLVCBModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_THERMAL:
                                                                        return (
                                                                            <CreateMsThermal
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsThermalModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        )
                                                                    case Element.MS_THERMAL_CT:
                                                                        return (
                                                                            <CreateMsThermalCT
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsThermalCTModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_TEXT:
                                                                        return (
                                                                            <CreateText
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsTextModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_MOTOR_GROUP:
                                                                        return (
                                                                            <CreateMsMotorGroup
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsMotorGroupModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_LOAD:
                                                                        return (
                                                                            <CreateMsLoad
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsLoadModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_LIGHTBOARD:
                                                                        return (
                                                                            <CreateMsLightBoard
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsLightBoardModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_POWERBOARD:
                                                                        return (
                                                                            <CreateMsPowerBoard
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsPowerBoardModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_CAPACITOR:
                                                                        return (
                                                                            <CreateMsCapacitor
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsCapacitorModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_TRANSCENTER:
                                                                        return (
                                                                            <CreateMSTransCenter
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MSTransCenterModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                handleUpdateTCSuccess={handleUpdateTCSuccess}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_VCT:
                                                                        return (
                                                                            <CreateMsVCT
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsVCTModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_VT:
                                                                        return (
                                                                            <CreateMsVT
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MSVTModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_ARRESTOR:
                                                                        return (
                                                                            <CreateMsArrestor
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsArrestorModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_EARTH:
                                                                        return (
                                                                            <CreateMsEarth
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsEarthModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_CABLEHEAD:
                                                                        return (
                                                                            <CreateMsCableHead
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsCableHeadModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_CT:
                                                                        return (
                                                                            <CreateMsCT
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsCTModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_ZCT:
                                                                        return (
                                                                            <CreateMsZCT
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MSZCTModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_INV:
                                                                        return (
                                                                            <CreateMsINV
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsINVModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    case Element.MS_RELAY:
                                                                        return (
                                                                            <CreateMsRelay
                                                                                key={element.id}
                                                                                {...element}
                                                                                isHidden={false}
                                                                                properties={element.properties as Model.MsRelayModel}
                                                                                lineDiagramRef={lineDiagramRef}
                                                                                gridSizeController={{
                                                                                    width: widthGrid,
                                                                                    height: heightGrid,
                                                                                    scale: scaleGrid,
                                                                                    stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                    stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                                }}
                                                                                isOnShiftKey={isOnShiftKey}
                                                                            />
                                                                        );
                                                                    default:
                                                                        return null;
                                                                }
                                                            })}
                                                        </Layer>
                                                        <Layer ref={groupLayerRef}>
                                                            {/* this layer is used for draw group frames */}
                                                            
                                                        </Layer>
                                                        <Layer>
                                                            <Transformer
                                                                ref={transformRef}
                                                                rotateEnabled={false}
                                                                resizeEnabled={false}
                                                                keepRatio={false}
                                                                enabledAnchors={[]}
                                                                visible={false}
                                                            />
                                                            <Transformer
                                                                ref={groupingRef}
                                                                rotateEnabled={false}
                                                                resizeEnabled={false}
                                                                keepRatio={false}
                                                                enabledAnchors={[]}
                                                                // borderDash={[0.5, 0.5]}
                                                                // anchorStroke="black"
                                                                // borderStroke="green"
                                                                // anchorFill="black"
                                                                borderStrokeWidth= {0}
                                                                visible={true}
                                                            />
                                                            <Rect
                                                                id="selectGroupControl"
                                                                ref={selectGroupControlRef}
                                                                {...selectGroupControl}
                                                                fill="rgba(255, 255, 255, 0)"
                                                                stroke='black'
                                                                strokeWidth={1}
                                                                dash={[2, 2]}
                                                            />
                                                        </Layer>
                                                    </Stage>
                                                </div>
                                            </div>
                                            <VerticalScrollbar
                                                contentRef={dispDiagramRef}
                                                currentTabId={currentTabId}
                                                heightGrid={heightGrid}
                                                scaleGrid={scaleGrid}
                                            />
                                            <HorizontalScrollbar
                                                contentRef={dispDiagramRef}
                                                currentTabId={currentTabId}
                                                widthGrid={widthGrid + (isOpenRightSideBar?Constant.DASH_BOARD_RIGHT_WIDTH:0)}
                                                scaleGrid={scaleGrid}
                                            />
                                        </div>
                                    {isDrawPDF &&
                                        <div>
                                            <div id={'mainDiagramPDF'} style={{display:'none'}}> 
                                                <Stage
                                                    width={stageWidthPDF}//{window.innerWidth} // Stageは表示範囲と同じ大きさにする
                                                    height={stageHeightPDF}//{window.innerHeight + 60}
                                                    ref={stageRefPDF}
                                                >
                                                    <Layer ref={layerRefPDF}>
                                                        <Rect 
                                                            x={-200}
                                                            y={- 120} 
                                                            width={widthGridPDF * 2 + 200} 
                                                            height={heightGridPDF * 2 + 120} 
                                                            fillLinearGradientStartPoint={ {x: 0, y: 0} }
                                                            fillLinearGradientEndPoint= { {x: widthGridPDF, y: heightGridPDF} }
                                                            fillLinearGradientColorStops={[0,'white',]}
                                                            listening={false}
                                                        >
                                                        </Rect>
                                                        {(currentTabId != 1 && transCenterSelected && topTranscenterElement && isReloadMainDiagramPDF) &&
                                                            <CreateMsHiddenTopControlTransCenter
                                                            key={topTranscenterElement.id}
                                                            {...topTranscenterElement}
                                                            x={topTranscenterElement.type == Element.MS_ZEROCOND? shapes.find((x:any)=>x.type == Element.MS_INTO_POINT).x - 20 : shapes.find((x:any)=>x.type == Element.MS_INTO_POINT).x}
                                                            y={-10}
                                                            isSelected = {false}
                                                            isHidden={true}
                                                            properties={topTranscenterElement.properties}
                                                            lineDiagramRef={lineDiagramRefPDF}
                                                            gridSizeController={{
                                                                width: widthGridPDF,
                                                                setWidth: setWidthGridPDF,
                                                                height: heightGridPDF,
                                                                setHeight: setHeightGridPDF,
                                                                scale: scaleGridPDF,
                                                            }}
                                                            chartDataList={chartDataList}
                                                            currentIDChartTab={currentIDChartTab}
                                                        />
                                                        }
                                                        {
                                                            (currentTabId != 1 && transCenterSelected && !topTranscenterElement && isReloadMainDiagramPDF) &&
                                                            <>
                                                                <Line
                                                                    points={[shapes.find((x:any)=>x.type == Element.MS_INTO_POINT).x + 10, -10, shapes.find((x:any)=>x.type == Element.MS_INTO_POINT).x + 10, 45 ]}
                                                                    stroke={'black'}
                                                                    strokeWidth={1}
                                                                    lineJoin={'round'}
                                                                    dash={[5, 5]}
                                                                >
                                                                </Line>
                                                                <Line
                                                                    points={[shapes.find((x:any)=>x.type == Element.MS_INTO_POINT).x + 10, 45, shapes.find((x:any)=>x.type == Element.MS_INTO_POINT).x + 10, 60 ]}
                                                                    stroke={'black'}
                                                                    strokeWidth={1}
                                                                    lineJoin={'round'}
                                                                >
                                                                </Line>
                                                            </>
                                                          
                                                        }
                                                        {drawShapesPDF?.map((element: Model.ControlModel) => {
                                                            if(!element.isSelected && !isReloadMainDiagramPDF) return
                                                            switch (element.type) {
                                                                case Element.MS_HVCB:
                                                                    return (
                                                                        <CreateMsHVCB
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsHVCBModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_SOURCE:
                                                                    return (
                                                                        <CreateMsSource
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsSourceModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            chartDataList={chartDataList}
                                                                            currentIDChartTab={currentIDChartTab}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_MOTOR:
                                                                    return (
                                                                        <CreateMsMotor
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsMotorModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_GENERATOR:
                                                                    return (
                                                                        <CreateMsGenerator
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsGeneratorModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_TRANS3:
                                                                    return (
                                                                        <CreateMsTrans3
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsTrans3Model}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_3WINDING:
                                                                    return (
                                                                        <CreateMs3Winding
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.Ms3WindingModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_TRANS1:
                                                                    return (
                                                                        <CreateMsTrans1
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsTrans1Model}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_TRANSSCOTT:
                                                                    return (
                                                                        <CreateMsTransscott
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsTransScottModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_DS:
                                                                    return (
                                                                        <CreateMsDS
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsDSModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_LBS:
                                                                    return (
                                                                        <CreateMsLBS
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsLBSModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_MVCB:
                                                                    return (
                                                                        <CreateMsMVCB
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsMVCBModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_CONTACTOR:
                                                                    return (
                                                                        <CreateMsContactor
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsContactorModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_FUSE:
                                                                    return (
                                                                        <CreateMsFUSE
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsFUSEModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_2E:
                                                                    return (
                                                                        <CreateMs2E
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.Ms2EModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_ZEROCOND:
                                                                    return (
                                                                        <CreateMsZerocond
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsZerocondModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_CONNECT:
                                                                    return (
                                                                        <CreateMsConnect
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            properties={element.properties as Model.ConnectModel}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_WIRE:
                                                                    return (
                                                                        <CreateMsWire
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsWireModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_BUSBAR:
                                                                    return (
                                                                        <CreateMsBusbar
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsBusbarModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_BUSDUCT:
                                                                    return (
                                                                        <CreateMsBusduct
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsBusductModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_REACTOR:
                                                                    return (
                                                                        <CreateMsReactor
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsReactorModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_IMPEDANCE:
                                                                    return (
                                                                        <CreateMsImpedance
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsImpedanceModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_LVCB:
                                                                    return (
                                                                        <CreateMsLVCB
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsLVCBModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_THERMAL:
                                                                    return (
                                                                        <CreateMsThermal
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsThermalModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    )
                                                                case Element.MS_THERMAL_CT:
                                                                    return (
                                                                        <CreateMsThermalCT
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsThermalCTModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_TEXT:
                                                                    return (
                                                                        <CreateText
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsTextModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_MOTOR_GROUP:
                                                                    return (
                                                                        <CreateMsMotorGroup
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsMotorGroupModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_LOAD:
                                                                    return (
                                                                        <CreateMsLoad
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsLoadModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_LIGHTBOARD:
                                                                    return (
                                                                        <CreateMsLightBoard
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsLightBoardModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_POWERBOARD:
                                                                    return (
                                                                        <CreateMsPowerBoard
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsPowerBoardModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_CAPACITOR:
                                                                    return (
                                                                        <CreateMsCapacitor
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsCapacitorModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_TRANSCENTER:
                                                                    return (
                                                                        <CreateMSTransCenter
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MSTransCenterModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            handleUpdateTCSuccess={handleUpdateTCSuccess}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case 'MS_VCT':
                                                                    return (
                                                                        <CreateMsVCT
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsVCTModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_VT:
                                                                    return (
                                                                        <CreateMsVT
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MSVTModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case 'MS_ARRESTOR':
                                                                    return (
                                                                        <CreateMsArrestor
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsArrestorModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case 'MS_EARTH':
                                                                    return (
                                                                        <CreateMsEarth
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsEarthModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case 'MS_CABLEHEAD':
                                                                    return (
                                                                        <CreateMsCableHead
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsCableHeadModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case 'MS_CT':
                                                                    return (
                                                                        <CreateMsCT
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsCTModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_ZCT:
                                                                    return (
                                                                        <CreateMsZCT
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MSZCTModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_INV:
                                                                    return (
                                                                        <CreateMsINV
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsINVModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                case Element.MS_RELAY:
                                                                    return (
                                                                        <CreateMsRelay
                                                                            key={element.id}
                                                                            {...element}
                                                                            y={currentTabId != 1 && transCenterSelected ? element.y + 60 : element.y}
                                                                            isSelected = {false}
                                                                            isHidden={true}
                                                                            properties={element.properties as Model.MsRelayModel}
                                                                            lineDiagramRef={lineDiagramRefPDF}
                                                                            gridSizeController={{
                                                                                width: widthGrid,
                                                                                height: heightGrid,
                                                                                scale: scaleGrid,
                                                                                stageOffsetX: stageRef?.current?.x() ?? 0,
                                                                                stageOffsetY: stageRef?.current?.y() ?? 0,
                                                                            }}
                                                                            isOnShiftKey={isOnShiftKey}
                                                                        />
                                                                    );
                                                                default:
                                                                    return null;
                                                            }
                                                        })}
                                                    </Layer>
                                            </Stage>
                                            </div>
                                        </div>
                                    }
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>

                {showProtectionDiagram && (
                    <div style={{ width: '39.9vw', aspectRatio: '2/1', minWidth:'300px', display: 'flex', flexDirection: 'column' }}>
                        <DashboardSecondaryTopNavbar userId={userId} isOpenGraphScreen={false} modeViewOnly={modeViewOnly} onClose={()=>{setIsReOpenProtectionDiagram(false);setShowProtectionDiagram(false);isShowScreen(Constant.CONTROL_SCREEN)}}/>
                        <ChartTab onClose={()=>{setIsReOpenProtectionDiagram(false);setShowProtectionDiagram(false);isShowScreen(Constant.CONTROL_SCREEN)}}/>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <CreateGraph
                                listObjectText={listTextGraph}
                                setListObjectText={setListTextGraph}
                                undoGraphData={undoGraphData}
                                shapes={chartData}
                                drawLineInfo={drawLineInfo}
                                addNewGraph={isAddNewGraph}
                                isOpenGraphPage={false}
                                undoCut={undoCut}
                                isReOpenGraphTemporary={isReOpenProtectionDiagram}
                                isOpenGraphTemporary={true}
                                onDrawLines={()=>{}}
                                onSetUndoData={(data:any)=>{setUndoGraphData(data)}}
                                onFinishDrawLineInfo={(data:boolean)=>{
                                    setDrawLineInfo({...drawLineInfo,state:data})
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
            {Array.from(createElementInfo.values()).map((info: CreateElementInfo) => {
                return (
                    <FetchHelper
                        key={info.fetchId}
                        fetchId={info.fetchId}
                        onComplete={(success, data, error) =>
                            success
                                ? handleCreateEleSuccess(info, data)
                                : handleCreateEleError(info, success, data, error)
                        }
                    />
                );
            })}
            
            <FetchHelper
                fetchId={GET_TEMPLATES}
                onComplete={(success, data, error) =>
                    success
                        ? handleGetTemplatesSuccess(data)
                        : handleErrorGetTemplate(error)
                }
            />
            <FetchHelper
                fetchId={OPEN_PROJECT}
                onComplete={(success, data, error) => {
                    success ? handleSuccessOpenProject(data) : handleErrorOpenProject(success, data, error);
                }}
            />     
            <FetchHelper
                fetchId={CREATE_GROUP}
                onComplete={(success, response, error) => {
                    success ? handleCreateGroupSuccess(response) : onShowMessage({
                        type: "error",
                        title: "GROUP ELEMENTS",
                        body: "Cannot create group",
                    });;
                }}
            />     
            <FetchHelper
                fetchId={DELETE_GROUP}
                onComplete={(success, response, error) => {
                    success ? handleDeleteGroupSuccess(response) : onShowMessage({
                        type: "error",
                        title: "UNGROUP ELEMENTS",
                        body: "Cannot delete group",
                    });;
                }}
            />     
            <FetchHelper
                fetchId={SAVE_INFO_CIRCUIT_DB}
                onComplete={(success, data, error) => {
                    setLoadingFlag(false)
                    success 
                    || onShowMessage({
                        type: "error",
                        title: "回路情報",
                        body: "回路情報の保存に失敗しました",
                    });
                }}
            />   
            <FetchHelper
                fetchId={SAVE_INFO_SKELETON_DB}
                onComplete={(success, data, error) => {
                    success ? handleSuccessSaveInfoSkeleton(data) : handleErrorSaveInfoSkeleton(error)
                }}
            />     
            <FetchHelper
                fetchId={SAVE_INFO_COVER_DB}
                onComplete={(success, data, error) => {
                    setLoadingFlag(false)
                    success 
                    || onShowMessage({
                        type: "error",
                        title: "単線結線図の設定",
                        body: "単線結線図の設定の保存に失敗しました",
                    });
                }}
            />     
            <FetchHelper
                fetchId={SAVE_REPORT_DB}
                onComplete={(success, data, error) => {
                    setLoadingFlag(false)
                    success 
                    || onShowMessage({
                        type: "error",
                        title: "レポートの設定",
                        body: "レポートの設定の保存に失敗しました",
                    });
                }}
            />     
            <FetchHelper
                fetchId={RENUMBER_SKELETON}
                onComplete={(success, data, error) => {
                    success ? handleSuccessRenumber(data) : handleErrorRenumberSkeleton(success, data, error);
                }}
            />
            <FetchHelper
                fetchId={RENUMBER_POINT_TEXT}
                onComplete={(success, data, error) => {
                    success ? handleSuccessRenumber(data) : handleErrorRenumberPointText(success, data, error);
                }}
            />
            <FetchHelper
                fetchId={UPDATE_SHAPE}
                onComplete={(success, data, error) => {
                    success ? handleSuccessSetShape(data) : handleErrorSetShape(error);
                }}
            />
            <FetchHelper
                fetchId={PASTE_ELES_AND_GROUPS}
                onComplete={(success, data, error) => {
                    success ? handleSuccessPaste(data) : handleErrorPaste(success, data, error);
                }}
            />  

             <FetchHelper
                fetchId={UNDO_ELES_AND_GROUPS}
                onComplete={(success, data, error) => {
                    success ? handleSuccessUndoDelete(data) : handleErrorPaste(success, data, error);
                }}
            />  

            <FetchHelper
                fetchId={SAVE_FILE}
                onComplete={(success, data, error) => {
                    success ? handleSuccessSaveFile(data) : handleErrorSaveFile(success, data, error);
                }}
            />     
            <FetchHelper
                fetchId={SAVE_AS_FILE}
                onComplete={(success, data, error) => {
                    success ? handleSuccessSaveFile(data, true) : handleErrorSaveFile(success, data, error);
                }}
            />     
            <FetchHelper
                fetchId={DISCARD_CHANGES_FILE}
                onComplete={(success, data, error) => {
                    success ? getOpenProjectData({
                                requestPrms: {
                                    userId: userId,
                                    projectId,
                                    reportIndex : 1,
                                    ownerProject: ownerProject,
                                }
                             }) 
                            : handleErrorSaveFile(success, data, error);
                }}
            />     
            <FetchHelper
                fetchId={GET_GRAPH}
                onComplete={(success, data, error) => {
                    success ? handleSuccessGetGraph(data) : handleErrorGetGraph(success, data, error);
                }}
            />
            <FetchHelper
                fetchId={GET_GRAPH2}
                onComplete={(success, data, error) => {
                    success ? handleSuccessGetGraph2(data) : handleErrorGetGraph(success, data, error);
                }}
            />
            <FetchHelper 
                fetchId={UPDATE_GRAPH}
                onComplete={(success, data, error) => {
                    success ? handleSuccessUpdateGraph(data) : handleErrorUpdateGraph(success, data, error);
                }}
            />

            <FetchHelper 
                fetchId={UPDATE_TITLE_GRAPH}
                onComplete={(success, data, error) => {
                    success ? handleSuccessUpdateTitleGraph(data) : handleErrorUpdateGraph(success, data, error);
                }}
            />
            
            <FetchHelper
                fetchId={GET_GROUP_LIST}
                onComplete={(success, data, error) =>
                    success
                        ? handleGetGroupListSuccess(data)
                        : handleErrorGetGroupList(error)
                }
            />
            <FetchHelper
                fetchId="CREATE_GROUP_LIST"
                onComplete={(success, data, error) =>
                success
                    ? handleCreateGroupListSuccess(data)
                    : handleCreateGroupListError(error)
                }
            />
            <FetchHelper
                fetchId={PLACEMENT_GROUP_LIST}
                onComplete={(success, data, error) =>
                success
                    ? handlePlacementGroupListSuccess(data)
                    : handlePlacementGroupListError(error)
                }
            />

            <FetchHelper
                fetchId={CREATE_GRAPH}
                onComplete={(success, data, error) =>
                success
                    ? handleCreateGraphSuccess(data)
                    : handleCreateGraphError(error)
                }
            />

            <FetchHelper 
                fetchId={UPDATE_QUICK_PROPERTIES}
                onComplete={(success:any,data:any,error:any) => success ? handleSuccessUpdateQuickProperties(data) : handleErrorUpdateQuickProperties(data,error)} 
            />

            <FetchHelper
                fetchId={UPDATE_TRANS_CENTER}
                onComplete={(success, data, error) =>
                success
                    ? handleUpdateTCSuccess(data)
                    : handleUpdateTCError(error)
                }
            />

            {/* #region UNDO */}
            <FetchHelper
                fetchId="UNDO"
                onComplete={(success:any,data:any,error:any) => 
                    success 
                    ? handleSuccessUpdatePropertiesUndo(data) 
                    : handleErrorUpdatePropertiesUndo(data,error)
                } 
            />

            <FetchHelper
                fetchId="UNDO_CREATE"
                onComplete={(success:any,data:any,error:any) =>
                    success
                    ? handleSuccessCreateElementUndo(data)
                    : handleErrorCreateElementUndo(data,error)}
            />
            
            <FetchHelper
                fetchId={UNDO_MODE_PM}
                onComplete={(success:any,data:any,error:any) =>
                    success
                    ? handleSuccessUndoModePM(data)
                    : handleErrorUndoModePM(data,error)}
            />

            <FetchHelper
                fetchId={UNDO_SET_PARAM}
                onComplete={(success:any,data:any,error:any) =>
                    success
                    ? handleSuccessUndoSetParam(data)
                    : handleErrorUndoSetParam(data,error)}
            />

            <FetchHelper
                fetchId={UNDO_MODE}
                onComplete={(success:any,data:any,error:any) =>
                    success
                    ? handleSuccessUndoMode(data)
                    : handleErrorUndoMode(data,error)}
            />

            <FetchHelper
                fetchId={UNDO_PASTE}
                onComplete={(success:any,data:any,error:any) =>
                    success
                    ? handleSuccessUndoPaste(data)
                    : handleErrorUndoPaste(data,error)}
            />

            <FetchHelper
                fetchId={UNDO_UPDATE_SCALE_GRAPH}
                onComplete={(success:any,data:any,error:any) =>
                    success
                    ? handleSuccessUndoScaleGraph(data)
                    : handleErrorUndoScaleGraph(data,error)}
            />

            <FetchHelper
                fetchId={"CALC_VOLT_DROP"}
                onComplete={(success:any,data:any,error:any) => 
                    success 
                    ? handleSucessCalcVoltDrop(data)
                    : handleErrorCalcVoltDrop(data,error)
                }
            />

            <FetchHelper
                fetchId={MAIN_DIAGRAM_PDF}
                onComplete={(success, data, error) =>
                success ? handleGetMainDiagramPDFSuccess(data) : handleGetMainDiagramPDFError(error)
                }
            />
            {/* #endregion */}
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userId: state.app.user?.userId,
        currUser: state.app.user,
        userGroupPermission: state.app.userGroupPermission,

        groupList: state.app.diagram.groupList,
        projectData: state.app.projectData,
        elementTemplates: state.app.diagram.elementTemplates,
        infoCircuit: state.app.diagram.infoCircuit,
        infoSkeleton: state.app.diagram.infoSkeleton,
        infoCover: state.app.diagram.infoCover,

        report: state.app.diagram.report,
        transformData: state.app.diagram.transformData,
        transformFlag: state.app.diagram.transformFlag,
        elementGroups: state.app.diagram.elementGroups,
        tcGroups: state.app.diagram.tcGroups,
        diagramDataList: state.app.diagram.diagramData,
        diagramData: state.app.diagram.diagramData.filter((r: any) => r.tabId === state.app.diagram.currentIDDiagramTab),
        currentTabId: state.app.diagram.currentIDDiagramTab,
        currentIDChartTab: state.app.diagram.currentIDChartTab,
        processMode: state.app.diagram.processMode,
        chartDataList: state.app.diagram.chartData,
        isOpenChart: state.app.diagram.isOpenChart,
        isAddLineToChart: state.app.diagram.isAddToChart,
        isAddCalcPointToChart: state.app.diagram.isAddCalcPointToChart,
        pointerPosition: state.app.diagram.pointerPosition,
        m_bModePM: state.app.diagram.m_bModePM,
        modeViewOnly: state.app.diagram.modeViewOnly,
        listGraphName: state.app.diagram.listGraphName,
        chartEvents: state.app.diagram.chartEvents,
        contextMenuFunction: state.app.diagram.contextMenuFunction,

        copyShapes: state.app.diagram.copyShapes,
        copyEleGroups: state.app.diagram.copyEleGroups,
        copyTCEleGroups: state.app.diagram.copyTCEleGroups,
        copyDiagramData: state.app.diagram.copyDiagramData,
        undoData: state.app.diagram.undoData,
        diagramState: state.app.diagram,

        openDialogEvents: state.app.diagram.openDialog["RELOAD"],
        maxIDChartTab: state.app.diagram.maxIDChartTab,
        fetchOpenProjectState: selectors.getFetchState(state, OPEN_PROJECT) as FetchState,
        fetchGetTemplatesState: selectors.getFetchState(state, GET_TEMPLATES) as FetchState,
        fetchCreateGroupState: selectors.getFetchState(state, CREATE_GROUP) as FetchState,
        fetchDeleteGroupState: selectors.getFetchState(state, DELETE_GROUP) as FetchState,
        appLoading: state.app.loading,
		clearMenuState: state.app.diagram.clearOptionMenu,
		listGraph: state.app.diagram.listGraph,
        diagramDataUpdate: state.app.diagram.diagramDataUpdate,
        isSelectTouchGroup: state.app.diagram.isSelectTouchGroup,
        isShowMaintenanceScreen: state.app.diagram.isShowMaintenanceScreen,
        elementReposition: state.app.diagram.elementReposition,
        storeProjectId: state.app.projectData.projectId,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        // DISPATCH FETCH API ACTIONS
        //      diagram api
        getElementTemplates: (data: any) => dispatch(actionCreators.fetch(GET_TEMPLATES, "/diagram/get-templates", "POST", data, false, true)),
        createElementPost: (info: CreateElementInfo, data: any) => dispatch(actionCreators.fetch(info.fetchId, "/diagram/create-ele", "POST", data, false, true)),
        pasteElementAndGroup: (params: any) => dispatch(actionCreators.fetch(PASTE_ELES_AND_GROUPS, "/diagram/paste-elements", "POST", params, false, true)),
        undoElementAndGroup: (params: any) => dispatch(actionCreators.fetch(UNDO_ELES_AND_GROUPS, "/diagram/undo-elements", "POST", params, false, true)),
        saveInfoCircuitToDB: (params : any) => dispatch(actionCreators.fetch(SAVE_INFO_CIRCUIT_DB, "/diagram/set-circuit", "POST", params, false, true)),
        saveInfoSkeletonToDB: (params : any) => dispatch(actionCreators.fetch(SAVE_INFO_SKELETON_DB, "/diagram/set-skeleton", "POST", params, false, true)),
        saveInfoCoverToDB: (params : any) => dispatch(actionCreators.fetch(SAVE_INFO_COVER_DB, "/diagram/set-info-cover", "POST", params, false, true)),
        saveReportToDB: (params : any) => dispatch(actionCreators.fetch(SAVE_REPORT_DB, "/diagram/set-report", "POST", params, false, true)),
        renumberPointText: (params : any) => dispatch(actionCreators.fetch(RENUMBER_POINT_TEXT, "/diagram/renumber-point-text", "POST", params, false, true)),
        renumberSkeleton: (params : any) => dispatch(actionCreators.fetch(RENUMBER_SKELETON, "/diagram/renumber-skeleton", "POST", params, false, true)),
        //      project api
        getOpenProjectData: (data: any) => dispatch(actionCreators.fetch(OPEN_PROJECT, "/project/open", "POST", data, false, true)),
        // saveFile: (params : any) => dispatch(actionCreators.fetch(SAVE_FILE, "/project/save", "POST", params, false, true)),
        // saveAsFile: (params : any) => dispatch(actionCreators.fetch(SAVE_AS_FILE, "/project/save-as", "POST", params, false, true)),
        discardChangesFile: (params : any) => dispatch(actionCreators.fetch(DISCARD_CHANGES_FILE, "/project/discard-changes", "POST", params, false, true)),
        
        //      group_list api
        getGroupList: (params: any) =>
            dispatch(
                actionCreators.fetch(
                    GET_GROUP_LIST,
                    "/grouplist/get",
                    "POST",
                    params,
                    false,
                    true
                )
            ),
        placementGroupList: (params: any) =>
            dispatch(
                actionCreators.fetch(
                    PLACEMENT_GROUP_LIST,
                    "/grouplist/placement",
                    "POST",
                    params,
                    false,
                    true
                )
            ),
        // OTHER ACTIONS
        setProjectOpenTime: (data: any) => dispatch(actionCreators.setProjectOpenTime(data)),
        unSelectAllControls: () => dispatch(unSelectAllControls()),
        saveGroupList: (data: Model.GroupListModel[]) => dispatch(actionCreators.saveGroupList(data)),
        resetDiagramAndProjectData: () => dispatch(actionCreators.resetDiagramAndProjectData()),
        checkZerocondConnect: () => dispatch(actionCreators.checkZerocondConnect()),
        saveOpenProjectData: (data: Model.ProjectModel) => dispatch(actionCreators.saveOpenProjectData(data)),
        saveInfoCircuit: (data: Model.InfoCircuit) => dispatch(actionCreators.saveInfoCircuit(data)),
        saveElementGroups: (data: any) => dispatch(actionCreators.saveElementGroups(data)),
        saveTCGroups: (data: any) => dispatch(actionCreators.saveTCGroups(data)),
        saveInfoSkeleton: (data: Model.InfoSkeleton) => dispatch(actionCreators.saveInfoSkeleton(data)),
        saveInfoCover: (data: Model.InfoCover) => dispatch(actionCreators.saveInfoCover(data)),
        saveReport: (data: Model.Report) => dispatch(actionCreators.saveReport(data)),
        setGraphData: (param : any) => dispatch(setGraphData(param)),
        createChartTab: (param: any) => dispatch(createChartTab(param)),
        changeModePM: (data: Boolean) => dispatch(changeModePM(data)),
        setDownloadDiagramPDF:(data: boolean, type: number, image: any)=> dispatch(isDownloadDiagramPDF(data,type,image)),
        updateSelectControl: (controlId: string, isMultiple : boolean = false) =>
            dispatch(updateSelectControl(controlId, isMultiple)),
        clearOptionMenu: (state:boolean) => dispatch(clearOptionMenu(state)),
        setSelectTouchGroup: (state:boolean) => dispatch(isSelectTouchGroup(state)),
        setRepositionElement: (id:any,state:boolean) => dispatch(setRepositionElement(id,state)),

        // graph api
        createGraph: (params:any) => dispatch(actionCreators.fetch(CREATE_GRAPH,"/graph/create-graph","POST",params,false,true)),
        getGraph:(params:any) => dispatch(actionCreators.fetch(GET_GRAPH,"/graph/get-param","POST",params,false,true)),
        getGraph2:(params:any) => dispatch(actionCreators.fetch(GET_GRAPH2,"/graph/get-param","POST",params,false,true)),
        updateGraph:(params:any) => dispatch(actionCreators.fetch(UPDATE_GRAPH,"/graph/set-param","POST",params,false,true)),
        updateTitleGraph:(params:any) => dispatch(actionCreators.fetch(UPDATE_TITLE_GRAPH,"/graph/set-param","POST",params,false,true)),
        saveListGraphNameToStore: (projectId:any,data:any) => dispatch(saveListGraphName(projectId,data)),
        addDataUndoToChart:(data:any) => dispatch(addUndoDataToChart(data)),

        saveElementTemplates: (data: object) => dispatch(saveElementTemplates(data)),
        changeProcessMode: (mode : Model.ProcessMode) => dispatch(changeProcessMode(mode)),
        setTransformData: (shapes: Node<NodeConfig>[]) => dispatch(setTransformData(shapes)),
        saveAllControlsToDiagram: (data: Model.DiagramModel, flag: boolean) => dispatch(saveAllControlsToDiagram(data, flag)),
        setDiagramDataUpdate: () => dispatch(setDiagramDataUpdate()),
        setShapes: (data: Model.DiagramModel,controlChange:Model.ControlModel[], flag: boolean) => dispatch(setShapes(data,controlChange,flag)),
        updateTransCenterProps: (id: string, data: object) => dispatch(updatePropertiesOfTransCenter(id, data)),
        updateIdTabChart: (tabId: number) => dispatch(saveCurrentIdChartTab(tabId)),
        saveMaxIdChartTab: (maxId: number, flag: boolean) => dispatch(saveMaxIdChartTab(maxId, flag)),
        publishChartEvents: (payload: any) => dispatch(publishChartEvents(payload)),
        saveElementMaxId: (maxId: number) => dispatch(saveElementMaxId(maxId)),
        saveTypesMaxId: (typeMaxId : number, elementNoKey : string) => dispatch(saveTypesMaxId(typeMaxId, elementNoKey)),
        // copy - paste
        setCopyData: (data: any) => dispatch(setCopyData(data)),
        copyAction: (tabId: number) => dispatch(copyAction(tabId)),
        cutAction: (tabId: number) => dispatch(cutAction(tabId)),
        pasteAction: (tabId: number, pointerPosition: Model.Coordinates) => dispatch(pasteAction({ tabId, pointerPosition })),
        
        removeAction: (tabId: number) => dispatch(removeAction(tabId)),
        setPointerPositionAction: (pointerPosition: Model.Coordinates) => dispatch(setPointerPositionAction(pointerPosition)),
        setOpenChart: (isOpenChart: boolean) => dispatch(openChart(isOpenChart)),
        deleteGraphScreen: (isOpenGraphScreen: boolean, isDelete: boolean) => dispatch(deleteGraphScreen(isOpenGraphScreen, isDelete)),
        saveCurrentTabId: (tabId: number) => dispatch(saveCurrentIdDiagramTab(tabId)),
        deleteDiagramByTabId: (tabId: number) => dispatch(deleteDiagramByTabId(tabId)),
        deleteTcGroupAction: (tc_id:any) => dispatch(deleteTcGroup(tc_id)),
        // GROUP ELEMENTS ACTIONS
        unGroupAction: (deletedGroupIds : number[]) => dispatch(unGroupAction(deletedGroupIds)),
        tcUnGroupAction: (deletedTCGroupIds : number[], tcId : string) => dispatch(tcUnGroupAction(deletedTCGroupIds, tcId)),
        groupAction: (data: any, newGroupEleIds: string[]) => dispatch(groupAction(data, newGroupEleIds)),
        tcGroupAction: (data: any, newGroupEleIds: string[], tcId: string) => dispatch(tcGroupAction(data, newGroupEleIds, tcId)),
        addNewTCGroups: (tcId: string) => dispatch(addNewTCGroups(tcId)),

        // DISPATCH NOTIFICATION ACTIONS
        onShowMessage: (message: Model.Message) => dispatch(actionCreators.showMessage(message)),
        // undo api
        undoEditPropertiesAction: (params:any) => dispatch(actionCreators.fetch("UNDO",`/check/undo-edit-properties`,"POST",params,false,true)),
        undoCreateElementAction: (params:any) => dispatch(actionCreators.fetch("UNDO_CREATE","/diagram/delete-ele","POST",params,false,true)),
        setUndoData: (data:Model.UndoModel) => dispatch(undo(data)),
        undoModePM: (params:any) => dispatch(actionCreators.fetch(UNDO_MODE_PM, "/diagram/set-mode-pm", "POST",params, false, true)),
        undoUpdateProperties: (params:any) => dispatch(actionCreators.fetch(UNDO_SET_PARAM,"/diagram/undo-set-param","POST",params,false,true)),
        undoMode: (params:any) => dispatch(actionCreators.fetch(UNDO_MODE, "/check/undo-mode","POST",params,false,true)),
        undoPasteAction: (params:any) => dispatch(actionCreators.fetch(UNDO_PASTE,"/diagram/delete-ele","POST",params,false,true)),
        undoCreateGroup: (params : any, actionUrl : string) => dispatch(actionCreators.fetch("DELETE_GROUP", `/diagram/${actionUrl}`, "POST", params, false, true)),
        undoDeleteGroup: (params : any, actionUrl : string) => dispatch(actionCreators.fetch("CREATE_GROUP", `/diagram/${actionUrl}`, "POST", params, false, true)),
        //undoUpdateScaleGraph: (params:any)=> dispatch(actionCreators.fetch(UNDO_UPDATE_SCALE_GRAPH,`/project/update-graph-scale`,"POST",params,false,true)),
        //setLineToChart: (isAddToChart: boolean, controlId: string, isUndo: boolean) => dispatch(addLineToChart(isAddToChart, controlId, isUndo)),
        saveNewNominalVolt: (nominalVolt: number, graphNo: number) => dispatch(saveNewNominalVolt(nominalVolt, graphNo)),
        saveChartZoom: (zoom: number, graphNo: number) => dispatch(saveChartZoom(zoom, graphNo)),
        updateGraphInfo:(param:any) => dispatch(actionCreators.fetch(UPDATE_GRAPH,"/graph/update-graph","POST",param,false,true)),
        isShowScreen: (screen:string) => dispatch(isShowScreen(screen)),
        getPDFReport: (params: any) =>
            dispatch(
              actionCreators.fetch(
                MAIN_DIAGRAM_PDF,
                "/report/get-graph-pdf",
                "POST",
                params,
                false,
                true
              )
            ),
        saveListGraphToStore: (data:any) => dispatch(saveListGraph(data)),
        onUpdateQuickProperties: (field:string,data:boolean) => dispatch(updateQuickProperties(field,data)),
        createGroup: (params : any, actionUrl : string) => dispatch(actionCreators.fetch("CREATE_GROUP", `/diagram/${actionUrl}`, "POST", params, false, true)),
        updateRotate: (tabId: number) => dispatch(rotate(tabId)),
        deleteGroup: (params : any, actionUrl : string) => dispatch(actionCreators.fetch("DELETE_GROUP", `/diagram/${actionUrl}`, "POST", params, false, true)),
        setLineToChart: (isAddToChart: boolean, controlId: string) => dispatch(addLineToChart(isAddToChart, controlId)),
        openUserCurveEvent: (params: any) => dispatch(openUserCurveEvents({type: "OPEN_USER_CURVE", data: { id: params }})),
        setCalcPointToChart: (isAddCalcPointToChart: boolean, controlId: string) => dispatch(addCalcPointToChart(isAddCalcPointToChart, controlId)),
        setModeViewOnly: (data: boolean) => dispatch(changeModeViewOnly(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);


export interface CreateElementInfo {
    kind: ElementKind;
    fetchId: string;
}

const createElementInfo = new Map<string, CreateElementInfo>([
    ["MS_SOURCE", { kind: "MS_SOURCE", fetchId: "CREATE_SOURCE" }],
    ["MS_HVCB", { kind: "MS_HVCB", fetchId: "CREATE_HVCB" }],
    ["MS_MOTOR", { kind: "MS_MOTOR", fetchId: "CREATE_MOTOR" }],
    ["MS_GENERATOR", { kind: "MS_GENERATOR", fetchId: "CREATE_GENERATOR" }],
    ["MS_TRANS3", { kind: "MS_TRANS3", fetchId: "CREATE_TRANS3" }],
    ["MS_3WINDING", { kind: "MS_3WINDING", fetchId: "CREATE_3WINDING" }],
    ["MS_TRANS1", { kind: "MS_TRANS1", fetchId: "CREATE_TRANS1" }],
    ["MS_TRANSSCOTT", { kind: "MS_TRANSSCOTT", fetchId: "CREATE_TRANSSCOTT" }],
    ["MS_DS", { kind: "MS_DS", fetchId: "CREATE_DS" }],
    ["MS_LBS", { kind: "MS_LBS", fetchId: "CREATE_LBS" }],
    ["MS_MVCB", { kind: "MS_MVCB", fetchId: "CREATE_MVCB" }],
    ["MS_CONTACTOR", { kind: "MS_CONTACTOR", fetchId: "CREATE_CONTACTOR" }],
    ["MS_FUSE", { kind: "MS_FUSE", fetchId: "CREATE_FUSE" }],
    ["MS_2E", { kind: "MS_2E", fetchId: "CREATE_2E" }],
    ["MS_ZEROCOND", { kind: "MS_ZEROCOND", fetchId: "CREATE_ZEROCOND" }],
    ["MS_CONNECT", { kind: "MS_CONNECT", fetchId: "CREATE_CONNECT" }],
    ["MS_WIRE", { kind: "MS_WIRE", fetchId: "CREATE_WIRE" }],
    ["MS_BUSBAR", { kind: "MS_BUSBAR", fetchId: "CREATE_BUSBAR" }],
    ["MS_BUSDUCT", { kind: "MS_BUSDUCT", fetchId: "CREATE_BUSDUCT" }],
    ["MS_REACTOR", { kind: "MS_REACTOR", fetchId: "CREATE_REACTOR" }],
    ["MS_IMPEDANCE", { kind: "MS_IMPEDANCE", fetchId: "CREATE_IMPEDANCE" }],
    ["MS_LVCB", { kind: "MS_LVCB", fetchId: "CREATE_LVCB" }],
    ["MS_THERMAL", { kind: "MS_THERMAL", fetchId: "CREATE_THERMAL" }],
    ["MS_THERMAL_CT", { kind: "MS_THERMAL_CT", fetchId: "CREATE_THERMAL_CT" }],
    ["MS_MOTOR_GROUP", { kind: "MS_MOTOR_GROUP", fetchId: "CREATE_MOTOR_GROUP" }],
    ["MS_LOAD", { kind: "MS_LOAD", fetchId: "CREATE_LOAD" }],
    ["MS_LIGHTBOARD", { kind: "MS_LIGHTBOARD", fetchId: "CREATE_LIGHTBOARD" }],
    ["MS_POWERBOARD", { kind: "MS_POWERBOARD", fetchId: "CREATE_POWERBOARD" }],
    ["MS_CAPACITOR", { kind: "MS_CAPACITOR", fetchId: "CREATE_CAPACITOR" }],
    ["MS_TRANSCENTER", { kind: "MS_TRANSCENTER", fetchId: "CREATE_TRANSCENTER" }],
    [Element.MS_INTO_POINT, { kind: Element.MS_INTO_POINT, fetchId: "CREATE_INTO_POINT" }],
    ["MS_VCT", { kind: "MS_VCT", fetchId: "CREATE_VCT" }],
    ["MS_VT", { kind: "MS_VT", fetchId: "CREATE_VT" }],
    ["MS_ARRESTOR", { kind: "MS_ARRESTOR", fetchId: "CREATE_ARRESTOR" }],
    ["MS_EARTH", { kind: "MS_EARTH", fetchId: "CREATE_EARTH" }],
    ["MS_CABLEHEAD", { kind: "MS_CABLEHEAD", fetchId: "CREATE_CABLEHEAD" }],
    ["MS_CT", { kind: "MS_CT", fetchId: "CREATE_CT" }],
    ["MS_ZCT", { kind: "MS_ZCT", fetchId: "CREATE_ZCT" }],
    ["MS_INV", { kind: "MS_INV", fetchId: "CREATE_INV" }],
    ["MS_RELAY", { kind: "MS_RELAY", fetchId: "CREATE_RELAY" }],
    ["MS_TEXT", { kind: "MS_TEXT", fetchId: "CREATE_TEXT" }],
]);