import { LoadingButton } from "@mui/lab";
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApplicationState } from "../../store";
import FetchHelper from "../FetchHelper";
import AlertDialog from "../common/AlertDialog";
import { Add } from '@mui/icons-material';
import HistoryProjectAddDialog, { HistoryProjectAddForm } from '../dialogs/HistoryProjectAddDialog';
import dayjs from 'dayjs';
import HistoryProjectGrid from '../project/HistoryProjectGrid';
import { store } from '../..';
import DraggablePaper from '../common/DraggablePaper';
import theme from '../../themes/globalStyles';

// FETCH ID
const GET_EDIT_HISTORY ="GET_EDIT_HISTORY"

//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

// Props
export type HistoryProjectDialogProps = {
    userId:number,
    projectId:string|undefined
    onCancel: () => void;
}


export const HistoryProjectDialog = (props: HistoryProjectDialogProps) => {
    //#region fields & hooks
    const {
        userId,
        projectId,//origin
        onCancel,
    } = props;

    const projectData = store.getState().app.projectData;
    const user = store.getState().app.user

    const [loadingFlag, setLoadingFlag] = useState(true);
    const gridRef = useRef() as any;
    const [colState, setColState] = useState<any>();

    const [editHistoryId, setEditHistoryId] = useState<string>("");
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [openHistoryProjectAddDialog,setOpenHistoryProjectAddDialog] =useState<{isOpen: boolean, mode: "ADD" | "UPDATE"}>({isOpen:false,mode:"ADD"});
    const [dataHistoryProjectAdd,setDataHistoryProjectAdd] = useState<HistoryProjectAddForm>({editHistoryId:undefined,subject:'',content:'',editDatetime:'',editor:''})
    const [dataHistoryProjectList,setDataHisotyProjectList] = useState<any>()
    const [isDeleteCallAPI,setDeleteCallAPI] = useState(false)
    const [isAddCallAPI,setAddCallAPI] = useState(false)
    const [isEditCallAPI,setEditCallAPI] = useState(false)
    const [objectAdd,setObjectAdd] = useState<any>()
    const [objectEdit,setObjectEdit] = useState<any>()
    const [listUser,setListUser] = useState<any>()
    const [subOwnerProject,setSubOwnerProject] = useState<any>()

    const [viewOnlySharedProjects, setViewOnlySharedProjects] = useState<any>(false)

    const objectGet ={
        "requestPrms":{
            "ownerId": projectData.createUserId,
            "userId": userId,
            "projectId": projectId,
        }
    }
    const objectDelete ={
        "requestPrms":{
            "userId": userId,
            "projectId": projectId,
            "editHistoryId":editHistoryId
        }
    }
    //#endregion fields & hooks

    //削除の処理
    const handleDelete = (data: any) => {
        setEditHistoryId(data.editHistoryId);
        const msg = "ID: " + data.subject + "を削除します。"
        setTitle("情報削除"); 
        setMessage(msg);
        setOpenDeleteDialog(true);
    }

    //削除の処理
    const handleShowDetailPage = (data: any) => {
        setEditHistoryId(data.editHistoryId);
        setOpenHistoryProjectAddDialog({isOpen:true,mode:"UPDATE"})
        setDataHistoryProjectAdd({editHistoryId:data.editHistoryId,subject:data.subject,content:data.content,editDatetime:data.editDatetime,editor:data.editor})
    }

    // 削除確認ダイアログでOKボタンのイベントを受け取って、APIに送信する
    const handleDeleteOK = () => {
        setDeleteCallAPI(true)
        setLoadingFlag(true);
    }

    //削除確認ダイアログでCancelボタンのイベントを受け取って、ダイアログを無効にする
    const handleDeleteCancel = () => {
        setEditHistoryId("");
        setOpenDeleteDialog(false);
    }

    const handleOpenHistoryProjectAddDialog =(e:any) =>{
        const datetime = dayjs(new Date().toLocaleString()).format("YYYY/MM/DD HH:mm")
        setOpenHistoryProjectAddDialog({isOpen:true,mode:"ADD"})
        const nameEditor = listUser.filter((item:any) =>item.sub == user?.userSub)[0]
        setDataHistoryProjectAdd({editHistoryId:undefined,subject:'',content:'',editDatetime:datetime,editor:nameEditor.family_name + nameEditor.given_name})
    }

    const handleGetListUser = (data: any) => {
        const result = [] as any[];
        data.user_records?.forEach((element: any) => {
            let userItem = {} as any
            userItem.username = element.Username;
            userItem.custom_management_no = "";
            userItem.custom_company_name = "";
            userItem.custom_zip_code = "";
            userItem.address = "";
            userItem.family_name = "";
            userItem.given_name = "";
            userItem.custom_role = "";
            userItem.email = "";
            userItem.phone_number = "";
            userItem.custom_expiration_date = "";
            userItem.custom_memo = "";
            userItem.custom_mfa = "";
            userItem.custom_mfa_system = "";
            userItem.custom_plan = "";
            userItem.custom_serial_no = "";
            userItem.isSelect = false;
            userItem.sub = "";
            userItem.account_status = element.Enabled;
            
            element.Attributes.forEach((item: any) => {
                if (item.Name === "sub") {
                    userItem.sub = item.Value;
                }
                if (item.Name === "custom:management_no") {
                    userItem.custom_management_no = item.Value;
                }
                if (item.Name === "custom:company_name") {
                    userItem.custom_company_name = item.Value;
                }
                if (item.Name === "custom:zip_code") {
                    userItem.custom_zip_code = item.Value;
                }
                if (item.Name === "address") {
                    userItem.address = item.Value;
                }
                if (item.Name === "family_name") {
                    userItem.family_name = item.Value;
                }
                if (item.Name === "given_name") {
                    userItem.given_name = item.Value;
                }
                if (item.Name === "custom:role") {
                    userItem.custom_role = item.Value;
                }
                if (item.Name === "email") {
                    userItem.email = item.Value;
                }
                if (item.Name === "phone_number") {
                    userItem.phone_number = item.Value;
                }
                if (item.Name === "custom:expiration_date") {
                    userItem.custom_expiration_date = item.Value;
                }
                if (item.Name === "custom:memo") {
                    userItem.custom_memo = item.Value;
                }
                if (item.Name === "custom:mfa") {
                    if(item.Value.toLowerCase() == "true"){
                        userItem.custom_mfa = "true";
                    }else{
                        userItem.custom_mfa = "false";
                    }
                }
                if (item.Name === "custom:mfa_system") {
                    userItem.custom_mfa_system = item.Value;
                }
                if (item.Name === "custom:plan") {
                    userItem.custom_plan = item.Value;
                }
                if (item.Name === "custom:serial_no") {
                    userItem.custom_serial_no = item.Value;
                }
            })
            result.push(userItem);
        });
        return result;
    }

    const handleHistoryProjectAddDialog = (data:any) =>{
        if(openHistoryProjectAddDialog.mode == 'ADD'){
            const ownerName = listUser.filter((item:any)=> item.sub == subOwnerProject)
            const ownerNameProject = ownerName[0].family_name + ownerName[0].given_name
            const resquest = {
                "requestPrms":{
                    "userId": userId,
                    "projectId": projectId,
                    "subject": data.subject,
                    "content": data.content,
                    "editDatetime": data.editDatetime,
                    "editor": data.editor,
                    "isShared":projectData.isShared,
                    "projectName": projectData.projectName,
                    "ownerNameProject": ownerNameProject
    
                }
            }
            setObjectAdd(resquest)
            setLoadingFlag(true)
            setAddCallAPI(true)
        }
        else{
            const resquest = {
                "requestPrms":{
                    "userId": userId,
                    "projectId": projectId,
                    "subject": data.subject,
                    "content": data.content,
                    "editDatetime": data.editDatetime,
                    "editor": data.editor,
                    "editHistoryId":editHistoryId
                }
            }
            setObjectEdit(resquest)
            setLoadingFlag(true)
            setEditCallAPI(true)
        }
        setLoadingFlag(true)
    }

    const handleGetEditHistoryProjectSuccess =(data:any)=>{
        let dataHistoryList:any = []
        data.data.forEach((item:any) =>{
            dataHistoryList.push({editHistoryId:item.editHistoryId,subject:item.subject,content:item.content,editDatetime:item.editDatetime,editor:item.editor})
        })
        gridRef.current.setRowData(dataHistoryList)
        setDataHisotyProjectList(dataHistoryList)
        
        const listUser = handleGetListUser(data.listUser.data)
        setListUser(listUser)
        setSubOwnerProject(data.sub)
        setViewOnlySharedProjects(data.editable_edit_history != true)

        setLoadingFlag(false)
    }

    const handleGetEditHistoryProjectError =(sucess:any,data:any,error:any)=>{
        setLoadingFlag(false)
    }

    const handleAddEditHistoryProjectSuccess =(success:any, data:any, error:any) =>{
        data.data.editDatetime = data.data.editDatetime
        gridRef.current.addRowData(data.data);
        setDataHisotyProjectList([data.data,...dataHistoryProjectList])
        setOpenHistoryProjectAddDialog({isOpen:false,mode:"ADD"})

        setTimeout(()=>{
            setAddCallAPI(false)
            setLoadingFlag(false)
        },2000)
    }

    const handleAddEditHistoryProjectError =(success:any, data:any, error:any) =>{
        setAddCallAPI(false)
        setOpenHistoryProjectAddDialog({isOpen:false,mode:"ADD"})
        setLoadingFlag(false)
    }

    const handleUpdateEditHistoryProjectSuccess =(success:any, data:any, error:any) =>{
        const newData = data.data
        dataHistoryProjectList.forEach((item:any)=>{
            if(item.editHistoryId == newData.editHistoryId){
                item.content = newData.content
                item.editDatetime = newData.editDatetime
                item.editor = newData.editor
                item.subject = newData.subject
            }
        })
        gridRef.current.setRowData(dataHistoryProjectList);
        setDataHisotyProjectList(dataHistoryProjectList)
        setOpenHistoryProjectAddDialog({isOpen:false,mode:'UPDATE'})
        setEditHistoryId("")
        setTimeout(()=>{
            setEditCallAPI(false)
            setLoadingFlag(false)
        },2000)
    }

    const handleUpdateEditHistoryProjectError =(success:any, data:any, error:any) =>{
        setOpenHistoryProjectAddDialog({isOpen:false,mode:'UPDATE'})
        setEditHistoryId("")
        setEditCallAPI(false)
        setLoadingFlag(false)
    }

    const handleDeleteEditHistoryProjectSuccess =(success:any, data:any, error:any) =>{
        const dataList = dataHistoryProjectList.filter((item:any)=> item.editHistoryId != data.data)
        gridRef.current.setRowData(dataList);
        setDataHisotyProjectList(dataList)
        setEditHistoryId("");
        setOpenDeleteDialog(false);
        setTimeout(()=>{
            setDeleteCallAPI(false)
            setLoadingFlag(false)
        },2000)
    }

    const handleDeleteEditHistoryProjectError =(success:any, data:any, error:any) =>{
        setEditHistoryId("");
        setDeleteCallAPI(false)
        setOpenDeleteDialog(false);
        setLoadingFlag(false)
    }

    return (
        <>
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <Dialog open={true} maxWidth={"md"} fullWidth
                PaperComponent={
                    DraggablePaper
                }
            >
                <DialogTitle
                    onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                    style={{ paddingTop: '2px', paddingBottom: '2px', background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                >
                    編集履歴
                </DialogTitle>
                <DialogContent style={{paddingTop: '20px', display: 'flex', alignItems: 'flex-end'}}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <Stack >
                            {!viewOnlySharedProjects &&
                                <Box display='flex' justifyContent='flex-end'>
                                    <Stack direction='row' mb={1} mt={1}>
                                        <Button
                                            variant="contained" 
                                            startIcon={
                                                <Add style={{fontSize:'24px', width: 50, paddingLeft:'10px'}}/>
                                            }
                                            sx={{height: '40px', padding: 0,width:50}}
                                            onClick={(e) => handleOpenHistoryProjectAddDialog(e)}
                                            disabled={user?.expired}
                                        ></Button>
                                    </Stack>
                                </Box>
                            }
                                <Stack>
                                    <HistoryProjectGrid
                                        ref={gridRef}
                                        colState={colState}
                                        onShowDetails={handleShowDetailPage}
                                        onDelete={handleDelete}
                                        heightForm="600"
                                        checkEdit={true}
                                        dataHisotyProjectList ={dataHistoryProjectList}
                                        viewOnlySharedProjects ={viewOnlySharedProjects || user?.expired}
                                    />
                                    {openDeleteDialog ?
                                        <AlertDialog title={title} message={message} isOpen={true} onOK={handleDeleteOK} onCancel={handleDeleteCancel} /> :
                                    <div />
                                    }
                                </Stack>
                                
                        </Stack>
                        <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 1 ,mb:2}}>
                            <LoadingButton variant="contained" color='inherit' type="submit" onClick={onCancel}>閉じる</LoadingButton>
                        </Stack>
                    </div>
                </DialogContent>
            </Dialog>
                {openHistoryProjectAddDialog.isOpen && <HistoryProjectAddDialog viewOnlySharedProjects={viewOnlySharedProjects || user?.expired} data={dataHistoryProjectAdd} onCancel={()=>setOpenHistoryProjectAddDialog({isOpen:false,mode:"ADD"})} onOK={handleHistoryProjectAddDialog}></HistoryProjectAddDialog>}
                
            {/*HISTORY PROJECT*/}
            <FetchHelper fetchId={GET_EDIT_HISTORY}
                    url={"/project/get-edit-history"}
                    method="POST"
                    request={true}
                    params={objectGet}
                    json={true}
                    multipart={false}
                    onComplete={(success, data, error) =>
                        success ? handleGetEditHistoryProjectSuccess(data) : handleGetEditHistoryProjectError(success, data, error)}
            />

            {isAddCallAPI && <FetchHelper fetchId={"ADD_EDIT_HISTORY"}
                    url={"/project/add-edit-history"}
                    method="POST"
                    request={true}
                    params={objectAdd}
                    json={true}
                    multipart={false}
                    onComplete={(success, data, error) =>
                        success ? handleAddEditHistoryProjectSuccess(success, data, error) : handleAddEditHistoryProjectError(success, data, error)}
            />
            }

            {isEditCallAPI && <FetchHelper fetchId={"UPDATE_EDIT_HISTORY"}
                    url={"/project/update-edit-history"}
                    method="POST"
                    request={true}
                    params={objectEdit}
                    json={true}
                    multipart={false}
                    onComplete={(success, data, error) =>
                        success ? handleUpdateEditHistoryProjectSuccess(success, data, error) : handleUpdateEditHistoryProjectError(success, data, error)}
            />
            }

            {isDeleteCallAPI && <FetchHelper fetchId={"DELETE_EDIT_HISTORY"}
                    url={"/project/delete-edit-history"}
                    method="POST"
                    request={true}
                    params={objectDelete}
                    json={true}
                    multipart={false}
                    onComplete={(success, data, error) =>
                        success ? handleDeleteEditHistoryProjectSuccess(success, data, error) : handleDeleteEditHistoryProjectError(success, data, error)}
            />
            }
            
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({});

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

export default HistoryProjectDialog;
