import { Button, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import theme from "../../themes/globalStyles";
import DraggablePaper from "../common/DraggablePaper";
import { DATA_STATUS, NEW_ID_NOT_DEFINED, userGroupRoleOptions } from "../../statics";
import { UserGroupMemberModel } from "../../models/Index";

type UserGroupMemberProps = {
    mode: "ADD" | "UPDATE",
    data: UserGroupMemberModel;
    membersOfAllGroups: any[];
    allUserList: any[];
    onOK: (data: any) => void;
    onCancel: () => void;
};

export const UserGroupMemberDialog = (props: UserGroupMemberProps) => {
    const {mode, data, membersOfAllGroups, allUserList, onOK, onCancel} = props;

    // 初期値設定
    const initialValue: UserGroupMemberModel = { ...data }

    const {control, handleSubmit, formState: {errors}, setValue, getValues, setError, clearErrors} = useForm<UserGroupMemberModel>({
        mode: "all",
        criteriaMode: "all",
        defaultValues: initialValue,
    })

    //#region methods
    const handleEditSubmit = (value: any, event: any) => {
        if (mode === "UPDATE") onOK(value)
        else if (mode === "ADD") {
            const sub = getValues("sub")
            const memberExisted = membersOfAllGroups.find((item:any) => item.sub === sub)
            if(memberExisted !== undefined){
                setError("email", {type: "custom", message:`既に「${memberExisted.userGroupName}」に属しているため、追加できません。`})
            }
            else if(getValues("fullName") == ""){
                setError("fullName", {type: "custom", message:"確認を押下でユーザー名を取得してください。"})
            }
            else{
                onOK(value)
            }
        }
    }

    const handleSetUserInfoByEmail = () =>{
        const email = getValues("email")
        let isNotFound = true
        allUserList.forEach((e:any) =>{
            if(e.email == email){
                setValue("fullName", (e.family_name || "") + (e.given_name || ""))
                setValue("username", email)
                setValue("sub", e.sub)
                isNotFound = false
            }
        })
        if(isNotFound){
            setError("email", {type: "custom", message:"ユーザーのメールアドレスをもう一度ご確認ください。"})
        }
        clearErrors("fullName")
    }

    const handleChangeEmail = (e: any) =>{
        setValue(e.target.name, e.target.value)
        if(e.target.value !== ""){
            clearErrors("email")
        }
        setValue("fullName", "")
        setValue("username", "")
        setValue("sub", "")
    }

    const handleChangePermission = (e: any) => {
        setValue(e.target.name, e.target.value)
        if (getValues("userId") !== NEW_ID_NOT_DEFINED){
            setValue("status", DATA_STATUS.UPDATE)
        }
    }
    //#endregion methods

    return (
        <>
            <Dialog open={true} maxWidth={"sm"} fullWidth PaperComponent={DraggablePaper}>
                <DialogTitle 
                    onMouseOver={(e : any) => e.target.style.cursor = "move"}
                    style={{ paddingTop: "2px", paddingBottom: "2px", background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                >
                    {mode === "ADD" ? "ユーザーの追加" : "ユーザーの編集"}
                </DialogTitle>
                <DialogContent style={{paddingTop: "20px", display: "flex", alignItems: "flex-end"}}>
                    <div style={{width: "100%"}}>
                        <form onSubmit={handleSubmit(handleEditSubmit)}>
                            <Stack gap={2}>
                                <Stack direction={"row"} width={"100%"}>
                                    <Controller
                                        name="email"
                                        control={control}
                                        rules={{required: "必須項目です。入力してください。"}}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                size="small"
                                                label="ユーザーのメールアドレス"
                                                disabled={mode==="UPDATE"}
                                                InputProps={{sx: { background: mode==="UPDATE" ? "floralwhite":"white" }}}
                                                error={!!errors?.email}
                                                helperText={errors?.email?.message}
                                                onChange={handleChangeEmail}
                                            />
                                        )}
                                    />
                                    {mode==="ADD" && <Button variant="contained" sx={{ml:"8px"}} onClick={handleSetUserInfoByEmail}>確認</Button>}
                                </Stack>
                                <Controller
                                    name="fullName"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="text"
                                            size="small"
                                            label="ユーザー名"
                                            disabled
                                            InputProps={{sx: { background:"floralwhite" }}}
                                            error={!!errors?.fullName}
                                            helperText={errors?.fullName?.message}
                                        />
                                    )}
                                />
                                <Stack>
                                    <FormControl fullWidth size="small">
                                        <InputLabel sx={{ color: "black" }}>権限</InputLabel>
                                        <Controller
                                            name="permission"
                                            control={control}
                                            render={({ field }: any) => (
                                                <Select {...field} label="権限" onChange={handleChangePermission}>
                                                    {userGroupRoleOptions.map((e) => 
                                                        <MenuItem key={e.value} value={e.value}>{e.label}</MenuItem>
                                                    )}
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </Stack>
                            </Stack>
                            <Stack direction="row" justifyContent="flex-end" gap={1} mt={2}>
                                <Button variant="contained" type="submit">{mode === "ADD" ? "追加" : "保存"}</Button>
                                <Button variant="contained" color="inherit" onClick={onCancel}>キャンセル</Button>
                            </Stack>
                        </form>
                    </div>                    
                </DialogContent>
            </Dialog>
        </>
    )
}
export default UserGroupMemberDialog;