import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from 'ag-grid-react';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { PageData } from '../../../models/Index';
import { AG_GRID_LOCALE_JP } from '../../../themes/localeJP';
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';
import PaginationCpn from '../../common/PaginationCpn';

type UserGroupGridProps = {
    pageSize: number;
    pageData: PageData;
    keyword: string;
    openSearch: boolean;
    onChangeSearchCondition: (name: string, value: any) => void;
    handleSelectUserGroup: (e: any) => void;
    handleOpenUserGroupMemberList: (e: any) => void;
};

export const UserGroupGrid = forwardRef((props: UserGroupGridProps, ref) => {
    const {
        pageSize,
        pageData,
        keyword,
        openSearch,
        onChangeSearchCondition,
        handleSelectUserGroup,
        handleOpenUserGroupMemberList,
    } = props;

    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState<any[]>([]);

    useImperativeHandle(ref, () => ({
        setRowData(value: any) {
            setRowData(value)
        },
        updateRowData(newValue: any) {
            setRowData(
                rowData.map(e => {
                    if (e.userGroupId === newValue.userGroupId) return newValue;
                    return e;
                })
            );
        },
        removeRowData(userGroupId: number) {
            setRowData(rowData.filter((e: any) => e.userGroupId != userGroupId));
        }
    }));

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);

    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);
    
    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };

    // 列の個別定義
    const columnDefs: any = [
        {
            headerName: "グループ名",
            field: "userGroupName",
            rowDrag: false,
            minWidth: 120,
            flex: 1,
        },
        {
            headerName: "備考",
            field: "notes",
            rowDrag: false,
            minWidth: 180,
            flex: 2,
        },
        {
            headerName: "登録ユーザー数",
            field: "numberOfMembers",
            rowDrag: false,
            minWidth: 120,
            flex: 1,
        }
    ];
    const rowHeight = 48;
    gridRef.current?.api?.setQuickFilter(keyword);
    
    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: openSearch ? 'calc(100vh - 350px)' : 'calc(100vh - 300px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        domLayout={"normal"}
                        rowData={rowData}
                        rowHeight={rowHeight}
                        rowSelection={'single'}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        localeText={AG_GRID_LOCALE_JP}
                        paginationPageSize={pageSize}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                        onRowClicked={handleSelectUserGroup}
                        onRowDoubleClicked={handleOpenUserGroupMemberList}
                    />
                </div>
                {pageSize && pageData && <PaginationCpn pageData={pageData} onChangeSearchCondition={onChangeSearchCondition} limit={pageSize} />}
            </div>
        </>
    );
});

export default UserGroupGrid;
