import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import PrivateRoute from './components/PrivateRoute';
import ChangePassword from './components/changePassword/ChangePassword';
import Login from './components/login/Login';
import ProjectList from './components/project/ProjectList';
import ProjectSetting from './components/project/ProjectSetting';
import ResetPassword from './components/resetPassword/ResetPassword';
import BreakCapacityEdit from './components/setting/BreakCapacity/BreakCapacityEdit';
import BreakCapacityList from './components/setting/BreakCapacity/BreakCapacityList';
import BreakElementEdit from './components/setting/BreakElement/BreakElementEdit';
import BreakElementList from './components/setting/BreakElement/BreakElementList';
import BreakGroupEdit from './components/setting/BreakGroup/BreakGroupEdit';
import BreakGroupList from './components/setting/BreakGroup/BreakGroupList';
import BreakMagniEdit from './components/setting/BreakMagni/BreakMagniEdit';
import BreakMagniList from './components/setting/BreakMagni/BreakMagniList';
import BreakOpeKindEdit from './components/setting/BreakOpeKind/BreakOpeKindEdit';
import BreakOpeKindList from './components/setting/BreakOpeKind/BreakOpeKindList';
import BreakRatedEdit from './components/setting/BreakRated/BreakRatedEdit';
import BreakRatedList from './components/setting/BreakRated/BreakRatedList';
import BreakTypeEdit from './components/setting/BreakType/BreakTypeEdit';
import BreakTypeList from './components/setting/BreakType/BreakTypeList';
import CurveHeaderEdit from './components/setting/CurveHeader/CurveHeaderEdit';
import CurveHeaderList from './components/setting/CurveHeader/CurveHeaderList';
import AddCurvePointList from './components/setting/CurvePoint/AddCurvePointList';
import CurvePointEdit from './components/setting/CurvePoint/CurvePointEdit';
import CurvePointList from './components/setting/CurvePoint/CurvePointList';
import CurveStandardEdit from './components/setting/CurveStandard/CurveStandardEdit';
import CurveStandardList from './components/setting/CurveStandard/CurveStandardList';
import DialCharacterEdit from './components/setting/DialCharacter/DialCharacterEdit';
import DialCharacterList from './components/setting/DialCharacter/DialCharacterList';
import DialCurrentEdit from './components/setting/DialCurrent/DialCurrentEdit';
import DialCurrentList from './components/setting/DialCurrent/DialCurrentList';
import DialMagniCurrentEdit from './components/setting/DialMagniCurrent/DialMagniCurrentEdit';
import DialMagniCurrentList from './components/setting/DialMagniCurrent/DialMagniCurrentList';
import DialMagniTimeEdit from './components/setting/DialMagniTime/DialMagniTimeEdit';
import DialMagniTimeList from './components/setting/DialMagniTime/DialMagniTimeList';
import DialTimeEdit from './components/setting/DialTime/DialTimeEdit';
import DialTimeList from './components/setting/DialTime/DialTimeList';
import ManufactureEdit from './components/setting/Manufacture/ManufactureEdit';
import ManufactureList from './components/setting/Manufacture/ManufactureList';
import Settings from './components/setting/Settings';
import UserList from './components/setting/Users/UserList';
import OperationHistoryList from './components/setting/OperationHistory/OperationHistoryList';
import { Layout } from './layouts/Layout';
import { Path as RoutePath } from "./path";
import { ApplicationState } from './store';
import { connect } from 'react-redux';
import { CircularProgress, ThemeProvider } from '@mui/material';
import UserGroupList from './components/setting/UserGroup/UserGroupList';
import UserGroupMemberList from './components/setting/UserGroup/UserGroupMemberList';
import UserSetting from './components/setting/Users/UserSetting';
import theme from './themes/globalStyles';
import EquipBusbarList from './components/setting/EquipBusbar/EquipBusbarList';
import EquipBusbarEdit from './components/setting/EquipBusbar/EquipBusbarEdit';

import EquipBusductList from './components/setting/EquipBusduct/EquipBusductList';
import EquipBusductEdit from './components/setting/EquipBusduct/EquipBusductEdit';
import EquipGeneratorList from './components/setting/EquipGenerator/EquipGeneratorList';
import EquipGeneratorEdit from './components/setting/EquipGenerator/EquipGeneratorEdit';
import EquipHarmoList from './components/setting/EquipHarmolist/EquipHarmoList';
import EquipHarmoEdit from './components/setting/EquipHarmolist/EquipHarmoEdit';
import EquipWirezList from './components/setting/EquipWirez/EquipWirezList';
import EquipWirezEdit from './components/setting/EquipWirez/EquipWirezEdit';
import EquipWireiList from './components/setting/EquipWirei/EquipWireiList';
import EquipWireiEdit from './components/setting/EquipWirei/EquipWireiEdit';
import EquipWirecList from './components/setting/EquipWirec/EquipWirecList';
import EquipWirecEdit from './components/setting/EquipWirec/EquipWirecEdit';
import EquipTransscottList from './components/setting/EquipTransscott/EquipTransscottList';
import EquipTransscottEdit from './components/setting/EquipTransscott/EquipTransscottEdit';
import EquipTrans3List from './components/setting/EquipTrans3/EquipTrans3List';
import EquipTrans3Edit from './components/setting/EquipTrans3/EquipTrans3Edit';
import EquipTrans1List from './components/setting/EquipTrans1/EquipTrans1List';
import EquipTrans1Edit from './components/setting/EquipTrans1/EquipTrans1Edit';
import EquipMotorhpList from './components/setting/EquipMotorhp/EquipMotorhpList';
import EquipMotorhpEdit from './components/setting/EquipMotorhp/EquipMotorhpEdit';
import EquipHarmoMaxList from './components/setting/EquipHarmomax/EquipHarmoMaxList';
import EquipHarmoMaxEdit from './components/setting/EquipHarmomax/EquipHarmoMaxEdit';
import EquipLoadList from './components/setting/EquipLoad/EquipLoadList';
import EquipLoadEdit from './components/setting/EquipLoad/EquipLoadEdit';
import EquipMotorList from './components/setting/EquipMotor/EquipMotorList';
import EquipMotorEdit from './components/setting/EquipMotor/EquipMotorEdit';
import EquipMotoreffList from './components/setting/EquipMotoreff/EquipMotoreffList';
import EquipMotoreffEdit from './components/setting/EquipMotoreff/EquipMotoreffEdit';
import ListCt1currentEdit from './components/setting/ListCt1current/ListCt1currentEdit';
import ListReactorList from './components/setting/ListReactor/ListReactorList';
import ListReactorEdit from './components/setting/ListReactor/ListReactorEdit';
import ListStdCapacityList from './components/setting/ListStdCapacity/ListStdCapacityList';
import ListStdCapacityEdit from './components/setting/ListStdCapacity/ListStdCapacityEdit';
import ListVolthighList from './components/setting/ListVolthigh/ListVolthighList';
import ListVolthighEdit from './components/setting/ListVolthigh/ListVolthighEdit';
import ListVoltlowList from './components/setting/ListVoltlow/ListVoltlowList';
import ListVoltlowEdit from './components/setting/ListVoltlow/ListVoltlowEdit';
import ListCt1currentList from './components/setting/ListCt1current/ListCt1currentList';
import EquipManufactureList from './components/setting/EquipManufacture/EquipManufactureList';
import EquipManufactureEdit from './components/setting/EquipManufacture/EquipManufactureEdit';
import NotificationList from './components/setting/Notification/NotificationList';
import ContractExpirationDate from './components/setting/ContractExpirationDate/ContractExpirationDate';
import ReflectMaster from './components/setting/ReflectMaster/ReflectMaster';
import MaintenanceNoticeScreen from './components/setting/MaintenanceNoticeScreen/MaintenanceNoticeScreen';
import ReactGA from "react-ga4"
import { useEffect } from 'react';

// トラッキングIDを設定
const trackingId = process.env.REACT_APP_GA_TRACKING_ID
if(["production", "development"].includes(process.env.NODE_ENV) && trackingId){
    ReactGA.initialize(trackingId)
}

// ページビューを記録するカスタムフック
const usePageTracking = () => {
    const location = useLocation()
    useEffect(()=>{
        if(["production", "development"].includes(process.env.NODE_ENV) && trackingId){
            ReactGA.send({hitType:"pageview",page:location.pathname + location.search})
        }
    },[location])
}

const PageTracker = () =>{
    usePageTracking()
    return null
}

export type AppProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
function App(props: AppProps) {
    return (
        (props.initAppLoading)
        ? <div style={{ 
            top: "0px",
            left: '0px',
            position: "fixed",
            zIndex: 1500,
            width: '100%',
            height: '100%',
            padding: '50vh 50% 50% 50%',
            background: '#00000030'
        }}>
            <CircularProgress />
        </div>
        : 
        <ThemeProvider theme={theme}>
        <PageTracker/>
        <Routes>
            <Route path={RoutePath.login} element={<Login />} />
            <Route path={RoutePath.changepass} element={<ChangePassword />} />
            <Route path={RoutePath.resetpass} element={<ResetPassword />} />
            <Route path={RoutePath.settinguser} element={<UserSetting />} />
            <Route path='/' element={<PrivateRoute returnElement={<Layout />}></PrivateRoute>}>
                <Route index element={<ProjectList />} />
                <Route path={RoutePath.projects} element={<ProjectList />} />
                <Route path={RoutePath.projectSetting} element={<ProjectSetting />} />
                <Route path={RoutePath.home} element={<Home />} />
                <Route path={RoutePath.setting} element={<Settings />}>
                    <Route index element={<ManufactureList />} />
                    <Route path={RoutePath.ManufactureList} element={<ManufactureList />} />
                    <Route path={RoutePath.ManufactureEdit + "/:mode/:id"} element={<ManufactureEdit />} />
                    <Route path={RoutePath.ManufactureEdit + "/:mode"} element={<ManufactureEdit />} />
                    <Route path={RoutePath.BreakGroupList} element={<BreakGroupList />} />
                    <Route path={RoutePath.BreakGroupEdit + "/:mode/:id"} element={<BreakGroupEdit />} />
                    <Route path={RoutePath.BreakGroupEdit + "/:mode"} element={<BreakGroupEdit />} />
                    <Route path={RoutePath.BreakTypeList} element={<BreakTypeList />} />
                    <Route path={RoutePath.BreakTypeEdit + "/:mode/:id"} element={<BreakTypeEdit />} />
                    <Route path={RoutePath.BreakTypeEdit + "/:mode"} element={<BreakTypeEdit />} />
                    <Route path={RoutePath.BreakRatedList} element={<BreakRatedList />} />
                    <Route path={RoutePath.BreakRatedEdit + "/:mode/:id"} element={<BreakRatedEdit />} />
                    <Route path={RoutePath.BreakRatedEdit + "/:mode"} element={<BreakRatedEdit />} />
                    <Route path={RoutePath.BreakElementList} element={<BreakElementList />} />
                    <Route path={RoutePath.BreakElementEdit + "/:mode/:id"} element={<BreakElementEdit />} />
                    <Route path={RoutePath.BreakElementEdit + "/:mode"} element={<BreakElementEdit />} />
                    <Route path={RoutePath.DialMagniCurrentList} element={<DialMagniCurrentList />} />
                    <Route path={RoutePath.DialMagniCurrentEdit + "/:mode/:id"} element={<DialMagniCurrentEdit />} />
                    <Route path={RoutePath.DialMagniCurrentEdit + "/:mode"} element={<DialMagniCurrentEdit />} />
                    <Route path={RoutePath.BreakMagniList} element={<BreakMagniList />} />
                    <Route path={RoutePath.BreakMagniEdit + "/:mode/:id"} element={<BreakMagniEdit />} />
                    <Route path={RoutePath.BreakMagniEdit + "/:mode"} element={<BreakMagniEdit />} />
                    <Route path={RoutePath.BreakOpeKindList} element={<BreakOpeKindList />} />
                    <Route path={RoutePath.BreakOpeKindEdit + "/:mode/:id"} element={<BreakOpeKindEdit />} />
                    <Route path={RoutePath.BreakOpeKindEdit + "/:mode"} element={<BreakOpeKindEdit />} />
                    <Route path={RoutePath.DialCharacterList} element={<DialCharacterList />} />
                    <Route path={RoutePath.DialCharacterEdit + "/:mode/:id"} element={<DialCharacterEdit />} />
                    <Route path={RoutePath.DialCharacterEdit + "/:mode"} element={<DialCharacterEdit />} />
                    <Route path={RoutePath.DialMagniTimeList} element={<DialMagniTimeList />} />
                    <Route path={RoutePath.DialMagniTimeEdit + "/:mode/:id"} element={<DialMagniTimeEdit />} />
                    <Route path={RoutePath.DialMagniTimeEdit + "/:mode"} element={<DialMagniTimeEdit />} />
                    <Route path={RoutePath.DialCurrentList} element={<DialCurrentList />} />
                    <Route path={RoutePath.DialCurrentEdit + "/:mode/:id"} element={<DialCurrentEdit />} />
                    <Route path={RoutePath.DialCurrentEdit + "/:mode"} element={<DialCurrentEdit />} />
                    <Route path={RoutePath.CurveStandardList} element={<CurveStandardList />} />
                    <Route path={RoutePath.CurveStandardEdit + "/:mode/:id"} element={<CurveStandardEdit />} />
                    <Route path={RoutePath.CurveStandardEdit + "/:mode"} element={<CurveStandardEdit />} />
                    <Route path={RoutePath.CurveHeaderList} element={<CurveHeaderList />} />
                    <Route path={RoutePath.CurveHeaderEdit + "/:mode/:id"} element={<CurveHeaderEdit />} />
                    <Route path={RoutePath.CurveHeaderEdit + "/:mode"} element={<CurveHeaderEdit />} />
                    <Route path={RoutePath.DialTimeList} element={<DialTimeList />} />
                    <Route path={RoutePath.DialTimeEdit + "/:mode/:id"} element={<DialTimeEdit />} />
                    <Route path={RoutePath.DialTimeEdit + "/:mode"} element={<DialTimeEdit />} />
                    <Route path={RoutePath.BreakCapacityList} element={<BreakCapacityList />} />
                    <Route path={RoutePath.BreakCapacityEdit + "/:mode/:id"} element={<BreakCapacityEdit />} />
                    <Route path={RoutePath.BreakCapacityEdit + "/:mode"} element={<BreakCapacityEdit />} />
                    <Route path={RoutePath.CurvePointList} element={<CurvePointList />} />
                    <Route path={RoutePath.CurvePointEdit + "/:mode/:id"} element={<CurvePointEdit />} />
                    <Route path={RoutePath.CurvePointEdit + "/:mode"} element={<CurvePointEdit />} />
                    <Route path={RoutePath.CurvePointAddList} element={<AddCurvePointList />} />

                    <Route path={RoutePath.EquipBusBarList} element={<EquipBusbarList />} />
                    <Route path={RoutePath.EquipBusBarEdit + "/:mode/:id"} element={<EquipBusbarEdit />} />
                    <Route path={RoutePath.EquipBusBarEdit + "/:mode"} element={<EquipBusbarEdit />} />
					<Route path={RoutePath.EquipBusDuctList} element={<EquipBusductList />} />
                    <Route path={RoutePath.EquipBusDuctEdit + "/:mode/:id"} element={<EquipBusductEdit />} />
                    <Route path={RoutePath.EquipBusDuctEdit + "/:mode"} element={<EquipBusductEdit />} />
                    <Route path={RoutePath.EquipGeneratorList} element={<EquipGeneratorList />} />
                    <Route path={RoutePath.EquipGeneratorEdit + "/:mode/:id"} element={<EquipGeneratorEdit />} />
                    <Route path={RoutePath.EquipGeneratorEdit + "/:mode"} element={<EquipGeneratorEdit />} />
                    <Route path={RoutePath.EquipHarmoList} element={<EquipHarmoList />} />
                    <Route path={RoutePath.EquipHarmoEdit + "/:mode/:id"} element={<EquipHarmoEdit />} />
                    <Route path={RoutePath.EquipHarmoEdit + "/:mode"} element={<EquipHarmoEdit />} />
                    <Route path={RoutePath.EquipHarmoMaxList} element={<EquipHarmoMaxList />} />
                    <Route path={RoutePath.EquipHarmoMaxEdit + "/:mode/:id"} element={<EquipHarmoMaxEdit />} />
                    <Route path={RoutePath.EquipHarmoMaxEdit + "/:mode"} element={<EquipHarmoMaxEdit />} />
                    <Route path={RoutePath.EquipLoadList} element={<EquipLoadList />} />
                    <Route path={RoutePath.EquipLoadEdit + "/:mode/:id"} element={<EquipLoadEdit />} />
                    <Route path={RoutePath.EquipLoadEdit + "/:mode"} element={<EquipLoadEdit />} />
                    <Route path={RoutePath.EquipMotorList} element={<EquipMotorList />} />
                    <Route path={RoutePath.EquipMotorEdit + "/:mode/:id"} element={<EquipMotorEdit />} />
                    <Route path={RoutePath.EquipMotorEdit + "/:mode"} element={<EquipMotorEdit />} />
                    <Route path={RoutePath.EquipMotoreffList} element={<EquipMotoreffList />} />
                    <Route path={RoutePath.EquipMotoreffEdit + "/:mode/:id"} element={<EquipMotoreffEdit />} />
                    <Route path={RoutePath.EquipMotoreffEdit + "/:mode"} element={<EquipMotoreffEdit />} />

					<Route path={RoutePath.EquipMotorhpList} element= {<EquipMotorhpList/>}/>
                    <Route path={RoutePath.EquipMotorhpEdit + "/:mode/:id"} element={<EquipMotorhpEdit />} />
                    <Route path={RoutePath.EquipMotorhpEdit + "/:mode"} element={<EquipMotorhpEdit />} />
					<Route path={RoutePath.EquipTrans1List} element= {<EquipTrans1List/>}/>
					<Route path={RoutePath.EquipTrans1Edit + "/:mode/:id"} element={<EquipTrans1Edit />} />
                    <Route path={RoutePath.EquipTrans1Edit + "/:mode"} element={<EquipTrans1Edit />} />
                    <Route path={RoutePath.EquipTrans3List} element= {<EquipTrans3List/>}/>
                    <Route path={RoutePath.EquipTrans3Edit + "/:mode/:id"} element={<EquipTrans3Edit />} />
                    <Route path={RoutePath.EquipTrans3Edit + "/:mode"} element={<EquipTrans3Edit />} />
                    <Route path={RoutePath.EquipTransscottList} element= {<EquipTransscottList/>}/>
                    <Route path={RoutePath.EquipTransscottEdit + "/:mode/:id"} element={<EquipTransscottEdit />} />
                    <Route path={RoutePath.EquipTransscottEdit + "/:mode"} element={<EquipTransscottEdit />} />
                    <Route path={RoutePath.EquipWirecList} element= {<EquipWirecList/>}/>
                    <Route path={RoutePath.EquipWirecEdit + "/:mode/:id"} element={<EquipWirecEdit />} />
                    <Route path={RoutePath.EquipWirecEdit + "/:mode"} element={<EquipWirecEdit />} />
                    <Route path={RoutePath.EquipWireiList} element= {<EquipWireiList/>}/>
                    <Route path={RoutePath.EquipWireiEdit + "/:mode/:id"} element={<EquipWireiEdit />} />
                    <Route path={RoutePath.EquipWireiEdit + "/:mode"} element={<EquipWireiEdit />} />
                    <Route path={RoutePath.EquipWirezList} element= {<EquipWirezList/>}/>
                    <Route path={RoutePath.EquipWirezEdit + "/:mode/:id"} element={<EquipWirezEdit />} />
                    <Route path={RoutePath.EquipWirezEdit + "/:mode"} element={<EquipWirezEdit />} />
                    <Route path={RoutePath.EquipManufactureList} element={<EquipManufactureList />} />
                    <Route path={RoutePath.EquipManufactureEdit + "/:mode/:id"} element={<EquipManufactureEdit />} />
                    <Route path={RoutePath.EquipManufactureEdit + "/:mode"} element={<EquipManufactureEdit />} />

                    <Route path={RoutePath.ListCt1currentList} element= {<ListCt1currentList/>}/>
                    <Route path={RoutePath.ListCt1currentEdit + "/:mode/:id"} element={<ListCt1currentEdit />} />
                    <Route path={RoutePath.ListCt1currentEdit + "/:mode"} element={<ListCt1currentEdit />} />
                    <Route path={RoutePath.ListReactorList} element= {<ListReactorList/>}/>
                    <Route path={RoutePath.ListReactorEdit + "/:mode/:id"} element={<ListReactorEdit />} />
                    <Route path={RoutePath.ListReactorEdit + "/:mode"} element={<ListReactorEdit />} />
                    <Route path={RoutePath.ListStdCapacityList} element= {<ListStdCapacityList/>}/>
                    <Route path={RoutePath.ListStdCapacityEdit + "/:mode/:id"} element={<ListStdCapacityEdit />} />
                    <Route path={RoutePath.ListStdCapacityEdit + "/:mode"} element={<ListStdCapacityEdit />} />
                    <Route path={RoutePath.ListVolthighList} element= {<ListVolthighList/>}/>
                    <Route path={RoutePath.ListVolthighEdit + "/:mode/:id"} element={<ListVolthighEdit />} />
                    <Route path={RoutePath.ListVolthighEdit + "/:mode"} element={<ListVolthighEdit />} />
                    <Route path={RoutePath.ListVoltlowList} element= {<ListVoltlowList/>}/>
                    <Route path={RoutePath.ListVoltlowEdit + "/:mode/:id"} element={<ListVoltlowEdit />} />
                    <Route path={RoutePath.ListVoltlowEdit + "/:mode"} element={<ListVoltlowEdit />} />
                    <Route path={RoutePath.UserGroupList} element={<UserGroupList />} />
                    <Route path={RoutePath.UserGroupList + "/:userGroupId"} element={<UserGroupMemberList />} />
                    <Route path={RoutePath.UserList} element={<UserList />} />
                    <Route path={RoutePath.OperationHistoryList} element={<OperationHistoryList />} />
                    <Route path={RoutePath.NotificationList} element={<NotificationList />} />
                    <Route path={RoutePath.ContractExpirationDate} element={<ContractExpirationDate />} />
                    <Route path={RoutePath.ReflectMaster} element={<ReflectMaster />} />
                    <Route path={RoutePath.MaintenanceNoticeScreen} element={<MaintenanceNoticeScreen/>} />
                </Route>
            </Route>
        </Routes>
        </ThemeProvider> 
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    initAppLoading: state.app.initAppLoading,
})
  
const mapDispatchToProps = {};
  
export default connect(mapStateToProps, mapDispatchToProps)(App as any);
// export default App;