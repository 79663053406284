
export const Path = {
    projects: "/projects",
    projectSetting: "/projects/setting/:projectId",
    login: "/login",
    changepass: "/changepass",
    resetpass: "/resetpass",
    settinguser: "/settinguser",
    Dashboard: "/dashboard/app",
    home: "/home/:projectId",
    setting: "/setting",
    BreakRoot: "/setting/break",
    CurveRoot: "/setting/curve",
    ManufactureList: "/setting/break/manufacture",
    ManufactureEdit: "/setting/break/manufactureEdit",
    BreakGroupList: "/setting/break/breakGroup",
    BreakGroupEdit: "/setting/break/breakGroupEdit",
    BreakTypeList: "/setting/break/breakType",
    BreakTypeEdit: "/setting/break/breakTypeEdit",
    BreakRatedList: "/setting/break/breakRated",
    BreakRatedEdit: "/setting/break/breakRatedEdit",
    BreakElementList: "/setting/break/breakElement",
    DialMagniCurrentList:"/setting/break/DialMagniCurrent",
    DialMagniCurrentEdit:"/setting/break/DialMagniCurrentEdit", 
    BreakOpeKindList: "/setting/break/OpeKind",
    BreakOpeKindEdit: "/setting/break/OpeKindEdit",
    BreakMagniList: "/setting/break/BreakMagni",
    BreakMagniEdit: "/setting/break/BreakMagniEdit",
    BreakElementEdit: "/setting/break/breakElementEdit",
    DialCharacterList: "/setting/break/dialCharacter",
    DialCharacterEdit: "/setting/break/dialCharacterEdit",
    DialMagniTimeList: "/setting/break/dialMagniTimeList",
    DialMagniTimeEdit: "/setting/break/dialMagniTimeEdit",
    DialCurrentList: "/setting/break/dialCurrentList",
    DialCurrentEdit: "/setting/break/dialCurrentEdit",
    CurveStandardList: "/setting/break/curveStandard",
    CurveStandardEdit: "/setting/break/curveStandardEdit",
    CurveHeaderList: "/setting/curve/CurveHeader",
    CurveHeaderEdit: "/setting/curve/CurveHeaderEdit",
    CurvePointAddList: "/setting/curve/AddCurvePointList",
    DialTimeList: "/setting/break/dialTimeList",
    DialTimeEdit: "/setting/break/dialTimeEdit",
    CurvePointList: "/setting/curve/CurvePoint",
    CurvePointEdit: "/setting/curve/CurvePointEdit",
    BreakCapacityList: "/setting/break/breakCapacityList",
    BreakCapacityEdit: "/setting/break/breakCapacityEdit",
    UserGroupList:"/setting/userGroup",
    UserList:"/setting/users",
    OperationHistoryList:"/setting/operationHistory",
    EquipBusBarList: "/setting/equip/equipBusBarList",
    EquipBusBarEdit: "/setting/equip/equipBusBarEdit",
    EquipTrans1List: "/setting/equip/equipTrans1List",
    EquipTrans1Edit: "/setting/equip/equipTrans1Edit",
    EquipTrans3List: "/setting/equip/equipTrans3List",
    EquipTrans3Edit: "/setting/equip/equipTrans3Edit",
    EquipTransscottList: "/setting/equip/equipTransscottList",
    EquipTransscottEdit: "/setting/equip/equipTransscottEdit",
    EquipWirecList: "/setting/equip/equipWirecList",
    EquipWirecEdit: "/setting/equip/equipWirecEdit",
    EquipWireiList: "/setting/equip/equipWireiList",
    EquipWireiEdit: "/setting/equip/equipWireiEdit",
    EquipWirezList: "/setting/equip/equipWirezList",
    EquipWirezEdit: "/setting/equip/equipWirezEdit",
    EquipBusDuctList: "/setting/equip/equipBusDuctList",
    EquipBusDuctEdit: "/setting/equip/equipBusDuctEdit",
    EquipGeneratorList: "/setting/equip/equipGeneratorList",
    EquipGeneratorEdit: "/setting/equip/equipGeneratorEdit",
    EquipHarmoList: "/setting/equip/equipHarmoList",
    EquipHarmoEdit: "/setting/equip/equipHarmoEdit",
	EquipMotorhpList: "/setting/equip/equipMotorhpList",
    EquipMotorhpEdit: "/setting/equip/equipMotorhpEdit",	
	EquipHarmoMaxList: "/setting/equip/equipHarmoMaxList",
    EquipHarmoMaxEdit: "/setting/equip/equipHarmoMaxEdit",
    EquipLoadList: "/setting/equip/equipLoadList",
    EquipLoadEdit: "/setting/equip/equipLoadEdit",
    EquipMotorList: "/setting/equip/equipMotorList",
    EquipMotorEdit: "/setting/equip/equipMotorEdit",
    EquipMotoreffList: "/setting/equip/equipMotoreffList",
    EquipMotoreffEdit: "/setting/equip/equipMotoreffEdit",
    EquipManufactureList:"/setting/equip/equipManufactureList",
    EquipManufactureEdit:"/setting/equip/equipManufactureEdit",
    ListCt1currentList: "/setting/list/listCt1currentList",
    ListCt1currentEdit : "/setting/list/listCt1currentEdit",
    ListReactorList: "/setting/list/listReactorList",
    ListReactorEdit : "/setting/list/listReactorEdit",
    ListStdCapacityList: "/setting/list/listStdCapacityList",
    ListStdCapacityEdit : "/setting/list/listStdCapacityEdit",
    ListVolthighList: "/setting/list/listVolhighList",
    ListVolthighEdit : "/setting/list/listVolhighEdit",
    ListVoltlowList: "/setting/list/listVollowList",
    ListVoltlowEdit : "/setting/list/listVollowEdit",
    NotificationList:"/setting/notification",
    ContractExpirationDate:"/setting/contractExpirationDate",
    ReflectMaster:"/setting/reflectMaster",
    MaintenanceNoticeScreen:"/setting/maintainance",
}
