import { Box, Drawer } from '@mui/material';
import { Breakpoint, styled } from '@mui/material/styles';
import NavSection from '../components/navigation/NavSection';
import useResponsive from '../hooks/useResponsive';
import { NavMenuItem } from '../models/Index';
import { Path as RoutePath } from "../path";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { useEffect, useState } from 'react';
import { ROLE_ADMIN } from '../models/Constants';
import { GROUP_PERMISSION } from '../statics';

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH
    },
}));

type DashboardSidebarProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function DashboardSidebar(props: DashboardSidebarProps) {
    const {user, userGroupPermission} = props
    let isDesktop = useResponsive({ query: 'up', key: 'lg' as Breakpoint });

    const [sidebarConfig, setSidebarConfig] = useState<NavMenuItem[]>([])
    const allSidebar = [
        {
            key: RoutePath.UserGroupList,
            title: "グループ管理",
            path: RoutePath.UserGroupList,
            icon: "",
        },
        {
            key: RoutePath.UserList,
            title: "ユーザー管理",
            path:RoutePath.UserList,
            icon:"",
        },
        {
            key: RoutePath.OperationHistoryList,
            title: "履歴表示",
            path:RoutePath.OperationHistoryList,
            icon:"",
        },
        {
            key: RoutePath.NotificationList,
            title: "お知らせ設定",
            path:RoutePath.NotificationList,
            icon:"",
        },
        {
            key: RoutePath.ContractExpirationDate,
            title: "契約終了日の事前通知設定",
            path:RoutePath.ContractExpirationDate,
            icon:"",
        },
        {
            key: RoutePath.MaintenanceNoticeScreen,
            title: "メンテナンス中画面設定",
            path:RoutePath.MaintenanceNoticeScreen,
            icon:"",
        },
        {
            key: "動作特性曲線管理",
            title: "動作特性曲線管理",
            path:"",
            icon:"",
            children:[
                {
                    key: RoutePath.BreakRoot,
                    title: "Break",
                    path: RoutePath.ManufactureList,
                    icon: '',
                    children: [
                        // Break
                        {
                            key: RoutePath.BreakRoot,
                            title: "Break",
                            path: RoutePath.ManufactureList,
                            icon: '',
                            children: [
                                {
                                    key: "manufacture",
                                    title: "メーカー",
                                    path: RoutePath.ManufactureList,
                                    icon: "",
                                },
                                {
                                    key: "breakGroup",
                                    title: "機器グループ",
                                    path: RoutePath.BreakGroupList,
                                    icon: "",
                                },
                                {
                                    key: "breakType",
                                    title: "型式",
                                    path: RoutePath.BreakTypeList,
                                    icon: "",
                                },
                                {
                                    key: "breakOpeKind",
                                    title: "動作種類",
                                    path: RoutePath.BreakOpeKindList,
                                    icon: "",
                                },
                                {
                                    key: "breakRated",
                                    title: "定格ダイアル",
                                    path: RoutePath.BreakRatedList,
                                    icon: "",
                                },
                                {
                                    key: "breakMagni",
                                    title: "定格倍率",
                                    path: RoutePath.BreakMagniList,
                                    icon: "",
                                },
                                {
                                    key: "breakElement",
                                    title: "要素",
                                    path: RoutePath.BreakElementList,
                                    icon: "",
                                },
                                {
                                    key: "dialCharacter",
                                    title: "特性",
                                    path: RoutePath.DialCharacterList,
                                    icon: "",
                                },
                                {
                                    key: "dialCurrent",
                                    title: "電流ダイアル",
                                    path: RoutePath.DialCurrentList,
                                    icon: "",
                                },
                                {
                                    key: "dialTime",
                                    title: "時間ダイアル",
                                    path: RoutePath.DialTimeList,
                                    icon: "",
                                },
                                {
                                    key: "dialMagniCurrent",
                                    title: "電流倍率",
                                    path: RoutePath.DialMagniCurrentList,
                                    icon: "",
                                },
                                {
                                    key: "dialMagniTime",
                                    title: "時間倍率",
                                    path: RoutePath.DialMagniTimeList,
                                    icon: "",
                                },
                
                            ],
                        },
                        // Curve
                        {
                            key: RoutePath.CurveRoot,
                            title: "Curve",
                            path: RoutePath.CurveStandardList,
                            icon: '',
                            children: [
                                {
                                    key: "curveStandard",
                                    title: "曲線基準",
                                    path: RoutePath.CurveStandardList,
                                    icon: "",
                                },
                                {
                                    key: "curveHeader",
                                    title: "動作特性本体",
                                    path: RoutePath.CurveHeaderList,
                                    icon: "",
                                }
                
                            ],
                        },
                    ]
                }
            ]
        },
        {
            key: "装置管理",
            title: "装置管理",
            path:"",
            icon:"",
            children:[
                {
                    key: "equip",
                    title: "equip",
                    path: RoutePath.DialMagniCurrentList,
                    icon: "",
                    children: [
                        {
                            key: RoutePath.BreakRoot,
                            title: "equip",
                            path: RoutePath.ManufactureList,
                            icon: '',
                            children: [
                                {
                                    key: "equip_busbar",
                                    title: "ブスバー",
                                    path: RoutePath.EquipBusBarList,
                                    icon: '',
                                },
                                {
                                    key: "equip_busduct",
                                    title: "バスダクト",
                                    path: RoutePath.EquipBusDuctList,
                                    icon: '',
                                },
                                {
                                    key: "equip_generator",
                                    title: "発電機",
                                    path: RoutePath.EquipGeneratorList,
                                    icon: '',
                                },
                                {
                                    key: "equip_harmolist",
                                    title: "高調波発生機器",
                                    path: RoutePath.EquipHarmoList,
                                    icon: '',
                                },
                                {
                                    key: "harmomax",
                                    title: "高調波発生機器(MAX)",
                                    path: RoutePath.EquipHarmoMaxList,
                                    icon: '',
                                },
                                {
                                    key: "equip_load",
                                    title: "負荷",
                                    path: RoutePath.EquipLoadList,
                                    icon: '',
                                },
                                {
                                    key: "equip_motor",
                                    title: "電動機",
                                    path: RoutePath.EquipMotorList,
                                    icon: '',
                                },
                                {
                                    key: "equip_motoreff",
                                    title: "電動機(FF)",
                                    path: RoutePath.EquipMotoreffList,
                                    icon: '',
                                },
                                {
                                    key: "equip_motorhp",
                                    title: "電動機(HP)",
                                    path: RoutePath.EquipMotorhpList,
                                    icon: '',
                                },
                                {
                                    key: "equip_trans1",
                                    title: "単相変圧器",
                                    path: RoutePath.EquipTrans1List,
                                    icon: '',
                                },
                                {
                                    key: "equip_trans3",
                                    title: "三相変圧器",
                                    path: RoutePath.EquipTrans3List,
                                    icon: '',
                                },
                                {
                                    key: "equip_transscott",
                                    title: "スコット結線変圧器",
                                    path: RoutePath.EquipTransscottList,
                                    icon: '',
                                },

                                {
                                    key: "equip_wirec",
                                    title: "電線(C)",
                                    path: RoutePath.EquipWirecList,
                                    icon: '',
                                },
                                {
                                    key: "equip_wirei",
                                    title: "電線(I)",
                                    path: RoutePath.EquipWireiList,
                                    icon: '',
                                },
                                {
                                    key: "equip_wirez",
                                    title: "電線(Z)",
                                    path: RoutePath.EquipWirezList,
                                    icon: '',
                                },
                                {
                                    key: "equip_manufacture",
                                    title: "メーカー",
                                    path: RoutePath.EquipManufactureList,
                                    icon: '',
                                }
                            ]
                        },
                    ]
                },
                {
                    key: RoutePath.BreakRoot,
                    title: "list",
                    path: RoutePath.ManufactureList,
                    icon:"",
                    children:[
                        {
                            key: "list",
                            title: "list",
                            path: RoutePath.DialMagniCurrentList,
                            icon: "",
                            children:[
                                {
                                    key: "list_ct1current",
                                    title: "一次側電流値",
                                    path: RoutePath.ListCt1currentList,
                                    icon: '',
                                },
                                {
                                    key: "list_reactor",
                                    title: "リアクトル",
                                    path: RoutePath.ListReactorList,
                                    icon: '',
                                },
                                {
                                    key: "list_stdcapacity",
                                    title: "基準容量",
                                    path: RoutePath.ListStdCapacityList,
                                    icon: '',
                                },
                                {
                                    key: "list_volthigh",
                                    title: "高圧電圧",
                                    path: RoutePath.ListVolthighList,
                                    icon: '',
                                },
                                {
                                    key: "list_voltlow",
                                    title: "低圧電圧",
                                    path: RoutePath.ListVoltlowList,
                                    icon: '',
                                },
                            ]
                        }
                    ]
                }
            ]
        },
        
    ];

    useEffect(() => {
        let sidebar: NavMenuItem[] = []
        if (user?.userRole === ROLE_ADMIN){
            sidebar = allSidebar
            if(process.env.REACT_APP_IS_ACCESS_REFLECT == "true"){
                sidebar.push({
                    key:"データ移行",
                    title:"データ移行",
                    path:RoutePath.ReflectMaster,
                    icon:"",
                })
            }
        } else if (userGroupPermission?.permission === GROUP_PERMISSION.ADMIN){
            sidebar = [
                {
                    key: RoutePath.UserGroupList,
                    title: "グループ管理",
                    path: RoutePath.UserGroupList,
                    icon: "",
                },
                {
                    key: RoutePath.OperationHistoryList,
                    title: "履歴表示",
                    path:RoutePath.OperationHistoryList,
                    icon:"",
                },
            ]
        }
        setSidebarConfig(sidebar)
    },[])

    const renderContent = (
        <>
            <div style={{ height: 'calc(100vh - 70px)', width: '100%', marginTop: '20px', overflowY: 'auto' }}>
                <div style={{ marginLeft: '20px', marginBottom: "15px" }}>
                    <NavSection navConfig={sidebarConfig}></NavSection>
                </div>
            </div>
            <Box sx={{ flexGrow: 1 }} />
        </>
    );

    return (
        <>
            {isDesktop ? (
                <RootStyle>
                    <Drawer
                        open
                        variant="persistent"
                        PaperProps={{
                            sx: {
                                width: DRAWER_WIDTH,
                                bgcolor: 'background.default',
                                borderRightStyle: 'dashed',
                                marginTop: "51px"
                            }
                        }}
                    >
                        {renderContent}
                    </Drawer>
                </RootStyle>
            ) : (
                <Drawer PaperProps={{sx: { width: DRAWER_WIDTH }}}>
                    {renderContent}
                </Drawer>
            )}
        </>
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    user: state.app.user,
    userGroupPermission: state.app.userGroupPermission
})

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSidebar as any);