import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Collapse, Grid, IconButton, List, ListItem, Stack, Typography, Tooltip, Button, FormControl, Select, MenuItem, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { ElementKind } from "../models/ElementKind";
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';

//icon 1
import icon1_1 from "../images/icon/ms_source.png";
import icon1_2 from "../images/icon/ms_generator.png";
import icon1_3 from "../images/icon/ms_trans3.png";
import icon1_4 from "../images/icon/ms_3winding.png";
import icon1_5 from "../images/icon/ms_trans1.png";
import icon1_6 from "../images/icon/ms_transscott.png";


// icon 2
import icon1 from "../images/icon/ms_ds.png";
import icon2 from "../images/icon/ms_lbs.png";
import icon2_3 from "../images/icon/ms_mvcb.png";
import icon2_4 from "../images/icon/ms_contactor.png";
import icon2_5 from "../images/icon/ms_hvcb.png";
import icon2_6 from "../images/icon/ms_fuse.png";
import icon2_7 from "../images/icon/ms_2e.png";
import icon2_8 from "../images/icon/ms_zerocond.png";
import icon2_9 from "../images/icon/ms_connect.png";
import icon2_10 from "../images/icon/ms_wire.png";
import icon2_11 from "../images/icon/ms_busbar.png";
import icon2_12 from "../images/icon/ms_busduct.png";
import icon2_13 from "../images/icon/ms_reactor.png";
import icon2_14 from "../images/icon/ms_impedance.png";
import icon2_15 from "../images/icon/ms_lvcb.png";
import icon2_16 from "../images/icon/ms_thermal.png";
import icon2_17 from "../images/icon/ms_thermal_ct.png";

// icon 3
import icon3_1 from "../images/icon/ms_motor_group.png";
import icon3_3 from "../images/icon/ms_load.png";
import icon3_4 from "../images/icon/ms_lightboard.png";
import icon3_5 from "../images/icon/ms_powerboard.png";
import icon3_6 from "../images/icon/ms_capacitor.png";
import iconMSTransCenter from "../images/icon/ms_transcenter.png";
import iconMotor from "../images/icon/ms_motor.png";


// icon 4
import icon4_1 from "../images/icon/ms_vct.png";
import iconMsVT from "../images/icon/ms_vt.png";
import icon4_3 from "../images/icon/ms_arrestor.png";
import icon4_4 from "../images/icon/ms_earth.png";
import icon4_5 from "../images/icon/ms_cablehead.png";
import icon4_6 from "../images/icon/ms_ct.png";
import iconMsZCT from "../images/icon/ms_zct.png";
import icon4_8 from "../images/icon/ms_inv.png";
import icon4_9 from "../images/icon/ms_relay.png";

// icon 5(horizontal)
import icon5 from "../images/icon/ms_ds_1.png";
import icon5_1 from "../images/icon/ms_ds_2.png";
import icon5_2 from "../images/icon/ms_lbs_1.png";
import icon5_3 from "../images/icon/ms_lbs_2.png";
import icon5_4 from "../images/icon/ms_mvcb_1.png";
import icon5_5 from "../images/icon/ms_mvcb_2.png";
import icon5_6 from "../images/icon/ms_contactor_1.png";
import icon5_7 from "../images/icon/ms_contactor_2.png";
import icon5_8 from "../images/icon/ms_hvcb_1.png";
import icon5_9 from "../images/icon/ms_hvcb_2.png";
import icon5_10 from "../images/icon/ms_fuse_1.png";
import icon5_11 from "../images/icon/ms_fuse_2.png";
import icon5_12 from "../images/icon/ms_wire_landscape.png";
import icon5_13 from "../images/icon/ms_busbar_landscape.png";
import icon5_14 from "../images/icon/ms_busduct_landscape.png";
import icon5_15 from "../images/icon/ms_reactor_1.png";
import icon5_16 from "../images/icon/ms_impedance_1.png";
import icon5_17 from "../images/icon/ms_lvcb_1.png";
import icon5_18 from "../images/icon/ms_lvcb_2.png";
import icon5_19 from "../images/icon/ms_connect_1.png";
import icon5_20 from "../images/icon/ms_cablehead_1.png";
import icon5_21 from "../images/icon/ms_cablehead_2.png";
import icon5_22 from "../images/icon/ms_relay_1.png";


import iconText from "../images/icon/ms_text.png";
import theme from '../themes/globalStyles';
import { connect } from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { GroupListModel, InfoCircuit } from "../models/Index";
import GroupListDialog from "../components/dialogs/GroupListDialog";
import AlertDialog from "../components/common/AlertDialog";
import { actionCreators } from "../store";
import FetchHelper from "../components/FetchHelper";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Controller } from "react-hook-form";
import TextFieldOptions from "../components/common/TextFieldOptions";


const UPDATE_STD_CAPACITY = "UPDATE_STD_CAPACITY"
const UPDATE_FREQUENCY = "UPDATE_FREQUENCY"
const OPEN_PROJECT = "OPEN_PROJECT"
const GET_TEMPLATES = "GET_TEMPLATES"

//#region Props
export type Page2Props = {
    onCreate: (key: string,rotation:number) => void;
    currentTabId: number;
    stdCapacityList: any;
    lineDiagramRef:any;
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
//#endregion

const DashboardLeftSidebar = (props: Page2Props) => {
    //#region Fields
    const { 
        onCreate,
        editGroupList,
        deleteGroupList,
        saveGroupList,
        currentTabId,
        processMode,
        groupList ,
        infoCircuit,
        updateStdCapacityDB,
        updateFrequencyDB,
        projectId,
        updateStdCapacity,
        updateFrequency,
        stdCapacityList,
        m_bModePM,
        modeViewOnly,
        projectData,
        // getListStdCapacity,
        userId,
        lineDiagramRef
    } = props;
    
    const [openSearch1, setOpenSearch1] = useState(true);
    const [openSearch2, setOpenSearch2] = useState(true);
    const [openSearch3, setOpenSearch3] = useState(true);
    const [openSearch4, setOpenSearch4] = useState(true);
    const [openSearch5, setOpenSearch5] = useState(true);
    const [openSearch6, setOpenSearch6] = useState(true);

    
    const [openGroupListDialog, setOpenGroupListDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [groupListSelected, setGroupListSelected] = useState<GroupListModel>();
    
    const [stdCapacity, setStdCapacity] = useState(infoCircuit.stdCapacity??10000)
    const [frequency, setFrequency] = useState(infoCircuit.frequency??50)
    const [handleDisableStd_Fre, setHandleDisableStd_Fre] = useState(processMode > 3)
    const [collapseDisabledStyle, setCollapseDisabledStyle] = useState({})
    const [displayNameDevice, setDisplayNameDevice] = useState(false)


    // useEffect(() => {
    //     getListStdCapacity({})
    // }, [])
    useEffect(() => {
        if(processMode !== 1 || m_bModePM || modeViewOnly)
            openCloseAll(false)
        else
            openCloseAll(true)
        
        if(processMode === 1 && !(m_bModePM || modeViewOnly))
            setCollapseDisabledStyle({})
        else
            setCollapseDisabledStyle({
                pointerEvents: 'none' as any, 
                color: '#ccc'
            })
    }, [processMode,m_bModePM, modeViewOnly])

    useEffect(() => {
        setHandleDisableStd_Fre(m_bModePM || modeViewOnly || processMode > 3)
    }, [processMode, m_bModePM, modeViewOnly])

    //#endregion

    const openCloseAll = (isOpen: boolean) => {
        setOpenSearch1(isOpen); 
        setOpenSearch2(isOpen); 
        setOpenSearch3(isOpen); 
        setOpenSearch4(isOpen);
        setOpenSearch5(isOpen);
        setOpenSearch6(isOpen)
    }

    //#region Style
    const styleIcon = {
        width: '15px',
        height: '15px',
        borderRadius: '0'
    }
    //#endregion

    //#region Method
    // ハンドルオープン
    const handleOpen = (setState: any) => {
        setState((prev: boolean) => !prev);
    };

    //ハンドルクリエート
    const handleCreate = (key: string,rotation:number = 0) => {
        onCreate(key,rotation)
        lineDiagramRef?.current?.focus()
    }
    const handleCreateFromElementKind = (key: ElementKind,rotation:number = 0) => onCreate(key,rotation);

    const handleEditGroupList = (group: any) => {
        setGroupListSelected(group)
        setOpenGroupListDialog(true)
    }

    const handleDeleteGroupList = (group: any) => {
        setGroupListSelected(group)
        setOpenDeleteDialog(true)
    }

    const handleEditGroupListOK = (data: GroupListModel) => {
        if (data){
            const params = {
                userId: userId,
                element_group_id: data.groupId,
                groupName: data.groupName,
                explan: data.explan
            }
            editGroupList(params)

            let newGroupList: GroupListModel[] = [...groupList]
            let index = newGroupList.findIndex(e => e.groupId === data.groupId);
            newGroupList[index] = data
            saveGroupList(newGroupList)
            
            setOpenGroupListDialog(false)
        }
    }

    const handleEditGroupListCancel = () => {
        setOpenGroupListDialog(false)
    }
    
    const handleDeleteOK = () => {
        if (groupListSelected){
            let groupId = groupListSelected.groupId
            const params = {
                userId: userId,
                element_group_id: groupId,
            }
            deleteGroupList(params)
            
            let newGroupList = groupList.filter(
                (e: GroupListModel) => e.groupId !== groupId
            );
            saveGroupList(newGroupList)

            setGroupListSelected(undefined)
            setOpenDeleteDialog(false)
        }
    }

    const handleDeleteCancel = () => {
        setOpenDeleteDialog(false)
    }

    const handleEditGroupListSuccess = (e: any) => {}
    
    const handleError = (error: any) => {
        console.log('>>> handleError' + error)
    }
    
    //#endregion

    const ImageButton = styled(ButtonBase)(({ theme }) => ({
        position: 'relative',
        height: 31,
        width: 31,
        ":disabled":{
            opacity: 0.4
        }
    }));

    const ImageSrc = styled('span')({
        position: 'absolute',
        left: 8,
        right: 8,
        top: 8,
        bottom: 8,
        backgroundSize: 'cover',
    });
    const frequencyOptions = [
        {
            label: '50',
            value: 50
        },
        {
            label: '60',
            value:60
        }
    ]
    const handleChangeStdCapacity = (e:any) =>{
        setStdCapacity(e.target.value)
        const param = {
            userId: userId,
            projectId: projectId,
            ownerProject: projectData.ownerId,
            std_capacity: e.target.value
        }
        updateStdCapacityDB(param)
    }
    const handleChangeFrequency = (e:any) =>{
        setFrequency(e.target.value)
        const param = {
            userId: userId,
            projectId: projectId,
            ownerProject: projectData.ownerId,
            frequency: e.target.value
        }
        updateFrequencyDB(param)
    }
    const handleChangeSuccessStdCapacity =(data : any) => {
        updateStdCapacity(data.data)
    }
    const handleChangeSuccessFrequency =(data : any) =>{
        updateFrequency(data.data)
    }

    const handleSuccessOpenProject = (data : any) => {
        const info_circuit = data.data.info_circuit;
        setStdCapacity(info_circuit.std_capacity);
        setFrequency(info_circuit.frequency);        
    }
    const handleGetTemplatesSuccess = () => {
        let stdCapacityList1 = [] as any
        stdCapacityList.forEach((item: any) => stdCapacityList1.push(item.dstdcapacity))
        if(!stdCapacityList1.includes(Number(infoCircuit.stdCapacity))){
            stdCapacityList1.push(infoCircuit.stdCapacity)
        }
        return stdCapacityList1
    };
    const handleErrorOpenProject = () => {

    }
    // const handleSuccessGetListStdCapacity= (data:any) =>{
    //     let stdCapacityList = [] as any
    //     data.data.forEach((item: any) => stdCapacityList.push(item.dstdcapacity))
    //     setListStdCapacity(stdCapacityList)
    // }
    const handleCheckDisplayNameDevice = (e:any) =>{
        setDisplayNameDevice(e.target.checked)

    }
    const handleChangeDisplayNameDevice = (e:any) =>{
        if(displayNameDevice){
            setDisplayNameDevice(false)
        }
        else{
            setDisplayNameDevice(true)
        }
    }
    const disableOnClick = (control:string) =>{
        if(currentTabId ==1){
            switch(control){
                case 'MS_SOURCE':
                    handleCreateFromElementKind('MS_SOURCE')
                    lineDiagramRef?.current?.focus()
                    break;
                case control:
                    handleCreate(control)
                    lineDiagramRef?.current?.focus()
                    break;
            }
        }
    }

    return (
        <div style={{ width: "207px", height: 'calc(100vh - 166px)', backgroundColor: theme.palette.secondary.light }}>
            <div style={{ overflowY: 'auto', height: 'calc(100vh - 166px)', display: 'flex', flexDirection: 'column'}}>
                <div style={{ cursor: 'pointer', display: 'inline-block', width: '100%' }} >
                    <div style={{ display: 'inline-block' }}>
                        <Typography variant="caption" style={{ marginLeft: '10px'}} >基準容量(kVA)</Typography>
                        
                    </div>
                    <Stack 
                        sx={{background: 'white',borderRadius:'5px',marginLeft: '10px',marginRight: '10px'}}
                    >
                        <FormControl fullWidth size='small' >
                            <Select
                                style={{height:"30px"}}
                                onChange={handleChangeStdCapacity}
                                value={infoCircuit.stdCapacity??stdCapacity}
                                disabled={handleDisableStd_Fre}
                            >
                                {handleGetTemplatesSuccess().map((item:any) => (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                </div>
                <div style={{ cursor: 'pointer', display: 'inline-block', width: '100%'}}>
                    <div style={{ display: 'inline-block' }}>
                        <Typography variant="caption" style={{ marginLeft: '10px'}}>周渡数(Hz)</Typography>
                    </div>
                    <Stack 
                        sx={{background: 'white',borderRadius:'5px',marginLeft: '10px',marginRight: '10px',marginBottom:'10px'}}
                    >
                        <FormControl fullWidth size='small' >
                            <Select
                                style={{height:"30px"}}
                                onChange={handleChangeFrequency}
                                value={infoCircuit.frequency??frequency}
                                disabled={handleDisableStd_Fre}
                            >
                                {frequencyOptions.map(item => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                <div style={{ cursor:m_bModePM || modeViewOnly?'auto': 'pointer', display: 'inline-block', width: '100%'}}>
                    <div style={{ display: 'inline-block'}}>
                        <Typography variant="caption" style={{ marginLeft: '10px',color:m_bModePM || modeViewOnly?'#ccc':''}} onClick={(e) =>handleChangeDisplayNameDevice(e)}>機器の説明表示<Checkbox checked={displayNameDevice} disabled={m_bModePM || modeViewOnly} onChange={(e) =>handleCheckDisplayNameDevice(e)}></Checkbox></Typography>
                    </div>
                </div>
                </div>
                {/* 電源機器 */}
                <div style={{ cursor: 'pointer', display: 'inline-block', width: '100%', backgroundColor: openSearch1 ? theme.palette.secondary.light : 'white', ...collapseDisabledStyle }} onClick={() => { handleOpen(setOpenSearch1); }}>
                    <div style={{ display: 'inline-block' }}>
                        <Typography variant="caption" style={{ marginLeft: '10px' }}>電源機器</Typography>
                    </div>
                    <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                        {
                            openSearch1 ? <ArrowDropDownIcon /> : <ArrowRightIcon />
                        }
                    </div>
                </div>
                <div style={{ marginLeft: "10px", marginRight: "10px", backgroundColor: 'white', borderRadius: '5px', marginBottom: openSearch1 ? '10px' : '0px' }}>
                    <Collapse in={openSearch1} timeout="auto" unmountOnExit>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                        >
                            <Grid item minWidth="1rem" onClick={() =>disableOnClick('MS_SOURCE')} style={{cursor:currentTabId !== 1?'auto':'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    disabled={currentTabId !== 1}
                                    title="電源を配置"
                                >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_SOURCE")}} style={{width:'80%',height:'80%', backgroundImage: `url(${icon1_1})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{marginLeft:'5px',marginTop:'5px',color:currentTabId !== 1?'#ccc':''}}>電源</Typography>
                                </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" style={{marginLeft:displayNameDevice?'15px':'5px',cursor:currentTabId !== 1?'auto':'pointer'}} onClick={() =>disableOnClick('MS_GENERATOR')}>
                                <ImageButton
                                    focusRipple
                                    disabled={currentTabId !== 1}
                                    title="発電機を配置"
                                >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_GENERATOR")}} style={{width:'80%',height:'80%', backgroundImage: `url(${icon1_2})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{marginLeft:'5px',marginTop:'5px',color:currentTabId !== 1?'#ccc':''}}>発電機</Typography>
                                </Stack>
                                }
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                            marginTop={0.5}
                        >
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_TRANS3')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="三相変圧器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_TRANS3")}} style={{width:'80%',height:'80%', backgroundImage: `url(${icon1_3})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                    <Stack>
                                        <Typography variant="caption" style={{paddingLeft:'9px',marginTop:'5px'}}>三相</Typography>
                                        <Typography variant="caption" style={{marginLeft:'5px'}}>変圧器</Typography>
                                    </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" marginLeft={0.5} onClick={() => handleCreate('MS_3WINDING')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="三巻線変圧器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_3WINDING")}} style={{width:'80%',height:'80%', backgroundImage: `url(${icon1_4})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                    <Stack>
                                        <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>三巻線</Typography>
                                        <Typography variant="caption" style={{marginLeft:'5px'}}>変圧器</Typography>
                                    </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" marginLeft={0.5} onClick={() => handleCreate('MS_TRANS1')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="単相変圧器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_TRANS1")}} style={{width:'80%',height:'80%',  backgroundImage: `url(${icon1_5})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                    <Stack>
                                        <Typography variant="caption" style={{paddingLeft:'9px',marginTop:'5px'}}>単相</Typography>
                                        <Typography variant="caption" style={{marginLeft:'5px'}}>変圧器</Typography>
                                    </Stack>
                                }
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                            marginTop={0.5}
                            marginBottom={1}
                        >
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_TRANSSCOTT')} style={{cursor:'pointer'}}>
                                    <ImageButton
                                        focusRipple
                                        title="スコット結線変圧器を配置"
                                        >
                                        <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_TRANSSCOTT")}} style={{width:'80%',height:'80%', backgroundImage: `url(${icon1_6})`}} />
                                    </ImageButton>
                                    {displayNameDevice &&
                                        <Stack>
                                            <Typography variant="caption" style={{marginLeft:'5px',marginTop:'5px'}}>スコット</Typography>
                                            <Typography variant="caption" style={{marginLeft:'9px'}}>結線</Typography>
                                            <Typography variant="caption" style={{marginLeft:'5px'}}>変圧器</Typography>
                                        </Stack>
                                    }
                            </Grid>
                        </Grid>
                    </Collapse>
                </div>

                {/* 電路機器 */}
                <div style={{ cursor: 'pointer', display: 'inline-block', width: '100%', backgroundColor: openSearch2 ? theme.palette.secondary.light : 'white', ...collapseDisabledStyle }} onClick={() => { handleOpen(setOpenSearch2); }}>
                    <div style={{ display: 'inline-block' }}>
                        <Typography variant="caption" style={{ marginLeft: '10px' }}>電路機器</Typography>
                    </div>
                    <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                        {
                            openSearch2 ? <ArrowDropDownIcon /> : <ArrowRightIcon />
                        }
                    </div>
                </div>
                <div style={{ marginLeft: "10px", marginRight: "10px", backgroundColor: 'white', borderRadius: '5px', marginBottom: openSearch2 ? '10px' : '0px' }}>
                    <Collapse in={openSearch2} timeout="auto" unmountOnExit>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                        >
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_DS')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="断路器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_DS")}} style={{width:'80%',height:'80%', backgroundImage: `url(${icon1})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                    <Stack>
                                        <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>断路器</Typography>
                                    </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" marginLeft={0.5} onClick={() => handleCreate('MS_LBS')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="開閉器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_LBS")}} style={{width:'80%',height:'80%', backgroundImage: `url(${icon2})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                    <Stack>
                                        <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>開閉器</Typography>
                                    </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" marginLeft={0.5} onClick={() => handleCreate('MS_MVCB')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="遮断器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_MVCB")}} style={{width:'80%',height:'80%', backgroundImage: `url(${icon2_3})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                    <Stack>
                                        <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>遮断器</Typography>
                                    </Stack>
                                }
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                        >
                            <Grid item minWidth="1rem" marginTop={0.5} onClick={() => handleCreate('MS_CONTACTOR')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="電磁接触器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_CONTACTOR")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon2_4})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                    <Stack>
                                        <Typography variant="caption" style={{paddingLeft:'9px',marginTop:'5px'}}>電磁</Typography>
                                        <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'1px'}}>接触器</Typography>
                                    </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem"  marginTop={0.5} onClick={() => handleCreateFromElementKind('MS_HVCB')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="過電流継電器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_HVCB")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon2_5})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                    <Stack>
                                        <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>過電流</Typography>
                                        <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'1px'}}>継電器</Typography>
                                    </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem"  marginTop={1} marginLeft={0.5} onClick={() => handleCreate('MS_FUSE')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="高圧ヒューズを配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_FUSE")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon2_6})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                    <Stack>
                                        <Typography variant="caption" style={{paddingLeft:'9px',marginTop:'5px'}}>高圧</Typography>
                                        <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'1px'}}>ヒューズ</Typography>
                                    </Stack>
                                }
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                        >
                            <Grid item minWidth="1rem" marginTop={1} onClick={() => handleCreate('MS_2E')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="電動機保護リレーを配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_2E")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon2_7})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                    <Stack >
                                        <Typography  variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>電動</Typography>
                                        <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'1px'}}>機保護</Typography>
                                        <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'1px'}}>リレー</Typography>
                                    </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" marginTop={1} marginLeft={0.2} style={{alignSelf:"start",cursor:'pointer'}} onClick={() => handleCreate('MS_ZEROCOND')}>
                                <ImageButton
                                    focusRipple
                                    title="母線を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_ZEROCOND")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon2_8})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                    <Stack>
                                        <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>母線</Typography>
                                    </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" marginTop={1} style={{alignSelf:"start",marginLeft:displayNameDevice?'13px':'5px',cursor:'pointer'}} onClick={() => handleCreate('MS_CONNECT')}>
                                <ImageButton
                                    focusRipple
                                    title="接続線を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_CONNECT")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon2_9})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                    <Stack>
                                        <Typography variant="caption" style={{paddingLeft:'9px',marginTop:'5px'}}>接続線</Typography>
                                    </Stack>
                                }
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                        >
                            <Grid item minWidth="1rem" marginTop={1} onClick={() => handleCreate('MS_WIRE')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="電線を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_WIRE")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon2_10})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                    <Stack>
                                        <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>電線</Typography>
                                    </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" marginTop={1} style={{marginLeft:displayNameDevice?'65px':'5px',cursor:'pointer'}} onClick={() => handleCreate('MS_BUSBAR')}>
                                <ImageButton
                                    focusRipple
                                    title="ブスバーを配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_BUSBAR")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon2_11})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                    <Stack>
                                        <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>ブスバー</Typography>
                                    </Stack>
                                }
                            </Grid>
                            {!displayNameDevice &&
                            <Grid item minWidth="1rem" marginTop={1} style={{alignSelf:"start"}}>
                                <ImageButton
                                    focusRipple
                                    onClick={() => handleCreate('MS_BUSDUCT')}
                                    title="バスダクトを配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_BUSDUCT")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon2_12})`}} />
                                </ImageButton>
                            </Grid>
                            }
                        </Grid>
                        {displayNameDevice &&
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                        >
                            <Grid item minWidth="1rem" marginTop={1} style={{alignSelf:"start",cursor:'pointer'}} onClick={() => handleCreate('MS_BUSDUCT')}>
                                <ImageButton
                                    focusRipple
                                    title="バスダクトを配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_BUSDUCT")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon2_12})`}} />
                                </ImageButton>
                                    <Stack>
                                        <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>バスダクト</Typography>
                                    </Stack>
                            </Grid>
                            <Grid item minWidth="1rem" marginTop={1} style={{marginLeft:displayNameDevice?'28px':'5px',cursor:'pointer'}} onClick={() => handleCreate('MS_REACTOR')}>
                                <ImageButton
                                    focusRipple
                                    title="限流リアクトルを配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_REACTOR")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon2_13})`}} />
                                </ImageButton>
                                    <Stack>
                                        <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>限流</Typography>
                                        <Typography variant="caption" style={{paddingLeft:'5px'}}>リアクトル</Typography> 
                                    </Stack>
                            </Grid>
                        </Grid>
                        }
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                        >
                            {!displayNameDevice &&
                            <Grid item minWidth="1rem" marginTop={1}>
                                <ImageButton
                                    focusRipple
                                    onClick={() => handleCreate('MS_REACTOR')}
                                    title="限流リアクトルを配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_REACTOR")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon2_13})`}} />
                                </ImageButton>
                            </Grid>
                            }
                            <Grid item minWidth="1rem" marginTop={1} onClick={() => handleCreate('MS_IMPEDANCE')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="インピーダンスを配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_IMPEDANCE")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon2_14})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                    <Stack>
                                        <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>インピーダンス</Typography>
                                    </Stack>    
                                }
                            </Grid>
                            <Grid item minWidth="1rem" marginTop={1} onClick={() => handleCreate('MS_LVCB')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="低圧遮断器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_LVCB")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon2_15})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                    <Stack>
                                        <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>低圧遮断器</Typography>
                                    </Stack>
                                }
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                            marginBottom={1}

                        >
                            <Grid item minWidth="1rem" marginTop={1} style={{alignSelf:'start',cursor:'pointer'}} onClick={() => handleCreate('MS_THERMAL')}>
                                <ImageButton
                                    focusRipple
                                    title="サーマルリレーを配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_THERMAL")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon2_16})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                    <Stack>
                                        <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>サーマルリレー</Typography>
                                    </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_THERMAL_CT')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="過負荷継電器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_THERMAL_CT")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon2_17})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                    <Stack>
                                        <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>過負荷</Typography>
                                        <Typography variant="caption" style={{paddingLeft:'5px'}}>継電器</Typography>
                                    </Stack>
                                }
                            </Grid>
                        </Grid>
                    </Collapse>
                </div>

                {/* 横向き機器 */}
                <div style={{ cursor: 'pointer', display: 'inline-block', width: '100%', backgroundColor: openSearch6 ? theme.palette.secondary.light : 'white', ...collapseDisabledStyle }} onClick={() => { handleOpen(setOpenSearch6); }}>
                    <div style={{ display: 'inline-block' }}>
                        <Typography variant="caption" style={{ marginLeft: '10px' }}>横向き機器</Typography>
                    </div>
                    <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                        {
                            openSearch6 ? <ArrowDropDownIcon /> : <ArrowRightIcon />
                        }
                    </div>
                </div>
                <div style={{ marginLeft: "10px", marginRight: "10px", backgroundColor: 'white', borderRadius: '5px', marginBottom: openSearch6 ? '10px' : '0px' }}>
                    <Collapse in={openSearch6} timeout="auto" unmountOnExit>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                        >
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_DS',-90)} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="断路器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_DS,-90")}} style={{width:'80%',height:'80%', backgroundImage: `url(${icon5})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                    <Stack>
                                        <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>断路器</Typography>
                                    </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_DS',90)} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="断路器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_DS,90")}} style={{width:'80%',height:'80%', backgroundImage: `url(${icon5_1})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>断路器</Typography>
                                </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_LBS',-90)} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="開閉器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_LBS,-90")}} style={{width:'80%',height:'80%', backgroundImage: `url(${icon5_2})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>開閉器</Typography>
                                </Stack>
                                }
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                            marginTop={0.5}

                        >
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_LBS',90)} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="開閉器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_LBS,90")}} style={{width:'80%',height:'80%', backgroundImage: `url(${icon5_3})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>開閉器</Typography>
                                </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_MVCB',-90)} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="遮断器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_MVCB,-90")}} style={{width:'80%',height:'80%', backgroundImage: `url(${icon5_4})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>遮断器</Typography>
                                </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_MVCB',90)} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="遮断器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_MVCB,90")}} style={{width:'80%',height:'80%', backgroundImage: `url(${icon5_5})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>遮断器</Typography>
                                </Stack>
                                }
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                            marginTop={0.5}

                        >
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_CONTACTOR',-90)} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="電磁接触器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_CONTACTOR,-90")}}  style={{width:'80%',height:'80%',  backgroundImage: `url(${icon5_6})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>電磁</Typography>
                                    <Typography variant="caption" style={{paddingLeft:'5px'}}>接触器</Typography>

                                </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_CONTACTOR',90)} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="電磁接触器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_CONTACTOR,90")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon5_7})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>電磁</Typography>
                                    <Typography variant="caption" style={{paddingLeft:'5px'}}>接触器</Typography>
                                </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" onClick={() => handleCreateFromElementKind('MS_HVCB',-90)} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="過電流継電器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_HVCB,-90")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon5_8})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>過電流</Typography>
                                    <Typography variant="caption" style={{paddingLeft:'5px'}}>継電器</Typography>
                                </Stack>
                                }
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                            marginTop={0.5}
                            >
                                <Grid item minWidth="1rem" onClick={() => handleCreateFromElementKind('MS_HVCB',90)} style={{cursor:'pointer'}}>
                                    <ImageButton
                                        focusRipple
                                        title="過電流継電器を配置"
                                        >
                                        <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_HVCB,90")}}  style={{width:'80%',height:'80%',  backgroundImage: `url(${icon5_9})`}} />
                                    </ImageButton>
                                    {displayNameDevice &&
                                    <Stack>
                                        <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>過電流</Typography>
                                        <Typography variant="caption" style={{paddingLeft:'5px'}}>継電器</Typography>
                                    </Stack>
                                    }
                                </Grid>
                                <Grid item minWidth="1rem" onClick={() => handleCreate('MS_FUSE',-90)} style={{cursor:'pointer'}}>
                                    <ImageButton
                                        focusRipple
                                        title="高圧ヒューズを配置"
                                        >
                                        <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_FUSE,-90")}}  style={{width:'80%',height:'80%',  backgroundImage: `url(${icon5_10})`}} />
                                    </ImageButton>
                                    {displayNameDevice &&
                                    <Stack>
                                        <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>高圧</Typography>
                                        <Typography variant="caption" style={{paddingLeft:'5px'}}>ヒューズ</Typography>
                                    </Stack>
                                    }
                                </Grid>
                                <Grid item minWidth="1rem" onClick={() => handleCreate('MS_FUSE',90)} style={{cursor:'pointer'}}>
                                    <ImageButton
                                        focusRipple
                                        title="高圧ヒューズを配置"
                                        >
                                        <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_FUSE,90")}}  style={{width:'80%',height:'80%',  backgroundImage: `url(${icon5_11})`}} />
                                    </ImageButton>
                                    {displayNameDevice &&
                                    <Stack>
                                        <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>高圧</Typography>
                                        <Typography variant="caption" style={{paddingLeft:'5px'}}>ヒューズ</Typography>
                                    </Stack>
                                    }
                                </Grid>
                        </Grid>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                            marginTop={0.5}

                        >
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_WIRE',-90)} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="電線を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_WIRE,-90")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon5_12})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>電線</Typography>
                                </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_BUSBAR',-90)} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="ブスバーを配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_BUSBAR,-90")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon5_13})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>ブスバー</Typography>
                                </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_BUSDUCT',-90)} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="バスダクトを配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_BUSDUCT,-90")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon5_14})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>バスダクト</Typography>
                                </Stack>
                                }
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                            marginTop={0.5}
                        >
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_REACTOR',-90)} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="限流リアクトルを配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_REACTOR,-90")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon5_15})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>限流</Typography>
                                    <Typography variant="caption" style={{paddingLeft:'5px'}}>リアクトル</Typography>
                                </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" style={{alignSelf:'start',cursor:'pointer'}} onClick={() => handleCreate('MS_IMPEDANCE',-90)}>
                                <ImageButton
                                    focusRipple
                                    title="インピーダンスを配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_IMPEDANCE,-90")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon5_16})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>インピーダンス</Typography>
                                </Stack>
                                }
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                            marginTop={0.5}
                            marginBottom={1}

                        >
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_LVCB',-90)} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="低圧遮断器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_LVCB,-90")}}  style={{width:'80%',height:'80%',  backgroundImage: `url(${icon5_17})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>低圧遮断器</Typography>
                                </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_LVCB',90)} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="低圧遮断器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_LVCB,90")}}  style={{width:'80%',height:'80%',  backgroundImage: `url(${icon5_18})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>低圧遮断器</Typography>
                                </Stack>
                                }
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                            marginTop={0.5}
                            marginBottom={1}

                        >
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_CONNECT',-90)} style={{alignSelf:"start",cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="接続線を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_CONNECT,-90")}}  style={{width:'80%',height:'80%',  backgroundImage: `url(${icon5_19})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>接続線</Typography>
                                </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_CABLEHEAD',-90)} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="ケーブルヘッドを配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_CABLEHEAD,-90")}}  style={{width:'80%',height:'80%',  backgroundImage: `url(${icon5_20})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>ケーブル</Typography>
                                    <Typography variant="caption" style={{paddingLeft:'5px'}}>ヘッド</Typography>
                                </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_CABLEHEAD',90)} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="ケーブルヘッドを配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_CABLEHEAD,90")}}  style={{width:'80%',height:'80%',  backgroundImage: `url(${icon5_21})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>ケーブル</Typography>
                                    <Typography variant="caption" style={{paddingLeft:'5px'}}>ヘッド</Typography>
                                </Stack>
                                }
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                            marginTop={0.5}
                            marginBottom={1}

                        >
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_RELAY',-90)} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="Ryを配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_RELAY,-90")}}  style={{width:'80%',height:'80%',  backgroundImage: `url(${icon5_22})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>Ry</Typography>
                                </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_RELAY',90)} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="Ryを配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_RELAY,90")}}  style={{width:'80%',height:'80%',  backgroundImage: `url(${icon5_22})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>Ry</Typography>
                                </Stack>
                                }
                            </Grid>
                        </Grid>
                    </Collapse>
                </div>

                {/* 負荷類機器 */}
                <div style={{ cursor: 'pointer', display: 'inline-block', width: '100%', backgroundColor: openSearch3 ? theme.palette.secondary.light : 'white', ...collapseDisabledStyle }} onClick={() => { handleOpen(setOpenSearch3); }}>
                    <div style={{ display: 'inline-block' }}>
                        <Typography variant="caption" style={{ marginLeft: '10px' }}>負荷類機器</Typography>
                    </div>
                    <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                        {
                            openSearch3 ? <ArrowDropDownIcon /> : <ArrowRightIcon />
                        }
                    </div>
                </div>
                <div style={{ marginLeft: "10px", marginRight: "10px", backgroundColor: 'white', borderRadius: '5px', marginBottom: openSearch3 ? '10px' : '0px' }}>
                    <Collapse in={openSearch3} timeout="auto" unmountOnExit>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                        >
                            <Grid item minWidth="1rem" onClick={() => disableOnClick('MS_MOTOR_GROUP')} style={{cursor:currentTabId !== 1?'auto':'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    disabled={currentTabId !== 1}
                                    title="電動機群を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_MOTOR_GROUP")}}  style={{width:'80%',height:'80%',  backgroundImage: `url(${icon3_1})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px',color:currentTabId !== 1?'#ccc':''}}>電動機群</Typography>
                                </Stack>
                                }
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                        >
                            <Grid item minWidth="1rem" onClick={() => handleCreateFromElementKind('MS_MOTOR')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="電動機を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_MOTOR")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${iconMotor})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>電動機</Typography>
                                </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_LOAD')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="負荷を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_LOAD")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon3_3})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>負荷</Typography>
                                </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" style={{marginLeft:displayNameDevice?'35px':'5px',cursor:'pointer'}} onClick={() => handleCreate('MS_LIGHTBOARD')}>
                                <ImageButton
                                    focusRipple
                                    title="分電盤を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_LIGHTBOARD")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon3_4})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>分電盤</Typography>
                                </Stack>
                                }
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                            marginBottom={1}
                            >
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_POWERBOARD')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="動力盤を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_POWERBOARD")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon3_5})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>動力盤</Typography>
                                </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_CAPACITOR')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="コンデンサを配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_CAPACITOR")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon3_6})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>コンデンサ</Typography>
                                </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" style={{marginLeft:displayNameDevice?'0px':'5px',cursor:currentTabId !== 1?'auto':'pointer'}} onClick={() => disableOnClick('MS_TRANSCENTER')}>
                                <ImageButton
                                    focusRipple
                                    disabled={currentTabId !== 1}
                                    title="変台を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_TRANSCENTER")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${iconMSTransCenter})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px',color:currentTabId !== 1?'#ccc':''}}>変台</Typography>
                                </Stack>
                                }
                            </Grid>
                        </Grid>
                    </Collapse>
                </div>

                {/* オプション機器 */}
                <div style={{ cursor: 'pointer', display: 'inline-block', width: '100%', backgroundColor: openSearch4 ? theme.palette.secondary.light : 'white', ...collapseDisabledStyle }} onClick={() => { handleOpen(setOpenSearch4); }}>
                    <div style={{ display: 'inline-block' }}>
                        <Typography variant="caption" style={{ marginLeft: '10px' }}>オプション機器</Typography>
                    </div>
                    <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                        {
                            openSearch4 ? <ArrowDropDownIcon /> : <ArrowRightIcon />
                        }
                    </div>
                </div>
                <div style={{ marginLeft: "10px", marginRight: "10px", backgroundColor: 'white', borderRadius: '5px' }}>
                    <Collapse in={openSearch4} timeout="auto" unmountOnExit>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                        >
                            <Grid item minWidth="1rem"  onClick={() => handleCreate('MS_TEXT')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="テキストボックスを配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_TEXT")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${iconText})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>テキストボックス</Typography>
                                </Stack>
                                }
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"

                            >
                            <Grid item minWidth="1rem" onClick={() => disableOnClick('MS_VCT')} style={{cursor:currentTabId !== 1?'auto':'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    disabled={currentTabId !== 1}
                                    title="計器用変成器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_VCT")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon4_1})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px',color:currentTabId !== 1?'#ccc':''}}>計器用</Typography>
                                    <Typography variant="caption" style={{paddingLeft:'5px',color:currentTabId !== 1?'#ccc':''}}>変成器</Typography>
                                </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_VT')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="計器用変圧器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_VT")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${iconMsVT})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>計器用</Typography>
                                    <Typography variant="caption" style={{paddingLeft:'5px'}}>変圧器</Typography>
                                </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" style={{alignSelf:'start',cursor:currentTabId !== 1?'auto':'pointer'}} onClick={() => disableOnClick('MS_ARRESTOR')}>
                                <ImageButton
                                    focusRipple
                                    disabled={currentTabId !== 1}
                                    title="避雷器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_ARRESTOR")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon4_3})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px',color:currentTabId !== 1?'#ccc':''}}>避雷器</Typography>
                                </Stack>
                                }
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"

                        >
                            <Grid item minWidth="1rem" onClick={() => disableOnClick('MS_EARTH')} style={{cursor:currentTabId !== 1?'auto':'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    disabled={currentTabId !== 1}
                                    title="接地を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_EARTH")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon4_4})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px',color:currentTabId !== 1?'#ccc':''}}>接地</Typography>
                                </Stack>
                                }
                            </Grid>
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_CABLEHEAD')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="ケーブルヘッドを配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_CABLEHEAD")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon4_5})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>ケーブルヘッド</Typography>
                                </Stack>
                                }
                            </Grid>
                            {!displayNameDevice &&
                            <Grid item minWidth="1rem">
                                <ImageButton
                                    focusRipple
                                    onClick={() => handleCreate('MS_CT')}
                                    title="変流器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_CT")}}  style={{ width:'80%',height:'80%',backgroundImage: `url(${icon4_6})`}} />
                                </ImageButton>
                            </Grid>
                            }
                        </Grid>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                            style={{marginBottom:displayNameDevice?'0px':'10px'}}
                        >
                            {displayNameDevice &&
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_CT')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="変流器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_CT")}}  style={{ width:'80%',height:'80%',backgroundImage: `url(${icon4_6})`}} />
                                </ImageButton>
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>変流器</Typography>
                                </Stack>
                            </Grid>
                            }
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_ZCT')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="零相変流器を配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_ZCT")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${iconMsZCT})`}} />
                                </ImageButton>
                                {displayNameDevice &&
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>零相変流器</Typography>
                                </Stack>
                                }
                            </Grid>
                            {!displayNameDevice &&
                            <Grid item minWidth="1rem">
                                <ImageButton
                                    focusRipple
                                    onClick={() => handleCreate('MS_INV')}
                                    title="インバーターを配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_INV")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon4_8})`}} />
                                </ImageButton>
                            </Grid>
                            }
                            {!displayNameDevice &&
                            <Grid item minWidth="1rem">
                                <ImageButton
                                    focusRipple
                                    onClick={() => handleCreate('MS_RELAY')}
                                    title="Ryを配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_RELAY")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon4_9})`}} />
                                </ImageButton>
                            </Grid>
                            }
                        </Grid>
                        {displayNameDevice &&
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems={"end"}
                            spacing={1}
                            direction="row"
                        >
                            <Grid item minWidth="1rem" onClick={() => handleCreate('MS_INV')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="インバーターを配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_INV")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon4_8})`}} />
                                </ImageButton>
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>インバーター</Typography>
                                </Stack>
                            </Grid>
                            <Grid item minWidth="1rem" marginLeft={1.5} onClick={() => handleCreate('MS_RELAY')} style={{cursor:'pointer'}}>
                                <ImageButton
                                    focusRipple
                                    title="Ryを配置"
                                    >
                                    <ImageSrc draggable onDragStart={(e) => {e.dataTransfer.setData("KEY", "MS_RELAY")}}  style={{width:'80%',height:'80%', backgroundImage: `url(${icon4_9})`}} />
                                </ImageButton>
                                <Stack>
                                    <Typography variant="caption" style={{paddingLeft:'5px',marginTop:'5px'}}>Ry</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                        }
                    </Collapse>
                </div>
                
                {/* 機器グループリスト */}
                <div 
                    style={{ 
                        cursor: 'pointer', 
                        display: 'inline-block', 
                        width: '100%', 
                        backgroundColor: 
                            openSearch4 
                            ? theme.palette.secondary.light 
                            : 'white',
                        ...collapseDisabledStyle 
                    }} 
                    onClick={() => { setOpenSearch5(!openSearch5) }}
                >
                    <div style={{ display: 'inline-block' }}>
                        <Typography variant="caption" style={{ marginLeft: '10px' }}>機器グループリスト</Typography>
                    </div>
                    <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                        {
                            openSearch5 ? <ArrowDropDownIcon /> : <ArrowRightIcon />
                        }
                    </div>
                </div>
                <div 
                    style={{ 
                        marginLeft: "10px",
                        marginRight: "10px",
                        marginBottom: "10px",
                        backgroundColor: 'white',
                        borderRadius: '5px'
                    }}
                >
                    <Collapse in={openSearch5} timeout="auto" unmountOnExit>
                        <Grid spacing={1}>
                            <List
                                sx={{ 
                                    width: '100%',
                                    bgcolor: 'background.paper',
                                    maxHeight: '160px',
                                    overflow: 'auto',
                                    borderRadius: '5px'
                                }}
                            >
                                {groupList.map((group: GroupListModel) => (
                                    <ListItem
                                        key={group.groupId}
                                        disableGutters
                                        secondaryAction={
                                            <>
                                                <IconButton 
                                                    aria-label="edit" 
                                                    sx={{ width:24, height:24 }}
                                                    onClick={() => handleEditGroupList(group)}
                                                >
                                                    <ModeEditIcon sx={{ width:16, height:16 }}/>
                                                </IconButton>
                                                <IconButton 
                                                    aria-label="delete" 
                                                    sx={{ width:24, height:24 }}
                                                    onClick={() => handleDeleteGroupList(group)}
                                                >
                                                    <DeleteIcon sx={{ width:16, height:16 }}/>
                                                </IconButton>
                                            </>
                                        }
                                    >
                                        <Stack px={1}>
                                            <Button 
                                                title={group.explan} 
                                                sx={{textTransform: 'none'}} 
                                                color="inherit"
                                                draggable 
                                                onDragStart={(e) => 
                                                    {e.dataTransfer.setData(
                                                        "GROUPID", 
                                                        group.groupId.toString()
                                                    )}
                                                } 
                                            >
                                                <Grid container direction="row" alignItems="center">
                                                    <Grid item>
                                                        < DragIndicatorIcon></ DragIndicatorIcon>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography 
                                                            variant="body2" 
                                                            sx={{width:'100%'}} 
                                                            textAlign={"start"}
                                                        >
                                                            {group.groupName}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Button>
                                        </Stack>
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    </Collapse>
                </div>
            </div>   
            
            {openGroupListDialog && 
                <GroupListDialog
                    onOK={handleEditGroupListOK}
                    onCancel={handleEditGroupListCancel}
                    data={groupListSelected}
                />
            }
            {openDeleteDialog && 
                <AlertDialog
                    title={"機器グループの削除"}
                    message={`グループ「${groupListSelected?.groupName}」を削除しますか?"`}
                    isOpen={true}
                    onOK={handleDeleteOK}
                    onCancel={handleDeleteCancel}
                />
            }
            
            <FetchHelper
                fetchId="EDIT_GROUP_LIST"
                onComplete={(success, data, error) =>
                    success
                        ? handleEditGroupListSuccess(data)
                        : handleError(error)
                }
            />
            <FetchHelper
                fetchId={UPDATE_STD_CAPACITY}
                onComplete={(success, data, error) =>
                success
                    ? handleChangeSuccessStdCapacity(data)
                    : handleError(error)
                }
            
            />
            <FetchHelper
                fetchId={UPDATE_FREQUENCY}
                onComplete={(success, data, error) =>
                success
                    ? handleChangeSuccessFrequency(data)
                    : handleError(error)
                }
            />
             <FetchHelper
                fetchId={OPEN_PROJECT}
                onComplete={(success, data, error) => {
                    success ? handleSuccessOpenProject(data) : handleErrorOpenProject();
                }}
            />   
            {/* <FetchHelper
                fetchId={GET_TEMPLATES}
                onComplete={(success, data, error) =>
                    success
                        ? handleGetTemplatesSuccess(data)
                        : handleError(error)
                }
            /> */}
             {/* <FetchHelper
                fetchId={"GET_LIST_STD_CAPCITY"}
                onComplete={(success, data, error) => {
                    success ? handleSuccessGetListStdCapacity(data) : handleError(error);
                }}
            />    */}
        </div>
    );
}

const mapStateToProps = (state: any) => {
    return {
        userId: state.app.user?.userId,
        processMode: state.app.diagram.processMode,
        groupList: state.app.diagram.groupList,
        infoCircuit: state.app.diagram.infoCircuit,
        projectId: state.app.projectData.projectId,
        projectData: state.app.projectData,
        m_bModePM: state.app.diagram.m_bModePM,
        modeViewOnly: state.app.diagram.modeViewOnly,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        editGroupList: (params: any) =>
            dispatch(
                actionCreators.fetch(
                "EDIT_GROUP_LIST",
                "/grouplist/edit",
                "POST",
                params,
                false,
                true
                )
            ),
        deleteGroupList: (params: any) =>
            dispatch(
                actionCreators.fetch(
                "DELETE_GROUP_LIST",
                "/grouplist/delete",
                "POST",
                params,
                false,
                true
                )
            ),
        saveGroupList: (data: GroupListModel[]) => 
            dispatch(actionCreators.saveGroupList(data)),
        updateStdCapacityDB:(data:any) => dispatch(actionCreators.fetch(UPDATE_STD_CAPACITY,"/diagram/update-std-capacity","POST",data,false,true)),
        updateFrequencyDB:(data:any) => dispatch(actionCreators.fetch(UPDATE_FREQUENCY,"/diagram/update-frequency","POST",data,false,true)),
        updateStdCapacity:(data:any) => dispatch(actionCreators.updateStdCapacity(data)),
        updateFrequency:(data:any) => dispatch(actionCreators.updateFrequency(data)),
        // getListStdCapacity:(data:any) => dispatch(actionCreators.fetch("GET_LIST_STD_CAPCITY","/diagram/get-list-std-capacity","POST",data,false,true))

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DashboardLeftSidebar);