import { Button, Card, CardContent, CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import MSSV3_logo from "../../images/icon/MSSV3_logo.png";
import { Credential } from "../../models/Index";
import { ApplicationState, actionCreators, selectors } from "../../store";
import ToastContainer from "../common/ToastContainer";
import MFADialog from "../dialogs/MFADialog";
import LoginForm from "./LoginForm";
import { post } from "../CallApi";
// -------------
// Props
export type LoginProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

// -------------
// Component
export const Login = (props: LoginProps) => {
  const {
    isAuthorize,
    isNewPasswordRequired,
    isMFARequired,
    isPasswordResetRequired,
    user,
    onSubmit,
    onVerificationCode,
    onCancleVerification,
    onExitResetPassword,
  } = props;

  const [loadingFlag, setLoadingFlag] = useState(false);
  const [openMFADialog, setOpenMFADialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  let navigate = useNavigate();

  useEffect(() => {
    onExitResetPassword();
  }, [])

  useEffect(() => {
    if (isNewPasswordRequired) {
      navigate('/changepass');
      return;
    }
    if (isAuthorize) {
      // add login history
      const params = {
        userId: user?.userId,
        type: "login"
      }
      post("/user/add-operation-history", params)
      
      navigate('/projects');
    }
    if (isMFARequired) {
      setOpenMFADialog(true);
    }
    if (isPasswordResetRequired) {
      navigate('/resetpass');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorize, isNewPasswordRequired, isMFARequired, isPasswordResetRequired])

  const onLogin = (loginData: Credential) => {
    localStorage.setItem("prevLocation", "projects"); //Mock
    onSubmit(loginData);
  };

  const handleSendCodeMFA = (code: string) => {
    if (code.length === 0) {
      setErrorMsg("必須項目です。入力してください。")
    } else {
      setErrorMsg("")
      onVerificationCode(code)
    }
  }

  const handleFogotPasswordClick = () => navigate('/resetpass');

  return (
    <>
      {loadingFlag && (
        <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
          <CircularProgress />
        </div>
      )}
      <MFADialog
        openDialog={openMFADialog}
        errorMsg={errorMsg}
        setOpenDialog={setOpenMFADialog}
        onOK={handleSendCodeMFA}
        onCancle={onCancleVerification}
      />
      <Stack style={{ height: '100vh', background: '#F9FAFB' }} justifyContent={'flex-start'}>
        <Stack justifyContent="center" alignItems="center" mt={2}>
          <img src={MSSV3_logo} alt="MSSV3_logo"></img>
          <Card sx={{ width: 400, boxShadow: 5, marginTop: 3 }}>
            <CardContent>
              <LoginForm handleDataLogin={onLogin} />
              <Stack justifyContent="center" alignItems="center" mt={2}>
                <Button color="inherit" onClick={handleFogotPasswordClick}>パスワードをお忘れの方</Button>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
        <Stack mt={2} alignItems={"center"}>
          <Typography>{process.env.REACT_APP_VERSION}</Typography>
        </Stack>
      </Stack >
      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  isAuthorize: selectors.isAuthorize(state),
  isNewPasswordRequired: selectors.isNewPasswordRequired(state),
  isMFARequired: selectors.isMFARequired(state),
  isPasswordResetRequired: selectors.isPasswordResetRequired(state),
  user: state.app.user,
})

const mapDispatchToProps = {
  onSubmit: (value: Credential) => actionCreators.login(value),
  onVerificationCode: (code: string) => actionCreators.verificationCode(code),
  onCancleVerification: () => actionCreators.cancleVerificationCode(),
  onExitResetPassword: () => actionCreators.exitResetPassword(),
};

export default connect(mapStateToProps, mapDispatchToProps)(Login as any);
