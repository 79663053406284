import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { ApplicationState, AppState } from "../store";
import { ROLE_ADMIN } from "../models/Constants";
import { GROUP_PERMISSION } from "../statics";
import { Path } from "../path";

// -------------
// Props
type PurePrivateRouteProps = { returnElement: React.ReactNode };
type PrivateRouteProps = AppState & PurePrivateRouteProps;
export const PrivateRoute = (props: PrivateRouteProps) => {
    const isAuthorize = (props.user && props.user.cognit_user.challengeName !== 'NEW_PASSWORD_REQUIRED') ? true : false;
    const isNewPasswordRequired = props.user && props.user.cognit_user.challengeName === 'NEW_PASSWORD_REQUIRED';
    if (isAuthorize) {
        let prevLocation = sessionStorage.getItem("prevLocation");
        if (prevLocation && (prevLocation.startsWith("/setting") || prevLocation.startsWith("/users"))) {
            if (props.user?.userRole === ROLE_ADMIN){
                return <>{props.returnElement}</>;
            } else if(props.userGroupPermission?.permission === GROUP_PERMISSION.ADMIN) {
                const allowLocation = [
                    Path.UserGroupList,
                    Path.UserGroupList+"/"+props.userGroupPermission?.groupId,
                    Path.OperationHistoryList
                ]
                if (allowLocation.includes(prevLocation)) {
                    return <>{props.returnElement}</>;
                }
            }
            sessionStorage.removeItem("prevLocation");
            return <Navigate to={"/"} replace={true}/>
        }
        return <>{props.returnElement}</>
    }
    else if (isNewPasswordRequired) {
        return <Navigate to={"/changepass"} replace={true} />
    }
    return (
        <Navigate to={"/login"} replace={true} />
    );
};

export default connect((state: ApplicationState) => state.app)(PrivateRoute);