import { POINTER_HEIGHT } from "../models/Constants";
import * as EKind from "../models/ElementKind";
import { OptionItem } from "../models/Index";

export const PROJECT_DISPLAYED = {
    MY_PROJECT: 1,
    SHARED_PROJECT: 2,
    GROUP_PROJECT: 3
}

export const GROUP_PERMISSION = {
    ADMIN: "admin",
    EDIT: "edit",
    VIEW: "view",
    STANDARD: "standard"
}

export const DATA_STATUS = {
    ORIGINAL: "original",
    ADD: "add",
    UPDATE: "update",
    DELETE: "delete"
}

export const NEW_ID_NOT_DEFINED = -1

export const userGroupRoleOptions: OptionItem[] = [
    {
        label: "管理者",
        value: GROUP_PERMISSION.ADMIN,
        attribute:""
    } as OptionItem,
    {
        label: "編集",
        value: GROUP_PERMISSION.EDIT,
        attribute:""
    } as OptionItem,
    {
        label: "参照",
        value: GROUP_PERMISSION.VIEW,
        attribute:""
    } as OptionItem,
    {
        label: "標準",
        value: GROUP_PERMISSION.STANDARD,
        attribute:""
    } as OptionItem,
];

export const operationHistoryMap: any = {
    login: "ログイン",
    logout: "ログアウト",
    add_project: "プロジェクト追加",
    import_project: "プロジェクトインポート",
    export_project: "プロジェクトエクスポート",
    copy_project: "プロジェクトをコピーして作成",
    delete_project: "プロジェクト削除",
    add_maintenance_data: "メンテナンスデータ追加",
    delete_maintenance_data: "メンテナンスデータ削除",
    display_maintenance_data: "メンテナンスデータ表示",
    change_project_name: "プロジェクト名の変更",
    share_project: "プロジェクトの共有",
    unshare_project: "プロジェクトの共有解除",
    add_project_sharer: "プロジェクトの共有先追加",
    change_project_sharer: "プロジェクトの共有先変更",
    delete_project_sharer: "プロジェクトの共有先削除",
    lock_share_project: "編集ロック取得",
    unlock_share_project: "編集ロック解除",
    add_edit_history: "編集履歴追加",
    edit_edit_history: "編集履歴編集",
    delete_edit_history: "編集履歴削除",
    read_notification: "お知らせ開封",
    delete_notification: "お知らせ削除",
    edit_single_line_diagram: "単線結線図の編集表示",
    mode_check_circuit: "回路チェック実施",
    mode_percent_z: "インピーダンスマップ実施",
    mode_caculation: "短絡電流実施",
    mode_volt_drop: "電圧降下実施",
    mode_improve_pf: "力率改善実施",
    mode_harmonic: "高調波実施",
    mode_power_flow: "潮流解析実施",
    create_graph: "保護協調図作成",
    delete_graph: "保護協調図削除"
}
export const ELEMENT_KIND_WITH_SET_SAME_VOLT = [
    EKind.MS_SOURCE,
    EKind.MS_GENERATOR,
    EKind.MS_TRANS1,
    EKind.MS_TRANS3,
    EKind.MS_TRANSSCOTT,
    EKind.MS_3WINDING
]

export const loginHistoryOptions: OptionItem[] = [
    {
        label: "ログイン",
        value: "login",
        attribute:""
    } as OptionItem,
    {
        label: "ログアウト",
        value: "logout",
        attribute:""
    } as OptionItem
];

// -------------- BreakOpeKind
// 権限
export const roleOptions: OptionItem[] = [
    {
        label: "システム管理者",
        value: "admin",
        attribute:""
    } as OptionItem,
    {
        label: "お客様",
        value: "customer",
        attribute:""
    } as OptionItem,
    {
        label: "体験版",
        value: "trial",
        attribute:""
    } as OptionItem,
    {
        label: "自社ユーザー",
        value: "user",
        attribute:""
    } as OptionItem,
];

export const packageOptions:OptionItem[] = [
    {
        label: "Light Pack",
        value: "lp",
    } as OptionItem,
    {
        label: "Service Pack",
        value: "sp",
    } as OptionItem,
    {
        label: "PM Pack",
        value: "pp",
    } as OptionItem,
]

export const accountStatusOptions:OptionItem[] = [
    {
        label: "有効",
        value: "enable",
    } as OptionItem,
    {
        label: "無効",
        value: "disable",
    } as OptionItem,
]

export const ratedFlagOptions = [
    {
        label: "未使用",
        value: 1,
    },
    {
        label: "固定可変",
        value: 2,
    },
    {
        label: "連続可変",
        value: 3,
    },
    {
        label: "固定連続混合",
        value: 4,
    }
]

export const magniFlagOptions = [
    {
        label: "未使用",
        value: 1,
    },
    {
        label: "固定可変",
        value: 2,
    },
    {
        label: "連続可変",
        value: 3,
    }
]

// -------------- CurveStandard
export const lineKindOptions = [
    {
        label: "実線",
        value: 1,
    },
    {
        label: "破線",
        value: 2,
    },
]

// -------------- CurveHeader
export const curveKindOptions = [
    {
        label: "最大値",
        value: 1,
    },
    {
        label: "中心値",
        value: 2,
    },
    {
        label: "最小值",
        value: 3,
    },
]

export const settingFlagOptions = [
    {
        label: "倍数値で設定",
        value: 1,
    },
    {
        label: "電流値で設定",
        value: 2,
    },
]

export const graphFlagOptions = [
    {
        label: "両対数グラフ",
        value: 1,
    },
    {
        label: "電流軸対数グラフ",
        value: 2,
    },
    {
        label: "時間軸対数グラフ",
        value: 3,
    },
    {
        label: "正グラフ",
        value: 4,
    },
]

// Home - Scale
export const scaleOptions = [
    {
        label: "200%",
        value: 2,
    },
    {
        label: "175%",
        value: 1.75,
    },
    {
        label: "150%",
        value: 1.5,
    },
    {
        label: "140%",
        value: 1.4,
    },
    {
        label: "130%",
        value: 1.3,
    },
    {
        label: "120%",
        value: 1.2,
    },
    {
        label: "110%",
        value: 1.1,
    },
    {
        label: "100%",
        value: 1,
    },
    {
        label: "90%",
        value: 0.9,
    },
    {
        label: "80%",
        value: 0.8,
    },
    {
        label: "70%",
        value: 0.7,
    },
    {
        label: "60%",
        value: 0.6,
    },
    {
        label: "50%",
        value: 0.5,
    },
    {
        label: "25%",
        value: 0.25,
    },
]

// -------------- TransCenterInfo
export const infoLeftOptions = [
    {
        label: '定格容量の合計(kW)',
        value: 1,
    },
    {
        label: '負荷力率(遅れ)',
        value: 2,
    },
    {
        label: '負荷電流(A)',
        value: 3,
    },
    {
        label: '最大需要電力(kW)',
        value: 4,
    },
    {
        label: '需要率',
        value: 5,
    },
    {
        label: '期間中の平均電力(W)',
        value: 6,
    },
    {
        label: '期間中の最大電力(kW) ',
        value: 7,
    },
    {
        label: '負荷率',
        value: 8,
    }
]

export const infoRightOptions = [
    {
        label: '上位機器名称',
        value: 9,
    },
    {
        label: '電源電圧(V)',
        value: 10,
    },
    {
        label: 'X/R',
        value: 11,
    },
    {
        label: '電源％インピーダンス(%)',
        value: 12,
    },
    {
        label: '電源インピーダンス (mΩ)',
        value: 13,
    },
    {
        label: 'I"k3peak(kA)',
        value: 14,
    },
    {
        label: 'I"k3asym(kA)',
        value: 15,
    },
    {
        label: 'I"k3sym(kA)',
        value: 16,
    }
]

// -------------- Busduct
export const nMaterialOption = [
    {
        label: "Cu",
        value: 1,
    },
    {
        label: "Al",
        value: 2,
    },
]

// -------------- nInsulation
export const nInsulationOption = [
    {
        label: "PVC",
        value: 1,
    },
    {
        label: "XLPE",
        value: 2,
    },
]

// -------------- nMethod
export const nMethodOption = [
    {
        label: "1",
        value: 1,
    },
    {
        label: "2",
        value: 2,
    },
    {
        label: "3",
        value: 3,
    },
    {
        label: "4",
        value: 4,
    },
    {
        label: "E方式",
        value: 5,
    },
    {
        label: "F方式",
        value: 6,
    },
    {
        label: "G方式",
        value: 7,
    },
]

export const DEFAULT_INTO_POINT_POSITION = {
    x: 340, // by default, trans center has 35 cells => 35*20 / 2 - 10
    y: -POINTER_HEIGHT
}

export const elementNotInTransCenter = [
    EKind.MS_SOURCE,
    EKind.MS_GENERATOR,
    EKind.MS_MOTOR_GROUP,
    EKind.MS_TRANSCENTER,
    EKind.MS_VCT,
    EKind.MS_ARRESTOR,
    EKind.MS_EARTH
]

// CONSTANT
export const VOLT_SIDE_SPECIAL_HIGH_MIN = 7000
export const VOLT_SIDE_LOW_MAX = 600
export const MS_XR_MAX = 99999
export const SOURCE_SYMBOL_INFINITE = 1
export const VALUE_NODE_VOLT_MIN = 0.00001
/**
 * 基準容量の取得方法は要調査のため、暫定で10000固定で計算してください
 */
export let STANDARD_CAPACITY = 10000
export const MAKE_YEAR_MAX = 2024
export const MAKE_YEAR_MIN = 1950

// 操作モード 
export const MS_MODE_DRAWING = 1
export const MS_MODE_CHECKING = 2
export const MS_MODE_PERCENT_Z = 3
export const MS_MODE_CALCULATION = 4
export const MS_MODE_VOLTDROP = 5
export const MS_MODE_IMPROVE_PF = 6
export const MS_MODE_HARMONIC = 7
export const MS_MODE_POWER_FLOW = 8

// 運転モード
export const FLOW_NODE_KIND_SLACK = 0;
export const FLOW_NODE_KIND_PQ = 1;
export const FLOW_NODE_KIND_PV = 2;

// 電圧(線間種別と一緒に使う場合があるので10倍にした)
export const VOLT_SIDE_PRIMARY = 10     // 一決側
export const VOLT_SIDE_SECONDARY = 20   // 二決側
export const VOLT_SIDE_THIRD = 30       // 三次側

// 
export const MS_3PHASES = 0
export const MS_OUTSIDE_NEUTRAL = 1;
export const MS_OUTSIDE_OUTSIDE = 2;
export const MS_SEAT_MAIN = 3;
export const MS_SEAT_T = 4;

// WIRE
export const WIRE_CU = 1;
export const WIRE_AL = 2;

export const WIRE_PVC = 1;
export const WIRE_XLPE = 2;

export const WIRE_SINGLE =1;
export const WIRE_MULTI = 2;

// 設置方法
export const WIRE_METHOD_A = 1;
export const WIRE_METHOD_B = 2;
export const WIRE_METHOD_C = 3;
export const WIRE_METHOD_D = 4;
export const WIRE_METHOD_E = 5;
export const WIRE_METHOD_F = 6;
export const WIRE_METHOD_G = 7;

//最大容精抵抗率
export const WIRE_RESISTIVITY_CU = 17.241; //銅(Ω ·mm2)
export const WIRE_RESISTIVITY_AL = 28.264; // 1F儿三 (Ω ·mm2)

export const WIRE_TEMP_COEFFICIENT_CU = 0.00393;
export const WIRE_TEMP_COEFFICIENT_AL = 0.00403;

export const WIRE_SYMBOL_NORMAL = 0;
export const WIRE_SYMBOL_CABLE = 1;

export const RS_3PHASES = 1;
export const RS_PHASE_N = 2;
export const RS_PHASE_PE = 3;

export const MS_COMPLEX_VOLTDROP = -99999;

export const elementTypeENtoJP = {
    MS_GENERATOR: "発電機",
    MS_TRANS3: "三相変圧器",
    MS_SOURCE: "電源",
    MS_3WINDING: "三巻線変圧器",
    MS_TRANS1: "単相変圧器",
    MS_TRANSSCOTT: "スコット結線変圧器",
    MS_DS: "断路器",
    MS_LBS: "開閉器",
    MS_MVCB: "遮断器",
    MS_CONTACTOR: "電磁接触器",
    MS_HVCB: "過電流継電器",
    MS_FUSE: "高圧ヒューズ",
    MS_2E: "電動機保護リレー",
    MS_ZEROCOND: "母線",
    MS_CONNECT: "接続線",
    MS_WIRE: "電線",
    MS_BUSBAR: "ブスバー",
    MS_BUSDUCT: "バスダクト",
    MS_REACTOR: "限流リアクトル",
    MS_IMPEDANCE: "インピーダンス",
    MS_LVCB: "低圧遮断器",
    MS_THERMAL: "サーマルリレー",
    MS_THERMAL_CT: "過負荷継電器",
    MS_MOTOR_GROUP: "電動機群",
    MS_MOTOR: "電動機",
    MS_LOAD: "負荷",
    MS_LIGHTBOARD: "分電盤",
    MS_POWERBOARD: "動力盤",
    MS_CAPACITOR: "コンデンサ",
    MS_TRANSCENTER: "変台",
    MS_VCT: "計器用変成器",
    MS_VT: "計器用変圧器",
    MS_ARRESTOR: "避雷器",
    MS_EARTH: "接地",
    MS_CABLEHEAD: "ケーブルヘッド",
    MS_CT: "変流器",
    MS_ZCT: "零相変流器",
    MS_INV: "インバーター",
    MS_RELAY: "リレー",
    MS_INTO_POINT: "引込口"
}

export const reportIndexToName = {
    "0": "機器情報",
    "1": "基準計算",
    "2": "インピーダンス解析結果",
    "3": "短絡電流解析結果(簡略)",
    "4": "力率改善解析結果",
    "5": "高調波解析結果",
    "6": "潮流解析結果",
    "7": "表紙",
    "8": "プロジェクト概要",
    "9": "施工概要",
    "10": "電圧降下解析結果",
    "11": "短絡電流解析結果(詳細)",
    "12": "",
    "13": "",
    "14": "",
    "15": "",
    "16": "整定情報一覧表",
    "17": "電圧降下合算結果",
    "18": "メモ編集"
}

export const numberExponent = [
    {
        label: '0',
        value: "⁰",
    },
    {
        label: '1',
        value: "¹",
    },
    {
        label: '2',
        value: "²",
    },
    {
        label: '3',
        value: "³",
    },
    {
        label: '4',
        value: "⁴",
    },
    {
        label: '5',
        value: "⁵",
    },
    {
        label: '6',
        value: "⁶",
    },
    {
        label: '7',
        value: "⁷",
    },
    {
        label: '8',
        value: "⁸",
    },
    {
        label: '9',
        value: "⁹",
    },
]

// 潮流計算方法
export const FLOW_METHOD_NEWTON = 0;
export const FLOW_METHOD_GAUSS = 1;
export const FLOW_METHOD_DECOUPLED = 2;

// 潮流流れの表示方法
export const FLOW_VIEW_EVERY_ELEMENT = 1;
export const FLOW_VIEW_ZEROCOND_ONLY = 2;
