import { Button, Dialog, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import theme from "../../themes/globalStyles";
import DraggablePaper from "../common/DraggablePaper";

type UserGroupForm = {
    userGroupName: string,
    notes: string,
}

const initFormValue: UserGroupForm = {
    userGroupName: "",
    notes: "",
}

type UserGroupProps = {
    data: UserGroupForm|null;
    isAdd: boolean;
    onOK: (data: any) => void;
    onCancel: () => void;
};

export const UserGroupDialog = (props: UserGroupProps) => {
    //#region fields
    const { data, isAdd, onOK, onCancel } = props;

    // 初期値設定
    const initialValue: UserGroupForm = isAdd ? {...initFormValue} : {...data!}

    const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<UserGroupForm>({
        mode: "all",
        criteriaMode: "all",
        defaultValues: initialValue,
    })
    //#endregion fields

    //#region methods
    // Submitイベント
    const handleEditSubmit = (value: any, event: any) => {
        const request = createRequestData(value);
        onOK(request);
    }
    
    // FormデータからRequestデータを作成する
    const createRequestData = (formValue : UserGroupForm) => {
        return { ...formValue };
    }
    //#endregion methods

    return (
        <>
            <Dialog open={true} maxWidth={"sm"} fullWidth PaperComponent={DraggablePaper}>
                <DialogTitle 
                    onMouseOver={(e : any) => e.target.style.cursor = 'move'}
                    style={{ 
                        paddingTop: '2px',
                        paddingBottom: '2px',
                        background: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText
                    }}
                >
                    {isAdd?"グループ追加":"グループ編集"}
                </DialogTitle>
                <DialogContent style={{paddingTop: '10px', display: 'flex', alignItems: 'flex-end'}}>
                    <div style={{width: '100%'}}>
                        <form onSubmit={handleSubmit(handleEditSubmit)}>
                            <Stack mt={1}>
                                <Controller
                                    name="userGroupName"
                                    control={control}
                                    rules={{
                                        required: '必須項目です。入力してください。',
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="text"
                                            size="small"
                                            label={"グループ名"}
                                            InputLabelProps={{style: { color: 'black' }}}
                                            error={!!errors?.userGroupName}
                                            helperText={errors?.userGroupName?.message}
                                        />
                                    )}
                                />
                            </Stack>
                            <Stack mt={1.5}>
                                <Controller
                                    name="notes"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="text"
                                            size="small"
                                            label={"備考"}
                                            InputLabelProps={{style: { color: 'black' }}}
                                        />
                                    )}
                                />
                            </Stack>
                            <Stack direction='row' justifyContent="flex-end" gap={1} mt={2}>
                                <Button variant="contained" type="submit">{isAdd ? '追加' : '保存'}</Button>
                                <Button color="inherit" variant="contained" onClick={onCancel}>キャンセル</Button>
                            </Stack>
                        </form>
                    </div>                    
                </DialogContent>
            </Dialog>
        </>
    )
}
export default UserGroupDialog;